import type React from "react";
import { useState } from "react";
import { Button } from "./shadcn/ui/button";
import Papa from "papaparse";
import { useApi } from "@/interfaces/api";
import { API, URLS } from "@/constant";
import type {
    CreateHistoryPayload,
    CreateTicketFromUIPayload,
    CreateTicketFromUIResponse,
} from "@/interfaces/serverData";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Dialog, DialogClose, DialogContent } from "./shadcn/ui/dialog";
import { Input } from "./shadcn/ui/input";

interface FileUploadForCreateTicketProps {
    userID: string;
    dialogIsOpen: boolean;
    setDialogIsOpen: (value: React.SetStateAction<boolean>) => void;
}

// Add this component with <FileUploadForCreateTicket /> whenever we need to ingest a CSV file and create tickets from the inputs
const FileUploadForCreateTicket: React.FC<FileUploadForCreateTicketProps> = ({
    userID,
    dialogIsOpen,
    setDialogIsOpen,
}) => {
    const [file, setFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const api = useApi();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.[0]) {
            setFile(event.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            alert("Please select a CSV file.");
            return;
        }

        setIsLoading(true);
        setError(null);
        setSuccessMessage(null);

        try {
            const csvData = await parseCSV(file);
            await UploadTickets(csvData);
        } catch (err) {
            setError("An error occurred while uploading tickets.");
        } finally {
            setIsLoading(false);
        }
    };

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const parseCSV = (file: File): Promise<any[]> => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => resolve(results.data),
                error: (error) => reject(error),
            });
        });
    };

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const UploadTickets = async (data: any[]) => {
        let index = 0;
        let firstIssue = "";
        let lastIssue = "";
        for (const row of data) {
            index += 1;
            try {
                console.log("processing row ", index);
                const prompt = row.QUERY;
                if (prompt == null) {
                    setError("There is no QUERY column in the CSV");
                    break;
                }
                const payload: CreateTicketFromUIPayload = {
                    title: "",
                    message: prompt,
                    user: userID,
                    status: "Open",
                    assignee_user_id: "",
                    tag: "",
                    topic: ["AI_MATCH_TOPIC"],
                };
                const response = await api.post(
                    URLS.serverUrl + API.createTicketFromUI,
                    payload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                if (response.status === 200) {
                    const res: CreateTicketFromUIResponse = response.data.data;
                    if (index === 1) {
                        firstIssue = res.org_specific_id;
                    }
                    lastIssue = res.org_specific_id;

                    const historyPayload: CreateHistoryPayload = {
                        id: res.id,
                        parent_id: res.id,
                        history_type: "Message",
                        content: prompt,
                        source: "Web",
                        unique_name: "Standard",
                        user: userID,
                        metadata: "",
                        source_specific_id: res.org_specific_id,
                        reactions: [],
                        files: [],
                    };

                    const historyResponse = await api.post(
                        URLS.serverUrl + API.createHistory,
                        historyPayload,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    );
                    if (historyResponse.status === 200) {
                        console.log(
                            `created ticket ${res.org_specific_id} successfully`,
                        );
                        setSuccessMessage(
                            `Added ${res.org_specific_id} successfully, creating next ticket...`,
                        );
                    } else {
                        console.log(
                            `Failed to create ticket: ${response.status}`,
                        );
                        setError("Failed to Create Ticket");
                    }
                } else {
                    console.log(`Failed to create ticket: ${response.status}`);
                    setError("Failed to Create Ticket");
                }
            } catch (error) {
                console.error("Error creating ticket:", error);
                setError("Failed to Create Ticket");
            }
        }
        setSuccessMessage(
            `Created all ${index} issues successfully (${firstIssue} - ${lastIssue})`,
        );
    };

    return (
        <Dialog open={dialogIsOpen} onOpenChange={setDialogIsOpen}>
            {/* Don't need to show the trigger since it's apart of the dropdown
            <DialogTrigger asChild>
                <Button
                    className="shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                    size="sm"
                    variant="outline"
                >
                    Bulk Upload Issues
                </Button>
            </DialogTrigger> */}
            <DialogContent className="inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50 max-h-full overflow-auto">
                <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                    <DialogClose asChild>
                        <Button
                            type="button"
                            variant="ghost"
                            className="absolute top-4 right-4 hover:bg-muted"
                        >
                            <Cross2Icon />
                        </Button>
                    </DialogClose>
                    <div className="px-4 py-2 flex flex-col gap-2">
                        <div className="text-lg font-semibold">
                            Bulk Upload Issues
                        </div>
                        <div className="text-sm text-muted-foreground">
                            Upload a CSV file with a "QUERY" column which
                            includes the text that will be ingested into
                            Assembly Issues. Each row will create one issue with
                            source Web.
                        </div>
                        <div className="text-sm text-muted-foreground italic">
                            Issue creation can take a while, please don't close
                            the screen until the success message pops up.
                        </div>
                        <div className="flex items-start justify-between mt-2">
                            <Input
                                type="file"
                                onChange={handleFileChange}
                                accept=".csv"
                                className="-ml-1 max-w-[350px] rounded-md border border-input bg-transparent px-2 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
                            />
                            <div className="flex flex-col items-end gap-1.5">
                                <Button
                                    onClick={handleUpload}
                                    disabled={isLoading}
                                    className="bg-[#5B5BD6] text-white py-2 px-3.5 hover:shadow-lg shadow-md outline outline-1 outline-offset-0 outline-white flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted"
                                    variant="outline"
                                >
                                    {isLoading ? "Uploading..." : "Submit"}
                                </Button>
                                {error && (
                                    <div className="text-destructive text-sm">
                                        {error}
                                    </div>
                                )}
                                {successMessage && (
                                    <div className="text-[#5B5BD6] text-sm italic">
                                        {successMessage}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default FileUploadForCreateTicket;
