import { createElement, useRef } from "react";

import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import { FancyMultiSelect } from "@/component/MultiSelect";
import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Integration, ScopeResponse } from "@/interfaces/serverData";
import {
    CheckCircledIcon,
    CrossCircledIcon,
    InfoCircledIcon,
    PlusIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Badge, ChevronDownIcon } from "@radix-ui/themes";
import type React from "react";
import { useState } from "react";
import { integrationBackEndDataMappingToSvg } from "../Admin/Integrations/constant";

import type { TeamCardUI } from "./TeamSettings";

interface TeamCardProps {
    teamId: string;
    teamCardId: string;
    isEditing: boolean;
    removeTeamCard: (teamId: string) => void;
    setToastText: React.Dispatch<React.SetStateAction<string>>;
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType>>;
    setToastOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setTeamCards: React.Dispatch<React.SetStateAction<TeamCardUI[]>>;
}

const NewTeamCard: React.FC<TeamCardProps> = ({
    teamId,
    teamCardId,
    isEditing,
    removeTeamCard,
    setToastText,
    setToastSymbol,
    setToastOpen,
    setTeamCards,
}) => {
    const [selectedSource, setSelectedSource] = useState<string>("");
    const [icon, setIcon] = useState<React.ElementType | undefined>(undefined);

    const [showSelectedChannels, setShowSelectedChannels] =
        useState<boolean>(false);
    const [selectedChannels, setSelectedChannels] = useState<ScopeResponse[]>(
        [],
    );

    const api = useApi();
    const [options, setOptions] = useState<ScopeResponse[]>([]);

    const handleSourceChange = (newSource: string) => {
        setSelectedChannels([]);
        setShowSelectedChannels(false);
        setSelectedSource(newSource);
        setIcon(integrationBackEndDataMappingToSvg.get(newSource));

        switch (newSource) {
            case "Slack":
            case "Discord":
            case "CommunitySlack":
            case "GitHubTicket": {
                api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/${newSource}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        const dataItems: ScopeResponse[] =
                            res.data.data?.asm_ticket_channels;
                        setOptions(dataItems);
                    })
                    .catch((res) => { });
                break
            }
            case "Gmail": {
                api.get(`${URLS.serverUrl}${API.getUniqueIntegrations}/Google`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            const integrationsResponse: Integration[] =
                                res.data.data;
                            const dataItems: ScopeResponse[] = [];
                            for (const integration of integrationsResponse) {
                                const scope: ScopeResponse = {
                                    key: integration.id,
                                    name: integration.unique_name
                                };
                                dataItems.push(scope);
                            }
                            setOptions(dataItems)
                        }
                    })
                    .catch((res) => {
                        console.error("Error fetching scope data:", res);
                    });
            }
        }
    };


    const timerRef = useRef(0);
    const handleAddSource = () => {
        const requestData = {
            source: selectedSource,
            metadata: selectedChannels,
        };
        const [url] = TeamsAPI.addTeamSource;
        api.put(`${URLS.serverUrl}${url}/${teamId}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    setToastText("Error adding source");
                    setToastSymbol(CrossCircledIcon);
                } else {
                    removeTeamCard(teamCardId);
                    setTeamCards((prev: TeamCardUI[]) => [
                        ...prev,
                        {
                            id: res.data.data.id,
                            isEditing: false,
                            card: res.data.data,
                        },
                    ]);
                    setToastText("Source added successfully");
                    setToastSymbol(CheckCircledIcon);
                }
            })
            .catch((res) => {
                console.error("Error fetching scope data:", res);
                setToastText("Error adding source");
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setToastOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setToastOpen(true);
                }, 100);
            });
    };

    return (
        <Card className="shadow-none p-3">
            <div className="flex flex-col h-full">
                <div className="flex gap-3 justify-between flex-col">
                    <div className="flex flex-row justify-between items-start w-full">
                        <div className="flex flex-col items-start gap-2 py-2 px-2">
                            <p className="text-sm font-medium">
                                Select Account
                            </p>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Badge
                                        color="gray"
                                        size="2"
                                        radius="full"
                                        variant="outline"
                                        className="px-2 py-1 text-xs rounded-xl flex items-center justify-between min-w-[100px]"
                                    >
                                        <div className="flex items-center">
                                            {icon !== undefined &&
                                                createElement(icon, {
                                                    className: "w-4 h-4",
                                                })}
                                            <p className="pl-1 pr-1 overflow-hidden text-ellipsis whitespace-nowrap">
                                                {selectedSource !== ""
                                                    ? selectedSource
                                                    : "Source"}
                                            </p>
                                        </div>
                                        <ChevronDownIcon className="ml-auto" />
                                    </Badge>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    align="start"
                                    className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                >
                                    <FilterDropdownElement
                                        type="Source"
                                        filters={new Map<string, Set<string>>()}
                                        handleItemSelect={(
                                            type: string,
                                            value: string
                                        ) => () => {
                                            if (type === "Source") {
                                                handleSourceChange(value);
                                            }
                                        }}
                                        topics={[]}
                                        users={[]}
                                        extraOptions={[]}
                                        customerGroups={[]}
                                        isSavedViewFilter={false}
                                        categories={[]}
                                        teams={[]}
                                        channels={new Map()} />
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                        <div className="flex items-start">
                            {selectedSource !== "" &&
                                selectedSource !== "Google" &&
                                selectedSource !== "ChatWidget" &&
                                selectedSource !== "Intercom" &&
                                selectedSource !== "Web" && (
                                    <Button
                                        className="text-xs outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                        size="sm"
                                        variant="outline"
                                        onClick={() => {
                                            setShowSelectedChannels(true);
                                        }}
                                        type="button"
                                    >
                                        add accounts
                                        <PlusIcon />
                                    </Button>
                                )}
                        </div>
                    </div>
                    {showSelectedChannels && (
                        <Card className="shadow-none mx-8">
                            <CardContent className="py-4 px-4 flex flex-row gap-5 justify-between items-center">
                                <div className="flex flex-col gap-2 grow">
                                    <div className="flex flex-row gap-1">
                                        <p className="text-xs font-normal">
                                            Select accounts
                                        </p>
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <InfoCircledIcon />
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    <p>
                                                        If you don't see your
                                                        channel here, make sure
                                                        to check if it's been
                                                        selected under Manage
                                                        Integrations &gt;
                                                        Customer Channels for
                                                        this source.
                                                    </p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                    <FancyMultiSelect
                                        setSelectedChannels={
                                            setSelectedChannels
                                        }
                                        selectedChannels={selectedChannels}
                                        options={options}
                                        placeholder="accounts..."
                                    />
                                </div>
                                <Button
                                    type="button"
                                    variant="outline"
                                    size="sm"
                                    className="flex-none"
                                    onClick={() => {
                                        setSelectedChannels([]);
                                        setShowSelectedChannels(false);
                                    }}
                                >
                                    <TrashIcon />
                                </Button>
                            </CardContent>
                        </Card>
                    )}
                    <div className="mt-auto flex justify-end">
                        <Button
                            className="text-xs outline outline-1 outline-slate-200 flex flex-wrap gap-2 data-[state=open]:bg-muted shadow-sm iris6 mr-3"
                            size="sm"
                            variant="outline"
                            type="button"
                            onClick={() => {
                                removeTeamCard(teamCardId);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="text-xs outline outline-1 outline-slate-200 flex flex-wrap gap-2 data-[state=open]:bg-muted shadow-sm iris6"
                            size="sm"
                            variant="outline"
                            type="button"
                            onClick={() => {
                                handleAddSource();
                            }}
                        >
                            Add
                        </Button>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default NewTeamCard;
