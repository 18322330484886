import { TeamsDropdown } from "@/Ticket/TeamsDropdown";
import { Badge } from "@/component/shadcn/ui/badge";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { Input } from "@/component/shadcn/ui/input";
import { Textarea } from "@/component/shadcn/ui/textarea";
import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { GradientPicker } from "@/design/GradientPicker";
import { useApi } from "@/interfaces/api";
import {
    type EditTopicPayload,
    type GetTopicsPrefResponse,
    type GetTopicsResponse,
    type SaveTopicPayload,
    type SaveTopicPrefsPayload,
    type Teams,
    type TopicPrefSettings,
    teamSchema,
} from "@/interfaces/serverData";
import { toggleTeamSelection } from "@/utilities/methods";
import { zodResolver } from "@hookform/resolvers/zod";
import { CaretRightIcon, PlusIcon } from "@radix-ui/react-icons";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Box, Button as RButton, Callout, Skeleton, Switch, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { HotelIcon, UsersIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { generateTagsColumns } from "./DataTable/columns";
import { type TagsRowState, workspace } from "./DataTable/constants";
import { TagsDataTable } from "./DataTable/tags-data-table";
import { TeamBadges } from "./TeamBadges";

const formSchema = z.object({
    tagName: z
        .string()
        .min(2, { message: "The tag name must be at least 2 characters." })
        .max(70, { message: "The tag name must be at most 70 characters." }),
    tagDescription: z.string().max(200, {
        message: "The tag name must be at most 200 characters.",
    }),
    color: z.string(),
    tagTeams: z.array(teamSchema).optional(),
});

interface TopicsProps {
    teams: Teams[];
}

export const Topics: React.FC<TopicsProps> = ({ teams }) => {
    const { id } = useParams(); // team ID

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const [aiTagging, setAITagging] = useState<boolean>(true);
    const [addNewAITopics, setAddNewAITopics] = useState<boolean>(true);
    const [inheritedFromOrg, setInheritedFromOrg] = useState<boolean>(true);
    const [currentTeam, setCurrentTeam] = useState<Teams | undefined>();

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredTeams, setFilteredTeams] = useState<Teams[]>(teams);

    useEffect(() => {
        if (searchQuery.trim() === "") {
            // If the search query is empty, reset to the full list
            setFilteredTeams(teams);
        } else {
            // Filter the queries based on the search value
            setFilteredTeams(
                teams.filter((team) =>
                    team.team_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                ),
            );
        }
    }, [teams]);

    useEffect(() => {
        if (id) {
            const team = teams.filter((team) => team.id === id);
            if (team.length === 1) {
                setCurrentTeam(team[0]);
            } else {
                setCurrentTeam(undefined);
            }
        } else {
            setCurrentTeam(undefined);
        }
    }, [id, teams]);

    const handleSearchChange = (event: { target: { value: string } }) => {
        const searchVal = event.target.value.toLowerCase();
        setSearchQuery(searchVal);

        if (searchVal.trim() === "") {
            // If the search query is empty, reset to the full list
            setFilteredTeams(teams);
        } else {
            // Filter the queries based on the search value
            setFilteredTeams(
                teams.filter((team) =>
                    team.team_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                ),
            );
        }
    };

    const api = useApi();
    const defaultBadgeColor = "#9B9EF0";


    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            tagTeams: id && currentTeam ? [currentTeam] : [workspace],
            tagName: "",
            tagDescription: "",
            color: defaultBadgeColor,
        },
    });
    const { control, setValue } = form;

    function onSubmit(values: z.infer<typeof formSchema>) {
        const requestData: SaveTopicPayload = {
            name: values.tagName,
            description: values.tagDescription,
            color: rowState.get("dialog")?.colorSelected ?? defaultBadgeColor,
            type: "User",
            team_ids: id
                ? [id]
                : (values.tagTeams
                    ?.map((team) => team.id)
                    .filter((teamID) => teamID !== "workspace") ?? []),
        };
        if (id) {
            requestData.team_ids = [id];
        }
        api.post(URLS.serverUrl + API.addTopic, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    setIsOpen(false);
                    refetch();
                    form.reset();
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }

    const [rowState, setRowState] = useState<Map<string, TagsRowState>>(
        new Map(),
    );
    const [isOpen, setIsOpen] = useState(false);
    const { data = [], refetch } = useQuery({
        queryKey: id ? [`teamTopics_${id}`] : ["topics"],
        queryFn: id ? () => fetchTeamTopics() : () => fetchTopics(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });
    const { data: topicPrefs, refetch: refetch_prefs } = useQuery({
        queryKey: ["topicsPreferences"],
        queryFn: () => fetchTopicsPref(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });

    useEffect(() => {
        refetch_prefs();
    }, [id]);

    useEffect(() => {
        setAITagging(topicPrefs?.ai_tagging ?? true);
        setAddNewAITopics(topicPrefs?.add_new_ai_topics ?? true);
        setInheritedFromOrg(topicPrefs?.inherited_from_org ?? true);
    }, [topicPrefs]);

    const updateRowState = (id: string, newState: Partial<TagsRowState>) => {
        setRowState((prevState) => {
            const currentState = prevState.get(id) || {
                id: "",
                colorSelected: "",
                tagSelected: "",
                tag_name: "",
                description: "",
                teams: [],
            };
            return new Map(prevState).set(id, {
                ...currentState,
                ...newState,
            });
        });
    };

    const fetchTopics = async (): Promise<GetTopicsResponse[]> => {
        const response = await api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            return response.data.data;
        }
        setLoadingState(2);
        return [];
    };

    const fetchTeamTopics = async (): Promise<GetTopicsResponse[]> => {
        const response = await api.get(
            `${URLS.serverUrl}${API.getTopics}/team/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        setLoadingState(2);
        return [];
    };

    const fetchTopicsPref = async (): Promise<TopicPrefSettings> => {
        let endpoint = URLS.serverUrl + API.getTopicsPref;
        if (id) {
            endpoint = `${URLS.serverUrl}${API.getTopicsPref}/team/${id}`;
        }
        const response = await api.get(endpoint, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            const topicsPref: GetTopicsPrefResponse = response.data.data;
            return {
                ai_tagging: topicsPref.ai_tagging,
                add_new_ai_topics: topicsPref.add_new_ai_topics,
                inherited_from_org:
                    (id !== undefined && topicsPref?.type === "Org") ?? true,
            };
        }
        // Default is true
        return {
            ai_tagging: true,
            add_new_ai_topics: true,
            inherited_from_org: true,
        };
    };

    useEffect(() => {
        if (data) {
            const newRowState = new Map<string, TagsRowState>();
            for (let i = 0; i < data.length; i++) {
                const query: GetTopicsResponse = data[i];
                newRowState.set(String(i), {
                    id: query.id,
                    tagSelected: query.topic_name,
                    colorSelected: query.color ?? defaultBadgeColor,
                    tag_name: query.topic_name,
                    description: query.description ?? "",
                    teams: query.teams,
                });
            }
            newRowState.set("dialog", {
                id: "",
                tagSelected: "",
                colorSelected: defaultBadgeColor,
                tag_name: "",
                description: "",
                teams: [],
            });
            setRowState(newRowState);
            setLoadingState(1);
        }
    }, [data]);

    const saveTag = (id: string, rowStateInfo: Partial<TagsRowState>) => {
        const requestData: EditTopicPayload = {
            id: id,
        };
        if (rowStateInfo.tag_name) {
            requestData.name = rowStateInfo.tag_name;
        }
        if (rowStateInfo.description) {
            requestData.description = rowStateInfo.description;
        }
        if (rowStateInfo.colorSelected) {
            requestData.color = rowStateInfo.colorSelected;
        }
        if (rowStateInfo.teams) {
            requestData.teams = rowStateInfo.teams
                .filter((team) => team.id !== "workspace")
                .map((team) => team.id);
        }
        api.post(URLS.serverUrl + API.editTopic, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const response: GetTopicsResponse = res.data.data;
                    updateRowState(response.id, {
                        colorSelected: response.color,
                        tagSelected: response.topic_name,
                        tag_name: response.topic_name,
                        description: response.description,
                        teams: response.teams,
                    });
                    refetch();
                }
            })
            .catch((res) => {
                console.log("failed to edit tag");
                setLoadingState(2);
            });
    };

    const deleteTag = (id: string) => {
        api.delete(`${URLS.serverUrl}${API.deleteTopic}/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    refetch();
                }
            })
            .catch((res) => {
                console.log("failed to edit tag");
            });
    };

    // TODO: allow going back to the org preferences
    // TODO: add loading state for topics preferences card
    const saveTopicsPref = (
        ai_tagging: boolean,
        add_new_ai_topics: boolean,
        team_id: string | undefined,
    ) => {
        const requestData: SaveTopicPrefsPayload = {
            ai_tagging: ai_tagging,
            add_new_ai_topics: add_new_ai_topics,
        };
        // Add new ai topics can only be on if ai tagging is on
        if (!ai_tagging) {
            requestData.add_new_ai_topics = false;
        }

        // Include the team id if it's not undefined
        if (team_id) {
            requestData.team_id = team_id;
        }

        api.post(URLS.serverUrl + API.saveTopicsPref, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    refetch_prefs();
                    console.log("updated topics preferences successfully");
                } else {
                    console.log("failed to get repository");
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
            });
    };

    const toggleTeam = (team: Teams, tagTeams: Teams[]) => {
        setValue("tagTeams", toggleTeamSelection(team, tagTeams));
    };

    return (
        <div>
            <Box mt={"3%"}>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                        <h2 className="text-1xl font-semibold">Tags</h2>
                    </div>
                    <Dialog open={isOpen} onOpenChange={setIsOpen}>
                        <DialogTrigger asChild>
                            <Button
                                className="shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                size="sm"
                                variant="outline"
                            >
                                Add Tag
                                <PlusIcon />
                            </Button>
                        </DialogTrigger>
                        <DialogContent className="inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50 max-h-full overflow-auto">
                            <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                                <DialogClose asChild>
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        className="absolute top-4 right-4 hover:bg-muted"
                                    >
                                        <Cross2Icon />
                                    </Button>
                                </DialogClose>
                                <div className="mx-8 my-3">
                                    <DialogHeader className="justify-left text-left items-left pb-5">
                                        <DialogTitle>Add Tag</DialogTitle>
                                        <DialogDescription>
                                            Add a new tag with a description.
                                            We'll use this as a way to tag
                                            incoming customer support requests.
                                        </DialogDescription>
                                    </DialogHeader>

                                    <Form {...form}>
                                        <form
                                            onSubmit={form.handleSubmit(
                                                onSubmit,
                                            )}
                                            className="space-y-8"
                                        >
                                            <FormField
                                                control={form.control}
                                                name="tagName"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                            <FormLabel htmlFor="tagName">
                                                                Name
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    id="tagName"
                                                                    className="col-span-3"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            <FormMessage className="text-xs" />
                                                        </div>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="tagDescription"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                            <FormLabel htmlFor="tagDescription">
                                                                Description
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Textarea
                                                                    id="tagDescription"
                                                                    className="col-span-3"
                                                                    placeholder="Please be concise but descriptive, this will passed to the AI to help categorize issues."
                                                                    {...field}
                                                                    rows={2}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="color"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                            <FormLabel>
                                                                Color
                                                            </FormLabel>
                                                            <FormControl>
                                                                <GradientPicker
                                                                    id={
                                                                        "dialog"
                                                                    }
                                                                    background={
                                                                        rowState.get(
                                                                            "dialog",
                                                                        )
                                                                            ?.colorSelected ??
                                                                        defaultBadgeColor
                                                                    }
                                                                    setBackground={
                                                                        updateRowState
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={control}
                                                name="tagTeams"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                            <FormLabel htmlFor="tagDescription">
                                                                Teams
                                                            </FormLabel>
                                                            {id ? (
                                                                <div className="flex flex-col gap-2 col-span-3">
                                                                    <Button
                                                                        variant="ghost"
                                                                        className="flex items-center gap-2 justify-start p-0 w-full data-[state=open]:bg-muted overflow-hidden"
                                                                        type="button"
                                                                        disabled={
                                                                            true
                                                                        }
                                                                    >
                                                                        <TeamBadges
                                                                            teams={
                                                                                currentTeam
                                                                                    ? [
                                                                                        currentTeam,
                                                                                    ]
                                                                                    : []
                                                                            }
                                                                        />
                                                                    </Button>
                                                                </div>
                                                            ) : (
                                                                <FormControl>
                                                                    <TeamsDropdown
                                                                        selectedTeams={
                                                                            field?.value ??
                                                                            []
                                                                        }
                                                                        teams={
                                                                            teams
                                                                        }
                                                                        toggleTeam={
                                                                            toggleTeam
                                                                        }
                                                                        defaultIsWorkspace={
                                                                            true
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        </div>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />

                                            <DialogFooter className="justify-end text-end items-end">
                                                <Button
                                                    className="bg-iris9"
                                                    type="submit"
                                                >
                                                    Save
                                                </Button>
                                            </DialogFooter>
                                        </form>
                                    </Form>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
                <Card
                    className={`p-5 my-4 rounded-lg flex flex-col gap-2.5 ${inheritedFromOrg && "bg-muted"}`}
                >
                    {inheritedFromOrg ? (
                        <div className="flex items-center gap-2 -my-0.5 text-[#5B5BD6] text-[9px]">
                            <Badge
                                variant="outline"
                                className="gap-1 py-1 px-1.5 font-normal"
                            >
                                <div className="flex items-center justify-center rounded-lg p-0.5 bg-blue3 border border-blue4 shadow-sm">
                                    <HotelIcon
                                        className="text-blue9"
                                        strokeWidth={1.5}
                                        size={9}
                                    />
                                </div>
                                Workspace
                            </Badge>
                            <div className="text-xs flex items-center gap-2 italic">
                                Edit Workspace's Preferences or
                                <RButton
                                    className="text-xs h-6 italic flex items-center gap-0.5 px-2 hover:cursor-pointer"
                                    variant="outline"
                                    onClick={() =>
                                        saveTopicsPref(false, false, id)
                                    }
                                >
                                    <PlusIcon className="w-3 h-3" />
                                    Create Team Specific Preferences
                                </RButton>
                            </div>
                        </div>
                    ) : (
                        <div className="-my-0.5 text-[9px]">
                            {id ? (
                                <Badge
                                    variant="outline"
                                    className="gap-1 py-1 px-1.5 font-normal"
                                >
                                    <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                        <UsersIcon
                                            className="text-red9"
                                            strokeWidth={1.5}
                                            size={9}
                                        />
                                    </div>
                                    Team
                                </Badge>
                            ) : (
                                <Badge
                                    variant="outline"
                                    className="gap-1 py-1 px-1.5 font-normal"
                                >
                                    <div className="flex items-center justify-center rounded-lg p-0.5 bg-blue3 border border-blue4 shadow-sm">
                                        <HotelIcon
                                            className="text-blue9"
                                            strokeWidth={1.5}
                                            size={9}
                                        />
                                    </div>
                                    Workspace
                                </Badge>
                            )}
                        </div>
                    )}
                    <div className="flex items-center gap-3 justify-between">
                        <div className="flex flex-col gap-0.5">
                            <div className="text-[14px] font-semibold">
                                AI Auto-Tagging
                            </div>
                            <div className="text-xs text-gray-500">
                                Automatically categorize and label incoming
                                issues with related tags.
                            </div>
                        </div>
                        {inheritedFromOrg ? (
                            <Switch checked={aiTagging} />
                        ) : (
                            <Switch
                                checked={aiTagging}
                                onCheckedChange={(checked) =>
                                    saveTopicsPref(checked, addNewAITopics, id)
                                }
                            />
                        )}
                    </div>
                    {aiTagging && (
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <CaretRightIcon className="w-6 h-6" />
                                <div className="flex flex-col gap-0.5">
                                    <div className="text-[14px] font-semibold">
                                        Add New AI Tags
                                    </div>
                                    <div className="text-xs text-gray-500">
                                        If the incoming issue does not fit any
                                        existing tags, allow the AI to create
                                        new tags to categorize the issue into.
                                    </div>
                                </div>
                            </div>
                            {inheritedFromOrg ? (
                                <Switch checked={addNewAITopics} />
                            ) : (
                                <Switch
                                    checked={addNewAITopics}
                                    onCheckedChange={(checked) => {
                                        saveTopicsPref(aiTagging, checked, id);
                                    }}
                                />
                            )}
                        </div>
                    )}
                </Card>

                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && (
                    <div className="flex flex-col items-end gap-2">
                        <TagsDataTable
                            data={data}
                            columns={generateTagsColumns(
                                rowState,
                                saveTag,
                                deleteTag,
                                updateRowState,
                                teams,
                                id,
                            )}
                        />
                    </div>
                )}
            </Box>
        </div>
    );
};
