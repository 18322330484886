import { Button } from "@/component/shadcn/ui/button";
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuPortal, ContextMenuTrigger } from "@/component/shadcn/ui/context-menu";
import { API, ContactsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Account,
    GetUserResponse,
    ListAccountsResponse,
} from "@/interfaces/serverData";
import { AccountsDataTable } from "@/pages/WorkspacePreferences/DataTable/accounts-data-table";
import AssemblyToastProvider from "@/reusable_components/actions/ToastProvider";
import {
    CheckCircledIcon,
    CrossCircledIcon,
    InfoCircledIcon,
    OpenInNewWindowIcon,
    Pencil1Icon,
    PlusIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import {
    Badge,
    Box,
    Callout,
    DropdownMenu,
    Flex,
    Heading,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import type { Row } from "@tanstack/react-table";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountPopup from "./AccountPopup";
import { generateCustomerColumms } from "./CustomersTable/columns";

export interface AccountsProps {
    orgID: string;
}

export const AccountsPage = ({ orgID }: AccountsProps) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [loadingState, setLoadingState] = useState<number>(0)

    const fetchAccounts = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListAccountsResponse> => {
        try {
            const { url, method } = ContactsAPI.listAccounts;
            const response = await api.get(
                `${URLS.serverUrl}${url}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 100,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
        useInfiniteQuery({
            queryKey: ["accounts"],
            queryFn: fetchAccounts,
            getNextPageParam: (lastPage) => {
                if (lastPage?.has_next_page) {
                    return lastPage.next_cursor;
                }
                return undefined; // No more pages
            },
            initialPageParam: 0,
            refetchInterval: 30000,
            refetchOnWindowFocus: true,
        });

    // Fetch all the data
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                ) // Filter out null or undefined items in page.data
            : [];

    useEffect(() => {
        if (data) {
            setLoadingState(1);
        }
    }, [data]);

    const api = useApi();

    const updateData = async () => {
        queryClient.refetchQueries({
            queryKey: ["accounts"],
            exact: true,
        });
    };

    const [users, setUsers] = useState<GetUserResponse[]>([]);

    const [toastText, setToastText] = useState<string>("Updated Settings!");

    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    useEffect(() => {
        api.post(URLS.serverUrl + API.getAllUsers, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setUsers(res.data.data);
            } else {
                console.log("Call to grab users failed");
            }
        });
    }, [api]);

    const [rowState, setRowState] = useState<Map<string, Account>>(new Map());

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const handleRowClick = (row: Row<Account>) => {
        navigate(getNavLink(row));
    };

    const getNavLink = (row: Row<Account>) => {
        if (row.original.account_type === "Company") {
            return `/accounts/${row.original.id}`;
        }
        return `/accounts/individual/${row.original.id}`;
    };

    const handleOpenInNewTab = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        row: Row<Account> | undefined,
    ) => {
        event.stopPropagation();
        if (row) {
            window.open(getNavLink(row), "_blank");
        }
    };

    const timerRef = useRef<number>(0);

    const handleDelete = (row: Row<Account> | undefined,) => {
        if (!row?.original.id) {
            return;
        }
        if (row.original.account_type === "Customer") {
            api.delete(`${ContactsAPI.deleteCustomer.url}/${row?.original.id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        updateData();
                        setToastOpen(true);
                        setToastSymbol(CheckCircledIcon);
                        setToastText("Deleted Customer!");
                    } else {
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setToastSymbol(CrossCircledIcon);
                    setToastText("Failed to delete customer");
                })
                .finally(() => {
                    setToastOpen(false);
                    window.clearTimeout(timerRef.current);
                    timerRef.current = window.setTimeout(() => {
                        setToastOpen(true);
                    }, 100);
                });
        } else {
            api.delete(`${ContactsAPI.deleteCompany.url}/${row?.original.id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        updateData();
                        setToastOpen(true);
                        setToastSymbol(CheckCircledIcon);
                        setToastText("Deleted Company!");
                    } else {
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setToastSymbol(CrossCircledIcon);
                    setToastText("Failed to delete company");
                })
                .finally(() => {
                    setToastOpen(false);
                    window.clearTimeout(timerRef.current);
                    timerRef.current = window.setTimeout(() => {
                        setToastOpen(true);
                    }, 100);
                });
        }
    };

    const [contextMenuRow, setContextMenuRow] = useState<Row<Account> | undefined>();

    const handleRowRightClick = (row: Row<Account>) => {
        setContextMenuRow(row);
    };

    return (
        <Flex direction="column" align="center" justify="center">
            <Box mt="7" height="100%" width="98%">

                <div className="flex items-start justify-between">
                    <Flex
                        align="start"
                        direction="column"
                        justify={"start"}
                        style={{ paddingLeft: "20px", paddingBottom: "20px" }}
                    >
                        <Heading size="5" align="left">
                            Accounts
                        </Heading>
                        <div className="flex flex-row gap-2 items-start">
                            <Text mb="12px" size="2">
                                Manage external accounts you stay in touch with
                            </Text>
                            <Badge size="1" color="green">
                                BETA
                            </Badge>
                        </div>
                        <Callout.Root size="1">
                            <Callout.Text className="flex flex-row gap-2 items-center">
                                <InfoCircledIcon />
                                <Text className="text-xs">
                                    This feature currently represents new accounts
                                    added to Assembly after September 25th, 2024.
                                </Text>
                            </Callout.Text>
                        </Callout.Root>
                    </Flex>
                    <div className="flex items-center mr-5">
                        <DropdownMenu.Root>
                            <DropdownMenu.Trigger>
                                <Button
                                    className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-5"
                                    size="sm"
                                    variant="outline"
                                >
                                    Add
                                    <PlusIcon />
                                </Button>
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Content>
                                <AccountPopup
                                    triggerElement={
                                        <DropdownMenu.Item
                                            onSelect={(e) => e.preventDefault()}
                                        >
                                            Company
                                        </DropdownMenu.Item>
                                    }
                                    editing={false}
                                    setToastOpen={setToastOpen}
                                    setToastSymbol={setToastSymbol}
                                    setToastText={setToastText}
                                    type="Company"
                                    updateData={updateData}
                                />
                                <AccountPopup
                                    triggerElement={
                                        <DropdownMenu.Item
                                            onSelect={(e) => e.preventDefault()}
                                        >
                                            Customer
                                        </DropdownMenu.Item>
                                    }
                                    editing={false}
                                    setToastOpen={setToastOpen}
                                    setToastSymbol={setToastSymbol}
                                    setToastText={setToastText}
                                    type="Customer"
                                    updateData={updateData}
                                />
                            </DropdownMenu.Content>
                        </DropdownMenu.Root>
                    </div>
                </div>

                {loadingState === 0 && (
                    <Flex maxWidth="85%" style={{ paddingLeft: "20px" }}>
                        <Text>
                            <Skeleton maxWidth="85%">
                                {[...Array(6)].map((_, index) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Text key={index}>{loremIpsum}</Text>
                                ))}
                            </Skeleton>
                        </Text>
                    </Flex>
                )}
                {loadingState === 2 && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}

                <AssemblyToastProvider
                    open={toastOpen}
                    ToastSymbol={ToastSymbol}
                    toastText={toastText}
                    setOpen={setToastOpen}
                />
                <ContextMenu>
                    <ContextMenuTrigger className={`[data-state='open']`}>
                        {combinedData && (
                            <div className="flex flex-col gap-2 mx-5">
                                <AccountsDataTable<Account, string>
                                    columns={generateCustomerColumms(
                                        rowState,
                                    )}
                                    data={combinedData}
                                    handleRowClick={handleRowClick}
                                    handleRowRightClick={handleRowRightClick}
                                />
                            </div>
                        )}
                    </ContextMenuTrigger>
                    <ContextMenuPortal forceMount>
                        <ContextMenuContent className="w-60">
                            <ContextMenuItem
                                inset
                                className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                onClick={(event) => {
                                    handleOpenInNewTab(event, contextMenuRow);
                                }}
                            >
                                <OpenInNewWindowIcon className="w-3.5 h-3.5" />
                                Open in New Tab
                            </ContextMenuItem>
                            <AccountPopup
                                triggerElement={
                                    <ContextMenuItem
                                        inset
                                        onSelect={(e) => e.preventDefault()}
                                        className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                    >
                                        <Pencil1Icon className="w-3.5 h-3.5" />
                                        Edit
                                    </ContextMenuItem>
                                }
                                editing={true}
                                setToastOpen={setToastOpen}
                                setToastSymbol={setToastSymbol}
                                setToastText={setToastText}
                                type={
                                    contextMenuRow?.original.account_type ===
                                        "Company"
                                        ? "Company"
                                        : "Customer"
                                }
                                editingObject={contextMenuRow?.original}
                                contacts={contextMenuRow?.original.associated_customers}
                                updateData={updateData}
                            />
                            <ContextMenuItem
                                inset
                                className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                onClick={(event) => {
                                    handleDelete(contextMenuRow);
                                }}
                            >
                                <TrashIcon className="w-3.5 h-3.5" />
                                Delete
                            </ContextMenuItem>
                        </ContextMenuContent>
                    </ContextMenuPortal>
                </ContextMenu>
            </Box>
        </Flex>
    );
};
