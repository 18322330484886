import { Input } from "@/component/shadcn/ui/input";

import type { GetUserResponse, Insight, Teams } from "@/interfaces/serverData";
import { arraysAreEqual } from "@/utilities/methods";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { memo, useEffect, useRef, useState } from "react";
import { FilterItems } from "./FilterItems";
import { useQuery } from "@tanstack/react-query";
import { API, URLS } from "@/constant";
import { useAuthInfo } from "@propelauth/react";

interface FilterBarProps {
    insights: Insight[];
    filteredInsights: Insight[];
    setFilteredInsights: React.Dispatch<React.SetStateAction<Insight[]>>;
    teams: Teams[];
    isGeneralTeam: boolean;
}

function FilterBar({
    insights,
    filteredInsights,
    setFilteredInsights,
    teams,
    isGeneralTeam
}: FilterBarProps) {
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);
    const [searchQuery, setSearchQuery] = useState("");
    const [filters, setFilters] = useState<Map<string, Set<string>>>(new Map());

    function shouldInclude(
        type: string,
        values: Set<string>,
        insight: Insight
    ): boolean {
        switch (type) {
            case "Team": {
                // All issues should be included in the general team
                if (values.has("General")) {
                    return true
                }
                for (const team of insight.teams) {
                    if (values.has(team.team_name)) {
                        return true; // At least one team is in the filter set
                    }
                }
                return false;
            }
            case "Insight Status": {
                return !!values.has(insight.status);
            }
            default: {
                return false; // Handle any unmatched case
            }
        }
    }

    useEffect(() => {
        let newFilteredInsights = insights;
        newFilteredInsights = newFilteredInsights.filter(
            (insight) => {
                // Check if insight matches the filters
                let matchesFilters = true;
                const entries = Array.from(filters.entries());
                for (const [type, values] of entries) {
                    matchesFilters =
                        matchesFilters && shouldInclude(type, values, insight);
                }

                // Check if the insight matches the search query
                const matchesSearch =
                    searchQuery.trim() === "" ||
                    (insight.title ?? "")
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    (insight.description ?? "")
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase());

                return matchesFilters && matchesSearch;
            }

        );
        if (!arraysAreEqual(newFilteredInsights, filteredInsights)) {
            setFilteredInsights(newFilteredInsights);
        }
    }, [insights, filteredInsights, setFilteredInsights, searchQuery, filters]);

    const usersQuery = useQuery<GetUserResponse[]>({
        queryKey: ["users"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getAllUsers, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            return data.data;
        },
    });

    return (
        <div className="flex flex-col gap-1 m-1 py-1 bg-muted">
            <div className="flex items-center gap-0 mx-5 mt-0.5">
                <MagnifyingGlassIcon className="w-4 h-4 text-gray-700" />
                <Input
                    type="text"
                    placeholder={"Search..."}
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                    className="w-full p-1.5 rounded-md text-xs border-none shadow-none focus:outline-none focus:border-transparent"
                />
            </div>
            <div className="flex mx-4 mt-0.5">
                <FilterItems
                    filters={filters}
                    setFilters={setFilters}
                    users={usersQuery.data ?? []}
                    teams={teams}
                    isGeneralTeam={isGeneralTeam}
                />
            </div>
        </div>
    );
}

export default memo(FilterBar);
