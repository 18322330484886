import { Button } from "@/component/shadcn/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
} from "@/component/shadcn/ui/dialog";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import { Textarea } from "@/component/shadcn/ui/textarea";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    CreateHistoryPayload,
    CreateTicketFromUIPayload,
    CreateTicketFromUIResponse,
    GetUserResponse,
    Teams,
} from "@/interfaces/serverData";
import { getStatusIcon } from "@/utilities/methods";
import {
    AvatarIcon,
    ComponentBooleanIcon,
    Cross2Icon,
    CrossCircledIcon,
    DotFilledIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import type { QueryClient } from "@tanstack/react-query";
import { useState } from "react";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import FilterDropdownElement from "./FilterDropdownElement";
import { ticketStatuses } from "./constants";

const DropdownTrigger = (
    type: string,
    categories: { color: string; name: string }[],
    filters: Map<string, Set<string>>,
    users: GetUserResponse[],
    topics: { color: string; label: string; value: string }[],
) => {
    switch (type) {
        case "Status": {
            const statusSet = filters.get("Status");
            const currStatus =
                statusSet && statusSet.size === 1
                    ? Array.from(statusSet)[0]
                    : "Unknown";
            const StatusIcon = getStatusIcon(currStatus ?? "Unknown");
            return (
                <Badge
                    color={"gray"}
                    size="2"
                    radius="full"
                    variant="outline"
                    className="m-0.5 px-2 py-1 text-xs rounded-xl"
                >
                    <div className="flex items-center gap-2">
                        <div className="flex items-center gap-2">
                            <StatusIcon className="w-4 h-4" />
                            {ticketStatuses.find(
                                (status) => status.value === currStatus,
                            )?.label ?? "Unknown"}
                        </div>
                    </div>
                </Badge>
            );
        }
        case "Assignee": {
            const assigneeSet = filters.get("Assignee");
            const currAssignee =
                assigneeSet && assigneeSet.size === 1
                    ? Array.from(assigneeSet)[0]
                    : "NoAssignee";
            const assigneeInfo = users.find((user) => user.id === currAssignee);
            return (
                <Badge
                    color={"gray"}
                    size="2"
                    radius="full"
                    variant="outline"
                    className="m-0.5 px-2 py-1 text-xs rounded-xl w-100"
                >
                    <div className="flex items-center gap-2">
                        <div className="lb-avatar rounded-lg w-4 h-4">
                            {assigneeInfo?.picture_url ? (
                                <img
                                    className="lb-avatar-image"
                                    src={assigneeInfo?.picture_url}
                                    alt={assigneeInfo?.username}
                                />
                            ) : (
                                <AvatarIcon className="w-4 h-4" />
                            )}
                        </div>
                        <span className="lb-comment-author text-xs font-normal font-destructive">
                            {assigneeInfo
                                ? `${assigneeInfo?.first_name} ${assigneeInfo?.last_name}`
                                : "No Assignee"}
                        </span>
                    </div>
                </Badge>
            );
        }
        case "Tag": {
            const tagSet = filters.get("Tag");
            const currTag =
                tagSet && tagSet.size === 1
                    ? Array.from(tagSet)[0]
                    : "AI_MATCH_TAG";
            return (
                <Badge
                    color={"gray"}
                    size="2"
                    radius="full"
                    variant="outline"
                    className="m-0.5 px-2 py-1 text-xs rounded-xl"
                >
                    <div className="flex flex-row items-center">
                        {currTag === "AI_MATCH_TAG" ? (
                            <>
                                <img
                                    src={SparklesIcon}
                                    alt=""
                                    className="h-4 w-4"
                                />
                                <p className="pl-0.5">AI Match Interaction Type</p>
                            </>
                        ) : (
                            <>
                                <ComponentBooleanIcon
                                    color={
                                        categories.find(
                                            (category) =>
                                                category.name === currTag,
                                        )?.color ?? "gray"
                                    }
                                />
                                <p className="pl-0.5">{currTag}</p>
                            </>
                        )}
                    </div>
                </Badge>
            );
        }
        case "Topic": {
            const topicList = Array.from(filters.get("Topic") ?? []);
            const hasAI = topicList.includes("AI_MATCH_TOPIC");
            const hasNone = topicList.includes("NONE");

            const renderTopicIcon = (topic: string) => {
                if (topic === "AI_MATCH_TOPIC") {
                    return (
                        <>
                            <img
                                src={SparklesIcon}
                                alt="AI Match Icon"
                                className="h-4 w-4"
                            />
                            <p className="pl-1">AI Match Tag</p>
                        </>
                    );
                }
                if (topic === "NONE") {
                    return (
                        <>
                            <CrossCircledIcon />
                            <p className="pl-1">No Topics</p>
                        </>
                    );
                }
                const topicInfo = topics.find((t) => t.value === topic);
                return (
                    <>
                        <DotFilledIcon
                            color={
                                topicInfo?.color && topicInfo?.color !== ""
                                    ? topicInfo?.color
                                    : "#9B9EF0"
                            }
                            style={{ transform: "scale(1.8)" }}
                        />
                        <p className="pl-0.3">{topicInfo?.label || topic}</p>
                    </>
                );
            };

            const renderTopics = () => {
                if (topicList.length === 1) {
                    return renderTopicIcon(topicList[0]);
                }
                return (
                    <>
                        {topicList.map((topic) => {
                            const color = topics.find(
                                (t) => t.value === topic,
                            )?.color;
                            return (
                                <div
                                    className="flex flex-row items-center"
                                    key={topic}
                                >
                                    <DotFilledIcon
                                        color={
                                            color && color !== ""
                                                ? color
                                                : "#9B9EF0"
                                        }
                                        style={{ transform: "scale(1.8)" }}
                                        className="-mx-1"
                                    />
                                </div>
                            );
                        })}
                        <p className="pl-1">{topicList.length} topics</p>
                    </>
                );
            };

            return (
                <Badge
                    color="gray"
                    size="2"
                    radius="full"
                    variant="outline"
                    className="m-0.5 px-2 py-1 text-xs rounded-xl"
                >
                    <div className="flex flex-row items-center">
                        {hasAI && renderTopicIcon("AI_MATCH_TOPIC")}
                        {hasNone && renderTopicIcon("NONE")}
                        {!(hasAI || hasNone) && renderTopics()}
                    </div>
                </Badge>
            );
        }
    }
};

interface CreateIssuePopupProps {
    topics: { color: string; label: string; value: string }[];
    categories: { color: string; name: string }[];
    userID: string;
    users: GetUserResponse[];
    queryClient: QueryClient;
    dialogIsOpen: boolean;
    setDialogIsOpen: (value: React.SetStateAction<boolean>) => void;
    teams: Teams[]
}

export function CreateIssuePopup({
    categories,
    topics,
    userID,
    users,
    queryClient,
    dialogIsOpen,
    setDialogIsOpen,
    teams
}: CreateIssuePopupProps) {
    const api = useApi();
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [title, setTitle] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [selectedTag, setSelectedTag] = useState<string>("AI_MATCH_TAG");
    const [selectedStatus, setSelectedStatus] = useState<string>("Open");
    const [selectedTopic, setSelectedTopic] = useState<string[]>([
        "AI_MATCH_TOPIC",
    ]);
    const [selectedAssignee, setSelectedAssignee] = useState<string>("");
    const filters = new Map<string, Set<string>>();
    filters.set("Tag", new Set([selectedTag]));
    filters.set("Status", new Set([selectedStatus]));
    filters.set("Assignee", new Set([selectedAssignee]));
    filters.set("Topic", new Set(selectedTopic));
    const dropdownTypes = ["Status", "Assignee", "Tag", "Topic"];

    function clearForm() {
        setTitle("");
        setMessage("");
        setSelectedTag("AI_MATCH_TAG");
        setSelectedStatus("Open");
        setSelectedTopic(["AI_MATCH_TOPIC"]);
        setSelectedAssignee("");
    }

    const handleItemSelect = (type: string, value: string) => () => {
        switch (type) {
            case "Tag": {
                setSelectedTag(value);
                filters.set("Tag", new Set([value]));
                return;
            }
            case "Status": {
                setSelectedStatus(value);
                filters.set("Status", new Set([value]));
                return;
            }
            case "Topic": {
                // Can only suggest AI Match Topics of any number of other topics
                if (value === "AI_MATCH_TOPIC" || value === "NONE") {
                    setSelectedTopic([value]);
                    filters.set("Topic", new Set([value]));
                } else if (
                    selectedTopic.includes("AI_MATCH_TOPIC") ||
                    selectedTopic.includes("NONE")
                ) {
                    setSelectedTopic([value]);
                    filters.set("Topic", new Set([value]));
                } else {
                    const isSelected = selectedTopic.includes(value);
                    let newTopicsSelected: string[];

                    if (isSelected) {
                        newTopicsSelected =
                            selectedTopic.filter((t) => t !== value) || [];
                    } else {
                        newTopicsSelected = [...(selectedTopic || []), value];
                    }
                    setSelectedTopic(newTopicsSelected);
                    filters.set("Topic", new Set(newTopicsSelected));
                }
                return;
            }
            case "Assignee": {
                setSelectedAssignee(value);
                filters.set("Assignee", new Set([value]));
                return;
            }
            default:
                console.log("handleItemSelect does not support type ", type);
        }
    };

    const CreateIssue = async () => {
        try {
            setFeedbackMessage("Creating the issue...");
            if (message.trim() === "") {
                setFeedbackMessage(
                    "Enter a message, the message field cannot be empty",
                );
                return;
            }
            const getTopicIDs = (
                selectedTopic: string[],
                topics: { color: string; label: string; value: string }[],
            ): string[] => {
                if (selectedTopic.length === 1) {
                    if (selectedTopic.includes("NONE")) {
                        return [];
                    }
                    if (selectedTopic.includes("AI_MATCH_TOPIC")) {
                        return selectedTopic;
                    }
                }

                return selectedTopic
                    .map((name) => topics.find((t) => t.label === name)?.value)
                    .filter((id): id is string => id !== undefined);
            };
            const payload: CreateTicketFromUIPayload = {
                title: title,
                message: message,
                user: userID,
                status: selectedStatus,
                assignee_user_id:
                    selectedAssignee === "noAssignee" ? "" : selectedAssignee,
                tag: selectedTag === "AI_MATCH_TAG" ? "" : selectedTag,
                topic: getTopicIDs(selectedTopic, topics),
            };

            const response = await api.post(
                URLS.serverUrl + API.createTicketFromUI,
                payload,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );

            if (response.status === 200) {
                const res: CreateTicketFromUIResponse = response.data.data;
                console.log("created issue successfully with response", res);

                const historyPayload: CreateHistoryPayload = {
                    id: res.id,
                    parent_id: res.id,
                    history_type: "Message",
                    content: message,
                    source: "Web",
                    user: userID,
                    metadata: "",
                    source_specific_id: res.org_specific_id,
                    reactions: [],
                    files: [],
                    original_timestamp: Date.now() * 1_000_000
                };

                const historyResponse = await api.post(
                    URLS.serverUrl + API.createHistory,
                    historyPayload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );
                if (historyResponse.status === 200) {
                    setFeedbackMessage(
                        `Successfully created Issue ${res.org_specific_id}`,
                    );
                    clearForm();
                    queryClient.refetchQueries({
                        queryKey: ["queries"],
                        exact: true,
                    });
                } else {
                    console.log(
                        `Failed to create history record for ticket: ${response.status}`,
                    );
                    setFeedbackMessage(
                        "Failed to create the issue's history record",
                    );
                }
            } else {
                console.log(`Failed to create issue: ${response.status}`);
                setFeedbackMessage("Failed to create the issue");
            }
        } catch (error) {
            console.error("Error creating issue:", error);
            setFeedbackMessage("Failed to create the issue");
        }
    };

    return (
        <Dialog open={dialogIsOpen} onOpenChange={setDialogIsOpen}>
            {/* Don't need to show the trigger since it's apart of the dropdown
            <DialogTrigger asChild>
                <Button
                    className="shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                    size="sm"
                    variant="outline"
                >
                    Create Issue
                </Button>
            </DialogTrigger> */}
            <DialogContent className="inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50 max-h-full overflow-auto">
                <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                    <DialogClose asChild>
                        <Button
                            type="button"
                            variant="ghost"
                            className="absolute top-4 right-4 hover:bg-muted"
                        >
                            <Cross2Icon />
                        </Button>
                    </DialogClose>
                    <Input
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="w-full py-1 text-lg border-none outline-none focus:ring-0"
                        placeholder="Issue Title"
                    />
                    <Textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="min-h-[100px] mb-4 w-full text-sm border-none outline-none focus:ring-0"
                        placeholder="Starting Message..."
                        rows={9}
                    />

                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-1.5">
                            {dropdownTypes.map((type) => {
                                return (
                                    <DropdownMenu key={type}>
                                        <DropdownMenuTrigger
                                            asChild
                                            type="button"
                                        >
                                            {DropdownTrigger(
                                                type,
                                                categories,
                                                filters,
                                                users,
                                                topics,
                                            )}
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent
                                            align="start"
                                            className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                        >
                                            <FilterDropdownElement
                                                type={type}
                                                categories={categories}
                                                filters={filters}
                                                handleItemSelect={
                                                    handleItemSelect
                                                }
                                                topics={topics}
                                                users={users}
                                                extraOptions={
                                                    type === "Tag"
                                                        ? [
                                                            {
                                                                label: "AI Match Interaction Type",
                                                                value: "AI_MATCH_TAG",
                                                                color: "",
                                                            },
                                                        ]
                                                        : type === "Topic"
                                                            ? [
                                                                {
                                                                    label: "AI Match Tag",
                                                                    value: "AI_MATCH_TOPIC",
                                                                    color: "",
                                                                },
                                                                {
                                                                    label: "No Tags",
                                                                    value: "NONE",
                                                                    color: "",
                                                                },
                                                            ]
                                                            : []
                                                }
                                                customerGroups={[]}
                                                teams={teams}
                                                isSavedViewFilter={false}
                                            />
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                );
                            })}
                        </div>
                        <div className="flex flex-col items-end">
                            <Button
                                className="bg-[#5B5BD6] text-white shadow-md outline outline-1 outline-white flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm text-xs"
                                size="sm"
                                variant="outline"
                                onClick={CreateIssue}
                                type="submit"
                            >
                                Create Issue
                            </Button>
                            {feedbackMessage && (
                                <p
                                    className={`mt-1 text-right italic text-xs ${feedbackMessage.includes("Failed") ||
                                        feedbackMessage.includes("message")
                                        ? "destructive"
                                        : "text-primary"
                                        }`}
                                >
                                    {feedbackMessage}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
