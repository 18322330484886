import { ChevronLeftIcon } from "@radix-ui/react-icons";
import { Flex, Link, Text } from "@radix-ui/themes";

interface IntegrationHeaderProps {
    integrationType: string;
    description: string;
    SvgIcon: React.ElementType;
}

const IntegrationHeader = (props: IntegrationHeaderProps) => {
    return (
        <Flex direction="column" gap="4" className="w-full">
            <Flex direction="column" gap="3">
                <Link
                    href="/admin/manage_integrations"
                    underline="none"
                    size={"2"}
                >
                    <Flex direction="row" align="center">
                        <ChevronLeftIcon />
                        Integrations
                    </Flex>
                </Link>

                <Flex
                    gap="3"
                    align="center"
                    direction={"row"}
                    style={{ marginLeft: "10px" }}
                >
                    {props.SvgIcon && (
                        <props.SvgIcon
                            style={{
                                width: "30px",
                                height: "30px",
                            }}
                        />
                    )}

                    <Flex direction={"column"} align="start">
                        <Text as="div" size="4" weight="bold">
                            {props.integrationType}
                        </Text>
                        <Text wrap="pretty" as="div" size="2" color="gray">
                            {props.description}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default IntegrationHeader;
