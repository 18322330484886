import {
    type FilterType,
    insightStatuses,
    sources,
    ticketStatuses,
} from "@/IssuesTable/constants";
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, URLS } from "@/constant";
import type {
    Account,
    AccountMonetaryRecord,
    CustomerGroup,
    GetTopicsResponse,
    GetUserResponse,
    Insight,
    InsightData,
    QueriesWithPaginationResponse,
    Query,
    ScopeResponse,
    Teams,
    Ticket,
    TicketStatus,
    TicketTag,
    TicketTopics,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";

import {
    CaretUpIcon,
    type CheckCircledIcon,
    EnvelopeOpenIcon,
    ExclamationTriangleIcon,
    type LapTimerIcon,
    OpenInNewWindowIcon,
    PersonIcon,
    QuestionMarkCircledIcon,
    ReaderIcon,
    ResetIcon,
    type StopwatchIcon,
    TargetIcon,
} from "@radix-ui/react-icons";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { Badge } from "@radix-ui/themes";
import type { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";
import type {
    InfiniteData,
    QueryClient,
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import type { AxiosInstance } from "axios";
import _ from "lodash";
import {
    ActivityIcon,
    DollarSignIcon,
    ImageIcon,
    ListChecksIcon,
    MailsIcon,
    MoonStarIcon,
    MoveRightIcon,
    PlusIcon,
    StarIcon,
    TagIcon,
    TrendingDownIcon,
    TrendingUpIcon,
} from "lucide-react";
import { createPortal } from "react-dom";
import ReactDOMServer from "react-dom/server";

export type BadgeColor = (typeof badgePropDefs.color.values)[number];

export function getColor(input: string): BadgeColor {
    switch (input) {
        case "Query":
            return "green";
        case "Feature":
            return "blue";
        case "Bug":
            return "red";
        default:
            return "gray";
    }
}

export function getColorLight(input: string): string {
    switch (input) {
        case "Query":
            return "#5BB98B";
        case "Feature":
            return "#5EB1EF";
        case "Bug":
            return "#EB8E90";
        default:
            return "#BBBBBB";
    }
}

export function getTopicColor(input: string): BadgeColor {
    switch (input) {
        case "autoresponder setup":
            return "green";
        case "channel activity":
            return "pink";
        default:
            return "iris";
    }
}

export function processInsightMetadata(
    insight: Insight,
): [boolean, string, string] {
    if (insight.metadata) {
        const metadata = new Map(Object.entries(JSON.parse(insight.metadata)));
        const isAIGenerated =
            metadata.has("ai_generated") &&
            Boolean(metadata.get("ai_generated"));
        const topicGeneratedFrom: string =
            (metadata.get("topic_generated_from") as string) ?? "";
        const categoryGeneratedFrom: string =
            (metadata.get("category_generated_from") as string) ?? "";
        return [isAIGenerated, topicGeneratedFrom, categoryGeneratedFrom];
    }
    return [false, "", ""];
}

export async function saveAIGeneratedInsight(
    insight: Insight,
    queryClient: QueryClient,
    userID: string,
    api: AxiosInstance,
    teamID?: string,
) {
    const requestData: InsightData = {
        title: insight.title,
        description: insight.description,
        user_id: userID,
        related_issues_added: insight.related_issues.map((ri) => ri.id),
        metadata: insight.metadata,
    };

    if (teamID && teamID !== "") {
        requestData.team_id = teamID;
    }

    try {
        const res = await api.post(
            URLS.serverUrl + API.saveInsight,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        if (res.status === 200) {
            console.log(`Saved insight id ${res.data.data} successfully`);
            await queryClient.refetchQueries({
                queryKey: ["insights"],
                exact: true,
            });
            return res.data.data;
        } else {
            console.log("Call to update status failed");
            return "";
        }
    } catch (error) {
        console.error("Error saving insight:", error);
        return "";
    }
}

export function getExternalIssueIcon(url: string): React.ElementType {
    const icon = url.toLowerCase().includes("linear")
        ? integrationBackEndDataMappingToSvg.get("Linear")
        : url.toLowerCase().includes("github")
          ? integrationBackEndDataMappingToSvg.get("GitHubTicket")
          : url.toLowerCase().includes("atlassian")
            ? integrationBackEndDataMappingToSvg.get("Jira")
            : url.toLowerCase().includes("intercom")
              ? integrationBackEndDataMappingToSvg.get("Intercom")
              : undefined;

    return icon || ReaderIcon;
}

export function getExternalIssueText(url: string): string {
    const text = url.toLowerCase().includes("linear")
        ? "Linear"
        : url.toLowerCase().includes("github")
          ? "GitHub"
          : url.toLowerCase().includes("atlassian")
            ? "Jira"
            : url.toLowerCase().includes("intercom")
              ? "Intercom"
              : "";
    return text;
}

export const areQueriesEqual = (query1: Query, query2: Query): boolean => {
    return (
        query1.id === query2.id ||
        query1.title === query2.title ||
        query1.query === query2.query
    );
};

export const addToCustomSet = (
    query: Query,
    set: Set<Query>,
    compareFn: (q1: Query, q2: Query) => boolean,
) => {
    if (
        !Array.from(set).some((existingQuery) =>
            compareFn(existingQuery, query),
        )
    ) {
        set.add(query);
    }
};

export function getPlural(type: string) {
    switch (type) {
        case "Source": {
            return "sources";
        }
        case "Broadcast": {
            return "broadcasts";
        }
        case "Tag": {
            // backend category -> tag -> "Interaction Type" on UI
            return "interaction types";
        }
        case "Topic": {
            // backend topics -> "Tag" on UI
            return "tags";
        }
        case "Status":
        case "Insight Status": {
            return "statuses";
        }
        case "Assignee": {
            return "assignees";
        }
        case "Team": {
            return "Teams";
        }
    }
}

export function getLowercase(type: string) {
    switch (type) {
        case "Tag": {
            // backend category -> tag -> "Interaction Type" on UI
            return "interaction type";
        }
        case "Topic": {
            // backend topics -> "Tag" on UI
            return "tag";
        }
        case "CommunitySlack": {
            return "Community Slack channels";
        }
        case "Slack":
        case "Discord": {
            return `${type} channels`;
        }
        default: {
            return type.toLowerCase();
        }
    }
}

export function getTypeName(type: string) {
    switch (type) {
        case "Tag": {
            // backend category -> tag -> "Interaction Type" on UI
            return "Interaction Type";
        }
        case "Topic": {
            // backend topics -> "Tag" on UI
            return "Tag";
        }
        case "Status":
        case "Insight Status": {
            return "Status";
        }
        default: {
            return type;
        }
    }
}

export function getOptions(
    type: string,
    topics: { color: string; label: string; value: string }[],
    users: GetUserResponse[],
    customerGroups: CustomerGroup[],
    categories: { color: string; name: string }[],
    teams: Teams[],
    channels: Map<string, ScopeResponse[]>,
    organizationSources?: string[],
) {
    switch (type) {
        case "Source": {
            if (organizationSources !== undefined) {
                return sources.filter((broadcast) =>
                    organizationSources.includes(broadcast.value),
                );
            } else {
                return sources;
            }
        }
        case "Tag": {
            return categories.map((category) => ({
                label: category.name,
                value: category.name,
                color: category.color,
            }));
        }
        case "Topic": {
            return topics;
        }
        case "Status": {
            return ticketStatuses;
        }
        case "Insight Status": {
            return insightStatuses;
        }
        case "Assignee": {
            const options = users.map((user) => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.id,
                color: "",
            }));
            options.push({
                color: "",
                label: "No Assignee",
                value: "noAssignee",
            });
            return options;
        }
        case "Customer Group": {
            const options = customerGroups.map((cg) => ({
                label: cg.group_name,
                value: cg.group_name,
                color: "",
            }));
            return options;
        }
        case "Team": {
            const options = teams.map((cg) => ({
                label: cg.team_name,
                value: cg.team_name,
                color: "",
            }));
            return options;
        }
        case "Slack":
        case "Discord":
        case "CommunitySlack": {
            return channels?.get(type)?.map((channel) => ({
                label: `${type} -- #${channel.name}`,
                value: `${type} -- ${channel.name} -- ${channel.key}`,
                color: "",
            }));
        }
        case "Gmail": {
            return channels?.get(type)?.map((channel) => ({
                label: `${type} -- ${channel.name}`,
                value: `${type} -- ${channel.name} -- ${channel.key}`,
                color: "",
            }));
        }
    }
}

export function getStatusFullIcon(status: string) {
    const Icon = getStatusIcon(status ?? "Unknown");
    switch (status) {
        case "Open": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#FFEFD6] border border-[#FFDFB5] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "NeedsResponse": {
            return (props: React.ComponentProps<typeof LapTimerIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#EEF1F0] border border-[#E6E9E8] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "Breaching": {
            return (
                props: React.ComponentProps<typeof ExclamationTriangleIcon>,
            ) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#FFF6F8] border border-[#FFE9ED] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "Closed": {
            return (props: React.ComponentProps<typeof CheckCircledIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#EDF2FE] border border-[#E1E9FF] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "New": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#e8f5fc] border border-[#bbdced] shadow-sm">
                    <Icon className="h-3.5 w-3.5" />
                </div>
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                    <Icon />
                </div>
            );
        }
    }
}

export function getStatusIcon(status: string) {
    switch (status) {
        case "Open": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <TargetIcon {...props} color="#EC9455" />
            );
        }
        case "NeedsResponse": {
            return (props: React.ComponentProps<typeof ResetIcon>) => (
                <ResetIcon {...props} color="#868E8B" />
            );
        }
        case "Breaching": {
            return (
                props: React.ComponentProps<typeof ExclamationTriangleIcon>,
            ) => <ExclamationTriangleIcon {...props} color="#f57695" />;
        }
        case "Closed": {
            return (props: React.ComponentProps<typeof MoonStarIcon>) => (
                <MoonStarIcon
                    strokeWidth={1.5}
                    size={16}
                    {...props}
                    color="#3358D4"
                />
            );
        }
        case "New": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <MailsIcon {...props} color="#8acaed" />
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => <QuestionMarkCircledIcon {...props} color="#808080" />;
        }
    }
}

export function getAnnouncementsFullIcon(status: string) {
    const Icon = getAnnouncementStatusIcon(status);
    switch (status) {
        case "Draft": {
            return (props: React.ComponentProps<typeof ReaderIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#F4FAFF] border border-[#D5EFFF] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "Sent": {
            return (props: React.ComponentProps<typeof EnvelopeOpenIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#FDF7FD] border border-[#F7DEF8] shadow-sm">
                    <Icon />
                </div>
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => <QuestionMarkCircledIcon {...props} color="#808080" />;
        }
    }
}

export function getAnnouncementStatusIcon(status: string) {
    switch (status) {
        case "Draft": {
            return (props: React.ComponentProps<typeof ResetIcon>) => (
                <ReaderIcon {...props} color="#0090FF" />
            );
        }
        case "Sent": {
            return (props: React.ComponentProps<typeof EnvelopeOpenIcon>) => (
                <EnvelopeOpenIcon {...props} color="#AB4ABA" />
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => <QuestionMarkCircledIcon {...props} color="#808080" />;
        }
    }
}

export function getTrendFullIcon(trend: string, size = 3.5) {
    const Icon = getTrendIcon(trend ?? "Unknown");
    const sizeClass = `w-${size} h-${size}`;
    switch (trend) {
        case "Increasing": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center rounded-lg p-1 bg-[#f5e4e9] border border-[#fad2dd] shadow-sm">
                                <Icon className={sizeClass} />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6]">
                            <p>Increasing Trend</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
        case "Decreasing": {
            return (props: React.ComponentProps<typeof LapTimerIcon>) => (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center rounded-lg p-1 bg-[#dff5df] border border-[#bce6bc] shadow-sm">
                                <Icon className={sizeClass} />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6]">
                            <p>Decreasing Trend</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
        case "Stagnant": {
            return (
                props: React.ComponentProps<typeof ExclamationTriangleIcon>,
            ) => (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center rounded-lg p-1 bg-[#d5e9ed] border border-[#bfdbe0] shadow-sm">
                                <Icon className={sizeClass} />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6]">
                            <p>Stagnant Trend</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
        case "Variable": {
            return (props: React.ComponentProps<typeof CheckCircledIcon>) => (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center rounded-lg p-1 bg-[#ebebf7] border border-[#cccced] shadow-sm">
                                <Icon className={sizeClass} />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6]">
                            <p>Variable Trend</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                    <Icon className={sizeClass} />
                </div>
            );
        }
    }
}

export function getTrendIcon(trend: string) {
    switch (trend) {
        case "Increasing": {
            return (props: React.ComponentProps<typeof TrendingUpIcon>) => (
                <TrendingUpIcon {...props} color="#d45b7b" />
            );
        }
        case "Decreasing": {
            return (props: React.ComponentProps<typeof TrendingDownIcon>) => (
                <TrendingDownIcon {...props} color="#4d804d" />
            );
        }
        case "Stagnant": {
            return (props: React.ComponentProps<typeof MoveRightIcon>) => (
                <MoveRightIcon {...props} color="#2f7180" />
            );
        }
        case "Variable": {
            return (props: React.ComponentProps<typeof ActivityIcon>) => (
                <ActivityIcon {...props} color="#5B5BD6" />
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => <QuestionMarkCircledIcon {...props} color="#808080" />;
        }
    }
}

export function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
    return _.isEqual(arr1, arr2);
}

export function formatUsernames(content: string) {
    const regex = /<@([^>]+)>/g;

    return content.replace(regex, (match, username) => {
        return `<span class="mention">@${username}</span>`;
    });
}

export const emojiMap = new Map<string, string>([
    ["large_green_circle", "🟢"],
    ["large_orange_circle", "🟠"],
    ["large_red_circle", "🔴"],
    ["flag-us", "🇺🇸"], // United States
    ["flag-ca", "🇨🇦"], // Canada
    ["flag-gb", "🇬🇧"], // United Kingdom
    ["flag-fr", "🇫🇷"], // France
    ["flag-de", "🇩🇪"], // Germany
    ["flag-it", "🇮🇹"], // Italy
    ["flag-es", "🇪🇸"], // Spain
    ["flag-jp", "🇯🇵"], // Japan
    ["flag-kr", "🇰🇷"], // South Korea
    ["flag-br", "🇧🇷"], // Brazil
    ["flag-cn", "🇨🇳"], // China
    ["flag-in", "🇮🇳"], // India
    ["flag-au", "🇦🇺"], // Australia
    ["flag-ru", "🇷🇺"], // Russia
    ["flag-se", "🇸🇪"], // Sweden
    ["flag-no", "🇳🇴"], // Norway
    ["flag-nz", "🇳🇿"], // New Zealand
    ["flag-mx", "🇲🇽"], // Mexico
    ["flag-pt", "🇵🇹"], // Portugal
    ["flag-ch", "🇨🇭"], // Switzerland
    ["flag-at", "🇦🇹"], // Austria
    ["flag-be", "🇧🇪"], // Belgium
    ["flag-dk", "🇩🇰"], // Denmark
    ["flag-fi", "🇫🇮"], // Finland
    ["flag-gr", "🇬🇷"], // Greece
    ["flag-ru", "🇷🇺"], // Russia
    ["flag-ua", "🇺🇦"], // Ukraine
    ["flag-ph", "🇵🇭"], // Philippines
    ["flag-za", "🇿🇦"], // South Africa
    ["flag-tw", "🇹🇼"], // Taiwan
    ["flag-id", "🇮🇩"], // Indonesia
    ["satellite_antenna", "📡"],
    ["waving_white_flag", "🏳️"],
]);

export function formatEmojis(content: string) {
    return content.replace(/(:[\w-]+:)/g, (match) => {
        const emojiName = match.slice(1, -1);
        // console.log("emoji name is", emojiName);
        return emojiMap.get(emojiName) || match;
    });
}

export function cleanText(text: string): string {
    // Replace HTML tags with an empty string
    const htmlTagRegex = /<[^>]*>/g;
    let cleanedText = text.replace(htmlTagRegex, " ");

    // Remove images
    cleanedText = cleanedText.replace(/!\[.*?\]\(.*?\)/g, "");
    // Remove headers
    cleanedText = cleanedText.replace(/^#.*$/gm, "");
    // Remove blockquotes
    cleanedText = cleanedText.replace(/^> .*/gm, "");

    // Replace newlines with a single space and trim leading/trailing spaces
    return cleanedText.replace(/(\r\n|\n|\r)/gm, " ").trim();
}

export function getHtmlStringFromReactContent(
    reactElement: React.ReactNode,
): string {
    return ReactDOMServer.renderToString(reactElement);
}

export const getIconForType = (type: string) => {
    switch (type) {
        case "Tag":
            return <TagIcon className="w-3 h-3" />;
        case "Topic":
            return <ListChecksIcon className="w-3 h-3" />;
        case "Status":
        case "Insight Status":
            return <StarIcon className="w-3.5 h-3.5" />;
        case "Assignee":
            return <PersonIcon className="w-3.5 h-3.5" />;
        default:
            return null;
    }
};

export function saveTag(
    tag: string,
    api: AxiosInstance,
    ids: string[],
    updateIssueState: (newState: Partial<Query>) => void,
    userID: string,
) {
    const requestData: TicketTag = {
        ids: ids,
        tag: tag,
        source: "Web",
        user_id: userID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
            updateIssueState({ bot_category: tag });
            console.log(`Updated tag to ${tag} successfully`);
        } else {
            console.log("Call to update tag failed");
        }
    });
}

export const toggleTopicSelection = (
    topic: string,
    issueState: Query,
    api: AxiosInstance,
    ids: string[],
    updateIssueState: (newState: Partial<Query>) => void,
    userID: string,
    topicsMap: Map<string, GetTopicsResponse>,
) => {
    const allTopics: string[] = issueState.topic;
    const isSelected = allTopics.includes(topic);
    let newTopicsSelected: string[];
    let addedTopics: string[] = [];
    let deletedTopics: string[] = [];

    if (isSelected) {
        newTopicsSelected = allTopics.filter((t) => t !== topic) || [];
        deletedTopics = [topic];
    } else {
        newTopicsSelected = [...(allTopics || []), topic];
        addedTopics = [topic];
    }

    updateIssueState({ topic: newTopicsSelected });
    saveTopics(addedTopics, deletedTopics, api, ids, userID);
};

export function saveTopics(
    addedTopics: string[],
    deletedTopics: string[],
    api: AxiosInstance,
    ids: string[],
    userID: string,
) {
    const requestData: TicketTopics = {
        ids: ids,
        added_topics: addedTopics,
        deleted_topics: deletedTopics,
        source: "Web",
        user_id: userID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
        } else {
            console.log("Call to update topics failed");
        }
    });
}

export function saveStatus(
    status: string,
    api: AxiosInstance,
    ids: string[],
    updateIssueState: (newState: Partial<Query>) => void,
    userID: string,
    refetch: (
        options?: RefetchOptions,
    ) => Promise<
        QueryObserverResult<
            InfiniteData<QueriesWithPaginationResponse, unknown>,
            Error
        >
    >,
) {
    updateIssueState({ disabled: true });
    const requestData: TicketStatus = {
        ids: ids,
        status: status,
        source: "Web",
        user_id: userID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
            refetch();
            updateIssueState({ ticket_status: status });
            console.log(`Updated status to ${status} successfully`);
        } else {
            console.log("Call to update status failed");
        }
    });
}

export function saveAssignee(
    assigneeID: string,
    api: AxiosInstance,
    ids: string[],
    updateIssueState: (newState: Partial<Query>) => void,
    userID: string,
) {
    console.log("ids", ids);
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        assignee_user_id: assigneeID === "noAssignee" ? "" : assigneeID,
    };
    console.log("requestData", requestData);
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
            updateIssueState({ assignee_user_id: assigneeID });
            console.log(`Updated assignee to ${assigneeID} successfully`);
        } else {
            console.log("Call to update assignee failed");
        }
    });
}

export const mapsEqual = (
    mapA: Map<string, Set<string>>,
    mapB: Map<string, Set<string>>,
) => {
    if (mapA.size !== mapB.size) return false;
    for (const [key, value] of Array.from(mapA.entries())) {
        if (!mapB.has(key) || mapB.get(key) !== value) {
            return false;
        }
    }
    return true;
};

export const toggleTeamSelection = (team: Teams, tagTeams: Teams[]) => {
    let newTagTeams = Array.from(tagTeams);
    if (newTagTeams.map((t) => t.id).includes(team.id)) {
        // Can't untoggle workspace if that's the only option selected
        if (team.team_name !== "Workspace") {
            // Delete the team if it's already in tag teams
            newTagTeams = newTagTeams.filter((t) => t.id !== team.id);
        }
    } else {
        // Workspace represents an organization level setting so if selected, it should not include other teams
        if (
            team.team_name === "Workspace" ||
            newTagTeams.map((t) => t.team_name).includes("Workspace")
        ) {
            newTagTeams = [team];
        } else {
            // Add the team if it's not there yet
            newTagTeams.push(team);
        }
    }
    return newTagTeams;
};

export const toggleCompanySelection = (
    company: Account,
    customerCompany: Account | undefined,
) => {
    // Removing customer from company
    if (customerCompany?.id === company.id) {
        return undefined;
    } else {
        return company;
    }
};

// Temporary solution to resolve the slightly different Ticket & Query types
// TODO: these two types should be combined in the future
export function convertTicketToQuery(ticket: Ticket): Query {
    return {
        id: ticket.id,
        ticket_identifier: ticket.ticket_identifier,
        ticket_number: ticket.number,
        number: ticket.number,
        title: ticket.title,
        query: ticket.query,
        created_at: ticket.created_at,
        ticket_updated_at: ticket.updated_at,
        bot_category: ticket.bot_category,
        source: ticket.source || undefined,
        topic: ticket.topic,
        ticket_status: ticket.ticket_status,
        url: ticket.url,
        source_specific_id: ticket.source_specific_id,
        ai_response: ticket.ai_response || undefined,
        internal_note: ticket.internal_note || undefined,
        assignee_user_id: ticket.assignee_user_id || undefined,
        external_issues: ticket.external_issues.map((issue) =>
            issue.toString(),
        ),
        breaching: ticket.breaching || undefined,
        user_info: {
            id: ticket.assignee_user_id || "",
            name: "", // Placeholder for the name
            email: "", // Placeholder for email
        },
        assembly_responded: false, // Placeholder value
        business_hours_id: ticket.business_hours_id || undefined,
        original_timestamp: ticket.created_at, // Placeholder for the original timestamp
        disabled: false, // Placeholder for the disabled flag
        teams: ticket.teams,
        user: ticket.assignee_user_id || "Unknown", // Use assignee_user_id or a fallback
        time_created: ticket.created_at,
        time_responded: ticket.updated_at,
        time_closed: "", // Placeholder, should be set if ticket has a closed date
        source_unique_name: ticket.source_unique_name,
    };
}

export const insightRelatedInteractionsBadge = (count: number) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color="gray"
                        size="2"
                        radius="full"
                        variant="soft"
                        className="m-0.5"
                    >
                        <div className="flex flex-row items-center">
                            <CaretUpIcon />
                            <p className="pl-0.5">{count}</p>
                        </div>
                    </Badge>
                </TooltipTrigger>

                {createPortal(
                    <TooltipContent className="bg-[#5B5BD6] max-w-[85px] text-center z-50">
                        <p>Interaction Count</p>
                    </TooltipContent>,
                    document.body,
                )}
            </Tooltip>
        </TooltipProvider>
    );
};

export const insightMonetaryValueBadge = (monetary_value: number) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color={"gray"}
                        size="2"
                        radius="medium"
                        variant="outline"
                        className="ring-[0.8px] text-gray-700 ring-[#E0E1E6] flex items-center gap-0.5 "
                    >
                        <DollarSignIcon strokeWidth={1.5} size={11} />
                        <p className="text-xs">
                            {Number.isInteger(monetary_value)
                                ? monetary_value.toString()
                                : monetary_value.toFixed(2)}
                        </p>
                    </Badge>
                </TooltipTrigger>
                {createPortal(
                    <TooltipContent className="bg-[#5B5BD6] max-w-[120px] text-center z-50">
                        <p>Monetary Value Per Month</p>
                    </TooltipContent>,
                    document.body,
                )}
            </Tooltip>
        </TooltipProvider>
    );
};

export const handleAccountBadgeClick = (
    account: AccountMonetaryRecord,
    event?: React.MouseEvent,
) => {
    if (event) {
        event.stopPropagation();
    }
    switch (account.type) {
        case "Company":
            window.open(
                `${process.env.REACT_APP_CALLBACK_URL}accounts/${account.id}`,
                "_blank",
            );
            return;
        case "Customer":
            window.open(
                `${process.env.REACT_APP_CALLBACK_URL}accounts/individual/${account.id}`,
                "_blank",
            );
            return;
        default:
            return;
    }
};

export const insightAccountBadge = (
    c: AccountMonetaryRecord,
    handleClick: (
        account: AccountMonetaryRecord,
        event?: React.MouseEvent,
    ) => void,
) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color={c.type === "Company" ? "blue" : "orange"}
                        size="2"
                        radius="medium"
                        variant="outline"
                        className="text-xs px-2 py-1.5 flex items-center gap-1 text-gray-700 bg-gray-50 cursor-pointer"
                        key={c.id}
                        onClick={(event) => handleAccountBadgeClick(c, event)}
                    >
                        {c.image_url !== "" && c.image_url !== undefined ? (
                            <div className="lb-avatar rounded w-4 h-4">
                                <img
                                    className="lb-avatar-image"
                                    src={c.image_url}
                                    alt={c.name}
                                />
                            </div>
                        ) : (
                            <div className="lb-avatar rounded w-4 h-4">
                                <ImageIcon className="w-4 h-4" />
                            </div>
                        )}
                        {c.name}
                    </Badge>
                </TooltipTrigger>
                {createPortal(
                    <TooltipContent className="bg-[#5B5BD6] max-w-[105px] text-center z-50 flex items-center gap-1">
                        <p>
                            {c.type === "Company"
                                ? "Company Customer"
                                : "Individual Customer"}
                        </p>
                        <OpenInNewWindowIcon className="w-5 h-5" />
                    </TooltipContent>,
                    document.body,
                )}
            </Tooltip>
        </TooltipProvider>
    );
};

export const insightAdditionalAccountsBadge = (
    numRemainingCustomers: number,
) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color="gray"
                        size="2"
                        radius="medium"
                        variant="outline"
                        className="text-xs px-2 py-1.5 flex items-center gap-0.5 text-gray-700 bg-gray-50"
                    >
                        <PlusIcon className="w-3 h-3" />
                        {numRemainingCustomers}
                    </Badge>
                </TooltipTrigger>
                {createPortal(
                    <TooltipContent className="bg-[#5B5BD6] max-w-[88px] text-center z-50">
                        <p>{`+${numRemainingCustomers} More Customer${numRemainingCustomers === 1 ? "" : "s"}`}</p>
                    </TooltipContent>,
                    document.body,
                )}
            </Tooltip>
        </TooltipProvider>
    );
};

export const getFilterOption = (
    type: string,
    filterType: FilterType,
    showTrigger: boolean,
) => {
    return {
        type: type,
        filterType: filterType,
        showTrigger: true,
    };
};
