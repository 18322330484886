import { ScrollArea } from "@/component/shadcn/ui/scroll-area";
import type { Account, GetUserResponse } from "@/interfaces/serverData";
import { CheckCircledIcon } from "@radix-ui/react-icons";
import * as Toast from "@radix-ui/react-toast";
import { useEffect, useState } from "react";
import { CustomersListCard } from "./CustomersListCard";

interface CustomersTableProps {
    customers: Account[];
    userID: string;
    users: GetUserResponse[];
    forceUpdate: () => Promise<void>;
    company: Account | undefined
}

export function CustomersTable({
    customers,
    userID,
    users,
    forceUpdate,
    company
}: CustomersTableProps) {
    const [filteredCustomers, setFilteredCustomers] =
        useState<Account[]>(customers);

    const [toastText, setToastText] = useState<string>("Saved customer!");
    const [open, setOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    useEffect(() => {
        setFilteredCustomers(customers);
    }, [customers]);

    return (
        <div className="m-2 outline-none rounded-sm h-screen w-full flex flex-col">
            {/* <div>
                <FilterBar
                    issues={issues}
                    setFilteredAnnouncements={setFilteredAnnouncements}
                    topics={topics}
                    users={users}
                />
            </div> */}
            {filteredCustomers?.length === 0 ? (
                <div className="flex w-full h-[50%] justify-center items-center">
                    <p className="text-md text-gray12">
                        No customers yet. Add a new one.
                    </p>
                </div>
            ) : (
                <ScrollArea className="h-full w-full outline-none shadow-none">
                    {filteredCustomers?.map((customer) => (
                        <CustomersListCard
                            key={customer.id}
                            customer={customer}
                            userID={userID}
                            users={users}
                            setToastSymbol={setToastSymbol}
                            setToastText={setToastText}
                            setOpen={setOpen}
                            forceUpdate={forceUpdate}
                            company={company}
                        />
                    ))}
                </ScrollArea>
            )}
            <Toast.Provider swipeDirection="right">
                <Toast.Root
                    className="ToastRoot"
                    open={open}
                    onOpenChange={setOpen}
                >
                    <Toast.Title className="ToastTitle">
                        <div className="flex flex-row items-center">
                            <ToastSymbol color="green" />
                            {toastText}
                        </div>
                    </Toast.Title>
                </Toast.Root>
                <Toast.Viewport className="ToastViewport" />
            </Toast.Provider>
        </div>
    );
}
