import {
    BookmarkIcon,
    ComponentBooleanIcon,
    PersonIcon,
} from "@radix-ui/react-icons";

export const supportedThenConstants = {
    AssignIssueTo: "Assign Issue To",
    AssignTag: "Assign Tag",
    AssignTopic: "Assign Topic",
};

export const getSupportedThenIcon = (action: string): JSX.Element => {
    switch (action) {
        case supportedThenConstants.AssignIssueTo:
            return (
                <div className="flex items-center justify-center rounded-lg p-1 bg-mint3 border border-mint4 shadow-sm">
                    <PersonIcon className="text-mint11" />
                </div>
            );
        case supportedThenConstants.AssignTag:
            return (
                <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                    <BookmarkIcon className="text-red11" />
                </div>
            );
        case supportedThenConstants.AssignTopic:
            return (
                <div className="flex items-center justify-center rounded-lg p-1 bg-blue3 border border-blue4 shadow-sm">
                    <ComponentBooleanIcon className="text-blue11" />
                </div>
            );
        default:
            return <></>;
    }
};
