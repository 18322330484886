import { APIWithMethod, TeamsAPI, URLS, pages } from "@/constant";
import type { Teams, View } from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import {
    GroupIcon,
    IdCardIcon,
    PlusIcon,
    ThickArrowRightIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import {
    BellIcon,
    BookmarkIcon,
    PersonIcon,
    TimerIcon,
} from "@radix-ui/react-icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { HouseIcon, SettingsIcon, UsersIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../shadcn/ui/button";
import BallPopover from "./BallPopover";
import MenuDropdownComponent from "./MenuDropdownComponent";

import { TriangleDownIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
} from "../shadcn/ui/context-menu";

import { ChevronDown, ChevronRight } from "lucide-react";
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "../shadcn/ui/collapsible";
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarGroup,
    SidebarGroupContent,
    SidebarGroupLabel,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarMenuSub,
    SidebarMenuSubButton,
    SidebarMenuSubItem,
    useSidebar,
} from "../shadcn/ui/sidebar";

export type FilterMetadata = {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    granularFilters: Map<any, boolean>;
    enabled: boolean;
};

export const NAVBAR_PREFERENCES_ITEMS = {
    profile: "profile",
    views: "views",
    labels: "labels",
    notifications: "notifications",
    members: "members",
    teams: "teams",
    sla: "sla",
    customergroups: "customergroups",
    integrations: "integrations",
    workflows: "workflows",
    savedTeam: "savedTeam",
    savedTeamSettings: "savedTeamSettings",
    savedTeamLabels: "savedTeamLabels",
    savedTeamViews: "savedTeamViews",
    categories: "categories",
};
interface SideBarProps {
    organizationName: string;
    orgID: string;
    filters?: Map<string, FilterMetadata>;
    activeButton: string;
    setActiveButton: (newTerm: string) => void;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    handleToggle?: (type: string, value: any) => () => void;
    isAdmin: boolean;
    width: number;
}

export const drawerWidth = "250px";

export default function WorkspaceSideBarComponent(props: SideBarProps) {
    const [selectedItem, setSelectedItem] = useState(props.activeButton);

    const [teamID, setTeamID] = useState<string | null>(null);

    // Change this to store the ID of the currently expanded team (if any)
    const [expandedTeamId, setExpandedTeamId] = useState<string>(props.orgID);
    const { pathname } = useLocation();

    useEffect(() => {
        const path = window.location.pathname;
        if (path.includes("/preferences/profile")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.profile);
        } else if (path.includes("/preferences/views")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.views);
        } else if (path.includes("/preferences/labels")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.labels);
        } else if (path.includes("/preferences/notifications")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.notifications);
        } else if (path.includes("/preferences/members")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.members);
        } else if (path.includes("/preferences/sla")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.sla);
        } else if (path.includes("/preferences/customergroups")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.customergroups);
        } else if (path.includes("/preferences/teams")) {
            const id = path.split("/").pop(); // Get the last segment of the path
            setTeamID(id || null); // Set teamID to the extracted id
            setSelectedItem(`savedTeam_${id}`);
        }
    }, [window.location.pathname]);

    const handleMenuClick = (buttonName: string, teamID?: string) => {
        // You can call the respective function here
        props.setActiveButton(buttonName);
        setSelectedItem(buttonName);
        if (buttonName === NAVBAR_PREFERENCES_ITEMS.profile) {
            navigate("/preferences/profile");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.views) {
            navigate("/preferences/views");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.labels) {
            navigate("/preferences/labels");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.notifications) {
            navigate("/preferences/notifications");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.members) {
            navigate("/preferences/members");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.sla) {
            navigate("/preferences/sla");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.customergroups) {
            navigate("/preferences/customergroups");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.workflows) {
            navigate("/preferences/workflows");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.teams) {
            navigate("/preferences/teams");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.savedTeam) {
            if (teamID) {
                setSelectedItem(`savedTeam_${teamID}`);
                navigate(`/preferences/teams/${teamID}`);
            } else {
                navigate("/preferences");
            }
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.savedTeamSettings) {
            if (teamID) {
                setSelectedItem(`savedTeamSettings_${teamID}`);
                navigate(`/preferences/teams/${teamID}/settings`);
            } else {
                navigate("/preferences");
            }
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.savedTeamLabels) {
            if (teamID) {
                setSelectedItem(`savedTeamLabels_${teamID}`);
                navigate(`/preferences/teams/${teamID}/labels`);
            } else {
                navigate("/preferences");
            }
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.savedTeamViews) {
            if (teamID) {
                setSelectedItem(`savedTeamViews_${teamID}`);
                navigate(`/preferences/teams/${teamID}/views`);
            } else {
                navigate("/preferences");
            }
        }
    };

    const authInfo = useAuthInfo();

    const authInfoRef = useRef(authInfo);

    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listTeams;
            const response = await fetch(`${URLS.serverUrl}${url}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
            });
            const d = await response.json();
            return d.data;
        },
    });

    const { state } = useSidebar();
    const isCollapsed = state === "collapsed";

    const deleteTeamsMutation = useMutation({
        mutationFn: async (id: string) => {
            const [url, method] = TeamsAPI.deleteTeam;
            const response = await fetch(`${URLS.serverUrl}${url}/${id}`, {
                method: method,
                headers: {
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            return result.data;
        },
        onSuccess: (data) => {
            teamsQuery.refetch();
            const currentViewId = selectedItem.split("_")[1];
            if (currentViewId === data) {
                setSelectedItem(NAVBAR_PREFERENCES_ITEMS.members);
                navigate("/preferences/members");
            }
        },
    });

    const collapsed = props.width < 10;
    const navigate = useNavigate();

    const [teamViewsExpanded, setTeamViewsExpanded] = useState<boolean>(true);

    const toggleTeamExpanded = (teamId: string) => {
        setExpandedTeamId((prevId) => (prevId === teamId ? "" : teamId));
    };

    const viewsQuery = useQuery<View[]>({
        queryKey: ["views"],
        queryFn: async () => {
            const [url, method] = APIWithMethod.listViews;
            const response = await fetch(`${URLS.serverUrl}${url}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
            });
            const d = await response.json();
            return d.data;
        },
    });

    const deleteViewsMutation = useMutation({
        mutationFn: async (id: string) => {
            const [url, method] = APIWithMethod.deleteView;
            const response = await fetch(`${URLS.serverUrl}${url}/${id}`, {
                method: method,
                headers: {
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            return result.data;
        },
        onSuccess: (data) => {
            viewsQuery.refetch();
        },
    });

    return (
        <Sidebar collapsible="icon">
            <SidebarHeader>
                <MenuDropdownComponent
                    organizationName={props.organizationName}
                    state={pages.search}
                    collapsed={isCollapsed}
                />
            </SidebarHeader>

            <SidebarContent>
                <SidebarGroup>
                    <SidebarGroupLabel className="text-xs">
                        My Account
                    </SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            <SidebarMenuItem>
                                <SidebarMenuButton
                                    onClick={() =>
                                        handleMenuClick(
                                            NAVBAR_PREFERENCES_ITEMS.profile,
                                        )
                                    }
                                    isActive={
                                        selectedItem ===
                                        NAVBAR_PREFERENCES_ITEMS.profile
                                    }
                                    asChild
                                    size="sm"
                                >
                                    <Link to="/preferences/profile">
                                        <PersonIcon className="h-4 w-4" />
                                        <span>Profile</span>
                                    </Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>

                {props.isAdmin && (
                    <SidebarGroup>
                        <SidebarGroupLabel>
                            Workspace Preferences
                        </SidebarGroupLabel>
                        <SidebarGroupContent>
                            <SidebarMenu>
                                <SidebarMenuItem>
                                    <SidebarMenuButton
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.labels,
                                            )
                                        }
                                        isActive={
                                            selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.labels
                                        }
                                        asChild
                                        size="sm"
                                    >
                                        <Link to="/preferences/labels">
                                            <BookmarkIcon className="h-4 w-4" />
                                            <span>Labels</span>
                                        </Link>
                                    </SidebarMenuButton>
                                </SidebarMenuItem>
                                <SidebarMenuItem>
                                    <SidebarMenuButton
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.notifications,
                                            )
                                        }
                                        isActive={
                                            selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.notifications
                                        }
                                        asChild
                                        size="sm"
                                    >
                                        <Link to="/preferences/notifications">
                                            <BellIcon className="h-4 w-4" />
                                            <span>Notifications</span>
                                        </Link>
                                    </SidebarMenuButton>
                                </SidebarMenuItem>
                                <SidebarMenuItem>
                                    <SidebarMenuButton
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.members,
                                            )
                                        }
                                        isActive={
                                            selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.members
                                        }
                                        asChild
                                        size="sm"
                                    >
                                        <Link to="/preferences/members">
                                            <IdCardIcon className="h-4 w-4" />
                                            <span>Members</span>
                                        </Link>
                                    </SidebarMenuButton>
                                </SidebarMenuItem>
                                <SidebarMenuItem>
                                    <SidebarMenuButton
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.sla,
                                            )
                                        }
                                        isActive={
                                            selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.sla
                                        }
                                        asChild
                                        size="sm"
                                    >
                                        <Link to="/preferences/sla">
                                            <TimerIcon className="h-4 w-4" />
                                            <span>SLAs</span>
                                        </Link>
                                    </SidebarMenuButton>
                                </SidebarMenuItem>
                                <SidebarMenuItem>
                                    <SidebarMenuButton
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.workflows,
                                            )
                                        }
                                        isActive={
                                            selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.workflows
                                        }
                                        asChild
                                        size="sm"
                                    >
                                        <Link to="/preferences/workflows">
                                            <ThickArrowRightIcon className="h-4 w-4" />
                                            <span>Workflows</span>
                                        </Link>
                                    </SidebarMenuButton>
                                </SidebarMenuItem>
                                <SidebarMenuItem>
                                    <SidebarMenuButton
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.customergroups,
                                            )
                                        }
                                        isActive={
                                            selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.customergroups
                                        }
                                        asChild
                                        size="sm"
                                    >
                                        <Link to="/preferences/customergroups">
                                            <GroupIcon className="h-4 w-4" />
                                            <span>Customer Groups</span>
                                        </Link>
                                    </SidebarMenuButton>
                                </SidebarMenuItem>
                            </SidebarMenu>
                        </SidebarGroupContent>
                    </SidebarGroup>
                )}
                {props.isAdmin && (
                    <Collapsible
                        defaultOpen
                        open={teamViewsExpanded}
                        onOpenChange={setTeamViewsExpanded}
                    >
                        <SidebarGroup>
                            <SidebarGroupLabel>
                                <CollapsibleTrigger className="flex items-center justify-between w-full">
                                    Teams
                                    {teamViewsExpanded ? (
                                        <ChevronDown className="h-4 w-4" />
                                    ) : (
                                        <ChevronRight className="h-4 w-4" />
                                    )}
                                </CollapsibleTrigger>
                            </SidebarGroupLabel>
                            <CollapsibleContent>
                                <SidebarGroupContent>
                                    <SidebarMenu>
                                        {teamsQuery.data?.map((team) => (
                                            <SidebarMenuItem key={team.id}>
                                                <Collapsible
                                                    open={
                                                        expandedTeamId ===
                                                        team.id
                                                    }
                                                >
                                                    <ContextMenu>
                                                        <ContextMenuTrigger
                                                            asChild
                                                        >
                                                            <SidebarMenuButton
                                                                onClick={() =>
                                                                    toggleTeamExpanded(
                                                                        team.id,
                                                                    )
                                                                }
                                                                className="flex items-center gap-2 cursor-pointer"
                                                                asChild
                                                                size="sm"
                                                            >
                                                                <SidebarMenuSubButton size="sm">
                                                                    {team.id ===
                                                                        props.orgID ? (
                                                                        <div className="flex  h-6 w-6 p-1 items-center justify-center rounded-lg bg-iris3 border border-iris4">
                                                                            <HouseIcon className="h-4 w-4 text-iris9" />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="flex  h-6 w-6 p-1 items-center justify-center rounded-lg bg-red3 border border-red4">
                                                                            <UsersIcon className="h-4 w-4 text-red9" />
                                                                        </div>
                                                                    )}
                                                                    <span>
                                                                        {
                                                                            team.team_name
                                                                        }
                                                                    </span>
                                                                    {expandedTeamId ===
                                                                        team.id ? (
                                                                        <TriangleDownIcon className="h-4 w-4" />
                                                                    ) : (
                                                                        <TriangleRightIcon className="h-4 w-4" />
                                                                    )}
                                                                </SidebarMenuSubButton>
                                                            </SidebarMenuButton>
                                                        </ContextMenuTrigger>
                                                        {team.id !==
                                                            props.orgID && (
                                                                <ContextMenuContent className="w-60">
                                                                    <ContextMenuItem
                                                                        onClick={() =>
                                                                            deleteTeamsMutation.mutate(
                                                                                team.id,
                                                                            )
                                                                        }
                                                                    >
                                                                        <TrashIcon className="mr-2 h-4 w-4" />
                                                                        Delete Team
                                                                    </ContextMenuItem>
                                                                </ContextMenuContent>
                                                            )}
                                                    </ContextMenu>

                                                    <CollapsibleContent>
                                                        <SidebarMenuSub className="ml-4 mr-0 border-l-[#e2e8f0]">
                                                            <SidebarMenuSubItem>
                                                                <SidebarMenuSubButton
                                                                    onClick={() =>
                                                                        handleMenuClick(
                                                                            NAVBAR_PREFERENCES_ITEMS.savedTeam,
                                                                            team.id,
                                                                        )
                                                                    }
                                                                    isActive={
                                                                        pathname ===
                                                                        `/preferences/teams/${team.id}`
                                                                    }
                                                                    className="flex items-center gap-2 cursor-pointer"
                                                                    asChild
                                                                    size="sm"
                                                                >
                                                                    <Link
                                                                        to={`/preferences/teams/${team.id}`}
                                                                    >
                                                                        <IdCardIcon className="h-4 w-4" />
                                                                        <span>
                                                                            Members
                                                                        </span>
                                                                    </Link>
                                                                </SidebarMenuSubButton>
                                                            </SidebarMenuSubItem>
                                                            {props.orgID !==
                                                                team.id ? (
                                                                <SidebarMenuItem>
                                                                    <SidebarMenuButton
                                                                        isActive={
                                                                            pathname ===
                                                                            `/preferences/teams/${team.id}/settings`
                                                                        }
                                                                        className="flex items-center gap-2 cursor-pointer"
                                                                        asChild
                                                                        size="sm"
                                                                    >
                                                                        <Link
                                                                            to={`/preferences/teams/${team.id}/settings`}
                                                                        >
                                                                            <SettingsIcon className="h-4 w-4" />
                                                                            <span>
                                                                                Settings
                                                                            </span>
                                                                        </Link>
                                                                    </SidebarMenuButton>
                                                                </SidebarMenuItem>
                                                            ) : null}
                                                            {props.orgID !==
                                                                team.id ? (
                                                                <SidebarMenuItem>
                                                                    <SidebarMenuButton
                                                                        asChild
                                                                        isActive={
                                                                            pathname ===
                                                                            `/preferences/teams/${team.id}/labels`
                                                                        }
                                                                        size="sm"
                                                                    >
                                                                        <Link
                                                                            to={`/preferences/teams/${team.id}/labels`}
                                                                        >
                                                                            <BookmarkIcon className="h-4 w-4" />
                                                                            <span>
                                                                                Labels
                                                                            </span>
                                                                        </Link>
                                                                    </SidebarMenuButton>
                                                                </SidebarMenuItem>
                                                            ) : null}
                                                            {props.orgID !==
                                                                team.id ? (
                                                                <SidebarMenuItem>
                                                                    <SidebarMenuButton
                                                                        asChild
                                                                        isActive={
                                                                            pathname ===
                                                                            `/preferences/teams/${team.id}/notifications`
                                                                        }
                                                                        size="sm"
                                                                    >
                                                                        <Link
                                                                            to={`/preferences/teams/${team.id}/notifications`}
                                                                        >
                                                                            <BellIcon className="h-4 w-4" />
                                                                            <span>
                                                                                Notifications
                                                                            </span>
                                                                        </Link>
                                                                    </SidebarMenuButton>
                                                                </SidebarMenuItem>
                                                            ) : null}
                                                        </SidebarMenuSub>
                                                    </CollapsibleContent>
                                                </Collapsible>
                                            </SidebarMenuItem>
                                        ))}
                                    </SidebarMenu>
                                </SidebarGroupContent>
                            </CollapsibleContent>
                        </SidebarGroup>
                    </Collapsible>
                )}
            </SidebarContent>

            {!isCollapsed && (
                <SidebarFooter>
                    <Button
                        className="bg-[#5B5BD6] h-8 text-xs text-white items-center hover:shadow-lg shadow-md outline outline-1 outline-offset-0 outline-white flex flex-wrap gap-2"
                        variant="outline"
                        onClick={() => navigate("/preferences/teams/new")}
                    >
                        Add Team
                        <PlusIcon />
                    </Button>

                    <BallPopover />
                </SidebarFooter>
            )}
        </Sidebar>
    );
}
