import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse, ScopeResponse } from "@/interfaces/serverData";
import type { BotMetadata } from "@/interfaces/serverData";
import type { GetConnectionsResponse } from "@/interfaces/serverData";
import {
    CheckCircledIcon,
    CrossCircledIcon,
    PlusIcon,
} from "@radix-ui/react-icons";
import * as Toast from "@radix-ui/react-toast";
import {
    Badge,
    Box,
    Button,
    Callout,
    Card,
    DataList,
    Flex,
    Separator,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { format, parseISO } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DiscordSvg } from "../../../images/integrations/discord.svg";
import type { BotConnection } from "../../../interfaces/serverData";
import "../toast_styles.css";
import { useQuery } from "@tanstack/react-query";
import ModeratorCard from "./IntegrationCards/ModeratorCard";
import IntegrationHeader from "./IntegrationHeader";
import Shortcuts from "./Shortcuts";
export interface metadataType {
    scopes: ScopeResponse[];
    indexedOn: string;
}

const DiscordIntegration = () => {
    const discordContinue = () => {
        const client_id = process.env.REACT_APP_DISCORD_CLIENT_ID;
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/discord`;
        const discord_url = `https://discord.com/oauth2/authorize?response_type=code&client_id=${client_id}&permissions=536939536&scope=bot+applications.commands&redirect_uri=${redirect_url}`;
        window.open(discord_url, "_self");
    };

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [additionalMetadata, setAdditionalMetadata] = useState<
        Map<string, metadataType>
    >(new Map());

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    const [enabledIntegrations, setEnabledIntegrations] = useState<string[]>(
        [],
    );

    const [toastText, setToastText] = useState<string>("Updated Settings!");

    const [open, setOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);
    const timerRef = useRef(0);

    const [asmTicketChannels, setAsmTicketChannels] = useState<string[]>([]);

    const [refreshPage, setRefreshPage] = useState<boolean>(false);

    const [allUsers, setAllUsers] = useState<ScopeResponse[]>([]);

    useEffect(() => {
        if (integrationEnabled) {
            api.get(`${URLS.serverUrl + API.getAllSourceUsers}/Discord`, {
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((res) => {
                if (res.status === 200) {
                    setAllUsers(res.data.data);
                }
            });
        }
    }, [api]);

    function saveSettings(
        connectionInput: string,
        connectionMetadata: ScopeResponse[],
    ) {
        const requestData: BotConnection = {
            bot_type: "Discord",
            connection_unique_name: "Standard",
            connection_source: connectionInput,
            connection_channels: connectionMetadata,
            operation: "Add",
        };

        api.post(URLS.serverUrl + API.updateConnection, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                } else {
                    setToastText("Updated Settings!");
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setOpen(false);
                setRefreshPage((prev) => !prev);

                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setOpen(true);
                }, 100);
            });
    }

    function saveBotSettings() {
        const requestData: BotMetadata = {
            bot_type: "Discord",
            space_owners: adminDiscordUsers,
        };

        api.post(
            `${URLS.serverUrl}${API.saveBotSettingsV2}/discord`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
            .then((res) => {
                if (res.status !== 200) {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                } else {
                    setToastText("Updated Settings!");
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setOpen(false);
                setRefreshPage((prev) => !prev);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setOpen(true);
                }, 100);
            });
    }

    const connectionsQuery = useQuery<GetConnectionsResponse[]>({
        queryKey: ["connections_discord"],
        queryFn: async () => {
            const requestData = {
                bot_type: "Discord",
            };
            const response = await api.post(
                URLS.serverUrl + API.getConnections,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            return response.data.data ?? [];
        },
    });

    useEffect(() => {
        if (refreshPage) {
            // keep this here to force update get connections after an update goes through
        }
        if (asmTicketChannels.length !== 0) {
            connectionsQuery.refetch();
        }
    }, [asmTicketChannels, api, refreshPage]);

    useEffect(() => {
        const requestData = {
            types: ["Discord", "GitHubTicket", "Linear"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp = new Map<string, metadataType>([
                    ["GitHubTicket", { scopes: [], indexedOn: "" }],
                    ["Discord", { scopes: [], indexedOn: "" }],
                    ["Linear", { scopes: [], indexedOn: "" }],
                ]);

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;
                        if (orgInfo.Discord) {
                            setIntegrationEnabled(true);
                            api.get(
                                `${URLS.serverUrl}${API.getBotSettingsV2}/Discord`,
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                },
                            )
                                .then((res) => {
                                    if (res.status === 200) {
                                        const settingsData: BotMetadata =
                                            res.data.data;
                                        const dataTemp: string[] = [];
                                        if (settingsData.asm_ticket_channels) {
                                            for (const repo of settingsData.asm_ticket_channels) {
                                                dataTemp.push(repo.name);
                                            }
                                        }
                                        setAsmTicketChannels(dataTemp);

                                        const spaceOwners: ScopeResponse[] = [];
                                        if (settingsData.space_owners) {
                                            for (const space_owner of settingsData.space_owners) {
                                                spaceOwners.push(space_owner);
                                            }
                                        }
                                        setAdminDiscordUsers(spaceOwners);
                                    } else {
                                        setLoadingState(2);
                                    }
                                })
                                .catch((res) => {
                                    setLoadingState(2);
                                });
                        }
                        if (orgInfo.Discord?.[0]?.scopes) {
                            for (const repo of orgInfo.Discord[0].scopes) {
                                temp.get("Discord")?.scopes.push(repo);
                            }

                            const metadata = temp.get("Discord");
                            if (metadata && orgInfo.Discord?.[0]?.date) {
                                const date: Date = parseISO(
                                    orgInfo.Discord?.[0]?.date ?? "",
                                );
                                const humanReadableDate = format(
                                    date,
                                    "MMMM dd, yyyy hh:mm a",
                                );
                                metadata.indexedOn = humanReadableDate;
                                temp.set("Discord", metadata);
                            }
                        }
                        setAdditionalMetadata(temp);
                        const enabledInts: string[] = [];
                        if (orgInfo.GitHubTicket) {
                            enabledInts.push("GitHubTicket");
                        }
                        if (orgInfo.Linear) {
                            enabledInts.push("Linear");
                        }

                        setEnabledIntegrations(enabledInts);

                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    const [adminDiscordUsers, setAdminDiscordUsers] = useState<ScopeResponse[]>(
        [],
    );

    const navigate = useNavigate();
    const addMoreRepositories = () => {
        navigate("/admin/integrations/update/discord");
    };

    const [isEditing, setIsEditing] = useState<boolean>(false);

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6" mb="3">
                    <IntegrationHeader
                        integrationType="Discord"
                        description="Stay up to date and interact with feature requests, bugs, and issues "
                        SvgIcon={DiscordSvg}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {loadingState === loadingTypes.loaded &&
                        !integrationEnabled ? (
                            <Button onClick={discordContinue}>Enable</Button>
                        ) : (
                            <Button disabled={true}>Enabled</Button>
                        )}
                    </Flex>
                </Flex>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && integrationEnabled && (
                    <Flex direction={"column"} gap="2" mb="2">
                        <Card
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                gap="2"
                                mb="2"
                                justify={"between"}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={"center"}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                    }}
                                >
                                    <Text size={"2"}>Channels Selected: </Text>

                                    <Button
                                        variant="outline"
                                        size="1"
                                        onClick={addMoreRepositories}
                                    >
                                        <PlusIcon width="15" height="15" /> Add
                                        More Channels
                                    </Button>
                                </Flex>
                                {asmTicketChannels?.length !== 0 && (
                                    <Separator
                                        size={"4"}
                                        mt="1"
                                        mb="1"
                                        style={{ width: "100%" }}
                                    />
                                )}
                                {asmTicketChannels?.length !== 0 && (
                                    <Flex
                                        style={{
                                            paddingRight: "20px",
                                            paddingLeft: "20px",
                                        }}
                                        direction="row"
                                        gap="3"
                                        align="center"
                                        wrap="wrap"
                                    >
                                        {asmTicketChannels.map((item) => (
                                            <Badge key={item}>{item}</Badge>
                                        ))}{" "}
                                    </Flex>
                                )}
                            </Flex>
                        </Card>

                        {additionalMetadata.get("Discord")?.indexedOn && (
                            <Flex
                                direction="column"
                                style={{
                                    justifyContent: "flex-end",
                                    alignItems: "end",
                                }}
                                gap="3"
                                mb="5"
                            >
                                <Flex direction={"row"}>
                                    <Text size={"1"}>
                                        Last Updated:{" "}
                                        {
                                            additionalMetadata.get("Discord")
                                                ?.indexedOn
                                        }{" "}
                                    </Text>
                                </Flex>
                            </Flex>
                        )}

                        <Card
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                gap="2"
                                mb="2"
                                justify={"between"}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={"center"}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                    }}
                                >
                                    <Text size={"2"}>Add Shortcut: </Text>

                                    <Shortcuts
                                        enabledIntegrations={
                                            enabledIntegrations
                                        }
                                        saveSettings={saveSettings}
                                        description={`If you type in the /issue or /lin command on
                                    Discord under a specified thread, we'll
                                    automatically create a ticket in the
                                    specified integration.`}
                                        botType="Discord"
                                    />
                                </Flex>
                                <Text
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                    }}
                                    size="1"
                                    color="gray"
                                >
                                    type in a specific /command (issue, lin,
                                    jira), under a discord thread to create a
                                    GitHub issue, Linear, or Jira ticket
                                </Text>
                                {connectionsQuery.data?.length !== 0 && (
                                    <Separator
                                        size={"4"}
                                        mt="1"
                                        mb="1"
                                        style={{ width: "100%" }}
                                    />
                                )}
                                {connectionsQuery.data?.length !== 0 && (
                                    <Flex
                                        style={{
                                            paddingRight: "20px",
                                            paddingLeft: "20px",
                                        }}
                                        direction="column"
                                        gap="3"
                                        align="start"
                                        wrap="wrap"
                                    >
                                        <DataList.Root>
                                            {connectionsQuery.data?.map(
                                                (item) => (
                                                    // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
                                                    <DataList.Item align="center">
                                                        <DataList.Label minWidth="88px">
                                                            {
                                                                item
                                                                    ?.connected_integrations
                                                                    ?.connection
                                                            }
                                                        </DataList.Label>
                                                        <DataList.Value>
                                                            {item?.connected_integrations?.scopes.map(
                                                                (badgeItem) => (
                                                                    <Badge
                                                                        radius="full"
                                                                        key={
                                                                            badgeItem.key
                                                                        }
                                                                    >
                                                                        {
                                                                            badgeItem?.name
                                                                        }
                                                                    </Badge>
                                                                ),
                                                            )}
                                                        </DataList.Value>
                                                    </DataList.Item>
                                                ),
                                            )}{" "}
                                        </DataList.Root>
                                    </Flex>
                                )}
                            </Flex>
                        </Card>

                        <ModeratorCard
                            adminUsers={adminDiscordUsers}
                            setAdminUsers={setAdminDiscordUsers}
                            saveBotSettings={saveBotSettings}
                            className="mb-4"
                            botType="Discord"
                            integrationEnabled={integrationEnabled}
                            getName={(option: ScopeResponse) =>
                                `${option.name}`
                            }
                            integrationName="Discord"
                        />
                    </Flex>
                )}
                <Toast.Provider swipeDirection="right">
                    <Toast.Root
                        className="ToastRoot"
                        open={open}
                        onOpenChange={setOpen}
                    >
                        <Toast.Title className="ToastTitle">
                            <Flex direction={"row"} align={"center"} gap="2">
                                <ToastSymbol color="green" />
                                {toastText}
                            </Flex>
                        </Toast.Title>
                    </Toast.Root>
                    <Toast.Viewport className="ToastViewport" />
                </Toast.Provider>
            </Box>
        </div>
    );
};

export default DiscordIntegration;
