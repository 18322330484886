import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import { FancyMultiSelect } from "@/component/MultiSelect";
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/component/shadcn/ui/alert";
import { Button } from "@/component/shadcn/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { Input } from "@/component/shadcn/ui/input";

import type {
    CustomerGroup,
    CustomerGroupCreatePayload,
    ScopeResponse,
} from "@/interfaces/serverData";
import {
    CheckCircledIcon,
    Cross2Icon,
    CrossCircledIcon,
    ExclamationTriangleIcon,
    PlusIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Badge, ChevronDownIcon, Separator } from "@radix-ui/themes";
import type React from "react";
import { useEffect, useRef, useState } from "react";

import { z } from "zod";

import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import { integrationBackEndDataMappingToSvg } from "../Admin/Integrations/constant";
import { useSearch } from "../SearchContext";
import type { CustomerGroupInfo } from "./CustomerGroups";

interface CustomerGroupPopupProps {
    customerGroup: CustomerGroup;
    sourceFields: CustomerGroupInfo[];
    triggerElement: React.ReactNode;
    editing: boolean;
    setToastOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setToastText: React.Dispatch<React.SetStateAction<string>>;
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType>>;
    setCustomerGroups: React.Dispatch<React.SetStateAction<CustomerGroup[]>>;
}

const formSchema = z.object({
    customerGroupName: z
        .string()
        .min(2, {
            message: "The customer group name must be at least 2 characters.",
        })
        .max(70, {
            message: "The customer group name must be at most 70 characters.",
        }),
    customerGroupDescription: z
        .string()
        .min(4, {
            message:
                "The customer group description must be at least 4 characters.",
        })
        .max(100, {
            message:
                "The customer group description must be at most 100 characters.",
        }),
    sourceMetadata: z.array(z.object({ source: z.string() })),
});

const CustomerGroupPopup: React.FC<CustomerGroupPopupProps> = ({
    customerGroup,
    sourceFields,
    triggerElement,
    editing,
    setToastSymbol,
    setToastText,
    setToastOpen,
    setCustomerGroups,
}) => {
    const { searchBarFilters, loading } = useSearch(); // using this for checking if CustomerGroupck integration is active
    const [open, setOpen] = useState<boolean>(false);
    const [filteredCustomerGroups, setFilteredCustomerGroups] = useState([
        {
            label: "Slack",
            value: "Slack",
            color: "",
        },
        {
            label: "Discord",
            value: "Discord",
            color: "",
        },
        // Only supporting Slack and Discord for customer groups currently
        // {
        //     label: "Google",
        //     value: "Google",
        //     color: "",
        // },
        // {
        //     label: "GitHubTicket",
        //     value: "GitHubTicket",
        //     color: "",
        // },
    ]);

    useEffect(() => {
        if (loading) {
            const updatedCustomerGroups = filteredCustomerGroups
                .filter((broadcast) => searchBarFilters.has(broadcast.value))
                .map((broadcast) => {
                    if (broadcast.value === "Google") {
                        return {
                            ...broadcast,
                            label: "Gmail",
                            value: "Gmail",
                            color: "",
                        };
                    }
                    return broadcast;
                });
            setFilteredCustomerGroups(updatedCustomerGroups);
        }
    }, [searchBarFilters, loading]);

    const defaultSource = "Source...";

    const validation = async () => {
        // if (selectedSource === defaultSource || selectedSource === "") {
        //     setErrorMsg("Please select a source!");
        //     setValidationErr(true);
        // } else {

        setOpen(false);
        setValidationErr(false);
        // }
    };

    const [customerGroupTitle, setCustomerGroupTitle] =
        useState<string>("New Customer Group");

    const [errorMsg, setErrorMsg] = useState<string>("");
    const [validationErr, setValidationErr] = useState<boolean>(false);

    useEffect(() => {
        if (editing) {
            setCustomerGroupTitle("Edit Customer Group");
        }
    }, [editing]);

    const api = useApi();

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            customerGroupName: customerGroup?.group_name ?? "",
            customerGroupDescription: customerGroup?.description ?? "",
            sourceMetadata: sourceFields.map((info) => ({
                source: info.selectedSource,
            })),
        },
    });

    const [fieldsState, setFieldsState] =
        useState<CustomerGroupInfo[]>(sourceFields);

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: "sourceMetadata",
    });

    const handleCustomerGroupChange = (index: number, newSource: string) => {
        const updatedFields = fieldsState.map((field, idx) =>
            idx === index
                ? {
                      ...field,
                      selectedSource: newSource,
                      filters: new Map(field.filters).set(
                          "Broadcast",
                          new Set([newSource]),
                      ),
                      icon:
                          integrationBackEndDataMappingToSvg.get(newSource) ||
                          undefined,
                      options: [],
                  }
                : field,
        );

        setFieldsState(updatedFields);

        api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/${newSource}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const dataItems: ScopeResponse[] =
                    res.data.data?.asm_ticket_channels;
                const updatedFieldsWithOptions = updatedFields.map(
                    (field, idx) =>
                        idx === index
                            ? {
                                  ...field,
                                  options: dataItems, // Set the retrieved options here
                                  selectedSource: field.selectedSource,
                              }
                            : field,
                );

                setFieldsState(updatedFieldsWithOptions);
            })
            .catch((res) => {
                console.error("Error fetching scope data:", res);
            });
    };

    const addNewSource = () => {
        append({ source: defaultSource });
        setFieldsState((prevState) => [
            {
                selectedSource: defaultSource,
                options: [],
                selectedChannels: [],
                icon: undefined,
                filters: new Map([["Broadcast", new Set([defaultSource])]]),
            },
            ...prevState,
        ]);
    };

    const removeSource = (index: number) => {
        remove(index);
        setFieldsState((prevState) =>
            prevState.filter((_, idx) => idx !== index),
        );
    };

    function onSubmit(values: z.infer<typeof formSchema>) {
        console.log(values);
    }

    const timerRef = useRef(0);

    const handleFormSubmit = async () => {
        let status = "CREATE";
        if (editing) {
            status = "EDIT";
        }

        handleSubmit(status);
    };

    const handleSubmit = async (status: string) => {
        if (
            form.getValues().customerGroupName === "" ||
            form.getValues().customerGroupDescription === ""
        ) {
            return;
        }
        const finalFields = new Map<string, ScopeResponse[]>([]);
        for (const field of fieldsState) {
            if (field.selectedSource !== "Source...") {
                finalFields.set(field.selectedSource, field.selectedChannels);
            }
        }

        if (finalFields.size === 0) {
            setErrorMsg("Add a source to create a customer group!");
            setValidationErr(true);
            return;
        } else {
            setValidationErr(false);
        }

        const scopeObject = Object.fromEntries(finalFields);
        const metadataPayload = JSON.stringify(scopeObject);

        const requestData: CustomerGroupCreatePayload = {
            id: customerGroup?.id,
            operation: status,
            group_name: form.getValues().customerGroupName,
            description: form.getValues().customerGroupDescription,
            metadata: metadataPayload,
        };
        console.log(requestData);

        api.post(URLS.serverUrl + API.saveCustomerGroup, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    setToastSymbol(CheckCircledIcon);
                    setToastText("Announcement saved!");
                    setCustomerGroups(res.data.data);
                } else {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                    console.log("could not create a draft announcement");
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setToastOpen(false);
                setOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    console.log("setting open to true");
                    setToastOpen(true);
                }, 100);
                setOpen(false);
            });
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{triggerElement}</DialogTrigger>
            <DialogContent className="fixed inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50">
                <div className="bg-white shadow-lg rounded-md p-7 w-1/2 relative">
                    <div className="ml-10 mr-10">
                        <DialogHeader className="justify-left text-left items-left pb-5">
                            <DialogTitle>{customerGroupTitle}</DialogTitle>
                            <DialogDescription>
                                Customer Groups are rules for how quickly you
                                want to respond and resolve customer issues.
                            </DialogDescription>
                        </DialogHeader>
                        <div className="flex flex-col gap-3">
                            <div className="">
                                <div className="">
                                    <Form {...form}>
                                        <form
                                            onSubmit={form.handleSubmit(
                                                (values) => onSubmit(values),
                                            )}
                                            className="flex flex-col space-y-4"
                                            id="announcements-form"
                                        >
                                            <FormField
                                                control={form.control}
                                                name="customerGroupName"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel
                                                            htmlFor="customerGroupName"
                                                            className="mt-2 text-xs flex-none"
                                                        >
                                                            Customer Group Name
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                id="customerGroupName"
                                                                className="mt-0 grow text-xs focus:outline-none focus:ring-0 focus:outline-offset-2 focus:outline-1"
                                                                style={{
                                                                    outlineColor:
                                                                        "#CBCDFF",
                                                                }}
                                                                {...field}
                                                                placeholder="Customer Group Name"
                                                            />
                                                        </FormControl>
                                                        <FormMessage className="text-xs" />
                                                    </FormItem>
                                                )}
                                            />

                                            <FormField
                                                control={form.control}
                                                name="customerGroupDescription"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel
                                                            htmlFor="customerGroupDescription"
                                                            className="mt-2 text-xs flex-none"
                                                        >
                                                            Description
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                id="customerGroupDescription"
                                                                className="mt-0 grow text-xs focus:outline-none focus:ring-0 focus:outline-offset-2 focus:outline-1"
                                                                style={{
                                                                    outlineColor:
                                                                        "#CBCDFF",
                                                                }}
                                                                {...field}
                                                                placeholder="Description"
                                                            />
                                                        </FormControl>
                                                        <FormMessage className="text-xs" />
                                                    </FormItem>
                                                )}
                                            />

                                            <Button
                                                className="self-end outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-iris8 shadow-md"
                                                size="sm"
                                                variant="outline"
                                                type="button"
                                                onClick={() => addNewSource()}
                                            >
                                                <PlusIcon />
                                                Add Source
                                            </Button>
                                            {fields.length !== 0 && (
                                                <FormLabel className="pb-0 mb-0 text-xs">
                                                    Sources
                                                </FormLabel>
                                            )}

                                            <div className="max-h-[300px] overflow-auto pb-10">
                                                {fields.map((item, index) => {
                                                    const IconComponent =
                                                        fieldsState[index]
                                                            ?.icon;

                                                    return (
                                                        <FormField
                                                            key={item.id}
                                                            control={
                                                                form.control
                                                            }
                                                            name={
                                                                "sourceMetadata"
                                                            }
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <>
                                                                    {index >=
                                                                        1 && (
                                                                        <Separator
                                                                            size="4"
                                                                            className="mt-4 mb-4"
                                                                        />
                                                                    )}
                                                                    <FormItem className="flex flex-row justify-between items-start">
                                                                        <div className="flex flex-col gap-y-5">
                                                                            <FormControl>
                                                                                <div className="z-[500] flex flex-col items-start gap-3">
                                                                                    <DropdownMenu>
                                                                                        <DropdownMenuTrigger
                                                                                            asChild
                                                                                            type="button"
                                                                                        >
                                                                                            <Badge
                                                                                                color={
                                                                                                    "gray"
                                                                                                }
                                                                                                size="2"
                                                                                                radius="full"
                                                                                                variant="outline"
                                                                                                className="m-0.5 px-2 py-1 text-xs rounded-xl"
                                                                                            >
                                                                                                <div className="flex flex-row items-center">
                                                                                                    <>
                                                                                                        {IconComponent !==
                                                                                                            undefined && (
                                                                                                            <IconComponent className="w-4 h-4" />
                                                                                                        )}
                                                                                                        <p className="pl-1 pr-1">
                                                                                                            {
                                                                                                                fieldsState[
                                                                                                                    index
                                                                                                                ]
                                                                                                                    .selectedSource
                                                                                                            }
                                                                                                        </p>
                                                                                                        <ChevronDownIcon />
                                                                                                    </>
                                                                                                </div>
                                                                                            </Badge>
                                                                                        </DropdownMenuTrigger>
                                                                                        <DropdownMenuContent
                                                                                            align="start"
                                                                                            className=" fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                                                                        >
                                                                                            <FilterDropdownElement
                                                                                                type={
                                                                                                    "Broadcast"
                                                                                                }
                                                                                                filters={
                                                                                                    fieldsState[
                                                                                                        index
                                                                                                    ]
                                                                                                        .filters
                                                                                                }
                                                                                                handleItemSelect={(
                                                                                                    type: string,
                                                                                                    value: string,
                                                                                                ) => {
                                                                                                    return () => {
                                                                                                        if (
                                                                                                            type ===
                                                                                                            "Broadcast"
                                                                                                        ) {
                                                                                                            handleCustomerGroupChange(
                                                                                                                index,
                                                                                                                value,
                                                                                                            );
                                                                                                        }
                                                                                                    };
                                                                                                }}
                                                                                                topics={[]}
                                                                                                users={[]}
                                                                                                customerGroups={[]}
                                                                                                extraOptions={
                                                                                                    filteredCustomerGroups
                                                                                                }
                                                                                                isSavedViewFilter={
                                                                                                    false
                                                                                                }
                                                                                            />
                                                                                        </DropdownMenuContent>
                                                                                    </DropdownMenu>
                                                                                    <div className="pl-3">
                                                                                        <FancyMultiSelect
                                                                                            setSelectedChannels={(
                                                                                                newChannels:
                                                                                                    | ScopeResponse[]
                                                                                                    | ((
                                                                                                          prev: ScopeResponse[],
                                                                                                      ) => ScopeResponse[]),
                                                                                            ) => {
                                                                                                // Ensure newChannels is of type ScopeResponse[]
                                                                                                setFieldsState(
                                                                                                    (
                                                                                                        prevState,
                                                                                                    ) => {
                                                                                                        const newState =
                                                                                                            [
                                                                                                                ...prevState,
                                                                                                            ];
                                                                                                        newState[
                                                                                                            index
                                                                                                        ].selectedChannels =
                                                                                                            Array.isArray(
                                                                                                                newChannels,
                                                                                                            )
                                                                                                                ? newChannels
                                                                                                                : newChannels(
                                                                                                                      prevState[
                                                                                                                          index
                                                                                                                      ]
                                                                                                                          .selectedChannels,
                                                                                                                  );
                                                                                                        return newState;
                                                                                                    },
                                                                                                );
                                                                                            }}
                                                                                            selectedChannels={
                                                                                                fieldsState[
                                                                                                    index
                                                                                                ]
                                                                                                    ?.selectedChannels ||
                                                                                                []
                                                                                            }
                                                                                            options={
                                                                                                fieldsState[
                                                                                                    index
                                                                                                ]
                                                                                                    .options
                                                                                            }
                                                                                            placeholder="Select Channels..."
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </FormControl>
                                                                            <FormMessage className="text-xs" />
                                                                        </div>
                                                                        <Button
                                                                            type="button"
                                                                            variant="outline"
                                                                            size="sm"
                                                                            className="mt-0"
                                                                            onClick={() => {
                                                                                removeSource(
                                                                                    index,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <TrashIcon />
                                                                        </Button>
                                                                    </FormItem>
                                                                </>
                                                            )}
                                                        />
                                                    );
                                                })}
                                            </div>

                                            <div className="flex flex-row gap-10 self-end ">
                                                <Button
                                                    className="self-end outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-md"
                                                    size="sm"
                                                    variant="outline"
                                                    type="submit"
                                                    value="submit"
                                                    form="announcements-form"
                                                    onClick={handleFormSubmit}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </form>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <DialogFooter className="flex flex-col">
                            {validationErr && (
                                <Alert variant="destructive" className="mt-4">
                                    <div className="flex flex-row gap-3 items-center">
                                        <ExclamationTriangleIcon className="h-4 w-4" />
                                        <div>
                                            <AlertTitle>Error</AlertTitle>
                                            <AlertDescription>
                                                {errorMsg}
                                            </AlertDescription>
                                        </div>
                                    </div>
                                </Alert>
                            )}
                            <DialogClose asChild>
                                <Button
                                    type="button"
                                    variant="ghost"
                                    className="absolute top-4 right-4"
                                    onClick={() => {
                                        setValidationErr(false);
                                    }}
                                >
                                    <Cross2Icon />
                                </Button>
                            </DialogClose>
                        </DialogFooter>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default CustomerGroupPopup;
