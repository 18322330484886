import { APIWithMethod, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { View } from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import { Box, Callout, Skeleton, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { generateViewsColumns } from "./DataTable/columns";
import type { ViewsRowState } from "./DataTable/constants";
import { ViewsDataTable } from "./DataTable/views-data-table";

const Views: React.FC = () => {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const { team_id } = useParams();

    const [rowState, setRowState] = useState<Map<string, ViewsRowState>>(
        new Map(),
    );

    const updateRowState = (id: string, newState: Partial<ViewsRowState>) => {
        setRowState((prevState) => {
            const newStateMap = new Map(prevState);
            const currentState = newStateMap.get(id) || {
                id: "",
                name: "",
                description: "",
            };
            newStateMap.set(id, { ...currentState, ...newState });
            return newStateMap;
        });
    };
    const authInfo = useAuthInfo();

    const authInfoRef = useRef(authInfo);
    const viewsQuery = useQuery<View[]>({
        queryKey: ["views"],
        queryFn: async () => {
            const [url, method] = APIWithMethod.listViews;
            const response = await fetch(`${URLS.serverUrl}${url}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
            });
            const d = await response.json();
            return d.data;
        },
    });

    useEffect(() => {
        if (viewsQuery.data) {
            const newRowState = new Map<string, ViewsRowState>();
            for (let i = 0; i < viewsQuery.data.length; i++) {
                const view: View = viewsQuery.data[i];
                newRowState.set(String(i), {
                    id: view.id,
                    name: view.name,
                    description: view.description,
                });
            }
            setRowState(newRowState);
            setLoadingState(1);
        }
    }, [viewsQuery.data]);

    return (
        <div>
            <Box mt={"5%"} ml={"7%"} mr={"7%"}>
                <h2 className="text-2xl font-semibold">Views</h2>
                <p className="text-sm text-gray11 pb-10">
                    Manage your saved views for this team.
                </p>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && (
                    <div className="flex flex-row gap-8">
                        <ViewsDataTable
                            data={viewsQuery.data ?? []}
                            columns={generateViewsColumns(
                                rowState,
                                updateRowState,
                            )}
                        />
                    </div>
                )}
            </Box>
        </div>
    );
};

export default Views;
