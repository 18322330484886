
import { Card, CardContent } from "@/component/shadcn/ui/card";
import type { IssueScore, QueriesWithPaginationResponse, Ticket } from "@/interfaces/serverData";
import type React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { cleanText, convertTicketToQuery, getHtmlStringFromReactContent, getStatusIcon } from "@/utilities/methods";
import { toHTML } from "slack-markdown";
import parse from "html-react-parser";
import { Badge } from "@radix-ui/themes";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/component/shadcn/ui/tooltip";
import { OpenInNewWindowIcon } from "@radix-ui/react-icons";
import { Button } from "@/component/shadcn/ui/button";
import { CreateInsightPopup } from "@/Insights/CreateInsightPopup";
import { type QueryObserverResult, type RefetchOptions, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/interfaces/api";
import { API, URLS } from "@/constant";

interface SimilarIssuesSectionProps {
    issue: Ticket;
    userId: string;
    similarIssues: IssueScore[];
    loadingSimilarIssues: boolean;
    genTitle: string;
    genDescription: string;
    teamID?: string;
    refetchTicketData?: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
}

const SimilarIssuesSection: React.FC<SimilarIssuesSectionProps> = ({
    issue,
    userId,
    similarIssues,
    loadingSimilarIssues,
    genTitle,
    genDescription,
    teamID,
    refetchTicketData
}) => {
    const api = useApi()
    const queryClient = useQueryClient();
    const fetchQueries = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        try {
            const response = await api.get(
                URLS.serverUrl + API.queriesWithPagination,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 1000,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const fetchTeamQueries = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        const teamID = window.location.pathname.split("/")[2];
        if (!teamID) {
            console.error("Team ID not found in URL");
            return { data: [], has_next_page: false, next_cursor: 0 };
        }

        try {
            const response = await api.get(
                `${URLS.serverUrl}${API.queriesByTeam}/${teamID}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 1000,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: teamID
            ? [`teamQueries_${teamID}`]
            : ["queries"],
        queryFn: teamID
            ? fetchTeamQueries
            : fetchQueries,
        getNextPageParam: (lastPage) => {
            if (lastPage.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });
    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                ) // Filter out null or undefined items in page.data
            : [];

    const handleClick =
        (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
            const url = `/issue/${id}`;
            window.open(url, "_blank");
        };

    const timestampCutoff = "2024-09-30T23:50:00.000000Z";
    const parsedTimestampCutoff = new Date(timestampCutoff);

    return (
        <div className="flex flex-col gap-3">
            {loadingSimilarIssues ? (
                <div className="flex items-center gap-2 text-xs flex items-center rounded-lg w-full mb-2">
                    Finding similar issues...
                    <div className="w-3 h-3 border-2 border-t-4 border-primary border-dashed rounded-full animate-spin" />
                </div>
            ) : (<div className="max-h-96 overflow-y-auto mb-3 flex flex-col gap-2">
                <div>
                    <CreateInsightPopup
                        userID={userId}
                        queryClient={queryClient}
                        issues={combinedData}
                        startingTitle={genTitle}
                        startingDescription={genDescription}
                        startingRelatedIssues={[convertTicketToQuery(issue), ...similarIssues.map(issueScore => issueScore.issue)]}
                        teamID={teamID}
                        fromSidebarCard={true}
                        refetchTicketData={refetchTicketData}
                    />
                </div>
                {similarIssues.length === 0 ? (
                    <div className="flex items-center gap-2 text-xs flex items-center rounded-lg w-full">
                        No similar issues found.
                    </div>
                ) : (
                    similarIssues.map((issueScore) => {
                        const commentParsedTimestamp = new Date(issueScore.issue.created_at);
                        const Icon = getStatusIcon(issueScore.issue.ticket_status);
                        let updatedDate = new Date(issueScore.issue.ticket_updated_at);
                        if (
                            Number.isNaN(updatedDate.getTime()) ||
                            !issueScore.issue.ticket_updated_at
                        ) {
                            updatedDate = new Date();
                        }
                        // Otherwise, return the standard date format
                        const userLocale = navigator.language || "en-US";
                        const date = updatedDate.toLocaleDateString(userLocale, {
                            month: "short",
                            day: "numeric",
                        });
                        const SourceSvgImage: React.ElementType | undefined = integrationBackEndDataMappingToSvg.get(
                            issueScore.issue.source ?? "Unknown",
                        )
                        return (
                            <Card key={issueScore.issue.id} className="p-2 rounded">
                                <CardContent className="py-1 px-1.5">
                                    <div className="flex flex-col w-full gap-2">
                                        <div className="flex flex-col" style={{ maxWidth: "100%" }}>
                                            <div className="flex items-center justify-between text-[9.5px] text-muted-foreground">
                                                {`${issueScore.ticket_identifier ?? "ASM"}-${issueScore.issue.number}`}
                                                <Button
                                                    type="button"
                                                    variant="ghost"
                                                    className="hover:bg-muted px-1 py-0 -m-1"
                                                    onClick={handleClick(
                                                        `${issueScore.ticket_identifier ?? "ASM"}-${issueScore.issue.number}`,
                                                    )}
                                                >
                                                    <TooltipProvider>
                                                        <Tooltip>
                                                            <TooltipTrigger asChild>
                                                                <OpenInNewWindowIcon className="w-3.5 h-3.5" />
                                                            </TooltipTrigger>
                                                            <TooltipContent className="bg-[#5B5BD6] text-xs">
                                                                <p>Open Issue</p>
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                </Button>
                                            </div>
                                            <div className="text-xs font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                                                {issueScore.issue.title?.trim() ||
                                                    issueScore.issue.query}
                                            </div>
                                            {(issueScore.issue.source === "Slack" ||
                                                issueScore.issue.source ===
                                                "CommunitySlack") &&
                                                commentParsedTimestamp <
                                                parsedTimestampCutoff ? (
                                                <div className="text-[11px] text-muted-foreground overflow-hidden whitespace-nowrap text-ellipsis">
                                                    <ReactMarkdown
                                                        remarkPlugins={[remarkGfm]}
                                                        rehypePlugins={[rehypeRaw]}
                                                    >
                                                        {getHtmlStringFromReactContent(
                                                            parse(
                                                                toHTML(
                                                                    cleanText(
                                                                        issueScore.issue.query ??
                                                                        "",
                                                                    ),
                                                                ),
                                                            ),
                                                        )}
                                                    </ReactMarkdown>
                                                </div>
                                            ) : (
                                                <div className="text-xs text-muted-foreground overflow-hidden whitespace-nowrap text-ellipsis">
                                                    <ReactMarkdown
                                                        remarkPlugins={[remarkGfm]}
                                                        rehypePlugins={[rehypeRaw]}
                                                    >
                                                        {cleanText(
                                                            issueScore.issue.query ??
                                                            "",
                                                        )}
                                                    </ReactMarkdown>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex items-center justify-between overflow-hidden" style={{ maxWidth: "100%" }}>
                                            <Badge
                                                color={"gray"}
                                                size="2"
                                                radius="medium"
                                                variant="outline"
                                                className="m-0 px-1 py-0.5 ring-[0.8px] text-gray-700 ring-[#E0E1E6]"
                                            >
                                                <Icon
                                                    style={{
                                                        height: "12px",
                                                    }}
                                                />
                                                <span className="text-[11px] font-normal">
                                                    {issueScore.issue.ticket_status}
                                                </span>
                                            </Badge>
                                            <div className="flex items-center">
                                                <div className="mx-1.5 text-[11px] w-15">{date}</div>
                                                {SourceSvgImage && (
                                                    <SourceSvgImage className="w-4 h-4 mr-0.5" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>)
                    })
                )}
            </div>)}

        </div>
    );
};

export default SimilarIssuesSection;
