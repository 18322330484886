import { Button } from "@/component/shadcn/ui/button";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import type { Table } from "@tanstack/react-table";

interface DataTablePaginationProps<TData> {
    table: Table<TData>;
    limit: number;
    setLimit: React.Dispatch<React.SetStateAction<number>>;
    offset: number;
    setOffset: React.Dispatch<React.SetStateAction<number>>;
}

export function DataTablePagination<TData>({
    table,
    limit,
    setLimit,
    offset,
    setOffset,
}: DataTablePaginationProps<TData>) {
    return (
        <Flex direction="row-reverse" align="center" justify="between">
            <Flex direction="row" align="center" gap="6" className="pb-2">
                <Flex direction="row" align="center" gap="2">
                    <p className="text-sm font-medium">Rows per page</p>
                    <Select
                        value={`${table.getState().pagination.pageSize}`}
                        onValueChange={(value) => {
                            table.setPageSize(Number(value));
                            setLimit(Number(value));
                        }}
                    >
                        <SelectTrigger className="h-8 w-[70px]">
                            <SelectValue
                                placeholder={
                                    table.getState().pagination.pageSize
                                }
                            />
                        </SelectTrigger>
                        <SelectContent side="top">
                            {[10, 20, 50, 100].map((pageSize) => (
                                <SelectItem
                                    key={pageSize}
                                    value={`${pageSize}`}
                                    className="p-2"
                                >
                                    {pageSize}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </Flex>
                <div className="text-sm font-medium">
                    {`Page ${Math.floor(offset / limit) + 1} of ${table.getPageCount()}`}
                </div>
                <Flex direction="row" align="center" gap="2">
                    <Button
                        variant="outline"
                        className="hidden h-8 w-8 p-0 lg:flex hover:bg-muted cursor-pointer"
                        onClick={() => {
                            table.setPageIndex(0);
                            setOffset(0);
                        }}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {/* <span className="sr-only">Go to first page</span> */}
                        <DoubleArrowLeftIcon className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="outline"
                        className="h-8 w-8 p-0 lg:flex hover:bg-muted cursor-pointer"
                        onClick={() => {
                            table.previousPage();
                            setOffset(offset - limit);
                        }}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {/* <span className="sr-only">Go to previous page</span> */}
                        <ChevronLeftIcon className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="outline"
                        className="h-8 w-8 p-0 lg:flex hover:bg-muted cursor-pointer"
                        onClick={() => {
                            table.nextPage();
                            setOffset(offset + limit);
                        }}
                        disabled={!table.getCanNextPage()}
                    >
                        {/* <span className="sr-only">Go to next page</span> */}
                        <ChevronRightIcon className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="outline"
                        className="hidden h-8 w-8 p-0 lg:flex hover:bg-muted cursor-pointer"
                        onClick={() =>
                            table.setPageIndex(table.getPageCount() - 1)
                        }
                        disabled={!table.getCanNextPage()}
                    >
                        {/* <span className="sr-only">Go to last page</span> */}
                        <DoubleArrowRightIcon className="h-4 w-4" />
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
}
