import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { ASSEMBLY_COOKIES } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
// import { ReactComponent as JiraSvg } from "../../../images/integrations/jira.svg";
import { PlusIcon } from "@radix-ui/react-icons";
import { ReloadIcon } from "@radix-ui/react-icons";
import {
    Badge,
    Box,
    Button,
    Callout,
    Card,
    Flex,
    Separator,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as JiraSvg } from "../../../images/integrations/jira.svg";
import { setCookie } from "../../../utilities/CookieManagement";
import type { metadata } from "../IntegrationsManagementPage";
import IntegrationHeader from "./IntegrationHeader";
import { JiraCardComponent } from "./JiraCardComponent";

const JiraIntegration = () => {
    const [popupOpen, setPopupOpen] = useState<boolean>(false);

    const jiraPopupIndex = (integrationType: string, jiraURL: string) => {
        setCookie(ASSEMBLY_COOKIES.jira_url, jiraURL);
        const scopes =
            "offline_access%20read%3Ame%20read%3Ajira-work%20read%3Afield%3Ajira%20read%3Aissue%3Ajira%20read%3Aissue-meta%3Ajira%20read%3Aissue-link%3Ajira%20read%3Aissue-details%3Ajira%20read%3Aproject%3Ajira%20write%3Afield%3Ajira%20read%3Aavatar%3Ajira%20read%3Afield%3Ajira%20read%3Aproject-category%3Ajira%20read%3Aproject%3Ajira%20read%3Afield-configuration%3Ajira%20write%3Afield-configuration%3Ajira%20manage%3Ajira-configuration%20write%3Ajira-work%20write%3Aissue%3Ajira%20write%3Acomment%3Ajira%20write%3Acomment.property%3Ajira%20read%3Aissue%3Ajira";
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/jira`;
        const url = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${process.env.REACT_APP_JIRA_CLIENT_ID}&scope=${scopes}&redirect_uri=${redirect_url}&state=ui-assembly-jira&response_type=code&prompt=consent`;

        window.open(url, "_self");
    };

    const navigate = useNavigate();

    const addMoreOrganizations = () => {
        navigate("/admin/integrations/add/jira");
    };
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [refreshDisabled, setRefreshDisabled] = useState<boolean>(true);

    const [additionalMetadata, setAdditionalMetadata] = useState<metadata>({
        scopes: [],
        indexedOn: "",
    });

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    useEffect(() => {
        const requestData = {
            types: ["Jira"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp: metadata = { scopes: [], indexedOn: "" };

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;

                        if (orgInfo.Jira) {
                            setIntegrationEnabled(true);
                        }

                        if (orgInfo.Jira?.[0]?.scopes) {
                            for (const repo of orgInfo.Jira[0].scopes) {
                                temp.scopes.push(repo.name);
                            }

                            if (orgInfo.Jira?.[0]?.date) {
                                const date: Date = parseISO(
                                    orgInfo.Jira?.[0]?.date ?? "",
                                );
                                const humanReadableDate = format(
                                    date,
                                    "MMMM dd, yyyy hh:mm a",
                                );
                                temp.indexedOn = humanReadableDate;

                                const currentTime: Date = new Date();

                                const difference: number =
                                    currentTime.getTime() - date.getTime();
                                const differenceInHours: number =
                                    difference / (1000 * 60 * 60);

                                const hasNotBeen24Hours: boolean =
                                    differenceInHours <= 24;
                                setRefreshDisabled(hasNotBeen24Hours);
                            }
                        }

                        setAdditionalMetadata(temp);
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    const jiraContinue = () => {
        // todo - come up with a better way (works for now, but not a great way to check for jira indexing state!)
        setCookie(ASSEMBLY_COOKIES.jira_reindex, "false");
        setPopupOpen(true);
    };

    function openJiraPopupReindex() {
        setCookie(ASSEMBLY_COOKIES.jira_reindex, "true");
        setPopupOpen(true);
    }

    return (
        <div>
            <JiraCardComponent
                index={jiraPopupIndex}
                popupOpen={popupOpen}
                setPopupOpen={setPopupOpen}
            />
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType="Jira"
                        description="Auto-generate or search through all Jira tickets from Slack or Discord"
                        SvgIcon={JiraSvg}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {loadingState === loadingTypes.loaded &&
                        !integrationEnabled ? (
                            <Button onClick={jiraContinue}>Enable</Button>
                        ) : (
                            <Flex gap="2" direction="column">
                                <Button disabled={true}>Enabled</Button>
                                <Button
                                    size="1"
                                    onClick={jiraContinue}
                                    mb={"20px"}
                                >
                                    <ReloadIcon /> Refresh Scopes
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && integrationEnabled && (
                    <Flex direction={"column"} gap="2" mb="2">
                        <Card
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                gap="2"
                                mb="2"
                                justify={"between"}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={"start"}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                    }}
                                    gap="2"
                                >
                                    <Flex direction={"column"} maxWidth={"75%"}>
                                        <Text size={"2"}>
                                            Organizations Selected
                                        </Text>
                                        <Text size={"1"} color="gray">
                                            Add an organization to index through
                                            and add to your searchable knowledge
                                            base.
                                        </Text>
                                    </Flex>
                                    <Button
                                        variant="outline"
                                        size="1"
                                        onClick={addMoreOrganizations}
                                    >
                                        <PlusIcon width="15" height="15" /> Add
                                        More Organizations
                                    </Button>
                                </Flex>
                                {additionalMetadata.scopes?.length !== 0 && (
                                    <Separator
                                        size={"4"}
                                        mt="1"
                                        mb="1"
                                        style={{ width: "100%" }}
                                    />
                                )}
                                {additionalMetadata.scopes?.length !== 0 && (
                                    <Flex
                                        style={{
                                            paddingRight: "20px",
                                            paddingLeft: "20px",
                                        }}
                                        direction="row"
                                        gap="3"
                                        align="center"
                                        wrap="wrap"
                                    >
                                        {additionalMetadata.scopes.map(
                                            (item) => (
                                                <Badge key={item}>{item}</Badge>
                                            ),
                                        )}{" "}
                                    </Flex>
                                )}
                            </Flex>
                        </Card>
                        {additionalMetadata.indexedOn && (
                            <Flex
                                direction="column"
                                style={{
                                    justifyContent: "flex-end",
                                    alignItems: "end",
                                }}
                                gap="3"
                                mb="5"
                            >
                                <Flex direction={"row"}>
                                    <Text size={"1"}>
                                        Last Updated:{" "}
                                        {additionalMetadata.indexedOn}{" "}
                                    </Text>
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                )}
            </Box>
        </div>
    );
};

export default JiraIntegration;
