import { Grouping } from "@/IssuesTable/IssuesList";
import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import { APIWithMethod, TeamsAPI, URLS } from "@/constant";
import type { Teams } from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import { CaretDownIcon, CheckIcon, PersonIcon } from "@radix-ui/react-icons";
import { Box } from "@radix-ui/themes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { HouseIcon, PlusIcon } from "lucide-react";
import { UsersIcon } from "lucide-react";
import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminQueriesPage from "../Admin/AdminQueriesPage";
import { IssueListType } from "../Admin/AdminQueriesPage";

interface Filters {
    Assignee?: string[];
    Status?: string[];
    Source?: string[];
    Tag?: string[];
    Topic?: string[];
    CustomerGroup?: string[];
    AssemblyBot?: string[];
}

export const NewView = () => {
    const auth = useAuthInfo();
    const navigate = useNavigate();
    const types = ["Personal"];

    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [filter, setFilter] = useState<Map<string, Set<string>>>(new Map());
    // Default grouping is Status for now
    const [group, setGroup] = useState<string>(Grouping.Status);
    const [type, setType] = useState<string>("Personal");

    const [teamId, setTeamId] = useState<string>("");

    const [nameError, setNameError] = useState<string | null>(null);

    const handleFilterChange = useCallback(
        (filter: Map<string, Set<string>>) => {
            console.log("filter changed");
            setFilter(filter);
        },
        [setFilter],
    );

    const handleGroupChange = useCallback(
        (group: string) => {
            console.log("group changed");
            setGroup(group);
        },
        [setGroup],
    );

    const getIcon = (type: string) => {
        if (type === "General") {
            return (
                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                    <HouseIcon
                        className="text-iris9"
                        strokeWidth={1.5}
                        size={16}
                    />
                </div>
            );
        } else if (type === "Personal") {
            return (
                <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                    <PersonIcon className="text-red9" strokeWidth={1.5} />
                </div>
            );
        } else {
            return (
                <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                    <UsersIcon
                        className="text-red9"
                        strokeWidth={1.5}
                        size={16}
                    />
                </div>
            );
        }
    };

    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listTeams;
            const response = await fetch(`${URLS.serverUrl}${url}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
            });
            const d = await response.json();
            return d.data;
        },
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Validate the form fields
        let valid = true;
        if (name.trim() === "") {
            setNameError("Name is required");
            valid = false;
        } else {
            setNameError(null);
        }

        const finFilter: Filters = {};
        filter.forEach((value, key) => {
            switch (key) {
                case "Assignee":
                    finFilter.Assignee = Array.from(value);
                    break;
                case "Status":
                    finFilter.Status = Array.from(value);
                    break;
                case "Source":
                    finFilter.Source = Array.from(value);
                    break;
                case "Tag":
                    finFilter.Tag = Array.from(value);
                    break;
                case "Topic":
                    finFilter.Topic = Array.from(value);
                    break;
                case "Customer Group":
                    finFilter.CustomerGroup = Array.from(value);
                    break;
                case "Assembly Bot":
                    finFilter.AssemblyBot = Array.from(value);
                    break;
            }
        });

        if (valid) {
            console.log(teamId);
            const formData = {
                name,
                description,
                filter: finFilter,
                group: group,
                personal: type === "Personal",
                team_id: teamId,
            };
            newViewMutation.mutate(formData);
        }
    };

    const authInfoRef = useRef(auth);
    const queryClient = useQueryClient();

    const newViewMutation = useMutation({
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        mutationFn: async (data: any) => {
            const [url, method] = APIWithMethod.createView;
            const response: Response = await fetch(`${URLS.serverUrl}${url}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            return result.data;
        },
        onSuccess: (data) => {
            queryClient.refetchQueries({ queryKey: ["views"] });
            navigate(`/views/${data}`);
        },
        onError: (error) => {
            setNameError(error.message);
        },
    });

    return (
        <Box mt={"2%"} ml={"3%"} mr={"3%"}>
            <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-4 mt-4 text-sm"
            >
                <div className="flex items-center justify-between">
                    <div>
                        <h2 className="text-2xl font-semibold">Create View</h2>
                        <div>
                            Use the filter bar below to select filters for this
                            saved view. You can create Team or Personal views.{" "}
                        </div>
                    </div>
                    <Button
                        className="bg-shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-1.5 justify-start data-[state=open]:bg-muted shadow-sm"
                        size="sm"
                        variant="outline"
                        type="submit"
                    >
                        <PlusIcon className="w-3 h-3" />
                        Create
                    </Button>
                </div>
                <div className="flex items-center gap-1.5">
                    <label htmlFor="name" className="w-[200px] flex">
                        Name
                        <div className="text-destructive pl-0.5">*</div>
                    </label>
                    <Input
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        name="name"
                        className="ml-8"
                    />
                    {nameError && <p className="form-message">{nameError}</p>}
                </div>

                <div className="flex items-center gap-1.5">
                    <label htmlFor="description" className="w-[200px]">
                        Description
                    </label>
                    <Input
                        placeholder="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        name="description"
                        className="ml-8"
                    />
                </div>
                <div className="flex items-center gap-1.5 ml-0.5">
                    <div className="w-[200px] flex">
                        Type
                        <div className="text-destructive pl-0.5">*</div>
                    </div>
                    <div>
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <div className="text-xs flex items-center gap-2 bg-white text-sm px-3 py-1 outline outline-1 outline-offset-0 outline-slate-200 rounded-md">
                                    {getIcon(type)}
                                    {type}
                                    <CaretDownIcon />
                                </div>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                {teamsQuery.data?.map((team) => {
                                    return (
                                        <DropdownMenuItem
                                            key={team.id}
                                            onClick={() => {
                                                console.log(team.id);
                                                setTeamId(team.id);
                                                setType(team.team_name);
                                            }}
                                            className="text-xs flex items-center gap-2"
                                        >
                                            {getIcon(team.team_name)}
                                            {team.team_name}
                                            {team.team_name === type && (
                                                <CheckIcon className="h-4 w-4" />
                                            )}
                                        </DropdownMenuItem>
                                    );
                                })}
                                {types.map((t) => (
                                    <DropdownMenuItem
                                        key={t}
                                        onClick={() => {
                                            setType(t);
                                            setTeamId("");
                                        }}
                                        className="text-xs flex items-center gap-2"
                                    >
                                        {getIcon(t)}
                                        {t}
                                        {t === type && (
                                            <CheckIcon className="h-4 w-4" />
                                        )}
                                    </DropdownMenuItem>
                                ))}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>

                <AdminQueriesPage
                    userID={auth.user?.userId ?? ""}
                    listType={IssueListType.Issues}
                    tableView={true}
                    hidePopup={true}
                    onFilterChange={handleFilterChange}
                    onGroupChange={handleGroupChange}
                    listHeight={window.innerHeight * 0.57}
                />
            </form>
        </Box>
    );
};
