import { PlusIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import FernIcon from "../../../images/integrations/fern.svg";
import WebIcon from "../../../images/integrations/web.svg";
import MintlifyIcon from "../../../images/mintlify.png";

import {
    Button,
    Dialog,
    Flex,
    Select,
    Text,
    TextField,
} from "@radix-ui/themes";
interface IntegrationProps {
    index: (integrationType: string, jiraUrl: string, isNew: boolean) => void; // separate function for jira, due to addition of URL
    disabled: boolean;
    buttonName: string;
    buttonVariant?: boolean;
    isNew: boolean; // true if we're adding secondary or more links.
}

export const WebCardPopup = (props: IntegrationProps) => {
    const [open, setOpen] = useState(false);

    const [inputValue, setInputValue] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [isValidClick, setIsValidClick] = useState(true);

    const [selectedOption, setSelectedOption] = useState("mintlify");

    const validateInput = (value: string): boolean => {
        if (selectedOption === "other") {
            if (value.includes("grammarly")) {
                return true; // forcing it to just auto include the link here.
            } else if (value.includes("supertokens")) {
                return true;
            } else if (value.includes("easypost")) {
                return true;
            } else if (value.includes("docs")) {
                return true;
            } else if (value.includes("latchel")) {
                return true;
            } else if (value.includes("snaptrade")) {
                return true;
            } else if (value.includes("hellobrigit")) {
                return true;
            } else if (value.includes("pando")) {
                return true;
            } else {
                return false;
            }
        } else {
            if (value.includes("docs") || value.includes("reference")) {
                return true;
            } else {
                return false;
            }
        }
    };

    const handleInputChange = (value: string) => {
        setInputValue(value);
        if (validateInput(value)) {
            setIsValid(true);
            setIsValidClick(true);
        } else {
            setIsValid(false);
        }
    };

    const handleOptionChange = (value: string) => {
        setSelectedOption(value);
    };

    const handleOnClick = () => {
        if (validateInput(inputValue)) {
            setIsValidClick(true);
            props.index(
                selectedOption.charAt(0).toUpperCase() +
                    selectedOption.slice(1),
                inputValue,
                props.isNew,
            );
            setOpen(false);
        } else {
            setIsValidClick(false);
        }
    };

    return (
        <div>
            <Dialog.Root open={open} onOpenChange={setOpen}>
                <Dialog.Trigger>
                    {props.buttonVariant ? (
                        <Button
                            style={{ width: "100%" }}
                            variant={"outline"}
                            size="1"
                        >
                            <PlusIcon width="15" height="15" />
                            {props.buttonName}
                        </Button>
                    ) : (
                        <Button
                            style={{ width: "100%" }}
                            disabled={props.disabled}
                        >
                            {props.buttonName}
                        </Button>
                    )}
                </Dialog.Trigger>

                <Dialog.Content maxWidth="450px">
                    <Dialog.Title>Add Documentation</Dialog.Title>
                    <Dialog.Description size="2" mb="4">
                        Choose one of the options below to let Assembly crawl
                        through your docs.
                    </Dialog.Description>

                    <Flex direction="column" gap="6">
                        <Flex direction="row" gap="1" align={"center"}>
                            <Select.Root
                                defaultValue="mintlify"
                                onValueChange={handleOptionChange}
                            >
                                <Select.Trigger />
                                <Select.Content>
                                    <Select.Item value="mintlify">
                                        <Flex
                                            direction="row"
                                            gap="3"
                                            align={"center"}
                                        >
                                            <img
                                                src={MintlifyIcon}
                                                alt="Mintlify"
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                }}
                                            />
                                            Mintlify
                                        </Flex>
                                    </Select.Item>
                                    <Select.Item value="fern">
                                        <Flex
                                            direction="row"
                                            gap="3"
                                            align={"center"}
                                        >
                                            <img
                                                src={FernIcon}
                                                alt="Fern"
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                }}
                                            />
                                            Fern
                                        </Flex>
                                    </Select.Item>
                                    <Select.Item value="other">
                                        <Flex
                                            direction="row"
                                            gap="3"
                                            align={"center"}
                                        >
                                            <img
                                                src={WebIcon}
                                                alt="Other"
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                }}
                                            />
                                            Other
                                        </Flex>
                                    </Select.Item>
                                </Select.Content>
                            </Select.Root>
                        </Flex>
                        <label>
                            <Text as="div" size="2" mb="1" weight="bold">
                                Documentation Link
                            </Text>
                            <TextField.Root
                                defaultValue=""
                                placeholder="https://docs.askassembly.app/"
                                value={inputValue}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) =>
                                    handleInputChange(
                                        (
                                            event.currentTarget as HTMLInputElement
                                        ).value,
                                    )
                                }
                                color={isValid ? "iris" : "red"}
                            />
                        </label>
                    </Flex>

                    <Flex gap="3" mt="4" justify="end">
                        <Dialog.Close>
                            <Button variant="soft" color="gray">
                                Cancel
                            </Button>
                        </Dialog.Close>
                        <Button
                            onClick={handleOnClick}
                            color={isValidClick ? "iris" : "red"}
                        >
                            Save
                        </Button>
                    </Flex>
                </Dialog.Content>
            </Dialog.Root>
        </div>
    );
};
