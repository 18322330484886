"use client";

import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    CsvRow,
    ExcelRow,
    HistoryResponse,
    MyFile,
    Teams,
    Ticket,
} from "@/interfaces/serverData";
import { arraysAreEqual } from "@/utilities/methods";
import { ChatBubbleIcon, EyeOpenIcon, StackIcon } from "@radix-ui/react-icons";
import { Tabs } from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { ActivityIcon, EyeOffIcon } from "lucide-react";
import mammoth from "mammoth";
import Papa from "papaparse";
import { memo, useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { Action } from "./Action";
import { Comment } from "./MyComment";
import { MyComposer } from "./MyComposer";

const areEqual = (prevProps: RoomProps, nextProps: RoomProps) => {
    return (
        prevProps.source === nextProps.source &&
        prevProps.source_specific_id === nextProps.source_specific_id &&
        prevProps.title === nextProps.title &&
        arraysAreEqual(
            prevProps.threadData ?? [],
            nextProps.threadData ?? [],
        ) &&
        prevProps.update === nextProps.update &&
        prevProps.setUpdate === nextProps.setUpdate &&
        prevProps.ai_response === nextProps.ai_response &&
        prevProps.refetchThreadData === nextProps.refetchThreadData &&
        prevProps.showComposer === nextProps.showComposer &&
        prevProps.url === nextProps.url &&
        prevProps.subtractHeight === nextProps.subtractHeight &&
        prevProps.teamsQuery === nextProps.teamsQuery
    );
};

interface RoomProps {
    originalTicket: Ticket;
    source: string;
    classNameThread?: string;
    className?: string;
    classNameComposer?: string;
    title: string;
    source_specific_id: string;
    threadData: HistoryResponse[];
    update: boolean;
    setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
    ai_response: string;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
    showComposer: boolean;
    url?: string;
    subtractHeight?: number;
    teamsQuery?: UseQueryResult<Teams[], Error>;
}

function Room({
    originalTicket,
    source,
    source_specific_id,
    classNameThread = "",
    className = "",
    classNameComposer = "",
    title,
    threadData,
    update,
    setUpdate,
    ai_response,
    refetchThreadData,
    showComposer,
    url,
    subtractHeight,
    teamsQuery,
}: RoomProps) {
    const api = useApi();
    const list = [
        "Slack",
        "CommunitySlack",
        "Discord",
        "Gmail",
        "GitHubTicket",
        "Web",
        "Intercom",
    ];
    const renderComment = (comment: HistoryResponse) => {
        switch (comment.type) {
            case "Message":
            case "Internal":
                return (
                    <li className="mb-4 ml-6 ms-6">
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-[#eceefb] rounded-full -start-3 ring-8 ring-white">
                            {comment.type === "Internal" && (
                                <EyeOffIcon className="w-2.5 h-2.5 text-[#5e6ad2]" />
                            )}
                            {comment.type === "Message" && (
                                <ChatBubbleIcon className="w-2.5 h-2.5 text-[#5e6ad2]" />
                            )}
                        </span>
                        <Comment
                            className={
                                comment.type === "Internal"
                                    ? "bg-[#f2f2f2]"
                                    : "bg-[#ffffff]"
                            }
                            key={comment.id}
                            comment={comment}
                            source={source}
                            filesMap={filesMap}
                            isInternal={comment.type === "Internal"}
                        />
                    </li>
                );
            case "Action":
                return (
                    <li className="mb-4 ml-6 ms-6">
                        <Action
                            key={comment.id}
                            comment={comment}
                            teamsQuery={teamsQuery}
                        />
                    </li>
                );
            default:
        }
    };

    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollRef.current) {
            const element = scrollRef.current;
            element.scrollTop = element.scrollHeight;
        }
    }, [threadData, source_specific_id]);

    const [filesMap, setFilesMap] = useState<Map<string, MyFile[]>>(new Map());
    const fetchFile = async () => {
        // Populate initial files map with the tags
        const initialMap = new Map(filesMap);
        // biome-ignore lint/complexity/noForEach: <explanation>
        threadData.forEach((comment) => {
            // Check if the comment ID already exists in the map
            if (!initialMap.has(comment.id)) {
                // Initialize the map with existing files only if the comment ID is not present
                initialMap.set(comment.id, comment.files || []); // Ensure it's an array
            }
        });
        setFilesMap(initialMap); // Set the initial filesMap
        let newFilesMap = new Map(initialMap);

        // TODO: gate to images only for discord? Or try out other file types
        // Users are more focused on the latest messages, which is where the scroll bar starts too
        for (const comment of [...threadData].reverse()) {
            if (comment.files) {
                for (const file of comment.files) {
                    let existingFiles = newFilesMap.get(comment.id) || [];
                    const fileAlreadyExists = existingFiles.some(
                        (existingFile) => existingFile.id === file.id,
                    );
                    const fileAlreadyExistsAndHasUrlLocal = existingFiles.some(
                        (existingFile) =>
                            existingFile.id === file.id &&
                            existingFile.url_local,
                    );

                    if (!fileAlreadyExistsAndHasUrlLocal) {
                        // If there's already a local/accessible url, don't call get file
                        if (file.url_local !== "") {
                            if (fileAlreadyExists) {
                                existingFiles = existingFiles.map((f) =>
                                    f.id === file.id ? file : f,
                                );
                            } else {
                                existingFiles.push(file);
                            }
                        } else {
                            const requestData = {
                                url: file.url_private_download,
                                source: source, // Should be either "Slack", "CommunitySlack", or "Discord" only,
                                mimetype: file.mimetype,
                                name: file.name,
                            };

                            try {
                                const res = await api.post(
                                    URLS.serverUrl + API.getFile,
                                    requestData,
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        responseType: "blob",
                                    },
                                );

                                if (res.status === 200) {
                                    const fileBlob = res.data;
                                    if (fileBlob.size > 0) {
                                        const fileUrl =
                                            URL.createObjectURL(fileBlob);
                                        const newFile = {
                                            ...file,
                                            url_local: fileUrl,
                                        };
                                        const sizeLimit = 1024 * 1024; // 1 MB

                                        if (
                                            file.mimetype ===
                                                "application/msword" ||
                                            file.mimetype ===
                                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        ) {
                                            // Check if the fileBlob's size exceeds the limit
                                            if (fileBlob.size <= sizeLimit) {
                                                // Newer Word formats
                                                const arrayBuffer =
                                                    await fileBlob.arrayBuffer();

                                                // Convert to html and create a blob url
                                                const result =
                                                    await mammoth.convertToHtml(
                                                        {
                                                            arrayBuffer,
                                                        },
                                                    );
                                                const htmlBlob = new Blob(
                                                    [result.value],
                                                    {
                                                        type: "text/html",
                                                    },
                                                );
                                                const htmlBlobUrl =
                                                    URL.createObjectURL(
                                                        htmlBlob,
                                                    );
                                                newFile.html_url = htmlBlobUrl;
                                            } else {
                                                console.warn(
                                                    `File size (${fileBlob.size} bytes) exceeds the limit (${sizeLimit} bytes). Skipping preview processing.`,
                                                );
                                                newFile.too_big_to_preview = true;
                                            }
                                        } else if (
                                            file.mimetype ===
                                                "application/vnd.ms-excel" ||
                                            file.mimetype ===
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        ) {
                                            // Check if the fileBlob's size exceeds the limit
                                            if (fileBlob.size <= sizeLimit) {
                                                const arrayBuffer =
                                                    await fileBlob.arrayBuffer();

                                                // Read the XLSX file
                                                const workbook = XLSX.read(
                                                    arrayBuffer,
                                                    {
                                                        type: "array",
                                                    },
                                                );
                                                // Only previewing the first sheet
                                                const firstSheetName =
                                                    workbook.SheetNames[0];
                                                const worksheet =
                                                    workbook.Sheets[
                                                        firstSheetName
                                                    ];

                                                // Convert to JSON
                                                const jsonData =
                                                    XLSX.utils.sheet_to_json<ExcelRow>(
                                                        worksheet,
                                                    );
                                                newFile.excel_rows = jsonData;
                                            } else {
                                                console.warn(
                                                    `File size (${fileBlob.size} bytes) exceeds the limit (${sizeLimit} bytes). Skipping preview processing.`,
                                                );
                                                newFile.too_big_to_preview = true;
                                            }
                                        } else if (
                                            file.mimetype.startsWith("text/csv")
                                        ) {
                                            // Check if the fileBlob's size exceeds the limit
                                            if (fileBlob.size <= sizeLimit) {
                                                // Read in the blob as text
                                                const text =
                                                    await fileBlob.text();
                                                const results =
                                                    Papa.parse<CsvRow>(text, {
                                                        header: true,
                                                    });
                                                newFile.csv_rows = results.data;
                                            } else {
                                                console.warn(
                                                    `File size (${fileBlob.size} bytes) exceeds the limit (${sizeLimit} bytes). Skipping preview processing.`,
                                                );
                                                newFile.too_big_to_preview = true;
                                            }
                                        }
                                        if (fileAlreadyExists) {
                                            existingFiles = existingFiles.map(
                                                (f) =>
                                                    f.id === newFile.id
                                                        ? newFile
                                                        : f,
                                            );
                                        } else {
                                            existingFiles.push(newFile);
                                        }
                                    } else {
                                        console.error("Received empty blob.");
                                        return null;
                                    }
                                } else {
                                    console.error(
                                        "Call to get image failed with status:",
                                        res.status,
                                    );
                                    return null;
                                }
                            } catch (error) {
                                console.error("Error fetching image:", error);
                                return null;
                            }
                        }
                        newFilesMap.set(comment.id, existingFiles);
                        setFilesMap(newFilesMap);
                    }
                }
                newFilesMap = new Map(newFilesMap);
            }
        }
    };
    useEffect(() => {
        // Current support: all slack and discord file types
        if (
            source === "Slack" ||
            source === "CommunitySlack" ||
            source === "Discord"
        ) {
            fetchFile();
        }
    }, [threadData]);

    return (
        <div className={`w-full lb-root flex flex-col relative ${className}`}>
            <div className="flex-1">
                <Tabs.Root defaultValue="all">
                    <Tabs.List className="sticky top-0 bg-white z-10 border-b border-gray-200">
                        <Tabs.Trigger
                            value="all"
                            className="group px-4 py-2 text-sm font-medium"
                        >
                            <div className="flex flex-row items-center gap-1">
                                <StackIcon className="group-hover:text-[#5e6ad2]" />
                                <p className="group-hover:text-[#5e6ad2]">
                                    All
                                </p>
                            </div>
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            value="activity"
                            className="group px-4 py-2 text-sm font-medium"
                        >
                            <div className="flex flex-row items-center gap-1">
                                <ActivityIcon
                                    className="group-hover:text-[#5e6ad2]"
                                    size={16}
                                    strokeWidth={1.5}
                                />
                                <p className="group-hover:text-[#5e6ad2]">
                                    Activity
                                </p>
                            </div>
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            value="internal"
                            className="group px-4 py-2 text-sm font-medium"
                        >
                            <div className="flex flex-row items-center gap-1">
                                <EyeOpenIcon
                                    className="group-hover:text-[#5e6ad2]"
                                    fontSize={16}
                                    strokeWidth={1.5}
                                />
                                <p className="group-hover:text-[#5e6ad2]">
                                    Internal
                                </p>
                            </div>
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            value="thread"
                            className="group px-4 py-2 text-sm font-medium"
                        >
                            <div className="flex flex-row items-center gap-1">
                                <ChatBubbleIcon className="group-hover:text-[#5e6ad2]" />
                                <p className="group-hover:text-[#5e6ad2]">
                                    Thread
                                </p>
                            </div>
                        </Tabs.Trigger>
                    </Tabs.List>
                    <div
                        ref={scrollRef}
                        className="flex-grow overflow-y-auto"
                        style={{
                            maxHeight: `calc(100vh - ${subtractHeight}px)`,
                        }}
                    >
                        <Tabs.Content
                            value="thread"
                            className={`${classNameThread}`}
                        >
                            <ol className="ml-5 mt-4 relative border-s border-gray-200 dark:border-gray-700">
                                {threadData
                                    .filter(
                                        (comment) => comment.type === "Message",
                                    )
                                    .map((comment) => renderComment(comment))}
                            </ol>
                        </Tabs.Content>
                        <Tabs.Content value="activity" className="pt-4">
                            <ol className="ml-5 mt-4 relative border-s border-gray-200 dark:border-gray-700">
                                {threadData
                                    .filter(
                                        (comment) => comment.type === "Action",
                                    )
                                    .map((comment) => renderComment(comment))}
                            </ol>
                        </Tabs.Content>
                        <Tabs.Content value="internal" className="pt-4">
                            <ol className="ml-5 mt-4 relative border-s border-gray-200 dark:border-gray-700">
                                {threadData
                                    .filter(
                                        (comment) =>
                                            comment.type === "Internal",
                                    )
                                    .map((comment) => renderComment(comment))}
                            </ol>
                        </Tabs.Content>
                        <Tabs.Content
                            value="all"
                            className={`${classNameThread}`}
                        >
                            <ol className="ml-5 mt-4 relative border-s border-gray-200 dark:border-gray-700">
                                {threadData.map((comment) =>
                                    renderComment(comment),
                                )}
                            </ol>
                        </Tabs.Content>
                    </div>
                </Tabs.Root>
            </div>
            <div className={classNameComposer}>
                {showComposer &&
                    list.includes(source) &&
                    source_specific_id !== "" &&
                    source_specific_id !== undefined &&
                    source_specific_id != null && (
                        <MyComposer
                            originalTicket={originalTicket}
                            source={source}
                            source_specific_id={source_specific_id}
                            update={update}
                            setUpdate={setUpdate}
                            title={title}
                            ai_response={ai_response}
                            refetchThreadData={refetchThreadData}
                        />
                    )}

                {/* added for space on the bottom of screen */}
            </div>
        </div>
    );
}

export default memo(Room, areEqual);
