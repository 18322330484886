import type React from "react";
import { useEffect, useState } from "react";

import { Dialog } from "@radix-ui/themes";
import { Button } from "./shadcn/ui/button";
import { Input } from "./shadcn/ui/input";

interface PopupProps {
    open: boolean;
    text: string;
    suggestText: string;
    onClickConfirm: () => void;
    onCancel: () => void;
    jiraUrl: string;
    setJiraUrl: (event: React.ChangeEvent<HTMLInputElement>) => void;
    invalidUrl: boolean;
}

const URLCustomPopup: React.FC<PopupProps> = ({
    open,
    text,
    suggestText,
    onClickConfirm,
    onCancel,
    jiraUrl,
    setJiraUrl,
    invalidUrl,
}) => {
    useEffect(() => {
        if (!invalidUrl && jiraUrl.length > 0) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [invalidUrl, jiraUrl]);

    const [disabledButton, setDisabledButton] = useState<boolean>(true);

    return (
        <Dialog.Root open={open} onOpenChange={(open) => onCancel()}>
            <Dialog.Content className="flex flex-col gap-5">
                <Dialog.Description>{text}</Dialog.Description>

                <Input
                    value={jiraUrl}
                    onChange={setJiraUrl}
                    id="outlined-basic"
                    placeholder={suggestText}
                />

                <div className="flex flex-row gap-2 justify-end">
                    <Button
                        onClick={onClickConfirm}
                        disabled={disabledButton}
                        type="submit"
                        variant="secondary"
                    >
                        Activate
                    </Button>
                    <Button onClick={onCancel} type="button" variant="outline">
                        Cancel
                    </Button>
                </div>
            </Dialog.Content>
        </Dialog.Root>
    );
};

export default URLCustomPopup;
