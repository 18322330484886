import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
    CLEAR_EDITOR_COMMAND,
    COMMAND_PRIORITY_LOW,
    type LexicalCommand,
    createCommand,
} from "lexical";
import { useEffect, useLayoutEffect } from "react";

export const INTERNAL_MESSAGE_COMMAND: LexicalCommand<KeyboardEvent> =
    createCommand("INTERNAL_MESSAGE_COMMAND");

const InternalMessageCommand = ({
    onSubmit = (internalMessage: boolean) => {},
}) => {
    const [editor] = useLexicalComposerContext();

    useLayoutEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (
                (event.ctrlKey || event.metaKey) &&
                (event.key === "m" || event.key === "M")
            ) {
                editor.dispatchCommand(INTERNAL_MESSAGE_COMMAND, event);
            }
        };

        return editor.registerRootListener(
            (
                rootElement: HTMLElement | null,
                prevRootElement: HTMLElement | null,
            ) => {
                if (prevRootElement !== null) {
                    prevRootElement.removeEventListener("keydown", onKeyDown);
                }
                if (rootElement !== null) {
                    rootElement.addEventListener("keydown", onKeyDown);
                }
            },
        );
    }, [editor]);

    useEffect(() => {
        return editor.registerCommand(
            INTERNAL_MESSAGE_COMMAND,
            (event) => {
                event.preventDefault();
                onSubmit(true);
                editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
                return true;
            },
            COMMAND_PRIORITY_LOW,
        );
    }, [editor, onSubmit]);

    return null;
};

export default InternalMessageCommand;
