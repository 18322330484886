import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { ASSEMBLY_COOKIES } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
// import { ReactComponent as ConfluenceSvg } from "../../../images/integrations/confluence.svg";
import { PlusIcon } from "@radix-ui/react-icons";
import {
    Badge,
    Box,
    Button,
    Callout,
    Card,
    Flex,
    Separator,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ConfluenceSvg } from "../../../images/integrations/confluence.svg";
import { setCookie } from "../../../utilities/CookieManagement";
import type { metadata } from "../IntegrationsManagementPage";
import { ConfluenceCardComponent } from "./ConfluenceCardComponent.tsx";
import IntegrationHeader from "./IntegrationHeader";

const ConfluenceIntegration = () => {
    function handleReindex(integrationType: string, integrationUrl?: string) {
        if (integrationType === undefined || integrationType === "") {
            return;
        }

        const requestData = {
            type: integrationType,
            is_reindex: true,
        };

        api.post(URLS.serverUrl + API.indexIntegration, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then(async (res) => {
            if (res.status === 200) {
            } else {
                // todo - need to handle this case.
            }
        });
        navigate("/admin/integrations/reindex");
    }

    const [popupOpen, setPopupOpen] = useState<boolean>(false);

    const confluencePopupIndex = (
        integrationType: string,
        confluenceURL: string,
    ) => {
        setCookie(ASSEMBLY_COOKIES.jira_url, confluenceURL);
        const scopes =
            "offline_access%20read%3Acontent%3Aconfluence%20write%3Acontent%3Aconfluence%20read%3Acontent-details%3Aconfluence%20read%3Aspace-details%3Aconfluence%20read%3Aaudit-log%3Aconfluence%20write%3Aaudit-log%3Aconfluence%20read%3Apage%3Aconfluence%20write%3Apage%3Aconfluence%20read%3Aattachment%3Aconfluence%20write%3Aattachment%3Aconfluence%20read%3Ablogpost%3Aconfluence%20write%3Ablogpost%3Aconfluence%20read%3Acustom-content%3Aconfluence%20write%3Acustom-content%3Aconfluence%20read%3Acomment%3Aconfluence%20write%3Acomment%3Aconfluence%20read%3Atemplate%3Aconfluence%20write%3Atemplate%3Aconfluence%20read%3Alabel%3Aconfluence%20write%3Alabel%3Aconfluence%20read%3Awatcher%3Aconfluence%20write%3Awatcher%3Aconfluence%20read%3Agroup%3Aconfluence%20write%3Agroup%3Aconfluence%20read%3Arelation%3Aconfluence%20write%3Arelation%3Aconfluence%20read%3Auser%3Aconfluence%20read%3Aconfiguration%3Aconfluence%20write%3Aconfiguration%3Aconfluence%20read%3Aspace%3Aconfluence%20write%3Aspace%3Aconfluence%20read%3Aspace.permission%3Aconfluence%20read%3Aspace.property%3Aconfluence%20read%3Auser.property%3Aconfluence%20read%3Aspace.setting%3Aconfluence%20read%3Acontent.permission%3Aconfluence%20read%3Acontent.property%3Aconfluence%20read%3Acontent.restriction%3Aconfluence%20read%3Acontent.metadata%3Aconfluence%20read%3Ainlinetask%3Aconfluence%20read%3Atask%3Aconfluence%20read%3Apermission%3Aconfluence%20read%3Awhiteboard%3Aconfluence%20read%3Adatabase%3Aconfluence%20read%3Aembed%3Aconfluence%20read%3Aapp-data%3Aconfluence";
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/confluence`;
        const url = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${process.env.REACT_APP_CONFLUENCE_CLIENT_ID}&scope=${scopes}&redirect_uri=${redirect_url}&state=ui-assembly-confluence&response_type=code&prompt=consent`;

        window.open(url, "_self");
    };

    const navigate = useNavigate();

    const addMoreOrganizations = () => {
        navigate("/admin/integrations/add/confluence");
    };
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [refreshDisabled, setRefreshDisabled] = useState<boolean>(true);

    const [additionalMetadata, setAdditionalMetadata] = useState<metadata>({
        scopes: [],
        indexedOn: "",
    });

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    useEffect(() => {
        const requestData = {
            types: ["Confluence"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp: metadata = { scopes: [], indexedOn: "" };

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;

                        if (orgInfo.Confluence) {
                            setIntegrationEnabled(true);
                        }

                        if (orgInfo.Confluence?.[0]?.scopes) {
                            for (const repo of orgInfo.Confluence[0].scopes) {
                                temp.scopes.push(repo.name);
                            }

                            if (orgInfo.Confluence?.[0]?.indexedOn) {
                                const date: Date = parseISO(
                                    orgInfo.Confluence?.[0]?.indexedOn ?? "",
                                );
                                const humanReadableDate = format(
                                    date,
                                    "MMMM dd, yyyy hh:mm a",
                                );
                                temp.indexedOn = humanReadableDate;

                                const currentTime: Date = new Date();

                                const difference: number =
                                    currentTime.getTime() - date.getTime();
                                const differenceInHours: number =
                                    difference / (1000 * 60 * 60);

                                const hasNotBeen24Hours: boolean =
                                    differenceInHours <= 24;
                                setRefreshDisabled(hasNotBeen24Hours);
                            }
                        }

                        setAdditionalMetadata(temp);
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    const confluenceContinue = () => {
        // todo - come up with a better way (works for now, but not a great way to check for confluence indexing state!)
        setCookie(ASSEMBLY_COOKIES.confluence_reindex, "false");
        setPopupOpen(true);
    };

    function openConfluencePopupReindex() {
        setCookie(ASSEMBLY_COOKIES.confluence_reindex, "true");
        setPopupOpen(true);
    }

    return (
        <div>
            <ConfluenceCardComponent
                index={confluencePopupIndex}
                popupOpen={popupOpen}
                setPopupOpen={setPopupOpen}
            />
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType="Confluence"
                        description="Ingest your Confluence documents (BETA)"
                        SvgIcon={ConfluenceSvg}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {loadingState === loadingTypes.loaded &&
                        !integrationEnabled ? (
                            <Button onClick={confluenceContinue}>Enable</Button>
                        ) : (
                            <Flex gap="2" direction="column">
                                <Button mb={"20px"} disabled={true}>
                                    Enabled
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && integrationEnabled && (
                    <Flex direction={"column"} gap="2" mb="2">
                        <Card
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                gap="2"
                                mb="2"
                                justify={"between"}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={"start"}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                    }}
                                    gap="2"
                                >
                                    <Flex direction={"column"} maxWidth={"75%"}>
                                        <Text size={"2"}>
                                            Organizations Selected
                                        </Text>
                                        <Text size={"1"} color="gray">
                                            Add an organization to index through
                                            and add to your searchable knowledge
                                            base.
                                        </Text>
                                    </Flex>
                                    <Button
                                        variant="outline"
                                        size="1"
                                        onClick={addMoreOrganizations}
                                    >
                                        <PlusIcon width="15" height="15" /> Add
                                        More Organizations
                                    </Button>
                                </Flex>
                                {additionalMetadata.scopes?.length !== 0 && (
                                    <Separator
                                        size={"4"}
                                        mt="1"
                                        mb="1"
                                        style={{ width: "100%" }}
                                    />
                                )}
                                {additionalMetadata.scopes?.length !== 0 && (
                                    <Flex
                                        style={{
                                            paddingRight: "20px",
                                            paddingLeft: "20px",
                                        }}
                                        direction="row"
                                        gap="3"
                                        align="center"
                                        wrap="wrap"
                                    >
                                        {additionalMetadata.scopes.map(
                                            (item) => (
                                                <Badge key={item}>{item}</Badge>
                                            ),
                                        )}{" "}
                                    </Flex>
                                )}
                            </Flex>
                        </Card>
                        {additionalMetadata.indexedOn && (
                            <Flex
                                direction="column"
                                style={{
                                    justifyContent: "flex-end",
                                    alignItems: "end",
                                }}
                                gap="3"
                                mb="5"
                            >
                                <Flex direction={"row"}>
                                    <Text size={"1"}>
                                        Last Updated:{" "}
                                        {additionalMetadata.indexedOn}{" "}
                                    </Text>
                                </Flex>

                                <Button
                                    disabled={refreshDisabled}
                                    onClick={() => {
                                        handleReindex("Confluence");
                                    }}
                                >
                                    Refresh
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                )}
            </Box>
        </div>
    );
};

export default ConfluenceIntegration;
