import type React from "react";
import type { ReactNode } from "react";
import { cloneElement, useRef } from "react";
import { useEffect, useState } from "react";
import type { FilterMetadata } from "../component/SideBar/SideBarComponent";
import SideBarComponent from "../component/SideBar/SideBarComponent";

import { NAVBAR_ITEMS } from "../component/SideBar/SideBarComponent";
import { useSearch } from "./SearchContext";

import WorkspaceSideBarComponent, {
    NAVBAR_PREFERENCES_ITEMS,
} from "@/component/SideBar/WorkspaceSideBarComponent";
import { useSidebar } from "@/component/shadcn/ui/sidebar";

interface LayoutProps {
    children: ReactNode;
    navBarState: string;
    isAdmin: boolean;
    organizationName: string;
    isWorkspace?: boolean;
    withScroll?: boolean; // default is true
    viewID?: string;
    orgID?: string;
    setActiveTeam?: (newTerm: string) => void;
    activeTeam?: string;
}

const HomePage: React.FC<LayoutProps> = ({
    children,
    navBarState,
    isAdmin,
    organizationName,
    isWorkspace,
    withScroll,
    viewID,
    orgID,
    activeTeam,
    setActiveTeam,
}) => {
    const { searchBarFilters, setSearchBarFilters } = useSearch();
    const { isMobile } = useSidebar();

    // Show the selected state if there is one. Otherwise show inbox page for admins and search for non admins
    const [activeButton, setActiveButton] = useState<string>(() => {
        if (navBarState === "savedView" && viewID) {
            return `savedView_${viewID}`;
        }
        if (navBarState === "issues" && activeTeam) {
            return `issues_${activeTeam}`;
        }
        if (navBarState === "insights" && activeTeam) {
            return `insights_${activeTeam}`;
        }
        return isWorkspace
            ? NAVBAR_PREFERENCES_ITEMS.profile
            : navBarState
                ? navBarState
                : NAVBAR_ITEMS.inbox;
    });

    const MIN_PANEL_WIDTH = 6;

    const breakpoint = 1000;

    const [sidebarWidth, setSidebarWidth] = useState<number>(
        Math.max(
            window.innerWidth < breakpoint
                ? window.innerWidth * 0.25
                : window.innerWidth * 0.14,
            MIN_PANEL_WIDTH,
        ),
    );

    useEffect(() => {
        setSidebarWidth(
            Math.max(
                window.innerWidth < breakpoint
                    ? window.innerWidth * 0.25
                    : window.innerWidth * 0.16,
                MIN_PANEL_WIDTH,
            ),
        );
    }, [window.innerWidth]);

    const sidebarRef = useRef<HTMLDivElement | null>(null);
    const overflowStyle = withScroll === false ? {} : { overflow: "scroll" };

    const handleNavMenu = (menuItem: string) => {
        setActiveButton(menuItem);
    };

    const handleResize = (width: number) => {
        setSidebarWidth(
            Math.max(sidebarRef.current?.offsetWidth ?? width, MIN_PANEL_WIDTH),
        );
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const handleResize = () => {
            if (sidebarRef.current) {
                setSidebarWidth(
                    Math.max(sidebarRef.current.offsetWidth, MIN_PANEL_WIDTH),
                );
            }
        };
        const resizeObserver = new ResizeObserver(handleResize);
        if (sidebarRef.current) {
            resizeObserver.observe(sidebarRef.current);
        }
        handleResize();
        return () => {
            resizeObserver.disconnect();
        };
    }, [sidebarRef.current]);

    useEffect(() => {
        setActiveButton(navBarState);
    }, [navBarState]);

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handleToggle = (type: string, value: any) => () => {
        const temp = new Map(searchBarFilters);
        const metadata = temp.get(type);

        temp.forEach((metadata: FilterMetadata, key: string) => {
            if (key !== type) {
                metadata.enabled = false;

                metadata.granularFilters.forEach(
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    (enabled: boolean, key: any) => {
                        metadata.granularFilters.set(key, false);
                    },
                );
            }
        });

        if (
            type === value &&
            metadata !== undefined &&
            metadata.enabled === false
        ) {
            metadata.enabled = !metadata.enabled;
            temp.set(type, metadata);
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            metadata.granularFilters.forEach((enabled: boolean, key: any) => {
                metadata?.granularFilters.set(key, metadata?.enabled);
            });
        } else if (type !== value && metadata !== undefined) {
            const filters = metadata.granularFilters;
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            const granularEnabled = filters.get(value)!;
            filters?.set(value, !granularEnabled);
            metadata.granularFilters = filters;
            temp.set(type, metadata);

            metadata.enabled = true;
        }
        setSearchBarFilters(temp);
    };

    return (
        <div className="h-screen flex overflow-hidden w-full" style={overflowStyle}>
            {isWorkspace ? (
                <WorkspaceSideBarComponent
                    activeButton={activeButton}
                    setActiveButton={handleNavMenu}
                    organizationName={organizationName}
                    isAdmin={isAdmin}
                    width={sidebarWidth}
                    orgID={orgID ?? ""}
                />
            ) : activeButton === NAVBAR_ITEMS.search ? (
                <SideBarComponent
                    activeButton={activeButton}
                    setActiveButton={handleNavMenu}
                    activeTeam={activeTeam}
                    setActiveTeam={setActiveTeam}
                    organizationName={organizationName}
                    filters={searchBarFilters}
                    handleToggle={handleToggle}
                    isAdmin={isAdmin}
                    width={sidebarWidth}
                />
            ) : (
                <SideBarComponent
                    activeButton={activeButton}
                    setActiveButton={handleNavMenu}
                    activeTeam={activeTeam}
                    setActiveTeam={setActiveTeam}
                    organizationName={organizationName}
                    isAdmin={isAdmin}
                    width={sidebarWidth}
                />
            )}

            <main className="flex-1 overflow-auto">
                {/* TODO: Add back in when we have a reusbale header */}
                {/* <SidebarTrigger /> */}
                {cloneElement(children as React.ReactElement, {
                    searchBarFilters,
                    setSearchBarFilters,
                    isAdmin,
                })}
            </main>
        </div>
    );
};

export default HomePage;
