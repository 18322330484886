import { CreateIssuePopup } from "@/IssuesTable/CreateIssuePopup";
import IssuesList from "@/IssuesTable/IssuesList";
import FileUploadForCreateTicket from "@/component/FileUploadForCreateTicket";
import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    CustomerGroup,
    GetTopicsResponse,
    GetUserResponse,
    Integration,
    OrgInfoResponse,
    QueriesWithPaginationResponse,
    ScopeResponse,
    Teams,
} from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import {
    PlusIcon,
    ViewHorizontalIcon,
    ViewVerticalIcon,
} from "@radix-ui/react-icons";
import { Box, Callout, Flex, Heading, Skeleton, Text } from "@radix-ui/themes";
import {
    useInfiniteQuery,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { memo, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import AdminProductFeedbackPage from "./AdminProductFeedbackPage";

export interface TopicDisplay {
    color: string;
    label: string;
    value: string;
}

const areEqual = (
    prevProps: AdminQueryPageProps,
    nextProps: AdminQueryPageProps,
) => {
    return (
        prevProps.userID === nextProps.userID &&
        prevProps.listType === nextProps.listType &&
        prevProps.tableView === nextProps.tableView &&
        prevProps.filters === nextProps.filters &&
        prevProps.groups === nextProps.groups &&
        prevProps.onFilterChange === nextProps.onFilterChange &&
        prevProps.onGroupChange === nextProps.onGroupChange &&
        prevProps.name === nextProps.name &&
        prevProps.description === nextProps.description &&
        prevProps.listHeight === nextProps.listHeight &&
        prevProps.viewId === nextProps.viewId &&
        prevProps.tempTeamId === nextProps.tempTeamId
    );
};
export enum IssueListType {
    Issues = "issues",
    Inbox = "inbox",
    Team = "team",
    View = "view",
}

interface AdminQueryPageProps {
    userID: string;
    listType: IssueListType;
    tableView: boolean;
    hidePopup: boolean;
    filters?: Map<string, Set<string>>;
    groups?: string;
    onFilterChange?: (filter: Map<string, Set<string>>) => void;
    onGroupChange?: (group: string) => void;
    name?: string;
    description?: string;
    viewId?: string;
    listHeight?: number;
    tempTeamId?: string; // just used to identify if this page is switched between teams
}

const AdminQueriesPage: React.FC<AdminQueryPageProps> = ({
    userID,
    listType,
    tableView,
    hidePopup,
    onFilterChange,
    onGroupChange,
    filters,
    groups,
    name,
    description,
    viewId,
    listHeight,
    tempTeamId,
}) => {
    const { id } = useParams(); // team ID
    const api = useApi();
    const queryClient = useQueryClient();
    const [loadingState, setLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error
    const [toggleTable, setToggleTable] = useState<boolean>(tableView); // true if table, false if kanban board
    const teamID = window.location.pathname.split("/")[2] || "";

    const fetchQueries = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        try {
            const response = await api.get(
                URLS.serverUrl + API.queriesWithPagination,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 1000,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const fetchTeamQueries = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        const teamID = window.location.pathname.split("/")[2];
        if (!teamID) {
            console.error("Team ID not found in URL");
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }

        try {
            const response = await api.get(
                `${URLS.serverUrl}${API.queriesByTeam}/${teamID}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 1000,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
        useInfiniteQuery({
            queryKey:
                listType === IssueListType.Team
                    ? [`teamQueries_${tempTeamId ?? teamID}`]
                    : ["queries"],
            queryFn:
                listType === IssueListType.Team
                    ? fetchTeamQueries
                    : fetchQueries,
            getNextPageParam: (lastPage) => {
                if (lastPage?.has_next_page) {
                    return lastPage.next_cursor;
                }
                return undefined; // No more pages
            },
            initialPageParam: 0,
            refetchInterval: 30000,
            refetchOnWindowFocus: true,
        });

    useEffect(() => {
        if (listType === IssueListType.Team) {
            refetch();
        }
    }, [teamID, listType, refetch]);

    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                  .filter((page) => page !== null && page !== undefined)
                  .flatMap((page) =>
                      Array.isArray(page.data)
                          ? page.data.filter(
                                (item) => item !== null && item !== undefined,
                            )
                          : [],
                  ) // Filter out null or undefined items in page.data
            : [];

    // Only showing teams that the member is apart of in the filter
    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listMemberTeams;
            const response = await fetch(
                `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                    },
                },
            );
            const d = await response.json();
            return d.data;
        },
    });

    const channelsQuery = useQuery<Map<string, ScopeResponse[]>>({
        queryKey: ["channels"],
        queryFn: async () => {
            const theMap = new Map<string, ScopeResponse[]>();
            // Fetching the orgInfo first
            const res = await fetch(URLS.serverUrl + API.getItemsByOrgID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
                body: JSON.stringify({
                    types: ["Slack", "CommunitySlack", "Discord", "Google"],
                }),
            });

            if (res.ok) {
                const orgInfo: OrgInfoResponse = (await res.json()).data;

                // Handle Slack scopes asynchronously
                if (orgInfo.Slack) {
                    api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/Slack`, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("Slack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle CommunitySlack scopes asynchronously
                if (orgInfo.CommunitySlack) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/CommunitySlack`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("CommunitySlack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle Discord scopes asynchronously
                if (orgInfo.Discord) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/Discord`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("Discord", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Set Google scopes to an empty array
                if (orgInfo.Google) {
                    api.get(`${URLS.serverUrl}${API.getUniqueIntegrations}/Google`, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                const integrationsResponse: Integration[] =
                                    res.data.data;
                                const dataItems: ScopeResponse[] = [];
                                for (const integration of integrationsResponse) {
                                    const scope: ScopeResponse = {
                                        key: integration.id,
                                        name: integration.unique_name
                                    };
                                    dataItems.push(scope);
                                }
                                theMap.set("Gmail", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }
            }

            return theMap;
        },
    });

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    // Fetch all the data
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const usersQuery = useQuery<GetUserResponse[]>({
        queryKey: ["users"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getAllUsers, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            return data.data;
        },
    });

    const topicsQuery = useQuery<GetTopicsResponse[]>({
        queryKey: id ? [`teamTopics_${id}`] : ["topics"],
        queryFn: id ? () => fetchTeamTopics() : () => fetchTopics(),
    });

    const fetchTopics = async (): Promise<GetTopicsResponse[]> => {
        const response = await api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };

    const fetchTeamTopics = async (): Promise<GetTopicsResponse[]> => {
        const response = await api.get(
            `${URLS.serverUrl}${API.getTopics}/team/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };

    const categoryOptionsQuery = useQuery<
        {
            id: string;
            name: string;
            color: string;
        }[]
    >({
        queryKey: ["categories"],
        queryFn: async () => {
            const response = await api.get(
                `${URLS.serverUrl}${API.getCategories}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });

    const customerGroupsQuery = useQuery<CustomerGroup[]>({
        queryKey: ["customers"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getCustomerGroups, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            const customerGroups: CustomerGroup[] = data.data;
            return customerGroups;
        },
    });

    const getTopicColors = (topics: GetTopicsResponse[]) => {
        return topics.map((topic) => ({
            color: topic.color ?? "#9B9EF0",
            label: topic.topic_name,
            value: topic.topic_name,
        }));
    };

    const getTopicMap = (topicArray: GetTopicsResponse[]) => {
        const map = new Map<string, GetTopicsResponse>();

        for (const topic of topicArray) {
            map.set(topic.topic_name, topic);
        }

        return map;
    };

    useEffect(() => {
        if (data) {
            setLoadingState(1);
        }
    }, [data]);

    const [isFirstDialogOpen, setIsFirstDialogOpen] = useState(false);
    const [isSecondDialogOpen, setIsSecondDialogOpen] = useState(false);

    return (
        <div>
            <Flex
                direction="column"
                align="center"
                justify="center"
                className="h-screen w-full overflow-hidden"
            >
                {" "}
                {/* Make Flex container take full height and prevent overflow */}
                <Box
                    height="100%" // Ensure it takes full height of its container
                    width="98%"
                    mt={`${hidePopup ? "0" : "6"}`}
                    className="h-full w-full overflow-hidden"
                >
                    <Box className="block md:flex md:items-center justify-between md:px-8 px-2">
                        {!hidePopup && (
                            <Flex
                                align="start"
                                direction="column"
                                justify="start"
                                className="overflow-hidden"
                            >
                                <Heading
                                    size="5"
                                    align="left"
                                    className="flex items-center gap-1"
                                >
                                    {listType === IssueListType.Inbox &&
                                        "Inbox"}
                                    {listType === IssueListType.Issues &&
                                        "Interactions"}
                                    {listType === IssueListType.Team &&
                                        "Team Interactions"}
                                    {name}
                                </Heading>

                                <Text mb="10px" size="2">
                                    {listType === IssueListType.Inbox &&
                                        "Track and manage interactions assigned to me"}
                                    {listType === IssueListType.Issues &&
                                        !name &&
                                        "Manage the interactions that Assembly identifies and creates."}
                                    {listType === IssueListType.Team &&
                                        "Manage the interactions that Assembly identifies and creates for your team."}
                                    {name && description}
                                </Text>
                            </Flex>
                        )}

                        {!hidePopup && (
                            <div className="flex items-center gap-1.5">
                                <Button
                                    type="button"
                                    variant="ghost"
                                    className="hover:bg-muted px-2"
                                    onClick={() => {
                                        setToggleTable((prev) => !prev);
                                    }}
                                >
                                    <TooltipProvider>
                                        {toggleTable ? (
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <ViewVerticalIcon />
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>Kanban View</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <ViewHorizontalIcon />
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>List View</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        )}
                                    </TooltipProvider>
                                </Button>
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button
                                            className="bg-shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-1.5 justify-start data-[state=open]:bg-muted shadow-sm"
                                            size="sm"
                                            variant="outline"
                                        >
                                            Create Issue
                                            <PlusIcon className="w-3 h-3" />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className="w-52 mx-4">
                                        <DropdownMenuItem
                                            onClick={() =>
                                                setIsFirstDialogOpen(true)
                                            }
                                            className="text-xs px-2 py-1.5 hover:bg-muted"
                                        >
                                            Create Single Issue
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                            onClick={() =>
                                                setIsSecondDialogOpen(true)
                                            }
                                            className="text-xs px-2 py-1.5 hover:bg-muted"
                                        >
                                            Bulk Upload Issues
                                        </DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                                <CreateIssuePopup
                                    topics={getTopicColors(
                                        topicsQuery.data ?? [],
                                    )}
                                    categories={categoryOptionsQuery.data ?? []}
                                    userID={userID}
                                    users={usersQuery.data ?? []}
                                    queryClient={queryClient}
                                    dialogIsOpen={isFirstDialogOpen}
                                    setDialogIsOpen={setIsFirstDialogOpen}
                                    teams={teamsQuery.data ?? []}
                                />
                                <FileUploadForCreateTicket
                                    userID={userID}
                                    dialogIsOpen={isSecondDialogOpen}
                                    setDialogIsOpen={setIsSecondDialogOpen}
                                />
                            </div>
                        )}
                    </Box>

                    <div className="flex-1 flex-col space-y-8 md:pt-8 md:flex overflow-hidden">
                        {loadingState === 0 && (
                            <div>
                                <Skeleton>
                                    <Text>
                                        {[...Array(6)].map((_, index) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                            <Text key={index}>
                                                {loremIpsum}
                                            </Text>
                                        ))}
                                    </Text>
                                </Skeleton>
                            </div>
                        )}
                        {loadingState === 1 &&
                            !topicsQuery.isLoading &&
                            !usersQuery.isLoading &&
                            data &&
                            (toggleTable ||
                                listType === IssueListType.Inbox) && (
                                <IssuesList
                                    issues={
                                        listType === IssueListType.Inbox
                                            ? combinedData.filter(
                                                  (issue) =>
                                                      issue.assignee_user_id ===
                                                      userID,
                                              )
                                            : combinedData
                                    }
                                    topics={getTopicColors(
                                        topicsQuery.data ?? [],
                                    )}
                                    topicsMap={getTopicMap(
                                        topicsQuery.data ?? [],
                                    )}
                                    userID={userID}
                                    listType={listType}
                                    usersQuery={usersQuery}
                                    customerGroupsQuery={customerGroupsQuery}
                                    teamsQuery={teamsQuery}
                                    onFilterChange={onFilterChange}
                                    onGroupChange={onGroupChange}
                                    inputFilters={filters ?? new Map()}
                                    inputGrouping={groups}
                                    viewId={viewId}
                                    teamID={teamID}
                                    listHeight={listHeight}
                                    refetch={refetch}
                                    channelsQuery={channelsQuery}
                                />
                            )}
                        {loadingState === 1 &&
                            data &&
                            !toggleTable &&
                            listType === IssueListType.Issues && (
                                <AdminProductFeedbackPage
                                    issues={combinedData}
                                    users={usersQuery.data ?? []}
                                />
                            )}
                        {loadingState === 2 && (
                            <Callout.Root
                                size="1"
                                variant="outline"
                                color="red"
                            >
                                <Callout.Text>
                                    Sorry, something's wrong! Please notify us
                                    at support@askassembly.app.
                                </Callout.Text>
                            </Callout.Root>
                        )}
                    </div>
                </Box>
            </Flex>
        </div>
    );
};

export default memo(AdminQueriesPage, areEqual);
