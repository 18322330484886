import { Card, CardContent } from "@/component/shadcn/ui/card";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import AssemblyToastProvider from "@/reusable_components/actions/ToastProvider";
import { CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import { Switch } from "@radix-ui/themes";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";

export default function Timer() {
	const api = useApi();

	const timerQuery = useQuery({
		queryKey: ["timerEnabled"],
		queryFn: async () => {
			const resp = await api.get(URLS.serverUrl + API.getTimerEnabled, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			});
			if (resp.status === 200) {
				return resp.data.data;
			}
			return false;
		},
	});

	useEffect(() => {
		setTimerEnabled(timerQuery.data ?? false);
	}, [timerQuery.data]);

	const updateTimerMutation = useMutation({
		mutationFn: async (data: boolean) => {
			const resp = await api.patch(URLS.serverUrl + API.saveTimerEnabled, {
				enabled: data,
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (resp.status === 200) {
				setToastText("Updated Settings!");
				setToastSymbol(CheckCircledIcon);
				setToastOpen(true);
			} else {
				setToastText(
					"Oops! Something's wrong. Please try again at a later time.",
				);
				setToastSymbol(CrossCircledIcon);
				setToastOpen(true);
			}

			setToastOpen(false);
			window.clearTimeout(timerRef.current);
			timerRef.current = window.setTimeout(() => {
				setToastOpen(true);
			}, 100);
		},
	});

	const [timerEnabled, setTimerEnabled] = useState<boolean>(
		timerQuery.data ?? false,
	);

	const [toastOpen, setToastOpen] = useState<boolean>(false);
	const [toastText, setToastText] = useState<string>("");
	const [ToastSymbol, setToastSymbol] =
		useState<React.ElementType>(CheckCircledIcon);
	const timerRef = useRef(0);

	return (
		<div>
			<Card className="mt-4 shadow-none">
				<CardContent className="p-5 flex flex-row justify-between items-center">
					<div>
						<h2 className="text-md font-semibold">Timer</h2>
						<p className="text-sm text-muted-foreground">
							Enable the timer to time how long it takes to resolve a ticket.
						</p>
					</div>

					<Switch
						checked={timerEnabled}
						onCheckedChange={(checked) => {
							setTimerEnabled(checked);
							updateTimerMutation.mutate(checked);
						}}
						className="w-8 h-4 rounded-full"
					/>
				</CardContent>
			</Card>
			<AssemblyToastProvider
				open={toastOpen}
				ToastSymbol={ToastSymbol}
				toastText={toastText}
				setOpen={setToastOpen}
			/>
		</div>
	);
}
