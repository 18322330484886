import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/component/shadcn/ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { Input } from "@/component/shadcn/ui/input";
import { API, URLS } from "@/constant";
import { GradientPicker } from "@/design/GradientPicker"; // Import the GradientPicker
import { useApi } from "@/interfaces/api";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    CaretUpIcon,
    ComponentBooleanIcon,
    Cross2Icon,
    LinkBreak1Icon,
    Pencil2Icon,
    PlusIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Box, Callout, Skeleton, Text } from "@radix-ui/themes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { workspace, type TagsRowState } from "./DataTable/constants";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { useParams } from "react-router-dom";
import { Textarea } from "@/component/shadcn/ui/textarea";
import { type Teams, teamSchema, type Category } from "@/interfaces/serverData";
import { Badge } from "@radix-ui/themes";
import { Badge as ScnBadge } from "@/component/shadcn/ui/badge";
import { HotelIcon, UsersIcon } from "lucide-react";
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger } from "@/component/shadcn/ui/context-menu";
import { toggleTeamSelection } from "@/utilities/methods";
import { TeamBadges } from "./TeamBadges";
import { TeamsDropdown } from "@/Ticket/TeamsDropdown";

// Validation Schema for form
const formSchema = z.object({
    categoryName: z
        .string()
        .min(2, { message: "Category name must be at least 2 characters." })
        .max(70, { message: "Category name must be at most 70 characters." }),
    categoryDescription: z
        .string()
        .max(100, { message: "Description must be at most 100 characters." }),
    color: z.string(),
    categoryTeams: z.array(teamSchema).optional(),
});

interface CategoriesProps {
    teams: Teams[]
}

export const Categories: React.FC<CategoriesProps> = ({ teams }) => {
    const { id } = useParams(); // team ID

    const api = useApi();
    const queryClient = useQueryClient();
    const defaultBadgeColor = "#9B9EF0";

    const [isOpen, setIsOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [editingCategory, setEditingCategory] = useState<Category | null>(
        null,
    );
    const [currentTeam, setCurrentTeam] = useState<Teams | undefined>()

    useEffect(() => {
        if (id) {
            const team = teams.filter(team => team.id === id)
            if (team.length === 1) {
                setCurrentTeam(team[0])
            } else {
                setCurrentTeam(undefined)
            }
        } else {
            setCurrentTeam(undefined)
        }
    }, [id, teams])

    // Form initialization
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            categoryName: "",
            categoryDescription: "",
            color: defaultBadgeColor,
            categoryTeams: id ? [currentTeam] : [workspace]
        },
    });
    const { control, setValue } = form;

    const fetchCategories = async (): Promise<Category[]> => {
        const response: AxiosResponse<{ data: Category[] }> = await api.get(
            `${URLS.serverUrl}${API.getCategories}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch categories");
    };

    const fetchTeamCategories = async (): Promise<Category[]> => {
        const response = await api.get(`${URLS.serverUrl}${API.getCategories}/team/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };

    // Fetch categories data using React Query v5 syntax
    const {
        data: categories = [],
        isLoading,
        isError,
        refetch,
    } = useQuery<Category[]>({
        queryKey:
            id
                ? [`teamCategories_${id}`]
                : ["categories"],
        queryFn:
            id
                ? () => fetchTeamCategories()
                : () => fetchCategories(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });

    // Create category mutation
    const createCategoryMutation = useMutation({
        mutationFn: async (newCategory: {
            name: string;
            description: string;
            color: string;
            team_ids: string[];
        }) => {
            const response: AxiosResponse = await api.put(
                `${URLS.serverUrl}${API.addCategory}`,
                newCategory,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            refetch()
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["categories"] });
            setIsOpen(false);
            form.reset();
        },
    });

    // Update category mutation
    const updateCategoryMutation = useMutation({
        mutationFn: async (updatedCategory: Category) => {
            const response: AxiosResponse = await api.patch(
                `${URLS.serverUrl}${API.editCategory}/${updatedCategory.id}`,
                updatedCategory,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            refetch()
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["categories"] });
            setIsOpen(false);
            setEditingCategory(null);
            form.reset();
        },
    });

    // Delete category mutation
    const deleteCategoryMutation = useMutation({
        mutationFn: async (id: string) => {
            const response: AxiosResponse = await api.delete(
                `${URLS.serverUrl}${API.deleteCategory}/${id}`,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            refetch()
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["categories"] });
        },
    });

    // Form submission handler
    const onSubmit = (values: z.infer<typeof formSchema>) => {
        if (editingCategory) {
            updateCategoryMutation.mutate({
                ...editingCategory,
                name: values.categoryName,
                description: values.categoryDescription,
                color: values.color,
                teams: values.categoryTeams?.filter(team => team.id !== "workspace") ?? []
            });
        } else {
            createCategoryMutation.mutate({
                name: values.categoryName,
                description: values.categoryDescription,
                color: values.color,
                team_ids: id ? [id] : values.categoryTeams?.map(team => team.id).filter(teamID => teamID !== "workspace") ?? []
            });
        }
    };

    // Delete category handler
    const handleDelete = (categoryId: string) => {
        deleteCategoryMutation.mutate(categoryId);
        setDeleteDialogOpen(false)
    };

    // Edit category handler
    const handleEdit = (category: Category) => {
        setEditingCategory(category);
        form.reset({
            categoryName: category.name,
            categoryDescription: category.description,
            color: category.color,
            categoryTeams: category.teams,
        });
        setIsOpen(true);
    };

    // Handle opening the 'Add Category' dialog
    const handleAddCategory = () => {
        setEditingCategory(null);
        form.reset({
            categoryName: "",
            categoryDescription: "",
            color: defaultBadgeColor,
        });
        setIsOpen(true);
    };

    // Remove from team handler
    const removeTeam = (category: Category) => {
        let newCategoryTeams: Teams[]
        if (category.teams.length === 0) {
            const validTeams = [...teams.filter(team => team.id !== "workspace")];
            newCategoryTeams = validTeams.filter(team => team.id !== id)
        } else {
            newCategoryTeams = category.teams.filter(team => team.id !== id)
        }
        category.teams = newCategoryTeams
        setEditingCategory(category);
        updateCategoryMutation.mutate(category);
    };

    const toggleTeam = (team: Teams, categoryTeams: Teams[]) => {
        setValue("categoryTeams", toggleTeamSelection(
            team,
            categoryTeams
        ));
    }

    return (
        <div>
            <Box mt={"3%"}>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                        <h2 className="text-1xl font-semibold">Interaction Types</h2>
                    </div>
                    <Dialog
                        open={isOpen}
                        onOpenChange={(open) => {
                            setIsOpen(open);
                            if (!open) {
                                setEditingCategory(null);
                                form.reset();
                            }
                        }}
                    >
                        <DialogTrigger asChild>
                            <Button
                                className="shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                size="sm"
                                variant="outline"
                                onClick={handleAddCategory}
                            >
                                Add Interaction Type
                                <PlusIcon />
                            </Button>
                        </DialogTrigger>
                        <DialogContent className="inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50 max-h-full overflow-auto">
                            <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                                <DialogClose asChild>
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        className="absolute top-4 right-4 hover:bg-muted"
                                    >
                                        <Cross2Icon />
                                    </Button>
                                </DialogClose>
                                <div className="mx-8 my-3">
                                    <DialogHeader className="justify-left text-left items-left pb-5">
                                        <DialogTitle>
                                            {editingCategory
                                                ? "Edit Interaction Type"
                                                : "Add Interaction Type"}
                                        </DialogTitle>
                                        <DialogDescription>
                                            {editingCategory
                                                ? "Edit an existing interaction type."
                                                : "Add a new interaction type with a description."}
                                        </DialogDescription>
                                    </DialogHeader>
                                    <Form {...form}>
                                        <form
                                            onSubmit={form.handleSubmit(onSubmit)}
                                            className="space-y-8"
                                        >
                                            <FormField
                                                control={form.control}
                                                name="categoryName"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                            <FormLabel htmlFor="categoryName">
                                                                Name
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    id="categoryName"
                                                                    className="col-span-3"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            <FormMessage className="text-xs" />
                                                        </div>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="categoryDescription"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                            <FormLabel htmlFor="categoryDescription">
                                                                Description
                                                            </FormLabel>
                                                            <FormControl>
                                                                <Textarea
                                                                    id="categoryDescription"
                                                                    className="col-span-3"
                                                                    placeholder="Please be concise but descriptive, this will passed to the AI to help categorize issues."
                                                                    {...field}
                                                                    rows={2}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="color"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                            <FormLabel>Color</FormLabel>
                                                            <FormControl>
                                                                <GradientPicker
                                                                    id="colorPicker"
                                                                    background={
                                                                        field.value
                                                                    }
                                                                    setBackground={(
                                                                        id: string,
                                                                        rowState: Partial<TagsRowState>,
                                                                    ) =>
                                                                        form.setValue(
                                                                            "color",
                                                                            rowState.colorSelected ??
                                                                            defaultBadgeColor,
                                                                        )
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={control}
                                                name="categoryTeams"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                            <FormLabel htmlFor="tagDescription">
                                                                Teams
                                                            </FormLabel>
                                                            {id ? (
                                                                <div className="flex flex-col gap-2 col-span-3">
                                                                    <Button
                                                                        variant="ghost"
                                                                        className="flex items-center gap-2 justify-start p-0 w-full data-[state=open]:bg-muted overflow-hidden"
                                                                        type="button"
                                                                        disabled={true}
                                                                    >
                                                                        <TeamBadges teams={currentTeam ? [currentTeam] : []} />
                                                                    </Button>
                                                                </div>
                                                            ) : (
                                                                <FormControl>
                                                                    <TeamsDropdown
                                                                        selectedTeams={field?.value ?? []}
                                                                        teams={teams}
                                                                        toggleTeam={toggleTeam}
                                                                        defaultIsWorkspace={true}
                                                                    />
                                                                </FormControl>)}
                                                        </div>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                            <DialogFooter className="justify-end text-end items-end">
                                                <Button
                                                    className="bg-iris9"
                                                    type="submit"
                                                >
                                                    {editingCategory
                                                        ? "Update"
                                                        : "Save changes"}
                                                </Button>
                                            </DialogFooter>
                                        </form>
                                    </Form>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
                        <DialogContent className="inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50 max-h-full overflow-auto">
                            <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                                <DialogClose asChild>
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        className="absolute top-4 right-4 hover:bg-muted"
                                    >
                                        <Cross2Icon />
                                    </Button>
                                </DialogClose>
                                <div>
                                    <DialogHeader className="justify-left text-left items-left pb-5">
                                        <DialogTitle>Are you absolutely sure?</DialogTitle>
                                        <DialogDescription>
                                            {`This action cannot be undone. This will permanently delete the tag "${editingCategory?.name}" from the Workspace.`}
                                        </DialogDescription>
                                    </DialogHeader>
                                </div>
                                <DialogFooter className="justify-end text-end items-end gap-3">
                                    <Button
                                        className="bg-iris9 py-0.5"
                                        type="submit"
                                        onClick={() => setDeleteDialogOpen(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="bg-red-500 py-0.5"
                                        type="submit"
                                        onClick={() => {
                                            if (editingCategory) {
                                                handleDelete(editingCategory.id)
                                            }
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </DialogFooter>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
                {isLoading && (
                    <Skeleton>
                        <Text>Loading...</Text>
                    </Skeleton>
                )}

                {isError && (
                    <Callout.Root color="red">
                        <Callout.Text>
                            Failed to load categories. Please try again later.
                        </Callout.Text>
                    </Callout.Root>
                )}

                {!isLoading && !isError && categories.length === 0 && (
                    <Callout.Root color="blue">
                        <Callout.Text>
                            No categories available. Please add one.
                        </Callout.Text>
                    </Callout.Root>
                )}

                {!isLoading && !isError && categories.length > 0 && (
                    <div className="space-y-4 my-5 w-full">
                        {categories.map((category) => (
                            <Card
                                key={category.id}
                                className={`p-4 shadow-none w-full flex flex-col gap-2 ${category.name === "Unknown" && category.type === "System" && 'bg-muted'}`}
                            >
                                <ContextMenu>
                                    <ContextMenuTrigger>
                                        <div className="flex justify-between items-center">
                                            <div className="mr-8 flex items-center">
                                                <ComponentBooleanIcon
                                                    color={category.color}
                                                    className="w-5 h-5 mr-3"
                                                />
                                                <div className="flex flex-col gap-0.5">
                                                    {category.name === "Unknown" && category.type === "System" && (
                                                        <div className="text-xs flex items-center gap-1 italic text-[#5B5BD6]">
                                                            Default category cannot be edited nor deleted
                                                        </div>
                                                    )}
                                                    <h3 className="font-semibold text-[13px] flex items-center gap-2">
                                                        {category.name}
                                                        <Badge
                                                            color="gray"
                                                            size="1"
                                                            radius="full"
                                                            variant="soft"
                                                            className="m-0.5 text-[11px]"
                                                        >
                                                            <div className="flex flex-row items-center gap-0.5">
                                                                <CaretUpIcon />
                                                                {category.count}
                                                            </div>
                                                        </Badge>
                                                    </h3>
                                                    <p className="text-xs text-gray-500">
                                                        {category.description}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex items-center gap-2.5">
                                                {category.teams.length === 0 ? (
                                                    <ScnBadge variant="outline" className="gap-1 py-1 px-1.5 text-[11px] font-normal">
                                                        <div className="flex items-center justify-center rounded-lg p-1 bg-blue3 border border-blue4 shadow-sm">
                                                            <HotelIcon
                                                                className="text-blue9"
                                                                strokeWidth={1.5}
                                                                size={10}
                                                            />
                                                        </div>
                                                        Workspace
                                                    </ScnBadge>
                                                ) : (
                                                    category.teams.map(team => (
                                                        <ScnBadge variant="outline" className="gap-1 py-1 px-1.5 text-[11px] font-normal" key={team.id}>
                                                            <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                                                <UsersIcon
                                                                    className="text-red9"
                                                                    strokeWidth={1.5}
                                                                    size={10}
                                                                />
                                                            </div>
                                                            {team.team_name}
                                                        </ScnBadge>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </ContextMenuTrigger>
                                    {category.name !== "Unknown" && category.type !== "System" && (
                                        <ContextMenuContent className="w-60">
                                            <ContextMenuItem
                                                inset
                                                onClick={() => handleEdit(category)}
                                                className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-2"
                                            >
                                                <Pencil2Icon className="w-3.5 h-3.5" />
                                                Edit
                                            </ContextMenuItem>
                                            {id ? (
                                                category.teams.length === 1 && category.teams[0].id === id ? (
                                                    <ContextMenuItem
                                                        inset
                                                        onClick={() => {
                                                            setEditingCategory(category);
                                                            setDeleteDialogOpen(true)
                                                        }}
                                                        className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                    >
                                                        <TrashIcon className="w-4 h-4" />
                                                        Delete
                                                    </ContextMenuItem>
                                                ) : (
                                                    <ContextMenuItem
                                                        inset
                                                        onClick={() => removeTeam(category)}
                                                        className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                    >
                                                        <LinkBreak1Icon className="w-3.5 h-3.5" />
                                                        Remove From Team
                                                    </ContextMenuItem>
                                                )
                                            ) : (
                                                <ContextMenuItem
                                                    inset
                                                    onClick={() => {
                                                        setEditingCategory(category);
                                                        setDeleteDialogOpen(true)
                                                    }}
                                                    className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                >
                                                    <TrashIcon className="w-4 h-4" />
                                                    Delete
                                                </ContextMenuItem>
                                            )}
                                        </ContextMenuContent>
                                    )}
                                </ContextMenu>
                            </Card>
                        ))}
                    </div>
                )}
            </Box>
        </div>
    );
};

