import { Card, CardContent } from "@/component/shadcn/ui/card";
import { API, loadingTypes, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { GetNotificationOptionsResponse, SaveNotificationOptionsPayload, SaveNotificationSettingsResponse } from "@/interfaces/serverData";
import {
    BellIcon,
    CheckCircledIcon,
    CrossCircledIcon,
    PlusIcon,
} from "@radix-ui/react-icons";
import * as Toast from "@radix-ui/react-toast";
import {
    Box,
    Button,
    Flex,
    HoverCard,
    Skeleton,
    Switch,
    Text,
} from "@radix-ui/themes";

import { useEffect, useRef, useState } from "react";
import { ReactComponent as SlackSvg } from "../../images/integrations/slack.svg";
import { useSearch } from "../SearchContext";
import SlackAlerts from "./SlackAlerts";
import { useParams } from "react-router-dom";
import { Badge } from "@/component/shadcn/ui/badge";
import { HotelIcon } from "lucide-react";
import { useQuery } from "@tanstack/react-query";

const NotificationPreferences: React.FC = () => {
    const { id } = useParams(); // team ID
    const { searchBarFilters, loading } = useSearch(); // using this for checking if slack integration is active
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [newNeedsResponse, setNewNeedsResponse] = useState<boolean>(false);
    const [updatedNeedsResponse, setUpdatedNeedsResponse] =
        useState<boolean>(false);
    const [slaBreach, setSlaBreach] = useState<boolean>(true);
    const [slaNotification, setSlaNotification] = useState<boolean>(true);
    const [inheritedFromOrg, setInheritedFromOrg] = useState<boolean>(true);

    const [toastText, setToastText] = useState<string>("Updated Settings!");
    const [open, setOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const [slackModalOpen, setSlackModalOpen] = useState<boolean>(false);

    const [isSlackIntegrationEnabled, setIsSlackIntegrationEnabled] =
        useState<boolean>(false);

    const api = useApi();

    useEffect(() => {
        if (loading) {
            if (searchBarFilters.get("Slack")) {
                setIsSlackIntegrationEnabled(true);
            } else {
                setIsSlackIntegrationEnabled(false);
            }
        }
    }, [searchBarFilters, loading]);

    useEffect(() => {
        refetch()
        refetchSlackNotifs()
    }, [id])

    const fetchNotificationOptions = () => {
        let endpoint = URLS.serverUrl + API.getNotificationOptions;
        if (id) {
            endpoint = `${URLS.serverUrl}${API.getNotificationOptions}/team/${id}`;
        }
        api.get(endpoint, {
            headers: { "Content-Type": "application/json" },
        })
            .then((generalRes) => {
                if (generalRes.status === 200) {
                    const data: GetNotificationOptionsResponse = generalRes.data.data;
                    setNewNeedsResponse(data.metadata.new_needs_response);
                    setUpdatedNeedsResponse(data.metadata.updated_needs_response);
                    setSlaBreach(data.metadata.sla_breaching_notification);
                    setSlaNotification(data.metadata.sla_notification);
                    setInheritedFromOrg(data.inherited_from_org)
                }
            })
            .catch((error) => {
                console.error(
                    "Error fetching general notification settings:",
                    error,
                );
            });
    }

    // Fetch the General notification options using useQuery
    const {
        data,
        refetch,
        isLoading,
    } = useQuery({
        queryKey: id ? [`fetchTeamGeneralNotifs_${id}`] : ['fetchGeneralNotifs'],
        queryFn: fetchNotificationOptions,
    });

    // Fetch the Slack notification settings using useQuery
    const {
        data: slackData,
        isError: slackError,
        isLoading: slackLoading,
        refetch: refetchSlackNotifs,
    } = useQuery({
        queryKey: id ? [`fetchTeamSlackNotifs_${id}`] : ['fetchSlackNotifs'],
        queryFn: async () => {
            let endpoint = `${URLS.serverUrl}${API.getNotifications}/Slack`;
            if (id) {
                endpoint = `${URLS.serverUrl}${API.getNotifications}/Slack/team/${id}`;
            }
            const response = await api.get(endpoint, {
                headers: { Accept: 'application/json' }
            });
            return response.data.data;
        },
    });

    const timerRef = useRef(0);

    const handleSavePreferences = async (
        new_needs_response: boolean,
        updated_needs_response: boolean,
        sla_breaching_notification: boolean,
        sla_notification: boolean
    ) => {
        const requestData: SaveNotificationOptionsPayload = {
            metadata: {
                new_needs_response: new_needs_response,
                updated_needs_response: updated_needs_response,
                sla_breaching_notification: sla_breaching_notification,
                sla_notification: sla_notification,
            }
        };
        if (id) {
            requestData.team_id = id
        }
        api.post(`${URLS.serverUrl}${API.saveNotificationOptions}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                } else {
                    setToastText("Updated Settings!");
                    refetch()
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    console.log("setting open to true");
                    setOpen(true);
                }, 100);
            });
    };

    const createTeamNotifications = async () => {
        const optionsRequestData: SaveNotificationOptionsPayload = {
            metadata: {
                new_needs_response: false,
                updated_needs_response: false,
                sla_breaching_notification: false,
                sla_notification: false,
            },
            team_id: id
        };
        api.post(`${URLS.serverUrl}${API.saveNotificationOptions}`, optionsRequestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    console.log("created new team notification options successfully")

                    const settingsRequestData: SaveNotificationSettingsResponse = {
                        enabled: false,
                        metadata: { channel: undefined }, // Send full object here
                    };
                    if (id) {
                        settingsRequestData.team_id = id
                    }
                    api.post(`${URLS.serverUrl}${API.saveNotificationSettings}/Slack`, settingsRequestData, {
                        headers: { "Content-Type": "application/json" }
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                console.log("created new team notification settings successfully")
                                setToastText("Created Team Notifications!");
                                refetch()
                                refetchSlackNotifs()
                            } else {
                                console.error("could not create new team settings options")
                                setToastText(
                                    "Oops! Something's wrong. Please try again at a later time.",
                                );
                            }
                        })
                        .catch((res) => {
                            console.error("could not create new team settings options");
                            setToastText(
                                "Oops! Something's wrong. Please try again at a later time.",
                            );
                        })
                } else {
                    console.error("could not create new team notification options")
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                }
            })
            .catch((res) => {
                console.error("could not create new team notification options")
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
            })
            .finally(() => {
                setOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setOpen(true);
                }, 100);
            });
    };

    return (
        <div>
            <Box mt={"5%"} ml={"20%"} mr={"20%"}>
                <h2 className="text-2xl font-semibold">Notifications</h2>
                <p className="pb-10 text-sm text-gray11">
                    Manage your notification settings
                </p>
                {isLoading ? (
                    <Skeleton>
                        <Text>
                            {[...Array(6)].map((_, index) => (
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                <Text key={index}>
                                    {loremIpsum}
                                </Text>
                            ))}
                        </Text>
                    </Skeleton>
                ) : (
                    <Flex direction="column" gap="4">
                        {inheritedFromOrg &&
                            <Card className={`shadow-none py-3 px-7 ${inheritedFromOrg && "bg-muted"}`}>
                                <div className="flex items-center gap-2 text-[#5B5BD6] text-[9px]">
                                    <div>
                                        <Badge
                                            variant="outline"
                                            className="gap-1 py-1 px-1.5 font-normal"
                                        >
                                            <div className="flex items-center justify-center rounded-lg p-0.5 bg-blue3 border border-blue4 shadow-sm">
                                                <HotelIcon
                                                    className="text-blue9"
                                                    strokeWidth={1.5}
                                                    size={9}
                                                />
                                            </div>
                                            Workspace
                                        </Badge>
                                    </div>
                                    <div className="text-xs flex items-center gap-2 italic">
                                        Edit Workspace's Notifications or
                                        <Button
                                            className="text-xs h-6 italic flex items-center gap-0.5 px-2 hover:cursor-pointer"
                                            variant="outline"
                                            onClick={createTeamNotifications}
                                        >
                                            <PlusIcon className="w-3 h-3" />
                                            Create Team Specific Notifications
                                        </Button>
                                    </div>
                                </div>
                            </Card>}
                        <Card className={`shadow-none pt-6 px-2 ${inheritedFromOrg && "bg-muted"}`}>
                            <CardContent>
                                <div className="flex flex-col gap-3">
                                    <div className="flex flex-row items-center gap-1">
                                        <BellIcon />
                                        <h2 className="text-md font-medium">
                                            General Notifications
                                        </h2>
                                    </div>

                                    <Flex
                                        align="center"
                                        justify="between"
                                        className="px-5"
                                    >
                                        <Flex direction="column">
                                            <h2 className="text-sm font-medium">
                                                New Needs Response
                                            </h2>
                                            <h4 className="text-xs text-gray-500">
                                                Send a notification when a new
                                                ticket is created
                                            </h4>
                                        </Flex>
                                        {inheritedFromOrg ? (
                                            <Switch checked={newNeedsResponse} />
                                        ) : (
                                            <Switch
                                                checked={newNeedsResponse}
                                                // disabled={loading}
                                                onCheckedChange={(checked: boolean) => {
                                                    setNewNeedsResponse(checked);
                                                    handleSavePreferences(checked, updatedNeedsResponse, slaBreach, slaNotification)
                                                }}
                                            />
                                        )}
                                    </Flex>

                                    <Flex
                                        align="center"
                                        justify="between"
                                        className="px-5"
                                    >
                                        <Flex direction="column">
                                            <h2 className="text-sm font-medium">
                                                Updated Needs Response
                                            </h2>
                                            <h4 className="text-xs text-gray-500">
                                                Send a notification when a ticket is
                                                newly assigned the status "Needs
                                                Response"
                                            </h4>
                                        </Flex>
                                        {inheritedFromOrg ? (
                                            <Switch
                                                checked={updatedNeedsResponse} />
                                        ) : (
                                            <Switch
                                                checked={updatedNeedsResponse}
                                                // disabled={loading}
                                                onCheckedChange={(checked: boolean) => {
                                                    setUpdatedNeedsResponse(checked);
                                                    handleSavePreferences(newNeedsResponse, checked, slaBreach, slaNotification)
                                                }}
                                            />
                                        )}
                                    </Flex>
                                    <Flex
                                        align="center"
                                        justify="between"
                                        className="px-5"
                                    >
                                        <Flex direction="column">
                                            <h2 className="text-sm font-medium">
                                                SLA Breach
                                            </h2>
                                            <h4 className="text-xs text-gray-500">
                                                Send a notification when an SLA is
                                                breached.
                                            </h4>
                                        </Flex>
                                        {inheritedFromOrg ? (
                                            <Switch checked={slaBreach} />
                                        ) : (
                                            <Switch
                                                checked={slaBreach}
                                                // disabled={loading}
                                                onCheckedChange={(checked: boolean) => {
                                                    setSlaBreach(checked);
                                                    handleSavePreferences(newNeedsResponse, updatedNeedsResponse, checked, slaNotification)
                                                }}
                                            />
                                        )}
                                    </Flex>
                                    <Flex
                                        align="center"
                                        justify="between"
                                        className="px-5"
                                    >
                                        <Flex direction="column">
                                            <h2 className="text-sm font-medium">
                                                SLA Notification
                                            </h2>
                                            <h4 className="text-xs text-gray-500">
                                                Send a notification in Slack when a
                                                ticket crosses the SLA notification
                                                time.
                                            </h4>
                                        </Flex>
                                        {inheritedFromOrg ? (
                                            <Switch checked={slaNotification} />
                                        ) : (
                                            <Switch
                                                checked={slaNotification}
                                                // disabled={loading}
                                                onCheckedChange={(checked: boolean) => {
                                                    setSlaNotification(checked);
                                                    handleSavePreferences(newNeedsResponse, updatedNeedsResponse, slaBreach, checked)
                                                }}
                                            />
                                        )}
                                    </Flex>
                                </div>
                            </CardContent>
                        </Card>

                        {slackLoading ? (
                            <Skeleton>
                                <Text>
                                    {[...Array(2)].map((_, index) => (
                                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                        <Text key={index}>{loremIpsum}</Text>
                                    ))}
                                </Text>
                            </Skeleton>
                        ) : isSlackIntegrationEnabled ? (
                            <SlackAlerts
                                enabled={slackData?.enabled ?? false}
                                channel={slackData?.channel ?? { channel: undefined }}
                                loadingState={slackError ? loadingTypes.error : slackLoading ? loadingTypes.loading : loadingTypes.loaded}
                                refetch={refetchSlackNotifs}
                                setToastOpen={setOpen}
                                setToastText={setToastText}
                                setToastSymbol={setToastSymbol}
                                isOpen={slackModalOpen}
                                onOpenChange={setSlackModalOpen}
                                timerRef={timerRef}
                                inheritedFromOrg={inheritedFromOrg}
                                teamID={id}
                            />
                        ) : (
                            <HoverCard.Root>
                                <HoverCard.Trigger>
                                    <Card className="shadow-none px-1">
                                        <CardContent className="py-3 bg-gray-200">
                                            <div className="flex flex-col gap-6">
                                                <div className="flex flex-row items-center justify-between">
                                                    <div className="flex flex-row items-center gap-4">
                                                        <SlackSvg className="max-h-5 max-w-5 grayscale" />
                                                        <h2 className="text-md font-medium text-gray-500">
                                                            Slack Notifications
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </HoverCard.Trigger>
                                <HoverCard.Content>
                                    <Text>Connect Slack to enable</Text>
                                </HoverCard.Content>
                            </HoverCard.Root>
                        )}
                    </Flex>
                )}
                <Toast.Provider swipeDirection="right">
                    <Toast.Root
                        className="ToastRoot"
                        open={open}
                        onOpenChange={setOpen}
                    >
                        <Toast.Title className="ToastTitle">
                            <Flex direction={"row"} align={"center"} gap="2">
                                <ToastSymbol color="green" />
                                {toastText}
                            </Flex>
                        </Toast.Title>
                    </Toast.Root>
                    <Toast.Viewport className="ToastViewport" />
                </Toast.Provider>
            </Box>
        </div>
    );
};

export default NotificationPreferences;
