import type { Ticket } from "./serverData";

export const defaultTicket: Ticket = {
    insights: [],
    id: "",
    ticket_identifier: "",
    number: 0,
    created_at: "",
    updated_at: "",
    query: "",
    title: "",
    bot_category: "",
    source: "",
    source_unique_name: "",
    topic: [],
    ticket_status: "",
    url: "",
    source_specific_id: "",
    ai_response: "",
    internal_note: "",
    assignee_user_id: "",
    external_issues: [],
    channel_name: "",
    breaching: "",
};
