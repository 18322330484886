import { InsightListType } from "@/Insights/useInsightsData";
import { Filter } from "@/IssuesTable/Filter";
import FilterDropdown from "@/IssuesTable/FilterDropdown";
import { Button } from "@/component/shadcn/ui/button";
import { Calendar } from "@/component/shadcn/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    ExportTicketsResponse,
    InteractionsAnalyticsResponse,
    Teams,
} from "@/interfaces/serverData";
import TimezoneDropdown from "@/reusable_components/timestampDropdown";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Card, Flex, Skeleton, Text } from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import type React from "react";
import type { DateRange } from "react-day-picker";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import type { GroupedDataItem } from "../AdminAnalyticsPage";
import { useCallback, useState } from "react";
import { type FilterOption, FilterType } from "@/IssuesTable/constants";
import { getFilterOption } from "@/utilities/methods";

interface InteractionsProps {
    listType: InsightListType;
    teamID: string;
    setTeamID: React.Dispatch<React.SetStateAction<string>>;
    rawData: InteractionsAnalyticsResponse[];
    teamsQueryStatus: string;
    teamsQueryIsError: boolean;
    teamsQueryData: Teams[] | undefined;
    interactionsChartData: GroupedDataItem[];
    interactionsQueryStatus: string;
    interactionsQueryIsError: boolean;
    range: DateRange | undefined;
    setRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
    playgroundFilters: Map<string, Set<string>>;
    setPlaygroundFilters: React.Dispatch<
        React.SetStateAction<Map<string, Set<string>>>
    >;
    handleFilterSelect: (type: string, value: string) => () => void;
}

const InteractionsComponent = ({
    listType,
    teamID,
    setTeamID,
    rawData,
    teamsQueryData,
    interactionsChartData,
    interactionsQueryStatus,
    interactionsQueryIsError,
    range,
    setRange,
    playgroundFilters,
    setPlaygroundFilters,
    handleFilterSelect,
}: InteractionsProps) => {
    const api = useApi();

    const exportMutation = useMutation({
        mutationFn: async () => {
            const res = await api.post(URLS.serverUrl + API.exportTickets, {
                team_id: teamID,
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (res.status === 200) {
                return res.data.data;
            }
            throw new Error("Error fetching data");
        },
    });

    function convertDateToString(
        dateString: string | undefined,
        timezone: string,
    ): string {
        if (dateString) {
            const date = new Date(dateString);
            return `${date.toLocaleDateString("en-US", {
                timeZone: timezone,
            })} ${date.toLocaleTimeString("en-US", {
                timeZone: timezone,
            })}`;
        }
        return "";
    }

    function convertToRawCSV(
        data: ExportTicketsResponse,
        timezone: string,
    ): string[][] {
        const headers = [
            "Ticket Number",
            "Ticket Status",
            "Ticket Title",
            "URL",
            "Source",
            "Topics",
            "Interaction Type",
            "Assignee",
            "Created",
            "First Opened",
            "Closed At",
        ];
        const rows = data.tickets.map((item) => {
            return [
                `${item.ticket_identifier}-${item.ticket_number}`,
                item.ticket_status,
                `"${item.title}"`,
                `https://dashboard.askassembly.app/issue/${item.ticket_identifier}-${item.ticket_number}`,
                item.source,
                `"${item.topic.join(", ")}"`,
                item.bot_category,
                item.user,
                convertDateToString(item.time_created, timezone),
                convertDateToString(item.time_responded, timezone),
                convertDateToString(item.time_closed, timezone),
            ];
        });
        return [headers, ...(rows as string[][])];
    }

    // Function to convert data to CSV format
    const convertToCSV = (data: GroupedDataItem[], timeZone: string) => {
        const headers = ["Date", "Count"];
        const rows = data.map((item) => [
            new Date(item.date).toLocaleDateString("en-US", {
                timeZone,
            }),
            item.count,
        ]);
        return [headers, ...rows];
    };

    function downloadRawCSV(data: ExportTicketsResponse, timezone: string) {
        const csvData = convertToRawCSV(data, timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "export_tickets.csv");
    }

    const handleExport = async (timezone: string) => {
        try {
            const data = await exportMutation.mutateAsync();
            downloadRawCSV(data, timezone);
        } catch (error) {
            console.error("Error exporting tickets:", error);
        }
    };

    // Function to handle CSV download
    const downloadCSV = (timezone: string) => {
        const csvData = convertToCSV(interactionsChartData, timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "interactions_data.csv");
    };

    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    const filterOptions: FilterOption[] = [getFilterOption("Source", FilterType.MenuThenSelect, true)];
    const handleMenuClick = useCallback(
        (menu: string) => () => {
            // Reset activeMenu when dropdown is closed
            setActiveMenu(menu);
        },
        [],
    );


    return (
        <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2 mt-2">
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant="outline"
                            className="flex items-center h-9 gap-2 text-muted-foreground text-xs"
                        >
                            <CalendarIcon className="h-3 w-3" />
                            {range?.from ? (
                                format(range.from, "PP")
                            ) : (
                                <span>Oldest</span>
                            )}
                            <div>to</div>
                            {range?.to ? (
                                format(range.to, "PP")
                            ) : (
                                <span>Latest</span>
                            )}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                        <Calendar
                            mode="range"
                            selected={range}
                            onSelect={setRange}
                        />
                    </PopoverContent>
                </Popover>

                {Array.from(playgroundFilters.entries()).map(
                    ([type, values]) => (
                        <div key={type}>
                            <Filter
                                type={type}
                                values={values}
                                filters={playgroundFilters}
                                setFilters={setPlaygroundFilters}
                                topics={[]}
                                users={[]}
                                customerGroups={[]}
                                isSavedViewFilter={false}
                                categories={[]}
                                teams={[]}
                                channels={new Map()}
                                filterOptions={filterOptions}
                            />
                        </div>
                    ),
                )}
                <FilterDropdown
                    className="h-9 min-w-[60px]"
                    filters={playgroundFilters}
                    filterOptions={filterOptions}
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                    handleItemSelect={handleFilterSelect}
                    handleMenuClick={handleMenuClick}
                    topics={[]}
                    users={[]}
                    customerGroups={[]}
                    teams={[]}
                    categories={[]}
                    channels={new Map()}
                />

                {listType === InsightListType.General && (
                    <Select
                        value={teamID}
                        onValueChange={(value) => setTeamID(value)}
                    >
                        <SelectTrigger className="text-xs py-1 mx-5">
                            <SelectValue placeholder="Select Team" />
                        </SelectTrigger>
                        <SelectContent>
                            {teamsQueryData?.map((team) => (
                                <SelectItem key={team.id} value={team.id}>
                                    {team.team_name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                )}
                <TimezoneDropdown
                    onTimezoneSelect={(timezone) => {
                        downloadCSV(timezone);
                        handleExport(timezone);
                    }}
                    button={
                        <Button
                            className="bg-shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-1.5 justify-start data-[state=open]:bg-muted shadow-sm"
                            size="sm"
                            variant="outline"
                        >
                            Download CSV
                        </Button>
                    }
                />
            </div>

            <Card>
                <Flex direction="column">
                    <Text size="3" weight="bold">
                        Interactions
                    </Text>
                    <Text size="3" color="gray">
                        View interactions submitted over time.
                    </Text>
                    {interactionsQueryStatus === "loading" && <SkeletonText />}
                    {interactionsQueryStatus === "success" && (
                        <ResponsiveContainer height={400}>
                            <LineChart data={interactionsChartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    tickFormatter={(date) => {
                                        const d = new Date(date);
                                        const userLocale =
                                            navigator.language || "en-US";
                                        return d.toLocaleDateString(
                                            userLocale,
                                            {
                                                month: "short",
                                                day: "numeric",
                                                year: "2-digit",
                                            },
                                        );
                                    }}
                                />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="count"
                                    stroke="#5B5BD6"
                                    activeDot={{ r: 8 }}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                </Flex>
            </Card>
        </div>
    );
};

const SkeletonText = () => (
    <Skeleton>
        <Text size="5" color="gray">
            Loading data...
        </Text>
    </Skeleton>
);

export default InteractionsComponent;
