import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
import { GitHubLogoIcon, PlusIcon } from "@radix-ui/react-icons";
import {
    Badge,
    Box,
    Button,
    Callout,
    Card,
    Flex,
    Separator,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { metadata } from "../IntegrationsManagementPage";
import IntegrationHeader from "./IntegrationHeader";

const GitHubIntegration = () => {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [refreshDisabled, setRefreshDisabled] = useState<boolean>(true);

    const codeContinue = () => {
        const url = `https://github.com/apps/${process.env.REACT_APP_GITHUB_APP_NAME}/installations/new`;
        window.location.assign(url);
    };

    const [additionalMetadata, setAdditionalMetadata] = useState<metadata>({
        scopes: [],
        indexedOn: "",
    });

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    function handleReindex(integrationType: string, integrationUrl?: string) {
        if (integrationType === undefined || integrationType === "") {
            return;
        }

        const requestData = {
            type: integrationType,
            is_reindex: true,
        };

        api.post(URLS.serverUrl + API.indexIntegration, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then(async (res) => {
            if (res.status === 200) {
            } else {
                // todo - need to handle this case.
            }
        });
        navigate("/admin/integrations/reindex");
    }

    useEffect(() => {
        const requestData = {
            types: ["Code"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp: metadata = { scopes: [], indexedOn: "" };

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;
                        if (orgInfo.Code) {
                            setIntegrationEnabled(true);
                        }
                        if (orgInfo.Code?.[0]?.scopes) {
                            for (const repo of orgInfo.Code[0].scopes) {
                                temp.scopes.push(repo.name);
                            }

                            if (orgInfo.Code?.[0]?.indexedOn) {
                                const date: Date = parseISO(
                                    orgInfo.Code?.[0]?.indexedOn ?? "",
                                );
                                const humanReadableDate = format(
                                    date,
                                    "MMMM dd, yyyy hh:mm a",
                                );
                                temp.indexedOn = humanReadableDate;

                                const currentTime: Date = new Date();

                                const difference: number =
                                    currentTime.getTime() - date.getTime();
                                const differenceInHours: number =
                                    difference / (1000 * 60 * 60);

                                const hasNotBeen24Hours: boolean =
                                    differenceInHours <= 24;
                                setRefreshDisabled(hasNotBeen24Hours);
                            }
                        }

                        // if (orgInfo.Code?.token_scopes) {
                        //     if (orgInfo.Code.token_scopes.includes("repo")) {
                        //         setAdditionalScopeNeeded(false);
                        //     }
                        // }

                        setAdditionalMetadata(temp);
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    const navigate = useNavigate();
    const addMoreRepositories = () => {
        navigate("/admin/integrations/add/code");
    };

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType="Code"
                        description="Automatically search through and answer questions about your code"
                        SvgIcon={GitHubLogoIcon}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {loadingState === loadingTypes.loaded &&
                        !integrationEnabled ? (
                            <Button mb={"20px"} onClick={codeContinue}>
                                Enable
                            </Button>
                        ) : (
                            <Button mb={"20px"} disabled={true}>
                                Enabled
                            </Button>
                        )}
                    </Flex>
                </Flex>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && integrationEnabled && (
                    <Flex direction={"column"} gap="2" mb="2">
                        <Card
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                gap="2"
                                mb="2"
                                justify={"between"}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={"start"}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                    }}
                                    gap="2"
                                >
                                    <Flex
                                        direction={"column"}
                                        style={{ flex: 1, minWidth: 0 }}
                                    >
                                        <Text size={"2"}>
                                            Repositories Selected
                                        </Text>
                                        <Text
                                            size={"1"}
                                            color="gray"
                                            style={{
                                                overflow: "hidden",
                                                whiteSpace: "normal",
                                                textOverflow: "clip",
                                            }}
                                        >
                                            Add a repository to index through
                                            and add to your searchable knowledge
                                            base.
                                        </Text>
                                    </Flex>
                                    <Flex
                                        direction="column"
                                        gap="1"
                                        align={"start"}
                                        style={{ flexShrink: 0 }} // Ensure this part does not shrink
                                    >
                                        <Button
                                            variant="outline"
                                            size="1"
                                            onClick={addMoreRepositories}
                                            style={{
                                                width: "fit-content",
                                            }}
                                        >
                                            <PlusIcon width="15" height="15" />
                                            Add More Repositories
                                        </Button>
                                    </Flex>
                                </Flex>
                                {additionalMetadata.scopes?.length !== 0 && (
                                    <Separator
                                        size={"4"}
                                        mt="1"
                                        mb="1"
                                        style={{ width: "100%" }}
                                    />
                                )}
                                {additionalMetadata.scopes?.length !== 0 && (
                                    <Flex
                                        style={{
                                            paddingRight: "20px",
                                            paddingLeft: "20px",
                                        }}
                                        direction="row"
                                        gap="3"
                                        align="center"
                                        wrap="wrap"
                                    >
                                        {additionalMetadata.scopes.map(
                                            (item) => (
                                                <Badge key={item}>{item}</Badge>
                                            ),
                                        )}{" "}
                                    </Flex>
                                )}
                            </Flex>
                        </Card>
                        <Flex
                            direction="column"
                            style={{
                                justifyContent: "flex-end",
                                alignItems: "end",
                            }}
                            gap="3"
                        >
                            <Flex direction={"row"}>
                                <Text size={"1"}>
                                    Last Updated: {
                                        additionalMetadata.indexedOn
                                    }{" "}
                                </Text>
                            </Flex>

                            <Button
                                disabled={refreshDisabled}
                                onClick={() => {
                                    handleReindex("Code");
                                }}
                            >
                                Refresh
                            </Button>
                        </Flex>
                    </Flex>
                )}
            </Box>
        </div>
    );
};

export default GitHubIntegration;
