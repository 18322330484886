import { createElement, useEffect, useRef } from "react";

import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import { FancyMultiSelect } from "@/component/MultiSelect";
import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Integration, ScopeResponse, TeamSources } from "@/interfaces/serverData";
import {
    CheckCircledIcon,
    Cross2Icon,
    CrossCircledIcon,
    DotsHorizontalIcon,
    InfoCircledIcon,
    Pencil1Icon,
    PlusIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Badge, ChevronDownIcon } from "@radix-ui/themes";
import type React from "react";
import { useState } from "react";
import { integrationBackEndDataMappingToSvg } from "../Admin/Integrations/constant";

import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/component/shadcn/ui/alert-dialog";
import type { TeamCardUI } from "./TeamSettings";

interface TeamCardProps {
    teamId: string;
    teamSource: TeamSources;
    removeTeamCard: (teamCardId: string, source: string) => void;
    removeTeamCardFromUI: (teamCardId: string) => void;
    setTeamCards: React.Dispatch<React.SetStateAction<TeamCardUI[]>>;
    setToastText: React.Dispatch<React.SetStateAction<string>>;
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType>>;
    setToastOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TeamCard: React.FC<TeamCardProps> = ({
    teamId,
    teamSource,
    removeTeamCard,
    removeTeamCardFromUI,
    setTeamCards,
    setToastText,
    setToastSymbol,
    setToastOpen,
}) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [selectedSource, setSelectedSource] = useState<string>(
        teamSource.source,
    );
    const [icon, setIcon] = useState<React.ElementType | undefined>(
        integrationBackEndDataMappingToSvg.get(teamSource.source),
    );

    const [showSelectedChannels, setShowSelectedChannels] =
        useState<boolean>(true);
    const [selectedChannels, setSelectedChannels] = useState<ScopeResponse[]>(
        teamSource.scopes,
    );

    const api = useApi();

    const [options, setOptions] = useState<ScopeResponse[]>([]);

    const updateOptionsForSource = (source: string) => {
        switch (source) {
            case "Slack":
            case "Discord":
            case "CommunitySlack":
            case "GitHubTicket": {
                api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/${source}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        const dataItems: ScopeResponse[] =
                            res.data.data?.asm_ticket_channels;
                        setOptions(dataItems);
                    })
                    .catch((res) => { });
                break
            }
            case "Gmail": {
                api.get(`${URLS.serverUrl}${API.getUniqueIntegrations}/Google`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            const integrationsResponse: Integration[] =
                                res.data.data;
                            const dataItems: ScopeResponse[] = [];
                            for (const integration of integrationsResponse) {
                                const scope: ScopeResponse = {
                                    key: integration.id,
                                    name: integration.unique_name
                                };
                                dataItems.push(scope);
                            }
                            setOptions(dataItems)
                        }
                    })
                    .catch((res) => {
                        console.error("Error fetching scope data:", res);
                    });
            }
        }
    }

    useEffect(() => {
        updateOptionsForSource(teamSource.source)
    }, [teamSource.source]);

    const handleSourceChange = (newSource: string) => {
        setSelectedChannels([]);
        setShowSelectedChannels(false);
        setSelectedSource(newSource);
        setIcon(integrationBackEndDataMappingToSvg.get(newSource));
        updateOptionsForSource(newSource)
    };

    const timerRef = useRef(0);

    const handleEditSource = () => {
        console.log("where is it calling deletion?");
        const requestData = {
            source: selectedSource,
            metadata: selectedChannels,
        };
        const [url] = TeamsAPI.editTeamSource;
        api.patch(`${URLS.serverUrl}${url}/${teamId}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    setToastText("Error updating source");
                    setToastSymbol(CrossCircledIcon);
                } else {
                    removeTeamCardFromUI(teamSource.id);

                    const data = res.data.data as TeamSources;
                    setTeamCards((prev: TeamCardUI[]) => [
                        ...prev,
                        {
                            id: data.id,
                            isEditing: false,
                            card: data,
                        },
                    ]);
                    setToastText("Source updating successfully");
                    setToastSymbol(CheckCircledIcon);
                }
            })
            .catch((res) => {
                console.error("Error fetching scope data:", res);
                setToastText("Error updating source");
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setToastOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setToastOpen(true);
                }, 100);
            });
    };

    const handleDeleteSource = () => {
        removeTeamCard(teamSource.id, teamSource.source);
    };

    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    return (
        <Card className="shadow-none p-3">
            <div className="flex flex-col h-full">
                <div className="flex gap-3 justify-between flex-col">
                    <div className="flex flex-row justify-between items-start w-full">
                        <div className="flex flex-col items-start gap-2">
                            <p className="text-sm font-medium">Accounts</p>
                            {isEditing ? (
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Badge
                                            color="gray"
                                            size="2"
                                            radius="full"
                                            variant="outline"
                                            className="px-2 py-1 text-xs rounded-xl flex items-center justify-between min-w-[100px]"
                                        >
                                            <div className="flex items-center">
                                                {icon !== undefined &&
                                                    createElement(icon, {
                                                        className: "w-4 h-4",
                                                    })}
                                                <p className="pl-1 pr-1 overflow-hidden text-ellipsis whitespace-nowrap">
                                                    {selectedSource !== ""
                                                        ? selectedSource
                                                        : "Source"}
                                                </p>
                                            </div>
                                            <ChevronDownIcon className="ml-auto" />
                                        </Badge>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent
                                        align="start"
                                        className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                    >
                                        <FilterDropdownElement
                                            type="Source"
                                            filters={new Map<string, Set<string>>()}
                                            handleItemSelect={(
                                                type: string,
                                                value: string
                                            ) => () => {
                                                if (type === "Source") {
                                                    handleSourceChange(
                                                        value
                                                    );
                                                }
                                            }}
                                            topics={[]}
                                            users={[]}
                                            extraOptions={[]}
                                            customerGroups={[]}
                                            isSavedViewFilter={false}
                                            categories={[]}
                                            teams={[]}
                                            channels={new Map()} />
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            ) : (
                                <Badge
                                    color="gray"
                                    size="2"
                                    radius="full"
                                    variant="outline"
                                    className="px-2 py-1 text-xs rounded-xl flex items-center justify-between min-w-[100px]"
                                >
                                    <div className="flex items-center">
                                        {icon !== undefined &&
                                            createElement(icon, {
                                                className: "w-4 h-4",
                                            })}
                                        <p className="pl-1 pr-1 overflow-hidden text-ellipsis whitespace-nowrap">
                                            {selectedSource !== ""
                                                ? selectedSource
                                                : "Source"}
                                        </p>
                                    </div>
                                </Badge>
                            )}
                        </div>
                        <div className="flex items-end flex-row">
                            {isEditing && (
                                <div>
                                    {selectedSource !== "" &&
                                        selectedSource !== "Google" && (
                                            <Button
                                                className="text-xs outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                                size="sm"
                                                variant="outline"
                                                onClick={() => {
                                                    setShowSelectedChannels(
                                                        true,
                                                    );
                                                }}
                                                type="button"
                                            >
                                                add accounts
                                                <PlusIcon />
                                            </Button>
                                        )}
                                </div>
                            )}
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button
                                        variant="ghost"
                                        className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                                    >
                                        <DotsHorizontalIcon className="h-4 w-4" />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    align="end"
                                    className="w-[150px] max-h-60 p-0 rounded-md overflow-y-auto"
                                    onClick={(event) => event.stopPropagation()}
                                >
                                    <DropdownMenuItem asChild>
                                        <AlertDialog
                                            open={deleteDialogOpen}
                                            onOpenChange={setDeleteDialogOpen}
                                        >
                                            <AlertDialogTrigger asChild>
                                                <div className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex flex-row items-center">
                                                    <p className="flex-grow">
                                                        Delete
                                                    </p>
                                                    <TrashIcon className="justify-self-end" />
                                                </div>
                                            </AlertDialogTrigger>
                                            <AlertDialogContent className="fixed inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50">
                                                <div className="bg-white shadow-lg rounded-md py-3 px-7 overflow-auto relative w-full max-w-md mx-auto">
                                                    <div>
                                                        <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                                            <AlertDialogTitle>
                                                                Are you
                                                                absolutely sure?
                                                            </AlertDialogTitle>
                                                            <AlertDialogDescription>
                                                                This action
                                                                cannot be
                                                                undone. This
                                                                will permanently
                                                                delete this Account.
                                                            </AlertDialogDescription>
                                                        </AlertDialogHeader>
                                                    </div>
                                                    <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                                        <AlertDialogCancel>
                                                            Cancel
                                                        </AlertDialogCancel>
                                                        <AlertDialogAction
                                                            onClick={
                                                                handleDeleteSource
                                                            }
                                                        >
                                                            Delete
                                                        </AlertDialogAction>
                                                        <AlertDialogCancel className="shadow-none absolute top-0 right-2 px-2 py-2 ">
                                                            <Cross2Icon />
                                                        </AlertDialogCancel>
                                                    </AlertDialogFooter>
                                                </div>
                                            </AlertDialogContent>
                                        </AlertDialog>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem asChild>
                                        <div
                                            className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex flex-row items-center"
                                            onClick={() => setIsEditing(true)}
                                            onKeyDown={(event) => { }}
                                        >
                                            <p className="flex-grow text-xs ">
                                                Edit
                                            </p>
                                            <Pencil1Icon className="justify-self-end" />
                                        </div>
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    </div>
                    {showSelectedChannels && isEditing && (
                        <Card className="shadow-none mx-8">
                            <CardContent className="py-4 px-4 flex flex-row gap-5 justify-between items-center">
                                <div className="flex flex-col gap-2 grow">
                                    <div className="flex flex-row gap-1">
                                        <p className="text-xs font-normal">
                                            Select accounts
                                        </p>
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <InfoCircledIcon />
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    <p>
                                                        If you don't see your
                                                        channel here, make sure
                                                        to check if it's been
                                                        selected under Manage
                                                        Integrations &gt;
                                                        Customer Channels for
                                                        this source.
                                                    </p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                    <FancyMultiSelect
                                        setSelectedChannels={
                                            setSelectedChannels
                                        }
                                        selectedChannels={selectedChannels}
                                        options={options}
                                        placeholder="accounts..."
                                    />
                                </div>
                                <Button
                                    type="button"
                                    variant="outline"
                                    size="sm"
                                    className="flex-none"
                                    onClick={() => {
                                        setSelectedChannels([]);
                                        setShowSelectedChannels(false);
                                    }}
                                >
                                    <TrashIcon />
                                </Button>
                            </CardContent>
                        </Card>
                    )}
                    {showSelectedChannels && !isEditing && (
                        <Card className="shadow-none mx-8">
                            <CardContent className="py-4 px-4 flex flex-row gap-5 justify-between items-center">
                                <div className="flex flex-col gap-2 grow">
                                    <div className="flex flex-row gap-1">
                                        <p className="text-xs font-normal">
                                            Accounts Selected
                                        </p>
                                    </div>
                                    <div className="flex flex-wrap gap-4">
                                        {selectedChannels.map((channel) => (
                                            <Badge key={channel.name}>
                                                {channel.name}
                                            </Badge>
                                        ))}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    )}
                    {isEditing && (
                        <div className="mt-auto flex justify-end">
                            <Button
                                className="text-xs outline outline-1 outline-slate-200 flex flex-wrap gap-2 data-[state=open]:bg-muted shadow-sm iris6"
                                size="sm"
                                variant="outline"
                                type="button"
                                onClick={() => {
                                    handleEditSource();
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </Card>
    );
};

export default TeamCard;
