import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Announcement, Insight, Ticket } from "@/interfaces/serverData";
import {
    AnnouncementsState,
    AnnouncementsType,
    AnnouncementsTypeMap,
} from "@/pages/Announcements/constants";
import { getAnnouncementStatusIcon } from "@/utilities/methods";
import { QuestionMarkCircledIcon, ResetIcon, SpeakerLoudIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes/dist/cjs/components";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

function extractParts(url: string) {
    try {
        const urlObj = new URL(url);
        const pathParts = urlObj.pathname.split("/").filter((part) => part);
        if (url.toLowerCase().includes("linear")) {
            return pathParts.length > 2 ? pathParts[2] : null;
        } else if (url.toLowerCase().includes("github")) {
            return pathParts.length > 3
                ? `${pathParts[1]} ${pathParts[3]}`
                : null;
        } else if (url.toLowerCase().includes("atlassian")) {
            return pathParts.length > 1 ? pathParts[1] : null;
        }
    } catch (error) {
        console.error("Invalid URL or error parsing URL:", error);
        return null;
    }
}

// A ticket or an insight should be inputted
interface AnnouncementSectionProps {
    ticket?: Ticket;
    refetchTicketData?: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    insight?: Insight;
    updateInsightState?: (newState: Partial<Insight>) => void;
    userID: string;
    // sidebarLabels: string;
}

export const AnnouncementsSection: React.FC<AnnouncementSectionProps> = ({
    ticket,
    refetchTicketData,
    insight,
    updateInsightState,
    userID,
}) => {
    const api = useApi();
    const announcements: Announcement[] = insight?.announcements ?? [];

    const navigate = useNavigate();
    const createAnnouncement = async () => {
        const requestData = {
            status: AnnouncementsState.draft,
            id: "",
            insight_id: insight?.id,
            announcement_type: AnnouncementsType.replyInThread,
        };

        api.post(URLS.serverUrl + API.announcement, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                const id = res.data.data.id;
                navigate(`/announcements/new/${id}`, {
                    state: { announcement: res.data.data },
                });
            } else {
                console.log("could not create a draft announcement");
            }
        });
    };

    return (
        <div>
            {announcements.map((announcement) => {
                const Icon = getAnnouncementStatusIcon(announcement.status);
                return (
                    <Card className="p-2 my-1 rounded" key={announcement.id}>
                        <div className="prose max-w-none text-xs flex items-center justify-between px-2 py-0">
                            <div className="flex items-center gap-1.5 max-w-[60%]">
                                <Badge
                                    color="iris"
                                    size="2"
                                    radius="medium"
                                    variant="outline"
                                    className="px-2 my-0 ring-[0.8px] text-gray-70 flex items-center gap-1"
                                >
                                    {Icon ? (
                                        <Icon className="ml-0.5 w-3.5 h-3.5" />
                                    ) : (
                                        <QuestionMarkCircledIcon className="ml-0.5 w-3.5 h-3.5" />
                                    )}
                                    {announcement.status}
                                </Badge>
                                <span className="px-1 flex items-center gap-1.5" style={{ maxWidth: '250%' }}>
                                    <a
                                        href={`${process.env.REACT_APP_CALLBACK_URL}announcements/${announcement.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-iris9 hover:text-iris11 hover:underline flex items-end gap-2"
                                    >
                                        <div className="text-[13px] whitespace-nowrap overflow-hidden text-ellipsis max-w-[300px]">
                                            {announcement.name ? announcement.name : "Announcement"}
                                        </div>
                                    </a>
                                    <div className="text-gray-500 text-xs whitespace-nowrap overflow-hidden text-ellipsis max-w-[calc(60%-300px)]">
                                        {announcement.content ? announcement.content : ""}
                                    </div>
                                </span>
                            </div>
                            <div className="flex-shrink-0 ml-2">
                                <Badge
                                    color={
                                        "gray"
                                    }
                                    size="2"
                                    radius="full"
                                    variant="outline"
                                    className="py-1 transition-opacity duration-300 text-gray11"
                                >
                                    <div className="flex flex-row items-center gap-2">
                                        {announcement.announcement_type ===
                                            AnnouncementsType.broadcast ? (
                                            <SpeakerLoudIcon />
                                        ) : (
                                            <ResetIcon />
                                        )}
                                        {AnnouncementsTypeMap[
                                            announcement.announcement_type
                                        ] ??
                                            "Broadcast"}
                                    </div>
                                </Badge>
                            </div>
                        </div>
                    </Card>
                );
            })}
            <Button
                variant="secondary"
                className="py-0 my-1.5"
                size="sm"
                onClick={createAnnouncement}
            >
                Create Announcement
            </Button>
        </div>
    );
};
