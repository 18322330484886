"use client";
import ForwardTimer from "@/component/ForwardTimer";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { HistoryResponse } from "@/interfaces/serverData";
import {
    type QueryObserverResult,
    type RefetchOptions,
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { Pause, Play } from "lucide-react";
import * as React from "react";

import { Badge } from "@radix-ui/themes";

interface TimerComponentProps {
    id: string;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
}

export default function TimerComponent({
    id,
    refetchThreadData,
}: TimerComponentProps) {
    const api = useApi();

    interface TimerStatusResponse {
        running: boolean;
        time_started?: string;
    }

    const timerStatus = useQuery<TimerStatusResponse>({
        queryKey: ["timerStatus"],
        queryFn: async () => {
            const resp = await api.get(
                `${URLS.serverUrl + API.getTicketTimer}/${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (resp.status === 200) {
                const data: TimerStatusResponse = resp.data.data;

                setIsRunning(data.running);
                if (data.time_started) {
                    setTimeStarted(new Date(data.time_started));
                }
                return data;
            }
            return { running: false };
        },
    });

    const [isRunning, setIsRunning] = React.useState<boolean>(
        timerStatus.data?.running ?? false,
    );

    const [timeStarted, setTimeStarted] = React.useState<Date | null>(
        timerStatus.data?.time_started
            ? new Date(timerStatus.data.time_started)
            : null,
    );

    const [intervalId, setIntervalId] = React.useState<NodeJS.Timeout | null>(
        null,
    );

    const timerMutation = useMutation({
        mutationFn: async (status: "started" | "paused") => {
            await api
                .post(URLS.serverUrl + API.addTicketTimerEvent, {
                    id,
                    action:
                        status === "started"
                            ? "TIMER_ACTION_START"
                            : "TIMER_ACTION_STOP",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                })
                .then((resp) => {
                    refetchThreadData();
                    if (resp.status === 200) {
                        const data: TimerStatusResponse = resp.data.data;

                        setIsRunning(data.running);
                        if (data.time_started) {
                            setTimeStarted(new Date(data.time_started));
                        }
                    }
                });
        },
    });

    const toggleTimer = () => {
        if (isRunning) {
            if (intervalId) clearInterval(intervalId);
            timerMutation.mutate("paused");
        } else {
            // const id = setInterval(() => setTime((t) => t + 1), 1000);
            // setIntervalId(id);
            timerMutation.mutate("started");
        }
        setIsRunning(!isRunning);
    };

    React.useEffect(() => {
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [intervalId]);

    return (
        <div className="text-xs flex items-center">
            {/* <Button
                variant="ghost"
                size="sm"
                asChild
                onClick={toggleTimer}
                disabled={timerMutation.isPending}
            > */}
            {!timerMutation.isPending && (
                <Badge
                    color={"gray"}
                    size="2"
                    radius="medium"
                    variant="outline"
                    onClick={toggleTimer}
                    className="text-xs ring-[0.8px] text-gray-700 ring-[#E0E1E6]"
                >
                    {isRunning ? (
                        <div className="flex items-center gap-1">
                            <Pause className="h-3.5 w-3.5" strokeWidth={1.5} />
                            {timeStarted && (
                                <p className="text-xs">
                                    <ForwardTimer startTime={timeStarted} />
                                </p>
                            )}
                        </div>
                    ) : (
                        <div className="flex items-center gap-1">
                            <Play className="h-3.5 w-3.5" strokeWidth={1.5} />
                            <p className="text-xs">Start</p>
                        </div>
                    )}
                </Badge>
            )}
            {/* </Button> */}
        </div>
    );
}
