import { Button } from "@/component/shadcn/ui/button";
import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Insight,
    InsightData,
    Query,
    RelatedIssue,
} from "@/interfaces/serverData";
import { CheckIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { useEffect, useState } from "react";

interface RelatedIssuesDropdownProps<TData> {
    insight: Insight;
    updateInsightState: (newState: Partial<Insight>) => void;
    issues: Query[];
    userID: string;
}

export function RelatedIssuesDropdown<TData>({
    insight,
    updateInsightState,
    issues,
    userID,
}: RelatedIssuesDropdownProps<TData>) {
    const api = useApi();
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredIssues, setFilteredIssues] = useState(issues);

    useEffect(() => {
        if (searchQuery.trim() === "") {
            setFilteredIssues(issues);
        } else {
            setFilteredIssues(
                issues.filter((issue) =>
                    `${issue.ticket_identifier}-${issue.ticket_number}: ${
                        issue.title ? issue.title : issue.query
                    }`
                        .toLowerCase()
                        .includes(searchQuery),
                ),
            );
        }
    }, [issues]);

    const handleSearchChange = (event: { target: { value: string } }) => {
        const searchVal = event.target.value.toLowerCase();
        setSearchQuery(searchVal);

        if (searchVal.trim() === "") {
            setFilteredIssues(issues);
        } else {
            setFilteredIssues(
                issues.filter((issue) =>
                    `${issue.ticket_identifier}-${issue.ticket_number}: ${
                        issue.title ? issue.title : issue.query
                    }`
                        .toLowerCase()
                        .includes(searchVal),
                ),
            );
        }
    };

    const toggleIssueSelection = (issue: Query) => {
        const newSelectedIssues = [...insight.related_issues];
        const index = newSelectedIssues.findIndex((ri) => ri.id === issue.id);
        let requestData: InsightData = {};
        if (index !== -1) {
            newSelectedIssues.splice(index, 1);
            requestData = {
                existing_insight_id: insight.id,
                related_issues_removed: [issue.id],
                user_id: userID,
            };
        } else {
            const issueRI: RelatedIssue = {
                id: issue.id,
                org_specific_id: `${issue.ticket_identifier}-${issue.ticket_number}`,
                title: issue.title,
                query: issue.query,
                created_at: issue.created_at,
                ticket_updated_at: issue.ticket_updated_at,
                tag: issue.bot_category,
                status: issue.ticket_status,
                source: issue.source,
                source_unique_name: issue.source_unique_name,
                user_info: issue.user_info,
                assignee_user_id: issue.assignee_user_id,
            };
            newSelectedIssues.push(issueRI);
            requestData = {
                existing_insight_id: insight.id,
                related_issues_added: [issue.id],
                user_id: userID,
            };
        }
        updateInsightState({
            related_issues: newSelectedIssues,
            count: newSelectedIssues.length,
        });

        api.post(URLS.serverUrl + API.saveInsight, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                console.log(
                    `Updated insight relatedIssues with issue ${issue.title} successfully`,
                );
            } else {
                console.log("Call to update insight relatedIssues failed");
            }
        });
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild className="hover:bg-muted m-0">
                <Button
                    variant="ghost"
                    className="flex flex-wrap justify-start p-0 data-[state=open]:bg-muted m-0"
                >
                    <div className="flex flex-row items-center gap-1.5">
                        <div className="font-semibold text-md">
                            Related Interactions
                        </div>
                        {insight.count !== undefined && insight.count > 0 && (
                            <Badge
                                variant="outline"
                                color="gray"
                                className="text-[9px] p-1 py-0"
                            >
                                {insight.count}
                            </Badge>
                        )}
                    </div>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                align="start"
                className="fixed w-[450px] max-h-60 overflow-hidden p-0 bg-muted rounded-md shadow-lg"
            >
                <div className="p-2">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="w-full p-1.5 border rounded-md text-xs"
                    />
                </div>
                <div className="max-h-52 overflow-y-auto">
                    <Command className="bg-muted">
                        <CommandList>
                            <CommandGroup>
                                {filteredIssues.length > 0 ? (
                                    filteredIssues.map((issue) => {
                                        const isSelected =
                                            insight.related_issues
                                                ?.map((ri) => ri.id)
                                                .includes(issue.id);
                                        return (
                                            <CommandItem
                                                key={issue.id}
                                                onSelect={() =>
                                                    toggleIssueSelection(issue)
                                                }
                                                className="py-1 hover:bg-secondary cursor-pointer text-xs"
                                            >
                                                <div
                                                    className={`mr-2 flex h-3 w-3 items-center justify-center rounded-sm border border-primary flex-shrink-0 ${
                                                        isSelected
                                                            ? "bg-primary text-primary-foreground"
                                                            : "opacity-50"
                                                    }`}
                                                >
                                                    {isSelected && (
                                                        <CheckIcon className="h-3 w-3" />
                                                    )}
                                                </div>
                                                {`${issue.ticket_identifier}-${issue.ticket_number}: ${
                                                    issue.title
                                                        ? issue.title
                                                        : issue.query
                                                }`}
                                            </CommandItem>
                                        );
                                    })
                                ) : (
                                    <div className="p-2 text-xs text-gray-500">
                                        No results found
                                    </div>
                                )}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </div>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
