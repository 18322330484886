import type {
    UploadedFile,
    UploadedFileWithMetadata,
} from "@/interfaces/serverData";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $isParagraphNode } from "lexical";
import { CLEAR_EDITOR_COMMAND } from "lexical";
import {
    ChevronDownIcon,
    EyeOffIcon,
    LoaderCircleIcon,
    SendHorizonalIcon,
} from "lucide-react";
import { useEffect } from "react";
import { Button } from "../shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../shadcn/ui/dropdown-menu";

export default function ActionsPlugin({
    setIsSendDisabled,
    isSendDisabled,
    loading,
    handleSubmit,
    uploadedFiles,
}: {
    setIsSendDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    isSendDisabled: boolean;
    loading: boolean;
    handleSubmit: (isInternal: boolean) => void;
    uploadedFiles: (UploadedFile | UploadedFileWithMetadata)[];
}): JSX.Element {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (uploadedFiles?.length > 0) {
            setIsSendDisabled(true);
        } else {
            return editor.registerUpdateListener(() => {
                editor.getEditorState().read(() => {
                    const root = $getRoot();
                    const children = root.getChildren();
                    if (children.length > 1) {
                        setIsSendDisabled(true);
                    } else {
                        if ($isParagraphNode(children[0])) {
                            const paragraphChildren = children[0].getChildren();
                            const isParagraphEmpty = paragraphChildren.every(
                                (child) => {
                                    return !child.getTextContent().trim();
                                },
                            );
                            setIsSendDisabled(!isParagraphEmpty);
                        } else {
                            setIsSendDisabled(false);
                        }
                    }
                });
            });
        }
    }, [editor, setIsSendDisabled, uploadedFiles]);

    const handleMessageSubmit = (isInternal: boolean) => {
        editor.getEditorState().read(() => {
            handleSubmit(isInternal);
            editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        });
    };

    return (
        <div className="overflow-hidden flex justify-end pr-1">
            <DropdownMenu>
                <div className="relative flex">
                    {/* Main Send Button */}
                    <Button
                        disabled={!isSendDisabled}
                        className="px-4 py-2 disabled:opacity-50 bg-[#5B5BD6] rounded-r-none flex items-center"
                        onClick={() => handleMessageSubmit(false)} // Main send action
                        type="submit"
                    >
                        {loading ? (
                            <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                        ) : (
                            <>
                                <SendHorizonalIcon className="h-4 w-4 mr-2" />
                                Send
                            </>
                        )}
                    </Button>

                    {/* Dropdown Trigger */}
                    <DropdownMenuTrigger asChild>
                        <Button
                            disabled={!isSendDisabled}
                            className="px-2 py-2 disabled:opacity-50 bg-[#5B5BD6] rounded-l-none flex items-center"
                            type="button"
                        >
                            <ChevronDownIcon className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>

                    <DropdownMenuContent align="end">
                        <DropdownMenuItem
                            onSelect={() => handleMessageSubmit(true)}
                        >
                            <EyeOffIcon className="mr-2 h-4 w-4" />
                            Send as Internal
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </div>
            </DropdownMenu>
        </div>
    );
}
