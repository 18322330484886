
import { Card } from "@/component/shadcn/ui/card";
import { useApi } from "@/interfaces/api";
import type React from "react";
import { useState } from "react";
import ReactMarkdown from "react-markdown";

interface InternalNotesProps {
    ticketId: string;
    internalNote?: string;
    source: string;
    userId: string;
}

const InternalNotes: React.FC<InternalNotesProps> = ({
    ticketId,
    internalNote,
    source,
    userId,
}) => {
    const [savedNotes, setSavedNotes] = useState(internalNote ?? "");
    const api = useApi();

    // 10/23: Phasing out internal notes so removed adding and editing capabilities
    return (
        <div className="flex flex-col gap-3">
            {savedNotes.length !== 0 && (
                <Card className="p-2 rounded">
                    <div className="prose max-w-none text-xs">
                        <ReactMarkdown>{savedNotes}</ReactMarkdown>
                    </div>
                </Card>
            )}
        </div>
    );
};

export default InternalNotes;
