"use client";

import { LexicalComposer } from "@lexical/react/LexicalComposer";

import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

import { ContentEditable } from "@lexical/react/LexicalContentEditable";

import { HeadingNode, QuoteNode } from "@lexical/rich-text";

import { ListItemNode, ListNode } from "@lexical/list";

import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";

import {
    LiveblocksPlugin,
    liveblocksConfig,
    useEditorStatus,
} from "@liveblocks/react-lexical";

import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ExampleTheme } from "../../component/textEditor/Theme";
import Toolbar from "../../component/textEditor/Toolbar";
import { EmojiNode } from "../../component/textEditor/nodes/EmojiNode";
import { Loading } from "./Loading";

import "./editor.css";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import { AIResponsePlugin } from "@/component/textEditor/AIResponsePlugin";
import FilesPlugin from "@/component/textEditor/FilePlugin";
import ImagesPlugin from "@/component/textEditor/ImagesPlugin";
import { FileNode } from "@/component/textEditor/nodes/FileNode";
import { ImageNode } from "@/component/textEditor/nodes/ImageNode";
import type {
    UploadedFile,
    UploadedFileLive,
    UploadedFileWithMetadata,
} from "@/interfaces/serverData";
import { LiveList } from "@liveblocks/client";
import { useStorage } from "@liveblocks/react";
import { Cross1Icon, ReaderIcon } from "@radix-ui/react-icons";
import { useState } from "react";

// Wrap your initial config with `liveblocksConfig`
const initialConfig = liveblocksConfig({
    namespace: "liveblocks",
    nodes: [
        HeadingNode,
        QuoteNode,
        ListNode,
        ListItemNode,
        EmojiNode,
        ImageNode,
        FileNode,
    ],
    onError: (error: unknown) => {
        console.error(error);
        throw error;
    },
    theme: ExampleTheme,
});

interface CollaborativeEditorProps {
    getAIResponse?: () => void;
    aiResponse: string;
    aiResponseCompleted: number;
    aiResponseButton: boolean;
    uploadedFiles: (UploadedFile | UploadedFileWithMetadata)[];
    setUploadedFiles: React.Dispatch<
        React.SetStateAction<(UploadedFile | UploadedFileWithMetadata)[]>
    >;
}

export default function CollaborativeEditor({
    getAIResponse = () => {},
    aiResponse,
    aiResponseCompleted,
    aiResponseButton,
    uploadedFiles,
    setUploadedFiles,
}: CollaborativeEditorProps) {
    const status = useEditorStatus();
    const [isToggled, setIsToggled] = useState(false);
    const storageUploadedFiles =
        (useStorage(
            (root) => root.uploadedFiles,
        ) as unknown as LiveList<UploadedFileLive>) ||
        new LiveList<UploadedFileLive>([]);

    const handleFileUpload = (
        file_name: string,
        file_type: string,
        file_size: number,
        file: File,
    ) => {
        setUploadedFiles((prevFiles) => [
            ...prevFiles,
            {
                file_name: file_name,
                file_type: file_type,
                file_size: file_size,
                type: "file",
                file,
            },
        ]);
        // const file = new LiveObject({ src: src, alt: altText });
        // updateFiles(file, "add", -1);
    };

    const handleImageUpload = (src: string, altText: string) => {
        setUploadedFiles((prevFiles) => [
            ...prevFiles,
            { src: src, alt: altText, type: "image" },
        ]);
        // const file = new LiveObject({ src: src, alt: altText });
        // updateFiles(file, "add", -1);
    };

    const handleDeleteFile = (
        fileToDelete: UploadedFile | UploadedFileWithMetadata,
    ) => {
        const newUploadedFiles = uploadedFiles.filter((file) => {
            if (file.type === "image") {
                return (
                    (file as UploadedFile).alt !==
                    (fileToDelete as UploadedFile).alt
                );
            } else if (file.type === "file") {
                return (
                    (file as UploadedFileWithMetadata).file_name !==
                    (fileToDelete as UploadedFileWithMetadata).file_name
                );
            }
            return true;
        });
        setUploadedFiles(newUploadedFiles);
    };

    return (
        <div className="relative min-h-screen flex flex-col">
            <LexicalComposer initialConfig={initialConfig}>
                <LiveblocksPlugin>
                    {status === "not-loaded" || status === "loading" ? (
                        <Loading />
                    ) : (
                        <div className="relative flex flex-row justify-between w-full gap-[50px]">
                            <div className="focus-within:shadow-md focus-within:outline-0.5 focus-within:outline-offset-0  relative text-sm flex flex-col flex-1 pb-4 pr-4 border rounded-lg shadow-sm  transition-all min-h-[500px]">
                                <Toolbar
                                    handleFileUpload={handleFileUpload}
                                    handleImageUpload={handleImageUpload}
                                    source={"None"}
                                />
                                <AIResponsePlugin
                                    className="right-2"
                                    isToggled={isToggled ?? false}
                                    setIsToggled={setIsToggled}
                                    aiResponseButton={aiResponseButton}
                                    ai_response={aiResponse}
                                    getAIResponse={getAIResponse}
                                    aiResponseCompleted={aiResponseCompleted}
                                />
                                <RichTextPlugin
                                    contentEditable={
                                        <ContentEditable className="min-h-[500px] pl-4 pt-4 focus:outline-purple-600" />
                                    }
                                    placeholder={<div />}
                                    ErrorBoundary={LexicalErrorBoundary}
                                />
                                <ListPlugin />
                                <ImagesPlugin
                                    handleFileUpload={handleImageUpload}
                                />
                                <FilesPlugin
                                    handleFileUpload={handleFileUpload}
                                />
                                <div className="flex items-center flex-wrap gap-1">
                                    {uploadedFiles.map(
                                        (file) =>
                                            file.type === "image" && (
                                                <div
                                                    key={
                                                        (file as UploadedFile)
                                                            .alt
                                                    }
                                                    className="relative"
                                                >
                                                    <div className="w-[100px] h-[100px] overflow-hidden relative m-1">
                                                        <img
                                                            src={
                                                                (
                                                                    file as UploadedFile
                                                                ).src
                                                            }
                                                            alt={
                                                                (
                                                                    file as UploadedFile
                                                                ).alt
                                                            }
                                                            style={{
                                                                maxWidth:
                                                                    "auto",
                                                                height: "100%",
                                                                objectFit:
                                                                    "cover",
                                                            }}
                                                        />
                                                    </div>
                                                    <Button
                                                        onClick={() =>
                                                            handleDeleteFile(
                                                                file as UploadedFile,
                                                            )
                                                        }
                                                        className="absolute px-1 h-4 top-0 right-0 bg-[#5B5BD6]"
                                                    >
                                                        <Cross1Icon className="w-2.5 h-2.5" />
                                                    </Button>
                                                </div>
                                            ),
                                    )}
                                    {uploadedFiles.map(
                                        (file) =>
                                            file.type === "file" && (
                                                <div
                                                    key={
                                                        (
                                                            file as UploadedFileWithMetadata
                                                        ).file_name
                                                    }
                                                    className="relative "
                                                >
                                                    <Card className="shadow-none border-none rounded-lg flex flex-row items-center gap-1 text-sm text-gray-600 text-center px-2 overflow-hidden relative m-1 flex items-center justify-center bg-gray-100">
                                                        <ReaderIcon className="w-3.5 h-3.5" />
                                                        <p>
                                                            {
                                                                (
                                                                    file as UploadedFileWithMetadata
                                                                ).file_name
                                                            }
                                                        </p>
                                                        <Button
                                                            onClick={() =>
                                                                handleDeleteFile(
                                                                    file as UploadedFileWithMetadata,
                                                                )
                                                            }
                                                            variant="ghost"
                                                            className="p-0.5"
                                                        >
                                                            <Cross1Icon className="w-2.5 h-2.5" />
                                                        </Button>
                                                    </Card>
                                                </div>
                                            ),
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </LiveblocksPlugin>
            </LexicalComposer>
        </div>
    );
}
