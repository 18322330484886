import * as Toast from "@radix-ui/react-toast";
import { Flex } from "@radix-ui/themes";
interface ToastProviderProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    toastText: string;
    ToastSymbol: React.ElementType;
}

const AssemblyToastProvider = ({ open, setOpen, toastText, ToastSymbol }: ToastProviderProps) => {
    return (
        <Toast.Provider swipeDirection="right">
            <Toast.Root
                className="ToastRoot"
                open={open}
                onOpenChange={setOpen}
            >
                <Toast.Title className="ToastTitle">
                    <Flex direction={"row"} align={"center"} gap="2">
                        <ToastSymbol color="green" />
                        {toastText}
                    </Flex>
                </Toast.Title>
            </Toast.Root>
            <Toast.Viewport className="ToastViewport" />
        </Toast.Provider>
    );
};

export default AssemblyToastProvider;
