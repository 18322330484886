import { APIWithMethod, TeamsAPI, URLS, pages } from "@/constant";
import {
    DrawingPinIcon,
    FrameIcon,
    MagicWandIcon,
    MagnifyingGlassIcon,
    RocketIcon,
    SpeakerLoudIcon,
    TriangleDownIcon,
    TriangleRightIcon,
} from "@radix-ui/react-icons";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../shadcn/ui/button";
import BallPopover from "./BallPopover";
import MenuDropdownComponent from "./MenuDropdownComponent";

import type { Teams, View } from "@/interfaces/serverData";
import { integrationMappingToSvg } from "@/pages/Admin/Integrations/constant";
import { SvgIcon } from "@mui/material";
import { useAuthInfo } from "@propelauth/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
    BuildingIcon,
    ChevronDown,
    ChevronRight,
    HouseIcon,
    MailboxIcon,
    UsersIcon,
} from "lucide-react";
import { TrashIcon } from "lucide-react";
import { LayersIcon, SquarePenIcon } from "lucide-react";
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "../shadcn/ui/collapsible";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
} from "../shadcn/ui/context-menu";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../shadcn/ui/dropdown-menu";
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarGroup,
    SidebarGroupContent,
    SidebarGroupLabel,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarMenuSub,
    SidebarMenuSubButton,
    SidebarMenuSubItem,
    useSidebar,
} from "../shadcn/ui/sidebar";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../shadcn/ui/tooltip";
import FilterComponent from "./FilterComponent";

export type FilterMetadata = {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    granularFilters: Map<any, boolean>;
    enabled: boolean;
};

export const NAVBAR_ITEMS = {
    integrations: "integrations",
    search: "search",
    queries: "issues",
    inbox: "inbox",
    views: "views",
    analytics: "analytics",
    insights: "insights",
    announcements: "announcements",
    savedView: "savedView",
    crm: "accounts",
};

interface SideBarProps {
    organizationName: string;
    filters?: Map<string, FilterMetadata>;
    activeButton: string;
    setActiveButton: (newTerm: string) => void;
    setActiveTeam?: (newTerm: string) => void;
    activeTeam?: string;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    handleToggle?: (type: string, value: any) => () => void;
    isAdmin: boolean;
    width: number;
}

export const drawerWidth = "250px";

export default function SideBarComponent(props: SideBarProps) {
    const [selectedItem, setSelectedItem] = useState<string>(
        props.activeTeam
            ? `${props.activeButton}_${props.activeTeam}`
            : props.activeButton,
    );

    const { pathname } = useLocation();

    const { state } = useSidebar();
    const isCollapsed = state === "collapsed";

    const [searchDropdownOpen, setSearchDropdownOpen] = useState(
        props.activeButton === NAVBAR_ITEMS.search,
    );

    const [collapsed, setCollapsed] = useState(props.width < 11);

    function setDropdowns(buttonName: string) {
        if (buttonName === NAVBAR_ITEMS.queries) {
            setSearchDropdownOpen(false);
        } else if (buttonName === NAVBAR_ITEMS.search) {
            setSearchDropdownOpen(!searchDropdownOpen);
        } else {
            setSearchDropdownOpen(false);
        }
    }

    const handleMenuClick = (
        buttonName: string,
        viewID?: string,
        teamID?: string,
    ) => {
        if (buttonName === NAVBAR_ITEMS.integrations) {
            adminIntegrationsPage();
        } else if (buttonName === NAVBAR_ITEMS.queries) {
            if (teamID) {
                navigate(`/teams/${teamID}/issues`);
            } else {
                navigate("/issues");
            }
        } else if (buttonName === NAVBAR_ITEMS.inbox) {
            adminInboxPage();
        } else if (buttonName === NAVBAR_ITEMS.analytics) {
            if (teamID) {
                navigate(`/teams/${teamID}/analytics`);
            } else {
                navigate("/analytics");
            }
        } else if (buttonName === NAVBAR_ITEMS.search) {
            searchPage();
        } else if (buttonName === NAVBAR_ITEMS.insights) {
            if (teamID) {
                navigate(`/teams/${teamID}/insights`);
            } else {
                navigate("/insights");
            }
        } else if (buttonName === NAVBAR_ITEMS.views) {
            navigate("/views");
        } else if (buttonName === NAVBAR_ITEMS.announcements) {
            navigate("/announcements");
        } else if (buttonName === NAVBAR_ITEMS.savedView) {
            if (viewID) {
                navigate(`/views/${viewID}`);
            } else {
                navigate("/views");
            }
        } else if (buttonName === NAVBAR_ITEMS.crm) {
            navigate("/accounts");
        }
        props.setActiveButton(buttonName);
        if (viewID && viewID !== "") {
            setSelectedItem(`savedView_${viewID}`);
        } else if (teamID && teamID !== "") {
            setSelectedItem(`${buttonName}_${teamID}`);
        } else {
            setSelectedItem(buttonName);
        }
        setDropdowns(buttonName);
    };

    const authInfo = useAuthInfo();

    const [orgId, setOrgId] = useState<string>("");

    const [expandedTeamId, setExpandedTeamId] = useState<string>(
        props.activeTeam ?? "",
    );

    useEffect(() => {
        if (props.activeTeam) {
            setExpandedTeamId(props.activeTeam);
        } else {
            setExpandedTeamId(orgId);
        }
    }, [orgId, props.activeTeam]);

    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listMemberTeams;
            const response = await fetch(
                `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                    },
                },
            );
            const d = await response.json();
            return d.data;
        },
    });

    useEffect(() => {
        const orgs = authInfo.orgHelper?.getOrgs();
        if (orgs === undefined || orgs.length !== 1) {
            return;
        }

        setOrgId(orgs[0].orgId);
    }, [authInfo.orgHelper]);

    useEffect(() => {
        setCollapsed(props.width < 120);
    }, [props.width]);

    useEffect(() => {
        const path = window.location.pathname;
        if (path.includes("/inbox")) {
            setSelectedItem(NAVBAR_ITEMS.inbox);
        }
    }, [window.location.pathname]);

    const authInfoRef = useRef(authInfo);
    const viewsQuery = useQuery<View[]>({
        queryKey: ["views"],
        queryFn: async () => {
            const [url, method] = APIWithMethod.listViews;
            const response = await fetch(`${URLS.serverUrl}${url}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
            });
            const d = await response.json();
            return d.data;
        },
    });

    const navigate = useNavigate();

    const adminIntegrationsPage = () => {
        navigate("/admin/manage_integrations");
    };

    const adminInboxPage = () => {
        navigate("/inbox");
    };

    const searchPage = () => {
        navigate("/search");
    };

    const toggleTeamExpanded = (teamId: string) => {
        setExpandedTeamId((prevId) => (prevId === teamId ? "" : teamId));
    };

    useEffect(() => {
        toggleTeamExpanded(props.activeTeam ?? "");
    }, [props.activeTeam]);

    const deleteViewsMutation = useMutation({
        mutationFn: async (id: string) => {
            const [url, method] = APIWithMethod.deleteView;
            const response = await fetch(`${URLS.serverUrl}${url}/${id}`, {
                method: method,
                headers: {
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            return result.data;
        },
        onSuccess: (data) => {
            viewsQuery.refetch();
            const currentViewId = selectedItem.split("_")[1];
            if (currentViewId === data) {
                setSelectedItem(NAVBAR_ITEMS.queries);
                navigate("/issues");
            }
        },
    });

    const [personalExpanded, setPersonalExpanded] = useState(true);
    const [knowledgeSearchExpanded, setKnowledgeSearchExpanded] =
        useState(true);
    const [teamspacesExpanded, setTeamspacesExpanded] = useState(true);

    useEffect(() => {
        if (props.activeButton === NAVBAR_ITEMS.search) {
            setKnowledgeSearchExpanded(true);
            setTeamspacesExpanded(false);
        } else {
            setKnowledgeSearchExpanded(false);
            setTeamspacesExpanded(true);
        }
    }, [props.activeButton]);

    const logoUrl =
        "https://bookface-images.s3.amazonaws.com/small_logos/e776eb370bd36ebbc8087ded38ebcb435bf3d4c4.png";
    // Helper function for dropdown sections

    return (
        <Sidebar collapsible="icon">
            <SidebarHeader>
                <div
                    className={`flex ${isCollapsed ? "flex-col" : "flex-row"
                        } items-center justify-between`}
                >
                    <MenuDropdownComponent
                        organizationName={props.organizationName}
                        state={pages.search}
                        collapsed={isCollapsed}
                    />
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button
                                variant="outline"
                                size="icon"
                                className="h-7 w-7 shadow-sm"
                            >
                                <SquarePenIcon className="h-3 w-3" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent side="bottom" className="w-40">
                            <DropdownMenuItem
                                onClick={() => {
                                    navigate("/views/create");
                                    setSelectedItem("");
                                }}
                            >
                                <LayersIcon className="mr-2 h-4 w-4" />
                                <span>Create View</span>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </SidebarHeader>

            {isCollapsed ? (
                <SidebarContent className="mt-4">
                    <SidebarMenu className="flex flex-col items-center justify-center gap-2">
                        <SidebarMenuItem className="flex items-center justify-center cursor-pointer">
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <SidebarMenuButton
                                            onClick={() =>
                                                handleMenuClick(
                                                    NAVBAR_ITEMS.inbox,
                                                )
                                            }
                                            isActive={
                                                selectedItem ===
                                                NAVBAR_ITEMS.inbox
                                            }
                                            asChild
                                        >
                                            <DrawingPinIcon className="h-4 w-4" />
                                        </SidebarMenuButton>
                                    </TooltipTrigger>
                                    <TooltipContent side="right">
                                        <p>My Inbox</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </SidebarMenuItem>
                        {viewsQuery.data
                            ?.filter(
                                (view) =>
                                    view.user_id !==
                                    "00000000-0000-0000-0000-000000000000" &&
                                    !view.is_internal_team,
                            )
                            .map((view) => (
                                <SidebarMenuItem
                                    key={view.id}
                                    className="flex items-center justify-center cursor-pointer"
                                >
                                    <ContextMenu>
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <ContextMenuTrigger>
                                                        <SidebarMenuButton
                                                            onClick={() =>
                                                                handleMenuClick(
                                                                    NAVBAR_ITEMS.savedView,
                                                                    view.id,
                                                                )
                                                            }
                                                            isActive={
                                                                selectedItem ===
                                                                `savedView_${view.id}`
                                                            }
                                                            asChild
                                                        >
                                                            <LayersIcon className="h-4 w-4" />
                                                        </SidebarMenuButton>
                                                    </ContextMenuTrigger>
                                                </TooltipTrigger>
                                                <TooltipContent side="right">
                                                    <p>{view.name}</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                        <ContextMenuContent className="w-60">
                                            <ContextMenuItem
                                                onClick={() =>
                                                    deleteViewsMutation.mutate(
                                                        view.id,
                                                    )
                                                }
                                            >
                                                <TrashIcon className="mr-2 h-4 w-4" />
                                                <span>Delete View</span>
                                            </ContextMenuItem>
                                        </ContextMenuContent>
                                    </ContextMenu>
                                </SidebarMenuItem>
                            ))}

                        {props.isAdmin && (
                            <>
                                <SidebarMenuItem className="flex items-center justify-center cursor-pointer">
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <SidebarMenuButton
                                                    onClick={() =>
                                                        handleMenuClick(
                                                            NAVBAR_ITEMS.integrations,
                                                        )
                                                    }
                                                    isActive={
                                                        selectedItem ===
                                                        NAVBAR_ITEMS.integrations
                                                    }
                                                    asChild
                                                >
                                                    <FrameIcon className="h-4 w-4" />
                                                </SidebarMenuButton>
                                            </TooltipTrigger>
                                            <TooltipContent side="right">
                                                <p>Manage Integrations</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </SidebarMenuItem>
                                <SidebarMenuItem className="flex items-center justify-center cursor-pointer">
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <SidebarMenuButton
                                                    onClick={() =>
                                                        handleMenuClick(
                                                            NAVBAR_ITEMS.queries,
                                                        )
                                                    }
                                                    isActive={
                                                        selectedItem ===
                                                        NAVBAR_ITEMS.queries
                                                    }
                                                    asChild
                                                >
                                                    <MailboxIcon className="h-4 w-4" />
                                                </SidebarMenuButton>
                                            </TooltipTrigger>
                                            <TooltipContent side="right">
                                                <p>Interactions</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </SidebarMenuItem>
                                <SidebarMenuItem className="flex items-center justify-center cursor-pointer">
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <SidebarMenuButton
                                                    onClick={() =>
                                                        handleMenuClick(
                                                            NAVBAR_ITEMS.analytics,
                                                        )
                                                    }
                                                    isActive={
                                                        selectedItem ===
                                                        NAVBAR_ITEMS.analytics
                                                    }
                                                    asChild
                                                >
                                                    <MagicWandIcon className="h-4 w-4" />
                                                </SidebarMenuButton>
                                            </TooltipTrigger>
                                            <TooltipContent side="right">
                                                <p>Analytics</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </SidebarMenuItem>
                                <SidebarMenuItem className="flex items-center justify-center cursor-pointer">
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <SidebarMenuButton
                                                    onClick={() =>
                                                        handleMenuClick(
                                                            NAVBAR_ITEMS.insights,
                                                        )
                                                    }
                                                    isActive={
                                                        selectedItem ===
                                                        NAVBAR_ITEMS.insights
                                                    }
                                                    asChild
                                                >
                                                    <RocketIcon className="h-4 w-4" />
                                                </SidebarMenuButton>
                                            </TooltipTrigger>
                                            <TooltipContent side="right">
                                                <p>Product Insights</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </SidebarMenuItem>
                                <SidebarMenuItem className="flex items-center justify-center cursor-pointer">
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <SidebarMenuButton
                                                    onClick={() =>
                                                        handleMenuClick(
                                                            NAVBAR_ITEMS.announcements,
                                                        )
                                                    }
                                                    isActive={
                                                        selectedItem ===
                                                        NAVBAR_ITEMS.announcements
                                                    }
                                                    asChild
                                                >
                                                    <SpeakerLoudIcon className="h-4 w-4" />
                                                </SidebarMenuButton>
                                            </TooltipTrigger>
                                            <TooltipContent side="right">
                                                <p>Announcements</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </SidebarMenuItem>
                                <SidebarMenuItem className="flex items-center justify-center cursor-pointer">
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <SidebarMenuButton
                                                    onClick={() =>
                                                        handleMenuClick(
                                                            NAVBAR_ITEMS.crm,
                                                        )
                                                    }
                                                    isActive={
                                                        selectedItem ===
                                                        NAVBAR_ITEMS.crm
                                                    }
                                                    asChild
                                                >
                                                    <BuildingIcon className="h-4 w-4" />
                                                </SidebarMenuButton>
                                            </TooltipTrigger>
                                            <TooltipContent side="right">
                                                <p>Accounts</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </SidebarMenuItem>
                                {props?.filters &&
                                    Array.from(props?.filters?.entries()).map(
                                        ([key, value]) => {
                                            const svgIcon =
                                                integrationMappingToSvg.get(
                                                    key,
                                                );
                                            if (!svgIcon) return null;

                                            return (
                                                <TooltipProvider key={key}>
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            <SidebarMenuItem
                                                                key={key}
                                                                className="flex items-center justify-center cursor-pointer"
                                                            >
                                                                <TooltipProvider>
                                                                    <Tooltip>
                                                                        <TooltipTrigger
                                                                            asChild
                                                                        >
                                                                            <SidebarMenuButton
                                                                                asChild
                                                                            >
                                                                                <SvgIcon
                                                                                    component={
                                                                                        svgIcon
                                                                                    }
                                                                                    inheritViewBox
                                                                                    className="h-5 w-5"
                                                                                />
                                                                            </SidebarMenuButton>
                                                                        </TooltipTrigger>
                                                                        <TooltipContent side="right">
                                                                            <p>
                                                                                {
                                                                                    key
                                                                                }
                                                                            </p>
                                                                        </TooltipContent>
                                                                    </Tooltip>
                                                                </TooltipProvider>
                                                            </SidebarMenuItem>
                                                        </TooltipTrigger>
                                                        <TooltipContent side="right">
                                                            <p>{key}</p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            );
                                        },
                                    )}
                            </>
                        )}
                    </SidebarMenu>
                </SidebarContent>
            ) : (
                <SidebarContent className="gap-0">
                    <Collapsible
                        defaultOpen
                        open={personalExpanded}
                        onOpenChange={setPersonalExpanded}
                    >
                        <SidebarGroup>
                            <SidebarGroupLabel>
                                <CollapsibleTrigger className="flex items-center justify-between w-full">
                                    Personal
                                    {personalExpanded ? (
                                        <ChevronDown className="ml-auto h-4 w-4 transition-transform group-data-[state=open]/collapsible:rotate-180" />
                                    ) : (
                                        <ChevronRight className="ml-auto h-4 w-4 transition-transform group-data-[state=open]/collapsible:rotate-180" />
                                    )}
                                </CollapsibleTrigger>
                            </SidebarGroupLabel>
                            <CollapsibleContent>
                                <SidebarGroupContent>
                                    <SidebarMenu>
                                        <SidebarMenuItem>
                                            <SidebarMenuButton
                                                asChild
                                                onClick={() =>
                                                    handleMenuClick(
                                                        NAVBAR_ITEMS.inbox,
                                                    )
                                                }
                                                isActive={
                                                    selectedItem ===
                                                    NAVBAR_ITEMS.inbox
                                                }
                                                className="cursor-pointer"
                                                size="sm"
                                            >
                                                <SidebarMenuSubButton size="sm">
                                                    <DrawingPinIcon className="h-4 w-4" />
                                                    <span>My Inbox</span>
                                                </SidebarMenuSubButton>
                                            </SidebarMenuButton>
                                        </SidebarMenuItem>

                                        {viewsQuery.data
                                            ?.filter(
                                                (view) =>
                                                    view.user_id !==
                                                    "00000000-0000-0000-0000-000000000000" &&
                                                    !view.is_internal_team,
                                            )
                                            .map((view) => (
                                                <SidebarMenuItem key={view.id}>
                                                    <ContextMenu>
                                                        <ContextMenuTrigger
                                                            asChild
                                                        >
                                                            <SidebarMenuButton
                                                                onClick={() =>
                                                                    handleMenuClick(
                                                                        NAVBAR_ITEMS.savedView,
                                                                        view.id,
                                                                    )
                                                                }
                                                                isActive={
                                                                    selectedItem ===
                                                                    `savedView_${view.id}`
                                                                }
                                                                asChild
                                                                size="sm"
                                                                className="cursor-pointer"
                                                            >
                                                                <SidebarMenuSubButton size="sm">
                                                                    <LayersIcon className="h-4 w-4" />
                                                                    <span>
                                                                        {
                                                                            view.name
                                                                        }
                                                                    </span>
                                                                </SidebarMenuSubButton>
                                                            </SidebarMenuButton>
                                                        </ContextMenuTrigger>
                                                        <ContextMenuContent className="w-60">
                                                            <ContextMenuItem
                                                                onClick={() =>
                                                                    deleteViewsMutation.mutate(
                                                                        view.id,
                                                                    )
                                                                }
                                                            >
                                                                <TrashIcon className="mr-2 h-4 w-4" />
                                                                <span>
                                                                    Delete View
                                                                </span>
                                                            </ContextMenuItem>
                                                        </ContextMenuContent>
                                                    </ContextMenu>
                                                </SidebarMenuItem>
                                            ))}
                                    </SidebarMenu>
                                </SidebarGroupContent>
                            </CollapsibleContent>
                        </SidebarGroup>
                    </Collapsible>

                    {/* Teams Section */}
                    <Collapsible
                        defaultOpen
                        open={teamspacesExpanded}
                        onOpenChange={setTeamspacesExpanded}
                    >
                        <SidebarGroup>
                            <SidebarGroupLabel>
                                <CollapsibleTrigger className="flex items-center justify-between w-full">
                                    Teamspaces
                                    {teamspacesExpanded ? (
                                        <ChevronDown className="ml-auto h-4 w-4" />
                                    ) : (
                                        <ChevronRight className="ml-auto h-4 w-4" />
                                    )}
                                </CollapsibleTrigger>
                            </SidebarGroupLabel>
                            <CollapsibleContent>
                                <SidebarGroupContent>
                                    <SidebarMenu>
                                        {teamsQuery.data?.map((team) => (
                                            <SidebarMenuItem
                                                key={team.id}
                                                className="px-2"
                                            >
                                                <Collapsible
                                                    open={
                                                        expandedTeamId ===
                                                        team.id
                                                    }
                                                >
                                                    <SidebarMenuButton
                                                        onClick={() =>
                                                            toggleTeamExpanded(
                                                                team.id,
                                                            )
                                                        }
                                                        className="w-full"
                                                        asChild
                                                        size="sm"
                                                    >
                                                        <SidebarMenuSubButton
                                                            size="sm"
                                                            className="cursor-pointer"
                                                        >
                                                            {orgId ===
                                                                team.id ? (
                                                                <div className="flex h-6 w-6 p-1 items-center justify-center rounded-lg bg-iris3 border border-iris4">
                                                                    <HouseIcon className="h-4 w-4 text-iris9" />
                                                                </div>
                                                            ) : (
                                                                <div className="flex p-1 h-6 w-6 items-center justify-center rounded-lg bg-red3 border border-red4">
                                                                    <UsersIcon className=" text-red9" />
                                                                </div>
                                                            )}
                                                            <span>
                                                                {team.team_name}
                                                            </span>
                                                            {expandedTeamId ===
                                                                team.id ? (
                                                                <TriangleDownIcon className="h-2 w-2" />
                                                            ) : (
                                                                <TriangleRightIcon className="h-2 w-2" />
                                                            )}
                                                        </SidebarMenuSubButton>
                                                    </SidebarMenuButton>

                                                    <CollapsibleContent>
                                                        <SidebarMenuSub className="mr-0 ml-[1.125rem] border-l-[#e2e8f0]">
                                                            {orgId ===
                                                                team.id ? (
                                                                <>
                                                                    {props.isAdmin && (
                                                                        <>
                                                                            <SidebarMenuSubItem>
                                                                                <SidebarMenuSubButton
                                                                                    onClick={() =>
                                                                                        handleMenuClick(
                                                                                            NAVBAR_ITEMS.integrations,
                                                                                        )
                                                                                    }
                                                                                    isActive={
                                                                                        selectedItem ===
                                                                                        NAVBAR_ITEMS.integrations
                                                                                    }
                                                                                    className="cursor-pointer"
                                                                                    size="sm"
                                                                                >
                                                                                    <FrameIcon className="h-4 w-4" />
                                                                                    <span>
                                                                                        Manage
                                                                                        Integrations
                                                                                    </span>
                                                                                </SidebarMenuSubButton>
                                                                            </SidebarMenuSubItem>
                                                                            <SidebarMenuSubItem>
                                                                                <SidebarMenuSubButton
                                                                                    onClick={() =>
                                                                                        handleMenuClick(
                                                                                            NAVBAR_ITEMS.queries,
                                                                                        )
                                                                                    }
                                                                                    isActive={
                                                                                        selectedItem ===
                                                                                        NAVBAR_ITEMS.queries
                                                                                    }
                                                                                    className="cursor-pointer"
                                                                                    size="sm"
                                                                                >
                                                                                    <MailboxIcon className="h-4 w-4" />
                                                                                    <span>
                                                                                        Interactions
                                                                                    </span>
                                                                                </SidebarMenuSubButton>
                                                                            </SidebarMenuSubItem>
                                                                            {viewsQuery.data
                                                                                ?.filter(
                                                                                    (
                                                                                        view,
                                                                                    ) =>
                                                                                        view.user_id ===
                                                                                        "00000000-0000-0000-0000-000000000000",
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        view,
                                                                                    ) => (
                                                                                        <SidebarMenuSubItem
                                                                                            key={
                                                                                                view.id
                                                                                            }
                                                                                        >
                                                                                            <ContextMenu>
                                                                                                <ContextMenuTrigger
                                                                                                    asChild
                                                                                                >
                                                                                                    <SidebarMenuSubButton
                                                                                                        onClick={() =>
                                                                                                            handleMenuClick(
                                                                                                                NAVBAR_ITEMS.savedView,
                                                                                                                view.id,
                                                                                                            )
                                                                                                        }
                                                                                                        isActive={
                                                                                                            selectedItem ===
                                                                                                            `savedView_${view.id}`
                                                                                                        }
                                                                                                        className="cursor-pointer"
                                                                                                        size="sm"
                                                                                                    >
                                                                                                        <LayersIcon className="h-4 w-4" />
                                                                                                        <span>
                                                                                                            {
                                                                                                                view.name
                                                                                                            }
                                                                                                        </span>
                                                                                                    </SidebarMenuSubButton>
                                                                                                </ContextMenuTrigger>
                                                                                                <ContextMenuContent className="w-60">
                                                                                                    <ContextMenuItem
                                                                                                        inset
                                                                                                        className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                                                                        onClick={() => {
                                                                                                            deleteViewsMutation.mutate(
                                                                                                                view.id,
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <TrashIcon className="w-3.5 h-3.5" />
                                                                                                        Delete
                                                                                                        View
                                                                                                    </ContextMenuItem>
                                                                                                </ContextMenuContent>
                                                                                            </ContextMenu>
                                                                                        </SidebarMenuSubItem>
                                                                                    ),
                                                                                )}
                                                                            <SidebarMenuSubItem>
                                                                                <SidebarMenuSubButton
                                                                                    onClick={() =>
                                                                                        handleMenuClick(
                                                                                            NAVBAR_ITEMS.analytics,
                                                                                        )
                                                                                    }
                                                                                    isActive={
                                                                                        selectedItem ===
                                                                                        NAVBAR_ITEMS.analytics
                                                                                    }
                                                                                    className="cursor-pointer"
                                                                                    size="sm"
                                                                                >
                                                                                    <MagicWandIcon className="h-4 w-4" />
                                                                                    <span>
                                                                                        Analytics
                                                                                    </span>
                                                                                </SidebarMenuSubButton>
                                                                            </SidebarMenuSubItem>
                                                                            <SidebarMenuSubItem>
                                                                                <SidebarMenuSubButton
                                                                                    onClick={() =>
                                                                                        handleMenuClick(
                                                                                            NAVBAR_ITEMS.insights,
                                                                                        )
                                                                                    }
                                                                                    isActive={
                                                                                        selectedItem ===
                                                                                        NAVBAR_ITEMS.insights
                                                                                    }
                                                                                    className="cursor-pointer"
                                                                                    size="sm"
                                                                                >
                                                                                    <RocketIcon className="h-4 w-4" />
                                                                                    <span>
                                                                                        Product
                                                                                        Insights
                                                                                    </span>
                                                                                </SidebarMenuSubButton>
                                                                            </SidebarMenuSubItem>
                                                                            <SidebarMenuSubItem>
                                                                                <SidebarMenuSubButton
                                                                                    onClick={() =>
                                                                                        handleMenuClick(
                                                                                            NAVBAR_ITEMS.announcements,
                                                                                        )
                                                                                    }
                                                                                    isActive={
                                                                                        selectedItem ===
                                                                                        NAVBAR_ITEMS.announcements
                                                                                    }
                                                                                    className="cursor-pointer"
                                                                                    size="sm"
                                                                                >
                                                                                    <SpeakerLoudIcon className="h-4 w-4" />
                                                                                    <span>
                                                                                        Announcements
                                                                                    </span>
                                                                                </SidebarMenuSubButton>
                                                                            </SidebarMenuSubItem>
                                                                            <SidebarMenuSubItem>
                                                                                <SidebarMenuSubButton
                                                                                    onClick={() =>
                                                                                        handleMenuClick(
                                                                                            NAVBAR_ITEMS.crm,
                                                                                        )
                                                                                    }
                                                                                    isActive={
                                                                                        selectedItem ===
                                                                                        NAVBAR_ITEMS.crm
                                                                                    }
                                                                                    className="cursor-pointer"
                                                                                    size="sm"
                                                                                >
                                                                                    <BuildingIcon className="h-4 w-4" />
                                                                                    <span>
                                                                                        Accounts
                                                                                    </span>
                                                                                </SidebarMenuSubButton>
                                                                            </SidebarMenuSubItem>
                                                                        </>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <SidebarMenuSubItem className="cursor-pointer">
                                                                        <SidebarMenuSubButton
                                                                            onClick={() =>
                                                                                handleMenuClick(
                                                                                    NAVBAR_ITEMS.queries,
                                                                                    "",
                                                                                    team.id,
                                                                                )
                                                                            }
                                                                            isActive={
                                                                                pathname ===
                                                                                `/teams/${team.id}/issues`
                                                                            }
                                                                            className="cursor-pointer"
                                                                            size="sm"
                                                                        >
                                                                            <MailboxIcon className="h-4 w-4" />
                                                                            <span>
                                                                                Interactions
                                                                            </span>
                                                                        </SidebarMenuSubButton>
                                                                    </SidebarMenuSubItem>
                                                                    <SidebarMenuSubItem>
                                                                        <SidebarMenuSubButton
                                                                            onClick={() =>
                                                                                handleMenuClick(
                                                                                    NAVBAR_ITEMS.analytics,
                                                                                    "",
                                                                                    team.id,
                                                                                )
                                                                            }
                                                                            isActive={
                                                                                pathname ===
                                                                                `/teams/${team.id}/analytics`
                                                                            }
                                                                            className="cursor-pointer"
                                                                            size="sm"
                                                                        >
                                                                            <MagicWandIcon className="h-4 w-4" />
                                                                            <span>
                                                                                Analytics
                                                                            </span>
                                                                        </SidebarMenuSubButton>
                                                                    </SidebarMenuSubItem>
                                                                    <SidebarMenuSubItem>
                                                                        <SidebarMenuSubButton
                                                                            onClick={() =>
                                                                                handleMenuClick(
                                                                                    NAVBAR_ITEMS.insights,
                                                                                    "",
                                                                                    team.id,
                                                                                )
                                                                            }
                                                                            isActive={
                                                                                pathname ===
                                                                                `/teams/${team.id}/insights`
                                                                            }
                                                                            className="cursor-pointer"
                                                                            size="sm"
                                                                        >
                                                                            <RocketIcon className="h-4 w-4" />
                                                                            <span>
                                                                                Product
                                                                                Insights
                                                                            </span>
                                                                        </SidebarMenuSubButton>
                                                                    </SidebarMenuSubItem>
                                                                </>
                                                            )}
                                                        </SidebarMenuSub>
                                                    </CollapsibleContent>
                                                </Collapsible>
                                            </SidebarMenuItem>
                                        ))}
                                    </SidebarMenu>
                                </SidebarGroupContent>
                            </CollapsibleContent>
                        </SidebarGroup>
                    </Collapsible>

                    {/* Knowledge Search Section */}
                    <SidebarGroup>
                        <Collapsible
                            defaultOpen
                            open={knowledgeSearchExpanded}
                            onOpenChange={setKnowledgeSearchExpanded}
                        >
                            <SidebarGroupLabel
                                onClick={() =>
                                    handleMenuClick(NAVBAR_ITEMS.search)
                                }
                            >
                                <CollapsibleTrigger className="flex items-center justify-between w-full">
                                    <div className="flex items-center gap-1 w-full">
                                        <MagnifyingGlassIcon className="h-4 w-4" />
                                        <span>Knowledge Search</span>
                                    </div>
                                    {knowledgeSearchExpanded ? (
                                        <ChevronDown className="ml-auto h-4 w-4" />
                                    ) : (
                                        <ChevronRight className="ml-auto h-4 w-4" />
                                    )}
                                </CollapsibleTrigger>
                            </SidebarGroupLabel>
                            {props.filters && (
                                <SidebarGroupContent>
                                    <CollapsibleContent>
                                        <SidebarMenuSub className="ml-4 mr-0 border-l-[#e2e8f0]">
                                            {Array.from(
                                                props.filters.entries(),
                                            ).map(([key, value]) => {
                                                const svgIcon =
                                                    integrationMappingToSvg.get(
                                                        key,
                                                    );
                                                if (!svgIcon) return null;

                                                return (
                                                    <FilterComponent
                                                        key={key}
                                                        items={
                                                            value.granularFilters
                                                        }
                                                        name={key}
                                                        handleToggle={
                                                            // biome-ignore lint/style/noNonNullAssertion: <explanation>
                                                            props.handleToggle!
                                                        }
                                                        selected={value.enabled}
                                                        svg={
                                                            <SvgIcon
                                                                component={
                                                                    svgIcon
                                                                }
                                                                inheritViewBox
                                                                style={{
                                                                    height: "1rem",
                                                                    width: "1rem",
                                                                }}
                                                            />
                                                        }
                                                    />
                                                );
                                            })}
                                        </SidebarMenuSub>
                                    </CollapsibleContent>
                                </SidebarGroupContent>
                            )}
                        </Collapsible>
                    </SidebarGroup>
                </SidebarContent>
            )}

            <SidebarFooter className={isCollapsed ? "p-0" : "p-2"}>
                <BallPopover />
            </SidebarFooter>
        </Sidebar>
    );
}
