import type { FilterMetadata } from "@/component/SideBar/SideBarComponent";
import {
    CrossCircledIcon,
    InfoCircledIcon,
    MixerHorizontalIcon,
} from "@radix-ui/react-icons";
import {
    Badge,
    Box,
    Button,
    Card,
    DataList,
    Dialog,
    Flex,
    Heading,
    Separator,
    Strong,
    Switch,
    Text,
    TextField,
    Tooltip,
} from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogComponentHandler from "../../../component/DialogComponent";
import { URLS } from "../../../constant";
import { API } from "../../../constant";
import { useApi } from "../../../interfaces/api";

import { FancyMultiSelect } from "@/component/MultiSelect";
import type { BotMetadata, ScopeResponse } from "@/interfaces/serverData";
import { useSearch } from "@/pages/SearchContext";
import "../toast_styles.css";

interface SlackConfigProps {
    autoEscalate: boolean;
    setAutoEscalate: React.Dispatch<React.SetStateAction<boolean>>;
    previewMessage: boolean;
    setPreviewMessage: React.Dispatch<React.SetStateAction<boolean>>;
    autoRespond: boolean;
    setAutoRespond: React.Dispatch<React.SetStateAction<boolean>>;
    confidenceScore: boolean;
    setConfidenceScore: React.Dispatch<React.SetStateAction<boolean>>;
    indexCode: boolean;
    setIndexCode: React.Dispatch<React.SetStateAction<boolean>>;
    iconUrl: string;
    setIconUrl: React.Dispatch<React.SetStateAction<string>>;
    channels: ScopeResponse[];
    selectedChannels: ScopeResponse[];
    setSelectedChannels: React.Dispatch<React.SetStateAction<ScopeResponse[]>>;
    errDialogOpen: boolean;
    setErrDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setToastText: React.Dispatch<React.SetStateAction<string>>;
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType>>;
    isMain: boolean;
}

const SlackConfigPage = (props: SlackConfigProps) => {
    const api = useApi();

    const timerRef = useRef(0);

    const { searchBarFilters, loading } = useSearch();

    const closeDialog = () => props.setErrDialogOpen(false);

    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        return () => clearTimeout(timerRef.current);
    }, []);

    const [dataSources, setDataSources] = useState<string[]>([]);

    useEffect(() => {
        const srcs: string[] = [];
        if (loading) {
            searchBarFilters.forEach((value: FilterMetadata, key: string) => {
                srcs.push(key);
            });
            setDataSources(srcs);
        }
    }, [searchBarFilters, loading]);

    const handleIconUrlChange = (event: { target: { value: string } }) => {
        props.setIconUrl(event.target.value);
    };

    function saveSettings() {
        const requestData: BotMetadata = {
            bot_type: props.isMain ? "Slack" : "CommunitySlack",
            auto_escalate: props.autoEscalate,
            preview_message: props.previewMessage,
            auto_respond: props.autoRespond,
            channels_selected: props.selectedChannels,
            confidence_enabled: props.confidenceScore,
            icon_url: props.iconUrl,
            responding_enabled: true, // will always be true if system is enabled
            index_code: props.indexCode,
        };
        setModalOpen(false);
        api.post(URLS.serverUrl + API.saveBotSettings, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    props.setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    props.setToastSymbol(CrossCircledIcon);
                } else {
                    props.setToastText("Updated Settings!");
                }
            })
            .catch((res) => {
                props.setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                props.setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                props.setOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    props.setOpen(true);
                }, 100);
            });
    }

    const navigate = useNavigate();
    const closeDialogAndRedirect = async () => {
        navigate("/admin/manage_integrations");
    };

    return (
        <div>
            <DialogComponentHandler
                isDialogOpen={props.errDialogOpen}
                closeDialog={closeDialog}
                isButton={true}
                description={
                    "Seems like you need to first set up a bot to access this page! If you already have one, please contact us at support@askassembly.app."
                }
                title={"Oops!"}
                buttonText="Let's go!"
                onButtonClick={closeDialogAndRedirect}
                clickOutOf={false}
            />

            <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
                <Dialog.Trigger>
                    <Button
                        style={{ width: "100%" }}
                        variant={"outline"}
                        size="1"
                    >
                        <MixerHorizontalIcon width="15" height="15" />
                        Edit
                    </Button>
                </Dialog.Trigger>
                <Dialog.Content
                    style={{ width: "fit-content", minWidth: "750px" }}
                >
                    <Flex direction="column" gap="4">
                        <Flex direction="column">
                            <Heading size="3">Slackbot Configurations</Heading>
                            <Text mb="2" size="2">
                                To use the Slackbot, just tag{" "}
                                <Strong style={{ color: "#6E56CF" }}>
                                    @Assembly
                                </Strong>{" "}
                                in any channel!
                            </Text>
                        </Flex>

                        <Card style={{ flex: 1, padding: "20px" }}>
                            <Flex direction="column">
                                <DataList.Root
                                    size={"2"}
                                    style={{ maxWidth: "600px" }}
                                >
                                    <DataList.Item align="center">
                                        <DataList.Label minWidth="300px">
                                            <Text weight={"bold"}>
                                                Component
                                            </Text>
                                        </DataList.Label>
                                        <DataList.Value>
                                            <Badge
                                                color="jade"
                                                variant="soft"
                                                radius="full"
                                            >
                                                Slackbot
                                            </Badge>
                                        </DataList.Value>
                                    </DataList.Item>
                                    <DataList.Item>
                                        <Box gridColumn="span 2">
                                            <Separator
                                                size="4"
                                                style={{
                                                    width: "100%",
                                                }}
                                                orientation={"horizontal"}
                                            />
                                        </Box>
                                    </DataList.Item>

                                    <DataList.Item>
                                        <DataList.Label minWidth="200px">
                                            <Flex
                                                direction="column"
                                                gap="1"
                                                align={"start"}
                                            >
                                                <Text weight={"bold"}>
                                                    Auto Escalate
                                                </Text>
                                                <Text
                                                    weight={"light"}
                                                    size="1"
                                                    color="gray"
                                                >
                                                    Loop in a human if Assembly
                                                    has to escalate an incoming
                                                    question.
                                                </Text>
                                                {/* <Tooltip content="Loop in a human if Assembly has to escalate an issue.">
                                                        <InfoCircledIcon />
                                                    </Tooltip> */}
                                            </Flex>
                                        </DataList.Label>
                                        <DataList.Value>
                                            <Switch
                                                onCheckedChange={
                                                    props.setAutoEscalate
                                                }
                                                defaultChecked={
                                                    props.autoEscalate
                                                }
                                            />
                                        </DataList.Value>
                                    </DataList.Item>
                                    <DataList.Item>
                                        <DataList.Label minWidth="400px">
                                            <Flex
                                                direction="column"
                                                gap="1"
                                                align={"start"}
                                            >
                                                <Text weight={"bold"}>
                                                    Send as Preview Message
                                                </Text>
                                                <Text
                                                    weight={"light"}
                                                    size="1"
                                                    color="gray"
                                                    style={{
                                                        maxWidth: "500px",
                                                    }}
                                                >
                                                    Enable this if you'd like to
                                                    send this as a preview
                                                    message without sending it
                                                    to users just yet. Only the
                                                    admin of the channel will be
                                                    able to view the message if
                                                    enabled.
                                                </Text>
                                                {/* <Tooltip content="Loop in a human if Assembly has to escalate an issue.">
                                                        <InfoCircledIcon />
                                                    </Tooltip> */}
                                            </Flex>
                                        </DataList.Label>
                                        <DataList.Value>
                                            <Switch
                                                onCheckedChange={
                                                    props.setPreviewMessage
                                                }
                                                defaultChecked={
                                                    props.previewMessage
                                                }
                                            />
                                        </DataList.Value>
                                    </DataList.Item>

                                    <DataList.Item>
                                        <DataList.Label minWidth="400px">
                                            <Flex
                                                direction="column"
                                                gap="1"
                                                align={"start"}
                                            >
                                                <Text weight={"bold"}>
                                                    Auto Respond
                                                </Text>
                                                <Text
                                                    weight={"light"}
                                                    size="1"
                                                    color="gray"
                                                    wrap="balance"
                                                >
                                                    Have Assembly respond to
                                                    every question in a selected
                                                    channel without having to
                                                    tag the bot!
                                                </Text>
                                                {/* <Tooltip content="Loop in a human if Assembly has to escalate an issue.">
                                                        <InfoCircledIcon />
                                                    </Tooltip> */}
                                            </Flex>
                                        </DataList.Label>
                                        <DataList.Value>
                                            <Switch
                                                onCheckedChange={
                                                    props.setAutoRespond
                                                }
                                                defaultChecked={
                                                    props.autoRespond
                                                }
                                            />
                                        </DataList.Value>
                                    </DataList.Item>

                                    {props.autoRespond === true && (
                                        <DataList.Item>
                                            <DataList.Label minWidth="400px">
                                                <Flex
                                                    direction={"column"}
                                                    gap="2"
                                                    align={"start"}
                                                >
                                                    <Flex
                                                        direction={"row"}
                                                        gap="1"
                                                        align={"center"}
                                                    >
                                                        Which channel should we
                                                        auto-respond in?
                                                        <Tooltip content="This list is sourced from the Customer Channels Selected section. Add it there first!">
                                                            <InfoCircledIcon />
                                                        </Tooltip>
                                                    </Flex>
                                                    {/* <Select.Root
                                                        defaultValue={JSON.stringify(
                                                            props.selectedChannels,
                                                        )}
                                                        onValueChange={
                                                            updateSelectedChannel
                                                        }
                                                    >
                                                        <Select.Trigger />
                                                        <Select.Content>
                                                            <Select.Group>
                                                                {props.channels.map(
                                                                    (item) => (
                                                                        <Select.Item
                                                                            key={
                                                                                item.key
                                                                            }
                                                                            value={JSON.stringify(
                                                                                item,
                                                                            )}
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </Select.Item>
                                                                    ),
                                                                )}
                                                            </Select.Group>
                                                        </Select.Content>
                                                    </Select.Root> */}
                                                    <FancyMultiSelect
                                                        selectedChannels={
                                                            props.selectedChannels
                                                        }
                                                        setSelectedChannels={
                                                            props.setSelectedChannels
                                                        }
                                                        options={props.channels}
                                                        placeholder="Select Channels..."
                                                    />
                                                </Flex>
                                            </DataList.Label>
                                        </DataList.Item>
                                    )}

                                    <DataList.Item>
                                        <DataList.Label minWidth="400px">
                                            <Flex
                                                direction="column"
                                                gap="1"
                                                align={"start"}
                                            >
                                                <Text weight={"bold"}>
                                                    Show Confidence Score
                                                </Text>
                                                <Text
                                                    weight={"light"}
                                                    size="1"
                                                    color="gray"
                                                    wrap="balance"
                                                >
                                                    Assembly will send how
                                                    confident it is as a hidden
                                                    message when responding to
                                                    questions.
                                                </Text>
                                            </Flex>
                                        </DataList.Label>
                                        <DataList.Value>
                                            <Switch
                                                onCheckedChange={
                                                    props.setConfidenceScore
                                                }
                                                defaultChecked={
                                                    props.confidenceScore
                                                }
                                            />
                                        </DataList.Value>
                                    </DataList.Item>
                                    <DataList.Item>
                                        <DataList.Label minWidth="400px">
                                            <Flex
                                                direction="column"
                                                gap="1"
                                                align={"start"}
                                            >
                                                <Text weight={"bold"}>
                                                    Slack Bot Icon
                                                </Text>
                                                <Text
                                                    weight={"light"}
                                                    size="1"
                                                    color="gray"
                                                    wrap="balance"
                                                    style={{
                                                        maxWidth: "500px",
                                                    }}
                                                >
                                                    Input the public url of an
                                                    image to change the icon of
                                                    Assembly. If left empty, the
                                                    default Assembly logo will
                                                    be used.
                                                </Text>
                                                <Box minWidth="450px">
                                                    <TextField.Root
                                                        size="1"
                                                        variant="soft"
                                                        radius="small"
                                                        value={props.iconUrl}
                                                        onChange={
                                                            handleIconUrlChange
                                                        }
                                                    />
                                                </Box>
                                            </Flex>
                                        </DataList.Label>
                                    </DataList.Item>
                                    <DataList.Item>
                                        <DataList.Label minWidth="400px">
                                            <Flex
                                                direction={"column"}
                                                gap={"4"}
                                            >
                                                <Text weight={"bold"}>
                                                    Sources Connected
                                                </Text>
                                                {loading && (
                                                    <Flex
                                                        direction="row"
                                                        gap="3"
                                                        wrap="wrap"
                                                    >
                                                        {dataSources.map(
                                                            (item) => (
                                                                <Badge
                                                                    key={item}
                                                                >
                                                                    {item}
                                                                </Badge>
                                                            ),
                                                        )}
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </DataList.Label>
                                    </DataList.Item>
                                </DataList.Root>

                                <Button
                                    onClick={saveSettings}
                                    style={{
                                        width: "auto",
                                        alignSelf: "end",
                                        marginTop: "60px",
                                    }}
                                >
                                    Save Settings
                                </Button>
                            </Flex>
                        </Card>
                    </Flex>
                </Dialog.Content>
            </Dialog.Root>
        </div>
    );
};

export default SlackConfigPage;
