import { Badge } from "@/component/shadcn/ui/badge";
import type { Teams } from "@/interfaces/serverData";
import { HotelIcon, UsersIcon } from "lucide-react";

interface TeamBadgesProps {
    teams: Teams[];
}

export const TeamBadges: React.FC<TeamBadgesProps> = ({ teams }) => {
    return (
        <>
            {teams.length > 0 ? (
                teams.map((team) => (
                    <Badge variant="outline" className="gap-1 py-1 px-1.5 text-xs font-normal" key={team.id}>
                        {team.team_name === "Workspace" ? (
                            <div className="flex items-center justify-center rounded-lg p-1 bg-blue3 border border-blue4 shadow-sm">
                                <HotelIcon
                                    className="text-blue9"
                                    strokeWidth={1.5}
                                    size={12}
                                />
                            </div>
                        ) : (
                            <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                <UsersIcon
                                    className="text-red9"
                                    strokeWidth={1.5}
                                    size={12}
                                />
                            </div>
                        )}
                        {team.team_name}
                    </Badge>
                ))
            ) : (
                <Badge variant="outline" className="gap-1 py-1 px-1.5 text-xs font-normal">
                    <div className="flex items-center justify-center rounded-lg p-1 bg-blue3 border border-blue4 shadow-sm">
                        <HotelIcon
                            className="text-blue9"
                            strokeWidth={1.5}
                            size={12}
                        />
                    </div>
                    Workspace
                </Badge>
            )}
        </>
    );
};