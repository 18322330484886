import { useApi } from '@/interfaces/api';
import type React from 'react';
import { useEffect, useState } from 'react';

interface ExcelPreviewProps {
    excel_rows: ExcelRow[]
}

interface ExcelRow {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    [key: string]: any; // Dynamic key-value pair for the row data
}

export const ExcelPreview: React.FC<ExcelPreviewProps> = ({ excel_rows }) => {
    const api = useApi()
    const [data, setData] = useState<ExcelRow[]>([]); // State to hold the parsed Excel data
    useEffect(() => {
        setData(excel_rows)
    }, [excel_rows])

    // Render the table
    return (
        <div className="overflow-auto max-h-[650px] max-w-full border border-gray-300 rounded">
            {data.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-100">
                        <tr>
                            {Object.keys(data[0]).map((key) => (
                                <th key={key} className="px-4 py-2 text-left text-sm font-medium text-gray-600">{key}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {data.map((row, rowIndex) => (
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            <tr key={rowIndex}>
                                {Object.values(row).map((value, colIndex) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <td key={colIndex} className="px-4 py-2 text-sm text-gray-700">{value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center text-gray-500">No data available</p>
            )}
        </div>
    );
};

export default ExcelPreview;
