import TicketDisplay from "@/Ticket/TicketDisplay";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    HistoryResponse,
    Ticket,
    TicketStatus,
} from "@/interfaces/serverData";
import { Box, Callout, Flex, Skeleton, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import type { IssueListType } from "./AdminQueriesPage";

import type { NextTicket } from "@/interfaces/serverData";
import { useLocation, useNavigate } from "react-router-dom";

interface AdminTicketPageProps {
    identifier: string;
    userID: string;
    teamID?: string;
    listType: IssueListType;
}

const AdminTicketPage: React.FC<AdminTicketPageProps> = ({
    identifier,
    userID,
    teamID,
    listType,
}) => {
    const api = useApi();

    const {
        data: ticketData,
        isLoading: ticketIsLoading,
        isSuccess: ticketIsSuccess,
        error: ticketError,
        refetch: refetchTicketData,
    } = useQuery({
        queryKey: ["ticket", identifier, userID],
        queryFn: () => fetchTicket(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });
    const {
        data: threadData = [],
        isLoading: threadIsLoading,
        isSuccess: threadIsSuccess,
        error: threadError,
        refetch: refetchThreadData,
    } = useQuery({
        queryKey: ["ticket_thread", identifier],
        queryFn: () => fetchTicketThread(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });

    const [generateAIResponse, setGenerateAIResponse] =
        useState<boolean>(false);
    const [aiResponse, setAIResponse] = useState<string>("");

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const fetchTicket = async (): Promise<Ticket | null> => {
        let endpoint = `${URLS.serverUrl}${API.getTicketInfo}/${identifier}`;
        if (teamID) {
            endpoint = `${URLS.serverUrl}${API.getTicketInfo}/team/${teamID}/issue/${identifier}`;
        }
        const response = await api.get(endpoint, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            const ticketData: Ticket = response.data.data;
            if (
                ticketData.ai_response === undefined ||
                ticketData.ai_response === ""
            ) {
                setGenerateAIResponse(true);
            } else {
                setAIResponse(ticketData.ai_response);
            }
            return ticketData;
        } else {
            throw new Error("Failed to fetch ticket");
        }
    };

    const fetchTicketThread = async (): Promise<HistoryResponse[]> => {
        const response = await api.post(
            URLS.serverUrl + API.getHistoryRecords,
            {
                identifier: identifier,
                parent_id: "",
                latest_timestamp: "",
            },
        );
        if (response.status === 200) {
            return response.data.data;
        } else {
            throw new Error("Failed to fetch thread");
        }
    };

    type searchResponse = {
        id: string;
        org_id: string;
        kind: string;
    };

    type describeResponse = {
        summary: string;
        confidence: string;
    };

    useEffect(() => {
        // if this is true, generate ai response in background
        if (ticketData === undefined) {
            return;
        }
        if (
            ticketData?.ai_response !== undefined &&
            ticketData.ai_response !== ""
        ) {
            setAIResponse(ticketData.ai_response);
            return;
        }

        if (generateAIResponse && ticketData?.query) {
            const content = ticketData.query;
            const reqData = {
                query: content,
            };

            api.post(URLS.serverUrl + API.search, reqData, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }).then(async (res) => {
                if (res.status === 200) {
                    const searchResp: searchResponse[] = res.data.data;

                    const objects = [];

                    if (searchResp.length === 0) {
                        return {
                            content: [
                                {
                                    type: "text",
                                    text: "Sorry, don't know how to answer your question. Try asking something else or contacting the support team!",
                                },
                            ],
                        };
                    }

                    for (const searchElem of searchResp) {
                        const elem = {
                            id: searchElem.id,
                            source: searchElem.kind,
                        };
                        objects.push(elem);
                    }
                    const describeReq = {
                        query: content,
                        objects: objects,
                    };

                    api.post(URLS.serverUrl + API.describe, describeReq, {
                        headers: {
                            "Content-Type": "application/json",
                            "Analytics-Id": ticketData.id,
                        },
                    }).then(async (describeData) => {
                        if (describeData.status === 200) {
                            const describeResp: describeResponse =
                                describeData.data.data;
                            setAIResponse(describeResp.summary);
                        }
                    });
                }
            });
        }
    }, [generateAIResponse, ticketData, api]);

    const navigate = useNavigate();

    const location = useLocation();
    function moveToNextTicket() {
        if (!location.state || location.state.key !== "issue") {
            console.log("removed local storage");
            localStorage.removeItem("viewedTickets");
        }

        const requestData = {
            ids: JSON.parse(localStorage.getItem("viewedTickets") || "[]"),
            team_id: teamID,
        };

        api.post(`${URLS.serverUrl}${API.getMostRecentTicket}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const nextTicket: NextTicket = res.data.data;

                    const nextTicketURL = `${nextTicket.ticket_identifier}-${nextTicket.ticket_number}`;
                    if (teamID) {
                        navigate(`/teams/${teamID}/issue/${nextTicketURL}`, {
                            state: { key: "issue" },
                        });
                    } else {
                        navigate(`/issue/${nextTicketURL}`, {
                            state: { key: "issue" },
                        });
                    }
                }
            })
            .catch((res) => {});
    }

    function saveStatus(status: string) {
        if (!ticketData?.id) {
            return;
        }
        const requestData: TicketStatus = {
            ids: [ticketData?.id],
            status: status,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                refetchThreadData();
                console.log(`Updated status to ${status} successfully`);
                ticketData.ticket_status = status;
                refetchTicketData();
            } else {
                console.log("Call to update status failed");
            }
        });
    }

    useEffect(() => {
        if (ticketData?.id) {
            markTicketAsViewed(ticketData.id);
        }
    }, [ticketData?.id]);

    // Function to mark a ticket as viewed
    function markTicketAsViewed(ticketId: string) {
        const viewedTickets = JSON.parse(
            localStorage.getItem("viewedTickets") || "[]",
        );
        if (!viewedTickets.includes(ticketId)) {
            viewedTickets.push(ticketId);
            localStorage.setItem(
                "viewedTickets",
                JSON.stringify(viewedTickets),
            );
        }
    }

    function removeTicketsViewed(ticketId: string) {
        const viewedTickets = JSON.parse(
            localStorage.getItem("viewedTickets") || "[]",
        );
        const updatedTickets = viewedTickets.filter(
            (id: string) => id !== ticketId,
        );
        localStorage.setItem("viewedTickets", JSON.stringify(updatedTickets));
    }

    function moveToPreviousTicket() {
        if (ticketData?.id) {
            removeTicketsViewed(ticketData.id);
        }
        if (window.history.length > 1) {
            navigate(-1);
        }
    }

    return (
        <div className="overflow-hidden">
            <Flex direction="column" align="center" justify="center">
                <Box
                    height="100%" // Ensure it takes full height of its container
                    width="100%"
                    pl="100"
                >
                    <div className="h-full flex-1 flex-col space-y-8 md:flex ">
                        {ticketIsLoading && (
                            <div>
                                <Skeleton>
                                    <Text>
                                        {[...Array(6)].map((_, index) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                            <Text key={index}>
                                                {loremIpsum}
                                            </Text>
                                        ))}
                                    </Text>
                                </Skeleton>
                            </div>
                        )}
                        {ticketIsSuccess && ticketData && (
                            <TicketDisplay
                                threadData={threadData}
                                refetchTicketData={refetchTicketData}
                                refetchThreadData={refetchThreadData}
                                loadingThreadState={threadIsLoading}
                                identifier={identifier}
                                ticket={ticketData}
                                userID={userID}
                                ai_response={aiResponse}
                                listType={listType}
                                moveToNextTicket={moveToNextTicket}
                                moveToPreviousTicket={moveToPreviousTicket}
                                saveStatus={saveStatus}
                                errorThreadState={false}
                                teamID={teamID}
                            />
                        )}
                        {ticketError && (
                            <Callout.Root
                                size="1"
                                variant="outline"
                                color="red"
                            >
                                <Callout.Text>
                                    Sorry, something's wrong! Please notify us
                                    at support@askassembly.app.
                                </Callout.Text>
                            </Callout.Root>
                        )}
                    </div>
                </Box>
            </Flex>
        </div>
    );
};
export default AdminTicketPage;
