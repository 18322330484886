import { Button } from "@/component/shadcn/ui/button";
import { API, ContactsAPI, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Account,
    CustomerGroup,
    GetTopicsResponse,
    GetUserResponse,
    IconEntry,
    Integration,
    ListCustomersResponse,
    OrgInfoResponse,
    QueriesWithPaginationResponse,
    ScopeResponse,
    Teams,
} from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import {
    CheckCircledIcon,
    GlobeIcon,
    ImageIcon,
    Pencil2Icon,
} from "@radix-ui/react-icons";
import { Badge, Box, Callout, Flex, Skeleton, Text } from "@radix-ui/themes";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomersTable } from "./CustomersTable/CustomersTable";

import IssuesList from "@/IssuesTable/IssuesList";
import { Tabs } from "@radix-ui/themes";
import { BookUserIcon, TicketIcon } from "lucide-react";

import { useInfiniteQuery } from "@tanstack/react-query";
import { IssueListType } from "../AdminQueriesPage";
import AccountPopup from "./AccountPopup";
import { DropdownMenu } from "@radix-ui/themes";
import LinkContactsPopup from "./LinkContactsPopup";
import { integrationBackEndDataMappingToSvg } from "../Integrations/constant";
import React from "react";

export interface ContactsProps {
    orgID: string;
    userID: string;
}

export const CompanyPage = ({ orgID, userID }: ContactsProps) => {
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const queryClient = useQueryClient();

    const { id } = useParams<{ id: string }>();

    const api = useApi();

    const [company, setCompany] = useState<Account>();
    const [loadingCompany, setLoadingCompany] = useState<boolean>(true);

    const [loadingState, setLoadingState] = useState<number>(0);

    useEffect(() => {
        const { url } = ContactsAPI.getCompany;
        api.get(`${URLS.serverUrl}${url}/${id}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    setCompany(res.data.data);
                    setLoadingCompany(false)
                }
            })
            .catch((res) => {
                console.log("System is down.");
            });
    }, [api]);

    const fetchContacts = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListCustomersResponse> => {
        try {
            const { url, method } = ContactsAPI.listCustomers;
            const response = await api.get(
                `${URLS.serverUrl}${url}/${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 100,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const { data: contactsData, fetchNextPage: fetchContactsNextPage, hasNextPage: contactsHasNextPage, isFetchingNextPage: isFetchingContactsNextPage, refetch: refetchContacts, isLoading: contactsIsLoading, isError: contactsIsError } =
        useInfiniteQuery({
            queryKey: ["contacts"],
            queryFn: fetchContacts,
            getNextPageParam: (lastPage) => {
                if (lastPage?.has_next_page) {
                    return lastPage.next_cursor;
                }
                return undefined; // No more pages
            },
            initialPageParam: 0,
            refetchInterval: 30000,
            refetchOnWindowFocus: true,
        });

    // Fetch all the data
    useEffect(() => {
        if (contactsHasNextPage && !isFetchingContactsNextPage) {
            fetchContactsNextPage();
        }
    }, [contactsHasNextPage, isFetchingContactsNextPage, fetchContactsNextPage]);

    const contactsCombinedData =
        contactsData && Array.isArray(contactsData.pages)
            ? contactsData.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                ) // Filter out null or undefined items in page.data
            : [];

    const channelsQuery = useQuery<Map<string, ScopeResponse[]>>({
        queryKey: ["channels"],
        queryFn: async () => {
            const theMap = new Map<string, ScopeResponse[]>();
            // Fetching the orgInfo first
            const res = await fetch(URLS.serverUrl + API.getItemsByOrgID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
                body: JSON.stringify({
                    types: ["Slack", "CommunitySlack", "Discord", "Google"],
                }),
            });

            if (res.ok) {
                const orgInfo: OrgInfoResponse = (await res.json()).data;

                // Handle Slack scopes asynchronously
                if (orgInfo.Slack) {
                    api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/Slack`, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("Slack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle CommunitySlack scopes asynchronously
                if (orgInfo.CommunitySlack) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/CommunitySlack`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("CommunitySlack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle Discord scopes asynchronously
                if (orgInfo.Discord) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/Discord`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("Discord", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Set Google scopes to an empty array
                if (orgInfo.Google) {
                    api.get(`${URLS.serverUrl}${API.getUniqueIntegrations}/Google`, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                const integrationsResponse: Integration[] =
                                    res.data.data;
                                const dataItems: ScopeResponse[] = [];
                                for (const integration of integrationsResponse) {
                                    const scope: ScopeResponse = {
                                        key: integration.id,
                                        name: integration.unique_name
                                    };
                                    dataItems.push(scope);
                                }
                                theMap.set("Gmail", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }
            }

            return theMap;
        },
    });

    // Only showing teams that the member is apart of in the filter
    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listMemberTeams;
            const response = await fetch(
                `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                    },
                },
            );
            const d = await response.json();
            return d.data;
        },
    });

    const fetchCompanyTickets = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        try {
            const response = await api.get(
                URLS.serverUrl + API.queriesByAccount,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        company_id: id,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
        refetch
    } = useInfiniteQuery({
        queryKey: [`company_tickets_${id}`],
        queryFn: fetchCompanyTickets,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });
    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                )
            : [];

    // Fetch all the data
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    const updateData = async () => {
        queryClient.refetchQueries({
            queryKey: ["contacts"],
            exact: true,
        });
    };

    useEffect(() => {
        if (data) {
            setLoadingState(1);
        }
    }, [data]);

    const [topics, setTopics] = useState<
        { color: string; label: string; value: string }[]
    >([]);
    const [topicsMap, setTopicsMap] = useState(new Map());
    const [users, setUsers] = useState<GetUserResponse[]>([]);

    useEffect(() => {
        api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const newTopics: {
                        color: string;
                        label: string;
                        value: string;
                    }[] = [];

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        newTopics.push({
                            color: topic.color ?? "#9B9EF0",
                            label: topic.topic_name,
                            value: topic.topic_name,
                        });
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                    setTopics(newTopics);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
            });
    }, [api]);

    // Grab the users for the org
    useEffect(() => {
        api.post(URLS.serverUrl + API.getAllUsers, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setUsers(res.data.data);
            } else {
                console.log("Call to grab users failed");
            }
        });
    }, [api]);

    const usersQuery = useQuery<GetUserResponse[]>({
        queryKey: ["users"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getAllUsers, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            return data.data;
        },
    });

    const customerGroupsQuery = useQuery<CustomerGroup[]>({
        queryKey: ["customers"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getCustomerGroups, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            const customerGroups: CustomerGroup[] = data.data;
            return customerGroups;
        },
    });

    const navigate = useNavigate();

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [toastText, setToastText] = useState<string>("Saved customer!");
    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const [serviceIcons, setServiceIcons] = useState<Set<IconEntry>>(new Set<IconEntry>);
    useEffect(() => {
        const serviceNames = Object.keys(company?.metadata ?? {})
        const serviceIcons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        serviceNames.forEach((serviceName) => {
            const IconComponent =
                integrationBackEndDataMappingToSvg.get(serviceName);
            if (IconComponent) {
                serviceIcons.add({
                    Component: IconComponent,
                    props: {
                        width: 20,
                        height: 20,
                        style: {
                            marginLeft: "-2px",
                            marginRight: "-2px",
                        },
                    },
                });
            }
        });
        setServiceIcons(serviceIcons)
    }, [company?.metadata])

    return (
        <Flex direction="column" align="center" justify="center">
            <Box mt="7" height="100%" width="98%">
                <div className="flex flex-row items-center gap-3 pb-4 ml-2">
                    {company?.image_url !== "" &&
                        company?.image_url !== undefined ? (
                        <div className="lb-avatar rounded w-12 h-12">
                            <img
                                className="lb-avatar-image"
                                src={company.image_url}
                                alt={company.name}
                            />

                            <span>{company.name ?? ""}</span>
                        </div>
                    ) : (
                        <ImageIcon className="w-6 h-6 ml-2" />
                    )}
                    <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-1 pl-1 text-lg font-semibold">
                            {company?.name}
                        </div>
                        <div className="flex items-center gap-1.5">
                            {company?.domain !== "" && (
                                <Badge
                                    color="blue"
                                    size="2"
                                    radius="full"
                                    variant="outline"
                                    className="text-xs"
                                >
                                    <GlobeIcon />
                                    {company?.domain}
                                </Badge>
                            )}
                            {company?.metadata && (
                                <div
                                    className="flex flex-row mx-1"
                                >
                                    {Array.from(serviceIcons).map((icon) =>
                                        React.createElement(icon.Component, icon.props),
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex items-center absolute top-4 right-4 my-4">
                    <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                            <Button
                                className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-5"
                                size="sm"
                                variant="outline"
                            >
                                Edit
                                <Pencil2Icon />
                            </Button>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content>
                            {!loadingCompany &&
                                <LinkContactsPopup
                                    triggerElement={
                                        <DropdownMenu.Item
                                            onSelect={(e) => e.preventDefault()}
                                        >
                                            Link Contacts
                                        </DropdownMenu.Item>
                                    }
                                    setToastOpen={setToastOpen}
                                    setToastSymbol={setToastSymbol}
                                    setToastText={setToastText}
                                    updateData={updateData}
                                    company={company}
                                    contacts={contactsCombinedData}
                                />
                            }
                            <AccountPopup
                                triggerElement={
                                    <DropdownMenu.Item
                                        onSelect={(e) => e.preventDefault()}
                                    >
                                        Create New Contact
                                    </DropdownMenu.Item>
                                }
                                editing={false}
                                setToastOpen={setToastOpen}
                                setToastSymbol={setToastSymbol}
                                setToastText={setToastText}
                                type="Customer"
                                updateData={updateData}
                                company={company}
                            />
                        </DropdownMenu.Content>
                    </DropdownMenu.Root>
                </div>

                <Tabs.Root defaultValue="contacts">
                    <Tabs.List className="flex flex-row items-start gap-4 ">
                        <Tabs.Trigger value="contacts">
                            <div className="flex flex-row items-center gap-0 space-x-2">
                                <BookUserIcon strokeWidth={2} size={16} />
                                <p>Contacts</p>
                            </div>
                        </Tabs.Trigger>
                        <Tabs.Trigger value="tickets">
                            <div className="flex flex-row items-center gap-0 space-x-2">
                                <TicketIcon strokeWidth={2} size={16} />
                                <p>Tickets</p>
                            </div>
                        </Tabs.Trigger>
                    </Tabs.List>
                    <Tabs.Content className="pt-3" value="contacts">
                        {contactsIsLoading && (
                            <Flex
                                maxWidth="85%"
                                style={{ paddingLeft: "20px" }}
                            >
                                <Text>
                                    <Skeleton maxWidth="85%">
                                        {[...Array(6)].map((_, index) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                            <Text key={index}>
                                                {loremIpsum}
                                            </Text>
                                        ))}
                                    </Skeleton>
                                </Text>
                            </Flex>
                        )}
                        {contactsIsError && (
                            <Callout.Root
                                size="1"
                                variant="outline"
                                color="red"
                            >
                                <Callout.Text>
                                    Sorry, something's wrong! Please notify us
                                    at support@askassembly.app.
                                </Callout.Text>
                            </Callout.Root>
                        )}
                        {!contactsIsLoading && contactsCombinedData && (
                            <div className="flex flex-col gap-2 px-2">
                                <CustomersTable
                                    userID=""
                                    forceUpdate={updateData}
                                    users={[]}
                                    customers={contactsCombinedData}
                                    company={company}
                                />
                            </div>
                        )}
                    </Tabs.Content>
                    <Tabs.Content className="pt-3" value="tickets">
                        {loadingState === 0 && (
                            <Flex
                                maxWidth="85%"
                                style={{ paddingLeft: "20px" }}
                            >
                                <Text>
                                    <Skeleton maxWidth="85%">
                                        {[...Array(6)].map((_, index) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                            <Text key={index}>
                                                {loremIpsum}
                                            </Text>
                                        ))}
                                    </Skeleton>
                                </Text>
                            </Flex>
                        )}
                        {loadingState === 2 && (
                            <Callout.Root
                                size="1"
                                variant="outline"
                                color="red"
                            >
                                <Callout.Text>
                                    Sorry, something's wrong! Please notify us
                                    at support@askassembly.app.
                                </Callout.Text>
                            </Callout.Root>
                        )}
                        {loadingState === 1 && combinedData.length !== 0 && (
                            <div className="flex flex-col gap-2 px-2">
                                <IssuesList
                                    issues={combinedData}
                                    topics={topics}
                                    topicsMap={topicsMap}
                                    userID={userID}
                                    usersQuery={usersQuery}
                                    customerGroupsQuery={customerGroupsQuery}
                                    listType={IssueListType.Issues}
                                    channelsQuery={channelsQuery}
                                    teamsQuery={teamsQuery}
                                    refetch={refetch} />
                            </div>
                        )}

                        {loadingState === 1 && combinedData.length === 0 && (
                            <div className="flex w-full h-[50%] justify-center items-center">
                                <p className="text-md text-gray12">
                                    No tickets yet.
                                </p>
                            </div>
                        )}
                    </Tabs.Content>
                </Tabs.Root>
            </Box>
        </Flex>
    );
};
