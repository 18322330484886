import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    Query,
    Ticket,
    TicketTag,
} from "@/interfaces/serverData";
import { ComponentBooleanIcon, TriangleDownIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useState } from "react";

interface TicketTagDropdownProps<TData> {
    ticket: Ticket;
    userID: string;
    teamID?: string;
}

export function TicketTagDropdown<TData extends Query>({
    ticket,
    userID,
    teamID,
}: TicketTagDropdownProps<TData>) {
    const api = useApi();
    const [tagSelected, setTagSelected] = useState<string>(ticket.bot_category);

    const fetchCategories = async (): Promise<Category[]> => {
        const response: AxiosResponse<{ data: Category[] }> = await api.get(
            `${URLS.serverUrl}${API.getCategories}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch categories");
    };

    const fetchTeamCategories = async (): Promise<Category[]> => {
        const response = await api.get(
            `${URLS.serverUrl}${API.getCategories}/team/${teamID}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };

    const availableTagsQuery = useQuery<
        {
            id: string;
            name: string;
            color: string;
        }[]
    >({
        queryKey: teamID ? [`teamCategories_${teamID}`] : ["categories"],
        queryFn: teamID ? () => fetchTeamCategories() : () => fetchCategories(),
    });

    function saveTag(tag: string) {
        const requestData: TicketTag = {
            ids: [ticket.id],
            tag: tag,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setTagSelected(tag);
                console.log(`Updated tag to ${tag} successfully`);
            } else {
                console.log("Call to update tag failed");
            }
        });
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Badge
                    color={"gray"}
                    size="2"
                    radius="full"
                    variant="outline"
                    className="m-1 ring-[0.8px] text-gray-700 ring-[#E0E1E6]"
                >
                    <div className="flex flex-row items-center gap-1">
                        <ComponentBooleanIcon
                            color={
                                availableTagsQuery.data?.find(
                                    (tag) => tag.name === tagSelected,
                                )?.color ?? "gray"
                            }
                        />
                        <p>{tagSelected}</p>
                    </div>
                    <TriangleDownIcon className="h-3 w-3" />
                </Badge>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                side="bottom"
                align="end"
                className="w-[200px] p-2"
            >
                {availableTagsQuery.data?.map((tag) => (
                    <DropdownMenuItem
                        key={tag.name}
                        className="py-1 hover:bg-muted cursor-pointer flex items-center"
                        onSelect={() => saveTag(tag.name)}
                    >
                        <div className="flex flex-row gap-2 items-center">
                            <div className="flex-shrink-0 w-4 h-4">
                                <ComponentBooleanIcon
                                    color={tag.color}
                                    className="w-full h-full"
                                />
                            </div>
                            <p>{tag.name}</p>
                        </div>
                    </DropdownMenuItem>
                ))}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
