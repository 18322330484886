import { ticketStatuses } from "@/IssuesTable/constants";
import { Card } from "@/component/shadcn/ui/card";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    HistoryResponse,
    IconEntry,
    Query,
    Ticket,
    TicketStatus,
} from "@/interfaces/serverData";
import { getStatusIcon } from "@/utilities/methods";
import {
    QuestionMarkCircledIcon,
    TriangleDownIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { CommandIcon } from "lucide-react";
import React, { useState } from "react";
import { useEffect } from "react";

interface TicketStatusDropdownProps<TData> {
    ticket: Ticket;
    userID: string;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
}

export function TicketStatusDropdown<TData extends Query>({
    ticket,
    userID,
    refetchThreadData,
}: TicketStatusDropdownProps<TData>) {
    const [statusSelected, setStatusSelected] = useState<string>("");
    useEffect(() => {
        const currStatus = ticketStatuses.find(
            (status) => status.value === ticket.ticket_status,
        );
        setStatusSelected(currStatus?.value ?? "Unknown");
    }, [ticket]);
    const api = useApi();

    function saveStatus(value: string) {
        const requestData: TicketStatus = {
            ids: [ticket.id],
            status: value,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setStatusSelected(value);
                refetchThreadData();
                console.log(`Updated status to ${value} successfully`);
            } else {
                console.log("Call to update status failed");
            }
        });
    }

    const selectedStatus = ticketStatuses.find(
        (status) => status.value === statusSelected,
    );
    const Icon = getStatusIcon(selectedStatus?.value ?? "Unknown");
    const statusesInfo: { label: string; value: string; icon: IconEntry }[] =
        [];
    for (const option of ticketStatuses) {
        const IconComponent = getStatusIcon(option.value ?? "Unknown");
        const iconEntry = {
            Component: IconComponent,
            props: {
                width: 15,
                height: 15,
                style: { marginRight: "8" },
            },
        };
        statusesInfo.push({
            label: option.label,
            value: option.value,
            icon: iconEntry,
        });
    }

    return (
        <div className="text-xs flex items-center">
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Badge
                        color={"gray"}
                        size="2"
                        radius="medium"
                        variant="outline"
                        className=" my-0 ring-[0.8px] text-gray-700 ring-[#E0E1E6]"
                    >
                        {selectedStatus ? (
                            <Icon
                                style={{
                                    height: "15px",
                                }}
                            />
                        ) : (
                            <QuestionMarkCircledIcon
                                style={{
                                    height: "15px",
                                }}
                            />
                        )}
                        <span className="text-xs font-normal ">
                            {selectedStatus ? selectedStatus.label : "Unknown"}
                        </span>
                        <TriangleDownIcon className="h-3 w-3" />
                    </Badge>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                    side="bottom"
                    align="end"
                    className="w-[200px] p-2"
                >
                    {statusesInfo.map((status) => (
                        <DropdownMenuItem
                            key={status.value}
                            className="py-1 hover:bg-muted cursor-pointer flex flex-row items-center gap-2"
                            onSelect={() => saveStatus(status.value)}
                        >
                            {React.createElement(
                                status.icon.Component,
                                status.icon.props,
                            )}
                            <p className="text-xs font-normal">
                                {status.label}
                            </p>

                            {status.label === "Closed" && (
                                <div className="text-xs flex flex-row gap-2 items-center text-gray10 mt-auto ml-auto">
                                    <Card className="rounded-md shadow-sm shadow-[#f3f4f6] flex flex-row gap-1 px-2 items-center">
                                        <CommandIcon
                                            strokeWidth={1.5}
                                            size={12}
                                        />
                                        E
                                    </Card>
                                </div>
                            )}
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}
