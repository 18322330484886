import { InfoCircledIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { Badge, Button, Callout, Card, Flex, Text } from "@radix-ui/themes";

import { FancyMultiSelect } from "@/component/MultiSelect";
import { API, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { ScopeResponse } from "@/interfaces/serverData";
import { AssemblyErrorMessage } from "@/reusable_components/loadingStates/ErrorMessage";
import { useEffect, useState } from "react";
interface ModeratorCardProps {
    adminUsers: ScopeResponse[];
    setAdminUsers: React.Dispatch<React.SetStateAction<ScopeResponse[]>>;
    saveBotSettings: () => void;
    className?: string;
    botType: string;
    integrationEnabled: boolean;
    getName: (option: ScopeResponse) => string;
    integrationName: string;
}

const ModeratorCard: React.FC<ModeratorCardProps> = ({
    adminUsers,
    setAdminUsers,
    saveBotSettings,
    className,
    botType,
    integrationEnabled,
    getName,
    integrationName,
}) => {
    const api = useApi();

    const [allUsers, setAllUsers] = useState<ScopeResponse[]>([]);

    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(loadingTypes.loading);

    useEffect(() => {
        if (integrationEnabled) {
            api.get(`${URLS.serverUrl}${API.getAllSourceUsers}/${botType}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    console.log(res.data);
                    if (res.status === 200) {
                        setAllUsers(res.data.data);
                        setLoading(loadingTypes.loaded);
                    } else {
                        setLoading(loadingTypes.error);
                    }
                })
                .catch((res) => {
                    setLoading(loadingTypes.error);
                });
        }
    }, [api, integrationEnabled, botType]);

    return (
        <Card
            style={{
                paddingRight: "0px",
                paddingLeft: "0px",
                position: "relative",
                overflow: "visible",
                height: isEditing ? "280px" : "auto",
            }}
            className={className}
        >
            <Flex direction={"column"} gap="2" mb="2" justify={"between"}>
                <Flex
                    direction={"row"}
                    justify={"between"}
                    align={"center"}
                    style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                    }}
                >
                    <Text size={"2"}>Assign Moderators: </Text>
                    <Button
                        variant="ghost"
                        size="1"
                        onClick={() => setIsEditing((prev) => !prev)}
                    >
                        Edit <Pencil2Icon width="15" height="15" />
                    </Button>
                </Flex>
                <Text
                    style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                    size="1"
                    color="gray"
                >
                    Assign moderators to a thread by selecting admins below for{" "}
                    {integrationName}.
                </Text>
                <div className="px-4">
                    <Callout.Root size="1">
                        <Callout.Icon>
                            <InfoCircledIcon />
                        </Callout.Icon>
                        <Callout.Text className="text-xs">
                            We'll automatically assign tickets to open based on
                            moderators selected or if their email matches the
                            company domain.
                        </Callout.Text>
                    </Callout.Root>
                </div>
                {loading === loadingTypes.loaded && (
                    <Flex
                        style={{
                            paddingRight: "20px",
                            paddingLeft: "20px",
                            paddingTop: "10px",
                            position: "relative",
                        }}
                        direction="column"
                        gap="3"
                        align="start"
                        wrap="wrap"
                    >
                        {isEditing ? (
                            <div className="flex flex-col gap-3 w-full">
                                <div>
                                    <FancyMultiSelect
                                        options={allUsers}
                                        placeholder={"Select Moderators"}
                                        setSelectedChannels={setAdminUsers}
                                        selectedChannels={adminUsers}
                                        getName={getName}
                                    />
                                </div>
                                <Flex justify="end" style={{ width: "100%" }}>
                                    <Button
                                        onClick={() => {
                                            setIsEditing(false);
                                            saveBotSettings();
                                        }}
                                        className="text-xs"
                                    >
                                        Save
                                    </Button>
                                </Flex>
                            </div>
                        ) : (
                            <div className="flex flex-row gap-3">
                                {adminUsers?.map((item) => (
                                    <Badge radius="full" key={item.key}>
                                        {getName(item)}
                                    </Badge>
                                ))}
                            </div>
                        )}
                    </Flex>
                )}
                {loading === loadingTypes.error && (
                    <AssemblyErrorMessage className="mx-4" />
                )}
            </Flex>
        </Card>
    );
};

export default ModeratorCard;
