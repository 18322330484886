type URLType = {
    serverUrl: string;
};

export const URLS: URLType = {
    serverUrl:
        process.env.REACT_APP_SERVER_URL ?? "https://dashboard.askassembly.app",
};

export const SERVERS = {
    github: "https://api.github.com/",
};

export const version_zero = "/api/v0/";
export const version_one = "/api/v1/";
export const authenticated_requests = `${version_one}auth/`;
export const API = {
    getAccessCode: `${authenticated_requests}get_access_code`,
    auth: `${authenticated_requests}auth`,
    index: `${authenticated_requests}index`,
    getItemsByOrgID: `${authenticated_requests}get_items_by_orgid`,
    update_cookie: `${authenticated_requests}update_cookie`,
    indexStatus: `${authenticated_requests}index_status`,
    getIntegrations: `${authenticated_requests}get_integrations`,
    getUniqueIntegrations: `${authenticated_requests}get_unique_integrations`,

    authenticateIntegration: `${authenticated_requests}authenticate_integration`,
    indexIntegration: `${authenticated_requests}index_integration`,
    deleteIntegration: `${authenticated_requests}delete_integration`,

    getScopes: `${authenticated_requests}get_scopes`,
    getScopesPaginated: `${authenticated_requests}get_scopes_paginated`,
    // TODO: can remove once pagination is verified with no issues
    queries: `${authenticated_requests}queries`,
    queriesWithPagination: `${authenticated_requests}queries_paginated`,
    queriesByTeam: `${authenticated_requests}queries/team`,
    queriesByAccount: `${authenticated_requests}queries/account`,

    analytics: `${authenticated_requests}analytics`,
    getInteractionsAnalytics: `${authenticated_requests}analytics/interactions`,
    getResponseTime: `${authenticated_requests}analytics/response`,
    getFirstResponseTime: `${authenticated_requests}analytics/first_response`,
    getResolutionTime: `${authenticated_requests}analytics/resolution`,
    getSLATime: `${authenticated_requests}analytics/sla`,

    saveBotSettingsV2: `${authenticated_requests}bot_settings`,
    getBotSettingsV2: `${authenticated_requests}bot_settings`,
    initializeBot: `${authenticated_requests}bot_settings`,
    getAllSourceUsers: `${authenticated_requests}get_all_source_users`, // source specific users

    saveSLA: `${authenticated_requests}sla`,
    getSLAs: `${authenticated_requests}sla`,

    saveBusinessHours: `${authenticated_requests}business_hours/save`,
    getBusinessHours: `${authenticated_requests}business_hours/get`,
    getTimeLeftWithinBusinessHours: `${authenticated_requests}business_hours/time_left`,

    getWorkflows: `${authenticated_requests}workflows`,
    getWorkflow: `${authenticated_requests}workflows`,
    saveWorkflow: `${authenticated_requests}workflows`,
    updateWorkflow: `${authenticated_requests}workflows`,
    deleteWorkflow: `${authenticated_requests}workflows`,

    saveNotificationOptions: `${authenticated_requests}notifications_options`,
    getNotificationOptions: `${authenticated_requests}notifications_options`,

    saveNotificationSettings: `${authenticated_requests}notifications`,
    getNotifications: `${authenticated_requests}notifications`,
    initializeNotification: `${authenticated_requests}notifications`,

    saveBotSettings: `${authenticated_requests}save_bot_settings`,
    getBotSettings: `${authenticated_requests}get_bot_settings`,
    whoAmI: `${authenticated_requests}whoami`,
    search: `${authenticated_requests}search_natural`,
    describe: `${authenticated_requests}describe`,
    updateConnection: `${authenticated_requests}update_connection`,
    getConnections: `${authenticated_requests}get_connections`,

    getCategories: `${authenticated_requests}category`,
    addCategory: `${authenticated_requests}category`,
    editCategory: `${authenticated_requests}category`,
    deleteCategory: `${authenticated_requests}category`,
    getCategory: `${authenticated_requests}category`,

    getTopics: `${authenticated_requests}get_topics`,
    addTopic: `${authenticated_requests}add_topic`,
    editTopic: `${authenticated_requests}edit_topic`,
    deleteTopic: `${authenticated_requests}delete_topic`,
    getTopicsPref: `${authenticated_requests}topics_preferences`,
    saveTopicsPref: `${authenticated_requests}save_topics_preferences`,
    saveTicket: `${authenticated_requests}ticket`,
    getTicketInfo: `${authenticated_requests}ticket`,

    determineTopTopics: `${authenticated_requests}insights/top_topics`,
    determineTopInsights: `${authenticated_requests}insights/top_insights`,
    saveInsight: `${authenticated_requests}insights/save`,
    getInsightsWithPagination: `${authenticated_requests}insights`,
    getInsightsByTeam: `${authenticated_requests}insights/team`,
    getInsightInfo: `${authenticated_requests}insights`,
    similarIssuesForInsight: `${authenticated_requests}insights/similar_issues`,
    genTitleDescForInsight: `${authenticated_requests}insights/gen_title_desc`,

    getApiKeys: `${authenticated_requests}get_api_keys`,
    createTicket: `${authenticated_requests}ticket/create`,
    createTicketFromUI: `${authenticated_requests}ticket/create_ui`,
    similarIssuesForTicket: `${authenticated_requests}ticket/similar_issues`,
    createHistory: `${authenticated_requests}ticket/history`,
    getHistoryRecords: `${authenticated_requests}ticket/get_history_records`,
    publishMessage: `${authenticated_requests}ticket/publish_message`,
    publishExternalIssue: `${authenticated_requests}ticket/publish`,
    getFile: `${authenticated_requests}ticket/get_file`,
    getOrg: `${authenticated_requests}get_org`,
    getUser: `${authenticated_requests}get_user`,
    getAllUsers: `${authenticated_requests}get_all_users`,
    changeUserRole: `${authenticated_requests}user_role`,
    announcement: `${authenticated_requests}announcement`,
    sendAnnouncement: `${authenticated_requests}send_announcement`,
    sendAnnouncementWithFiles: `${authenticated_requests}send_announcement_with_files`,
    getAnnouncements: `${authenticated_requests}get_announcements`,
    createHistoricalTickets: `${authenticated_requests}historical_tickets`,
    getAIResponse: `${authenticated_requests}generate_ai_response`,

    saveCustomerGroup: `${authenticated_requests}customer_group`,
    getCustomerGroups: `${authenticated_requests}get_customer_groups`,
    getCustomerGroup: `${authenticated_requests}get_customer_group`,

    getMostRecentTicket: `${authenticated_requests}get_next_ticket`,

    exportTickets: `${authenticated_requests}ticket/export`,
    getTimerEnabled: `${authenticated_requests}timer`,
    saveTimerEnabled: `${authenticated_requests}timer`,

    getTicketTimer: `${authenticated_requests}timer/status`,
    addTicketTimerEvent: `${authenticated_requests}timer/activity`,

    timerAnalytics: `${authenticated_requests}timer/analytics`,
    timerAnalyticsExport: `${authenticated_requests}timer/analytics/export`,
};

export const APIWithMethod = {
    listViews: [`${authenticated_requests}views`, "GET"],
    getView: [`${authenticated_requests}views`, "GET"],
    createView: [`${authenticated_requests}views`, "PUT"],
    deleteView: [`${authenticated_requests}views`, "DELETE"],
};

export const TeamsAPI = {
    createTeam: [`${authenticated_requests}teams`, "PUT"],
    updateTeam: [`${authenticated_requests}teams`, "PATCH"],
    deleteTeam: [`${authenticated_requests}teams`, "DELETE"],
    listTeams: [`${authenticated_requests}teams`, "GET"],
    listMemberTeams: [`${authenticated_requests}teams/all`, "GET"],
    getTeam: [`${authenticated_requests}teams`, "GET"],
    addTeamSource: [`${authenticated_requests}teams/add_source`, "PUT"],
    editTeamSource: [`${authenticated_requests}teams/edit_source`, "PATCH"],
    deleteTeamSource: [
        `${authenticated_requests}teams/delete_source`,
        "DELETE",
    ],
    getTeamSources: [`${authenticated_requests}teams/get_sources`, "GET"],
};

export interface APIWithMethodPair {
    url: string;
    method: string;
}

export const ContactsAPI: Record<string, APIWithMethodPair> = {
    listCustomers: {
        url: `${authenticated_requests}customers/get`,
        method: "GET",
    },
    getCustomer: { url: `${authenticated_requests}customers`, method: "GET" },
    createCustomer: {
        url: `${authenticated_requests}customers`,
        method: "PUT",
    },
    updateCustomer: {
        url: `${authenticated_requests}customers`,
        method: "PATCH",
    },
    deleteCustomer: {
        url: `${authenticated_requests}customers`,
        method: "DELETE",
    },

    importCompanies: {
        url: `${authenticated_requests}companies/import`,
        method: "POST",
    },
    listCompanies: { url: `${authenticated_requests}companies`, method: "GET" },
    getCompany: { url: `${authenticated_requests}companies`, method: "GET" },
    createCompany: { url: `${authenticated_requests}companies`, method: "PUT" },
    editCompany: { url: `${authenticated_requests}companies`, method: "PATCH" },
    deleteCompany: {
        url: `${authenticated_requests}companies`,
        method: "DELETE",
    },

    listAccounts: { url: `${authenticated_requests}accounts`, method: "GET" },
    getProperties: {
        url: `${authenticated_requests}crm/properties`,
        method: "GET",
    },
    getSchemas: {
        url: `${authenticated_requests}crm/schemas`,
        method: "GET",
    },
    getSavedSchema: {
        url: `${authenticated_requests}crm/get_saved_schema`,
        method: "GET",
    },
};

export const EXTERNAL_LINKS = {
    privacy_policy: "https://www.askassembly.app/privacy-policy",
    contact: "https://www.askassembly.app/contact",
};

// cookie management

type CookieKeys = {
    [key: string]: string;
};

export const ASSEMBLY_COOKIES: CookieKeys = {
    logged_in: "logged_in",
    indexed: "indexed",
    login_client: "client",
    admin: "admin",
    jira_url: "jira_url",
    jira_reindex: "jira_reindex",
    slack_bot_enabled: "slack_bot_enabled",
    linear_reauth: "linear_reauth",
};

// page states
export const pages = {
    login: "login",
    index: "index",
    search: "search",
};

export const login_clients = {
    GITHUB_CLIENT: "GitHub",
    GITLAB_CLIENT: "GitLab",
};

export enum loadingTypes {
    loading = 0,
    loaded = 1,
    error = 2,
    none = 3,
}
