import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    CheckCircledIcon,
    Cross2Icon,
    CrossCircledIcon,
} from "@radix-ui/react-icons";
import type React from "react";
import { useEffect, useRef, useState } from "react";


import { ContactsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { ContractType } from "@/interfaces/serverData";

import type {
    Account,
    AccountUpdatePayload,
    ListCustomersResponse,
} from "@/interfaces/serverData";
import { useInfiniteQuery } from "@tanstack/react-query";
import { ContactsDropdown } from "./ContactsDropdown";
import { Button } from "@/component/shadcn/ui/button";

interface LinkContactsPopupProps {
    triggerElement: React.ReactNode;
    setToastOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setToastText: React.Dispatch<React.SetStateAction<string>>;
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType>>;
    company?: Account;
    contacts?: Account[];
    updateData?: () => Promise<void>
}

const LinkContactsPopup: React.FC<LinkContactsPopupProps> = ({
    triggerElement,
    setToastOpen,
    setToastSymbol,
    setToastText,
    company,
    contacts: propsContacts,
    updateData
}) => {
    const [contacts, setContacts] = useState<Account[]>(propsContacts ?? [])

    const fetchContacts = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListCustomersResponse> => {
        try {
            const { url, method } = ContactsAPI.listCustomers;
            const response = await api.get(
                `${URLS.serverUrl}${url}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 100,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const { data: contactsData, fetchNextPage: fetchContactsNextPage, hasNextPage: contactsHasNextPage, isFetchingNextPage: isFetchingContactsNextPage, refetch: refetchContacts, isLoading: contactsIsLoading, isError: contactsIsError } =
        useInfiniteQuery({
            queryKey: ["all_customers"],
            queryFn: fetchContacts,
            getNextPageParam: (lastPage) => {
                if (lastPage?.has_next_page) {
                    return lastPage.next_cursor;
                }
                return undefined; // No more pages
            },
            initialPageParam: 0,
            refetchInterval: 30000,
            refetchOnWindowFocus: true,
        });

    // Fetch all the data
    useEffect(() => {
        if (contactsHasNextPage && !isFetchingContactsNextPage) {
            fetchContactsNextPage();
        }
    }, [contactsHasNextPage, isFetchingContactsNextPage, fetchContactsNextPage]);

    const contactsCombinedData =
        contactsData && Array.isArray(contactsData.pages)
            ? contactsData.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                ) // Filter out null or undefined items in page.data
            : [];

    const toggleContact = (customer: Account, contacts: Account[]) => {
        let newContacts = Array.from(contacts);
        // Remove if apart of the current contacts
        if (newContacts.map((t) => t.id).includes(customer.id)) {
            newContacts = newContacts.filter((t) => t.id !== customer.id);
        } else {
            // Add if it's a new customer that we're adding
            newContacts.push(customer)
        }
        setContacts(newContacts)
    };

    const api = useApi();

    const timerRef = useRef(0);

    function onEdit() {
        const requestData: AccountUpdatePayload = {
            id: company?.id ?? "",
            name: company?.name ?? "",
            domain: company?.domain ?? "",
            image_url: company?.image_url ?? "",
            contract_value: company?.contract_value ?? 0,
            contract_type: company?.contract_type
                ? (company?.contract_type as ContractType)
                : ContractType.Month,
            plan: company?.plan ?? "",
            contacts: contacts
        };

        console.log("request data is", requestData)

        api.patch(
            `${URLS.serverUrl}${ContactsAPI.editCompany.url}/${company?.id}`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
            .then((res) => {
                if (res.status !== 200) {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                } else {
                    setToastText("Updated Company!");
                    setToastSymbol(CheckCircledIcon);
                    if (updateData) {
                        updateData()
                    }
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setOpen(false);
                setToastOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setToastOpen(true);
                }, 100);
            });
    }
    const [open, setOpen] = useState<boolean>(false);
    const [validationErr, setValidationErr] = useState<boolean>(false);

    const formItemClassName = "flex flex-col";
    const formItemClassNameDiv = "flex flex-row items-center";
    const formLabelCN = "w-56 pt-1";
    const formLabelError = "text-xs";

    const toggleGroupItemClasses =
        "text-xs flex outline outline-1 outline-gray-300 h-[35px] w-[60px] items-center justify-center text-base leading-4 first:rounded-l last:rounded-r focus:z-10 focus:bg-iris3";

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{triggerElement}</DialogTrigger>
            <DialogContent className="fixed inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50">
                <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                    <div className="mx-10 my-5">
                        <DialogHeader className="justify-left text-left items-left pb-2">
                            <DialogTitle>Link Customer</DialogTitle>
                            <DialogDescription>{`Link or unlink existing customers as contacts of ${company ? company.name : "this company"}.`}</DialogDescription>
                        </DialogHeader>
                        <ContactsDropdown
                            selectedContacts={contacts}
                            customers={
                                contactsCombinedData
                            }
                            toggleContact={
                                toggleContact
                            }
                        />
                        <div className="pt-4 justify-end text-end items-end">
                            <Button className="bg-iris9" type="submit" onClick={() => onEdit()}>
                                Save
                            </Button>
                        </div>
                    </div>
                    <DialogClose asChild>
                        <Button
                            type="button"
                            variant="ghost"
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-900"
                        >
                            <Cross2Icon />
                        </Button>
                    </DialogClose>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default LinkContactsPopup;
