import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import type { UserResponse } from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import { AvatarIcon, CopyIcon } from "@radix-ui/react-icons";
import { DataList } from "@radix-ui/themes";
import { memo, useCallback, useMemo } from "react";
import * as Toggle from "@radix-ui/react-toggle";

interface CustomerProfileProps {
    userInfo: UserResponse | null;
    source: string;
    source_unique_name: string;
}

function CustomerProfile({
    userInfo,
    source,
    source_unique_name,
}: CustomerProfileProps) {
    const SourceSvgImage: React.ElementType | undefined = useMemo(
        () => integrationBackEndDataMappingToSvg.get(source ?? "Unknown"),
        [source],
    );

    const displayText =
        source_unique_name.length > 0
            ? `${source} (${source_unique_name})`
            : source;

    const copyEmail = useCallback((): void => {
        navigator.clipboard.writeText(userInfo?.email ?? "");
    }, [userInfo?.email]);

    const copyUsername = useCallback((): void => {
        navigator.clipboard.writeText(userInfo?.username ?? "");
    }, [userInfo?.username]);

    return (
        <div>
            {userInfo && (
                <div className="prose max-w-none text-xs flex flex-col gap-3">
                    <div className="flex items-center gap-1">
                        {userInfo?.avatar ? (
                            <div className="lb-avatar rounded-lg w-5 h-5">
                                {userInfo?.avatar && (
                                    <img
                                        className="lb-avatar-image"
                                        src={userInfo?.avatar}
                                        alt={userInfo?.username}
                                    />
                                )}
                                <span>{userInfo?.name ?? ""}</span>
                            </div>
                        ) : (
                            <AvatarIcon className="w-5 h-5" />
                        )}
                        <div className="text-[13px] font-medium mx-1">
                            {userInfo?.name ?? userInfo?.username}
                        </div>
                    </div>

                    <DataList.Root size="1">
                        {userInfo?.email && (
                            <DataList.Item align="center">
                                <DataList.Label
                                    className="text-xs"
                                    minWidth="60px"
                                >
                                    Email
                                </DataList.Label>
                                <DataList.Value className="text-xs">
                                    <div className="flex items-center gap-1.5">
                                        <div className="flex-1 truncate">
                                            {userInfo?.email}
                                        </div>
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Toggle.Root
                                                        aria-label="Toggle italic"
                                                        className="px-1 py-1 hover:bg-iris4 data-[state=on]:bg-[#9B9EF0] flex items-center justify-center rounded bg-white text-muted-foreground leading-4"
                                                        onClick={copyEmail}
                                                    >
                                                        <CopyIcon className="h-3 w-3" />
                                                    </Toggle.Root>
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>Copy Email</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                </DataList.Value>
                            </DataList.Item>
                        )}
                        {userInfo?.username && (
                            <DataList.Item align="center">
                                <DataList.Label
                                    className="text-xs"
                                    minWidth="60px"
                                >
                                    Username
                                </DataList.Label>
                                <DataList.Value>
                                    <div className="flex items-center gap-1.5">
                                        {userInfo?.username}
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Toggle.Root
                                                        aria-label="Toggle italic"
                                                        className="px-1 py-1 hover:bg-iris4 data-[state=on]:bg-[#9B9EF0] flex items-center justify-center rounded bg-white text-muted-foreground leading-4"
                                                        onClick={copyUsername}
                                                    >
                                                        <CopyIcon className="h-3 w-3" />
                                                    </Toggle.Root>
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>Copy Username</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                </DataList.Value>
                            </DataList.Item>
                        )}
                        <DataList.Item align="center">
                            <DataList.Label className="text-xs" minWidth="60px">
                                Source
                            </DataList.Label>
                            <DataList.Value>
                                <div className="flex items-center text-xs">
                                    {SourceSvgImage && (
                                        <SourceSvgImage className="w-4 h-4" />
                                    )}
                                    <span className="px-1">{displayText}</span>
                                </div>
                            </DataList.Value>
                        </DataList.Item>
                    </DataList.Root>
                </div>
            )}
        </div>
    );
}

export default memo(CustomerProfile);
