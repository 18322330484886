import { Button } from "@/component/shadcn/ui/button";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Integration,
    SLACreatePayload,
    SLAResponse,
    ScopeResponse,
} from "@/interfaces/serverData";
import {
    CheckCircledIcon,
    CrossCircledIcon,
    PlusIcon,
} from "@radix-ui/react-icons";
import * as Toast from "@radix-ui/react-toast";
import { Box, Flex } from "@radix-ui/themes";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import { integrationBackEndDataMappingToSvg } from "../Admin/Integrations/constant";
import SLACard from "./SLACard";
import SLAPopup from "./SLAPopup";
import Timer from "./Timer";

const SLAs: React.FC = () => {
    const [toastText, setToastText] = useState<string>("Updated Settings!");
    const [open, setOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const [slas, setSlas] = useState<SLAResponse[]>([]);

    const api = useApi();

    useEffect(() => {
        api.get(`${URLS.serverUrl}${API.getSLAs}`, {
            headers: { "Content-Type": "application/json" },
        })
            .then((generalRes) => {
                if (generalRes.status === 200) {
                    const data = generalRes.data.data as SLAResponse[];
                    setSlas(data);
                }
            })
            .catch((error) => {
                console.error(
                    "Error fetching general notification settings:",
                    error,
                );
            });
    }, [api]);

    const timerRef = useRef(0);

    const clearSLADetails = async () => {
        setSelectedChannels([]);
        setSelectedSource("");
        setShowSelectedChannels(false);
        setBreachTargetInput("0");
        setBreachTargetTimeUnit("");
        setNotificationTimeInput("0");
        setNotificationTimeUnit("");
        setIcon(undefined);
    };

    const saveSLA = async () => {
        const requestData: SLACreatePayload = {
            operation: "CREATE",
            enabled: true,
            source: selectedSource,
            channels: selectedChannels,
            breach_target: Number(breachTargetInput),
            breach_target_unit: breachTargetTimeUnit,
            notification: Number(notificationTimeInput),
            notification_time_unit: notificationTimeUnit,
        };
        api.post(`${URLS.serverUrl}${API.saveSLA}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                } else {
                    setToastText("Updated Settings!");
                    console.log("reached here!!>>");
                    setSlas(res.data.data);
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    console.log("setting open to true");
                    setOpen(true);
                }, 100);
                clearSLADetails();
            });
    };

    const [selectedSource, setSelectedSource] = useState<string>("");
    const [options, setOptions] = useState<ScopeResponse[]>([]);
    const [icon, setIcon] = useState<React.ElementType>();
    const [selectedChannels, setSelectedChannels] = useState<ScopeResponse[]>(
        [],
    );
    const [breachTargetInput, setBreachTargetInput] = useState<string>("0");
    const [breachTargetTimeUnit, setBreachTargetTimeUnit] = useState("hours");

    const [notificationTimeInput, setNotificationTimeInput] =
        useState<string>("0");
    const [notificationTimeUnit, setNotificationTimeUnit] = useState("hours");

    const [showSelectedChannels, setShowSelectedChannels] =
        useState<boolean>(false);

    const handleSLASourceChange = (newSLASource: string) => {
        setSelectedSource(newSLASource);
        setIcon(integrationBackEndDataMappingToSvg.get(newSLASource));
        switch (newSLASource) {
            case "Slack":
            case "Discord":
            case "CommunitySlack":
            case "GitHubTicket": {
                api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/${newSLASource}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        const dataItems: ScopeResponse[] =
                            res.data.data?.asm_ticket_channels;
                        setOptions(dataItems);
                    })
                    .catch((res) => { });
                break
            }
            case "Gmail": {
                api.get(`${URLS.serverUrl}${API.getUniqueIntegrations}/Google`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            const integrationsResponse: Integration[] =
                                res.data.data;
                            const dataItems: ScopeResponse[] = [];
                            for (const integration of integrationsResponse) {
                                const scope: ScopeResponse = {
                                    key: integration.id,
                                    name: integration.unique_name
                                };
                                dataItems.push(scope);
                            }
                            setOptions(dataItems)
                        }
                    })
                    .catch((res) => {
                        console.error("Error fetching scope data:", res);
                    });
            }
        }
    };

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <h2 className="text-2xl font-semibold">SLAs</h2>
                <p className=" text-sm text-gray11">Manage your SLAs</p>
                <div className="flex flex-col items-end gap-2">
                    <SLAPopup
                        triggerElement={
                            <Button
                                className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                size="sm"
                                variant="outline"
                            >
                                Add SLA
                                <PlusIcon />
                            </Button>
                        }
                        selectedSource={selectedSource}
                        setSelectedSource={setSelectedSource}
                        options={options}
                        setOptions={setOptions}
                        selectedChannels={selectedChannels}
                        setSelectedChannels={setSelectedChannels}
                        breachTargetInput={breachTargetInput}
                        setBreachTargetInput={setBreachTargetInput}
                        breachTargetTimeUnit={breachTargetTimeUnit}
                        setBreachTargetTimeUnit={setBreachTargetTimeUnit}
                        notificationTimeInput={notificationTimeInput}
                        setNotificationTimeInput={setNotificationTimeInput}
                        notificationTimeUnit={notificationTimeUnit}
                        setNotificationTimeUnit={setNotificationTimeUnit}
                        showSelectedChannels={showSelectedChannels}
                        setShowSelectedChannels={setShowSelectedChannels}
                        saveSLA={saveSLA}
                        icon={icon}
                        handleSLASourceChange={handleSLASourceChange}
                        subChannelsView={false}
                        editing={false}
                        clearSLADetails={clearSLADetails}
                    />
                </div>

                <Timer />

                <Toast.Provider swipeDirection="right">
                    <Toast.Root
                        className="ToastRoot"
                        open={open}
                        onOpenChange={setOpen}
                    >
                        <Toast.Title className="ToastTitle">
                            <Flex direction={"row"} align={"center"} gap="2">
                                <ToastSymbol color="green" />
                                {toastText}
                            </Flex>
                        </Toast.Title>
                    </Toast.Root>
                    <Toast.Viewport className="ToastViewport" />
                </Toast.Provider>
                <div className="flex flex-col gap-3 mt-4">
                    {slas.map((sla) => {
                        return (
                            <SLACard
                                key={sla.id}
                                sla={sla}
                                setOpen={setOpen}
                                setToastSymbol={setToastSymbol}
                                setToastText={setToastText}
                                setSlas={setSlas}
                                clearSLADetails={clearSLADetails}
                            />
                        );
                    })}
                </div>
            </Box>
        </div>
    );
};

export default SLAs;
