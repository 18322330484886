import { Grid, LinearProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API, URLS, pages } from "../../../constant";
import { StyledBox } from "../../../design/Box";
import { useApi } from "../../../interfaces/api";
import NavBarComponent from "../../../sharedPages/NavBar";
import { integrationsList } from "../constant";

import type { ScopeResponse } from "../../../interfaces/serverData";

import { useLocation } from "react-router-dom";

import { Em, Flex, Text } from "@radix-ui/themes";

const PRIndexingPage = () => {
    const navigate = useNavigate();
    const api = useApi();

    const navigateToIntegrations = useCallback(async () => {
        navigate("/admin/manage_integrations", { replace: true });
    }, [navigate]);

    const location = useLocation();

    const [startIndexing, setStartIndexing] = useState<boolean>(false);

    const [jobList, setJobList] = useState<string[]>([]);

    useEffect(() => {
        const integration = integrationsList.get(window.location.pathname);

        if (integration === undefined) {
            navigate("/*");
        }

        if (integration?.integrationType === "PullRequest") {
            const requestData = {
                type: integration?.integrationType,
            };

            api.post(URLS.serverUrl + API.indexIntegration, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        if (
                            res.data !== undefined &&
                            res.data.data !== undefined
                        ) {
                            const jobIdStatuses: Map<string, string> = new Map(
                                Object.entries(res.data.data),
                            );

                            setJobList(Array.from(jobIdStatuses.values()));
                        }

                        await new Promise((resolve) =>
                            setTimeout(resolve, 8000),
                        );
                        setStartIndexing(true);
                    } else {
                        await new Promise((resolve) =>
                            setTimeout(resolve, 8000),
                        );
                        setStartIndexing(true);
                    }
                })
                .catch(async (res) => {
                    // in case it fails, still just go to search page
                    await new Promise((resolve) => setTimeout(resolve, 40000));
                    setStartIndexing(true);
                });
        } else if (integration?.integrationType === "Web") {
            const searchParams = new URLSearchParams(location.search);

            const integrationType = searchParams.get("integration"); // mintlify, etc.
            const url = searchParams.get("url");
            const newlyAdded = searchParams.get("add");
            const requestData = {
                type: integration?.integrationType,
                info: {
                    code: integrationType,
                    url: url,
                },
            };
            api.post(
                URLS.serverUrl + API.authenticateIntegration,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    const channelRequest: ScopeResponse[] = [];

                    channelRequest.push({
                        key: integrationType!,
                        name: url!,
                    });
                    let addNewScopes = false;
                    if (newlyAdded === "true") {
                        addNewScopes = true;
                    }
                    const requestData = {
                        type: integration?.integrationType,
                        info: {
                            scopes: channelRequest,
                        },
                        add_new_scopes: addNewScopes,
                    };
                    api.post(
                        URLS.serverUrl + API.indexIntegration,
                        requestData,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then(async (res) => {
                            if (res.status === 200) {
                                if (
                                    res.data !== undefined &&
                                    res.data.data !== undefined
                                ) {
                                    const jobIdStatuses: Map<string, string> =
                                        new Map(Object.entries(res.data.data));

                                    setJobList(
                                        Array.from(jobIdStatuses.values()),
                                    );
                                }

                                await new Promise((resolve) =>
                                    setTimeout(resolve, 8000),
                                );
                                setStartIndexing(true);
                            } else {
                                await new Promise((resolve) =>
                                    setTimeout(resolve, 8000),
                                );
                                setStartIndexing(true);
                            }
                        })
                        .catch(async (res) => {
                            // in case it fails, still just go to search page
                            await new Promise((resolve) =>
                                setTimeout(resolve, 10000),
                            );
                            setStartIndexing(true);
                        });
                })
                .catch((res) => {
                    console.log(res);
                });
        } else {
            navigate("/*");
        }
    }, [navigate, location.search]);

    // todo - indexing is broken, so don't call this callback as of now
    // const fetchProgress = useCallback(async () => {
    //     if (!startIndexing) {
    //         return;
    //     }
    //     await new Promise((resolve) => setTimeout(resolve, 4000));

    //     const indexStatusInput = {
    //         job_ids: jobList,
    //     };
    //     await api
    //         .post(URLS.serverUrl + API.indexStatus, indexStatusInput, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //         })
    //         .then(async (res) => {
    //

    //             var isJobReady = true;
    //             if (res.status === 200) {
    //                 const jobIdStatuses: Map<string, string> = new Map(
    //                     Object.entries(res.data.data),
    //                 );
    //                 for (const element of jobList) {
    //                     // get job status from job id
    //                     const jobStatus = jobIdStatuses.get(element);
    //                     if (jobStatus) {
    //                         // for now if job status is not found, assume it's almost done indexing.
    //                         if (jobStatus !== "not found") {
    //                             const parts: string[] = jobStatus.split("/");
    //                             const numerator = parseInt(parts[0], 10);
    //                             const denominator = parseInt(parts[1], 10);

    //                             if (numerator === 0 && denominator === 0) {
    //                                 isJobReady = false; // hasn't updated yet.
    //                                 break;
    //                             }

    //                             if (
    //                                 !isNaN(numerator) &&
    //                                 !isNaN(denominator) &&
    //                                 denominator !== 0
    //                             ) {
    //                                 const fraction = numerator / denominator;
    //                                 // in the case that all threads don't get completed, go ahead and use this functionality instead.
    //                                 if (fraction < 0.95) {
    //                                     isJobReady = false;
    //                                     break;
    //                                 }
    //                             }
    //                         } else {
    //                             isJobReady = true; // timed out
    //                         }
    //                     } else {
    //                         isJobReady = false;
    //                         break;
    //                     }
    //                 }
    //             } else {
    //                 console.log("failed to get results");
    //                 isJobReady = false;
    //             }

    //             if (!isJobReady) {
    //                 // re-fetch progress after 2 seconds
    //                 setTimeout(fetchProgress, 3000);
    //             } else {
    //                 navigateToSearch();
    //             }
    //         });
    // }, [jobList, navigateToSearch, startIndexing]);

    const fetchProgress = useCallback(async () => {
        navigateToIntegrations();
    }, [navigateToIntegrations]);

    useEffect(() => {
        if (startIndexing) {
            fetchProgress();
        }
    }, [startIndexing, fetchProgress]);

    return (
        <div>
            <NavBarComponent state={pages.search} />

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: "80vh" }}
            >
                <StyledBox>
                    <Flex direction="column" gap="2">
                        <Text
                            size={{
                                sm: "4",
                                initial: "5",
                                md: "5",
                                xl: "6",
                            }}
                            weight="bold"
                        >
                            <b>
                                Please don't close this tab or refresh the page!
                            </b>
                        </Text>
                        <Text
                            size={{
                                sm: "3",
                                initial: "4",
                                md: "4",
                                xl: "4",
                            }}
                        >
                            <Em>Assembling...</Em>
                        </Text>
                        <Text
                            size={{
                                initial: "3",
                                sm: "2",
                                md: "3",
                                xl: "4",
                            }}
                            color="gray"
                            highContrast
                        >
                            This will take a few minutes, about as long as it
                            would take you to grab a cup of coffee!
                        </Text>
                        <LinearProgress />
                    </Flex>
                </StyledBox>
            </Grid>
        </div>
    );
};

export default PRIndexingPage;
