// TODO - migrate people off of IntegrationsIndexingPage, and use this instead (no more indexing upon enablement, only upon adding new scopes!)
// only tested for linear at the moment!
import { Box, Callout, Flex, Progress, Text } from "@radix-ui/themes";
import { useCallback, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API, ASSEMBLY_COOKIES, URLS, pages } from "../../../constant";
import { useApi } from "../../../interfaces/api";
import NavBarComponent from "../../../sharedPages/NavBar";
import { getCookie } from "../../../utilities/CookieManagement";
import { integrations, integrationsList } from "../constant";

const TokenPage = () => {
    const api = useApi();

    enum loadingTypes {
        loading = 0,
        loaded = 1,
        error = 2,
    }
    const [loading, setLoading] = useState(loadingTypes.loading);
    const navigate = useNavigate();

    useLayoutEffect(() => {
        const integration = integrationsList.get(window.location.pathname);
        if (integration === undefined) {
            navigate("/*");
        }

        const queryString = window.location.search;

        const i = integration ? integration.integrationType : "";
        const urlParams = new URLSearchParams(queryString);

        if (integration?.integrationType === integrations.Notion) {
            // todo for notion
        } else if (
            integration?.integrationType !== integrations.Code &&
            integration?.integrationType !== integrations.GitHubTicket &&
            integration?.integrationType !== integrations.HubSpot
        ) {
            const stateParam = urlParams.get("state");
            if (stateParam !== integration?.integrationCode) {
                navigate("/*");
            }
        }

        const codeParam = urlParams.get("code");
        if (codeParam === undefined || codeParam === "") {
            navigate("/*");
        }

        const cookie = getCookie(ASSEMBLY_COOKIES.jira_url);

        const requestData = {
            type: integration?.integrationType,
            info: {
                code: codeParam,
                url: cookie,
            },
        };

        const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
        api.post(serverUrl + API.authenticateIntegration, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(async (res) => {
                setLoading(loadingTypes.loaded);
                await new Promise((resolve) => setTimeout(resolve, 1500));
                navigateToIntegrations();
            })
            .catch((res) => {
                setLoading(loadingTypes.error);
                console.log(res);
            });
    }, [navigate]); //only run once

    const navigateToIntegrations = useCallback(async () => {
        navigate("/admin/manage_integrations", { replace: true });
    }, [navigate]);

    return (
        <div>
            <NavBarComponent state={pages.index} />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 65px)",
                }}
            >
                {(loading === loadingTypes.loaded ||
                    loading === loadingTypes.loading) && (
                        <Box>
                            <Flex direction="column" gap="2">
                                <Text
                                    size={{
                                        sm: "3",
                                        initial: "4",
                                        md: "4",
                                        xl: "5",
                                    }}
                                >
                                    Connecting...
                                </Text>

                                <Progress duration="2s" />
                            </Flex>
                        </Box>
                    )}
                {loading === loadingTypes.error && (
                    <Box maxWidth={"20%"}>
                        <Callout.Root size="1" variant="outline" color="red">
                            <Callout.Text>
                                Sorry, something's wrong! Please notify us at
                                support@askassembly.app, or try again later.
                            </Callout.Text>
                        </Callout.Root>
                    </Box>
                )}
            </div>
        </div>
    );
};

export default TokenPage;
