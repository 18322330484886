import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import { Form, FormField, FormLabel } from "@/component/shadcn/ui/form";
import { FormItem } from "@/component/shadcn/ui/form";
import { Label } from "@/component/shadcn/ui/label";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import { ContactsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { CRMProperties } from "@/interfaces/serverData";
import {
    ArrowRightIcon,
    CheckCircledIcon,
    CrossCircledIcon,
} from "@radix-ui/react-icons";
import {
    type UseQueryResult,
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { Loader } from "lucide-react";
import { useState } from "react";
import type React from "react";
import { type ControllerRenderProps, useForm } from "react-hook-form";
import type { z } from "zod";
import type { importForm } from "./HubSpotIntegration";
import { Separator } from "@radix-ui/themes";

type Schema = {
    id: string;
    name: string;
};

export const HubSpotSchemaImport = ({
    importData,
    exists,
    setOpen,
    setToastText,
    setToastSymbol,
}: {
    importData?: z.infer<typeof importForm>;
    exists: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setToastText: React.Dispatch<React.SetStateAction<string>>;
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType>>;
}) => {
    const [selectedSchemaCompany, setSelectedSchema] = useState<string>(
        importData?.company_schema_name?.name ?? "",
    );
    const [selectedSchemaIndividual, setSelectedSchemaIndividual] =
        useState<string>(importData?.individual_schema_name?.name ?? "");
    const api = useApi();

    // Query for available schemas
    const schemasQuery = useQuery<Schema[]>({
        queryKey: ["schemas"],
        queryFn: async () => {
            const { url } = ContactsAPI.getSchemas;
            const queryParams = new URLSearchParams({
                integration: "HubSpot",
            });
            const resp = await api.get(
                `${URLS.serverUrl}${url}?${queryParams}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            if (resp.status === 200) {
                return resp.data.data;
            } else {
                return [];
            }
        },
        refetchOnWindowFocus: false,
    });

    // Query for properties based on selected schema
    const propertyValuesQuery = useQuery<CRMProperties[]>({
        queryKey: ["properties", selectedSchemaCompany],
        queryFn: async () => {
            const { url } = ContactsAPI.getProperties;
            const queryParams = new URLSearchParams({
                integration: "HubSpot",
                schema: selectedSchemaCompany,
            });
            const resp = await api.get(
                `${URLS.serverUrl}${url}?${queryParams}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            if (resp.status === 200) {
                const list = resp.data.data;
                // sort the list by name
                return list.sort((a: CRMProperties, b: CRMProperties) =>
                    a.name.localeCompare(b.name),
                );
            } else {
                return [];
            }
        },
        enabled: !!selectedSchemaCompany, // Only run query when schema is selected
        refetchOnWindowFocus: false,
    });

    // Query for properties based on selected schema
    const propertyIndividualsQuery = useQuery<CRMProperties[]>({
        queryKey: ["properties", selectedSchemaIndividual],
        queryFn: async () => {
            const { url } = ContactsAPI.getProperties;
            const queryParams = new URLSearchParams({
                integration: "HubSpot",
                schema: selectedSchemaIndividual,
            });
            const resp = await api.get(
                `${URLS.serverUrl}${url}?${queryParams}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            if (resp.status === 200) {
                const list = resp.data.data;
                // sort the list by name
                return list.sort((a: CRMProperties, b: CRMProperties) =>
                    a.name.localeCompare(b.name),
                );
            } else {
                return [];
            }
        },
        enabled: !!selectedSchemaIndividual, // Only run query when schema is selected
        refetchOnWindowFocus: false,
    });

    // Add this query alongside other queries in the main component
    const contractFrequencyQuery = useQuery({
        queryKey: ["contract-frequency"],
        queryFn: () => [
            {
                label: "Month",
                name: "month",
                group_name: "",
            },
            {
                label: "Year",
                name: "year",
                group_name: "",
            },
        ],
    });

    const form = useForm<z.infer<typeof importForm>>({
        defaultValues: importData ?? {
            company_schema_name: {
                key: "companies",
                name: "companies",
            },
            company_name: {
                key: "",
                name: "",
            },
            company_domain: {
                key: "",
                name: "",
            },
            company_image_url: {
                key: "",
                name: "",
            },
            company_contract_value: {
                key: "",
                name: "",
            },
            company_contract_frequency: {
                key: "",
                name: "",
            },
            company_plan_type: {
                key: "",
                name: "",
            },
            individual_schema_name: {
                key: "contacts",
                name: "contacts",
            },
            individual_first_name: {
                key: "",
                name: "",
            },
            individual_last_name: {
                key: "",
                name: "",
            },
            individual_email: {
                key: "",
                name: "",
            },
            individual_image_url: {
                key: "",
                name: "",
            },
            individual_contract_value: {
                key: "",
                name: "",
            },
            individual_contract_frequency: {
                key: "",
                name: "",
            },
            individual_plan_type: {
                key: "",
                name: "",
            },
        },
    });

    const [isLoading, setIsLoading] = useState(false);

    const submitMutation = useMutation({
        mutationFn: async (data: z.infer<typeof importForm>) => {
            setIsLoading(true); // Start loading
            const { url } = ContactsAPI.importCompanies;
            const requestData = {
                integration: "HubSpot",
                ...data,
            };
            const resp = await api.post(URLS.serverUrl + url, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            setIsLoading(false); // Stop loading
            if (resp.status === 200) {
                setOpen(true);
                setToastText("Data submitted successfully!");
                setToastSymbol(CheckCircledIcon);
            } else {
                setOpen(true);
                setToastText("Failed to import contacts");
                setToastSymbol(CrossCircledIcon);
            }
            window.location.reload(); // Force refresh the screen
        },
    });

    function onSubmit(data: z.infer<typeof importForm>) {
        submitMutation.mutate(data);
    }

    return (
        <Card className="pb-10 p-6 rounded-md mx-[6%] mb-3">
            {/* Schema Selection */}
            <Form {...form}>
                <form
                    onSubmit={exists ? undefined : form.handleSubmit(onSubmit)}
                    className="flex flex-col items-center"
                >
                    <div className="flex items-center justify-between w-full p-3 pt-0 text-[15px]">
                        <div className="font-semibold tracking-tight">
                            {exists
                                ? "Review the information imported from Hubspot."
                                : "Select Fields to map information from Hubspot to Assembly. We'll use this information to create company profiles."}
                        </div>
                        {!exists && (
                            <Button
                                type="submit"
                                className="text-md px-4 ml-3 bg-[#5B5BD6]"
                                disabled={
                                    !form.formState.isValid &&
                                    submitMutation.isPending &&
                                    isLoading
                                }
                            >
                                {isLoading ? <Loader /> : "Submit"}
                            </Button>
                        )}
                    </div>

                    <Separator
                        size={"4"}
                        mt="1"
                        mb="1"
                        style={{ width: "100%" }}
                    />


                    <div className="flex flex-row items-center gap-3 mt-5 justify-center">
                        <FormField
                            control={form.control}
                            name="company_schema_name"
                            render={({ field }) => (
                                <>
                                    <FormLabel>
                                        How do you represent companies in Hubspot?
                                    </FormLabel>
                                    <Select
                                        onValueChange={(value) =>
                                            setSelectedSchema(value)
                                        }
                                        value={selectedSchemaCompany}
                                        disabled={exists}
                                    >
                                        <SelectTrigger className="w-[180px] h-5 ">
                                            <SelectValue placeholder="Select a schema" />
                                        </SelectTrigger>
                                        <SelectContent className="">
                                            {schemasQuery.data?.map((schema) => (
                                                <SelectItem
                                                    key={schema.name}
                                                    value={schema.name}
                                                >
                                                    {schema.name}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </>
                            )}
                        />
                    </div>

                    {/* Only show the form if a schema is selected */}
                    {selectedSchemaCompany && (
                        <div>
                            <FormField
                                control={form.control}
                                name="company_name"
                                render={({ field }) => (
                                    <SchemaSelect
                                        label="Company Name"
                                        selectorDescription="Select the property which indicates name..."
                                        field={field}
                                        propertyValuesQuery={
                                            propertyValuesQuery
                                        }
                                        disabled={exists}
                                    />
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="company_domain"
                                render={({ field }) => (
                                    <SchemaSelect
                                        label="Company Domain"
                                        selectorDescription="Select the property which indicates domain..."
                                        field={field}
                                        propertyValuesQuery={
                                            propertyValuesQuery
                                        }
                                        disabled={exists}
                                    />
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="company_image_url"
                                render={({ field }) => (
                                    <SchemaSelect
                                        label="Company Image URL"
                                        selectorDescription="Select the property which indicates image URL..."
                                        field={field}
                                        propertyValuesQuery={
                                            propertyValuesQuery
                                        }
                                        disabled={exists}
                                    />
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="company_contract_value"
                                render={({ field }) => (
                                    <SchemaSelect
                                        label="Company Contract Value"
                                        selectorDescription="Select the property which indicates contract value..."
                                        field={field}
                                        propertyValuesQuery={
                                            propertyValuesQuery
                                        }
                                        disabled={exists}
                                    />
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="company_plan_type"
                                render={({ field }) => (
                                    <SchemaSelect
                                        label="Company Plan Type"
                                        selectorDescription="Select the property which indicates plan type..."
                                        field={field}
                                        propertyValuesQuery={
                                            propertyValuesQuery
                                        }
                                        disabled={exists}
                                    />
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="company_contract_frequency"
                                render={({ field }) => (
                                    <SchemaSelect
                                        label="Company Contract Frequency"
                                        selectorDescription="Select the property which indicates contract frequency..."
                                        field={field}
                                        propertyValuesQuery={
                                            contractFrequencyQuery
                                        }
                                        disabled={exists}
                                    />
                                )}
                            />
                        </div>
                    )}

                    <div className="flex flex-row items-center gap-3 mt-5 justify-center">
                        <FormField
                            control={form.control}
                            name="individual_schema_name"
                            render={({ field }) => (
                                <>
                                    <FormLabel>
                                        How do you represent contacts in
                                        Hubspot?
                                    </FormLabel>
                                    <Select
                                        onValueChange={(value) =>
                                            setSelectedSchemaIndividual(value)
                                        }
                                        value={selectedSchemaIndividual}
                                        disabled={exists}
                                    >
                                        <SelectTrigger className="w-[180px] h-5 ">
                                            <SelectValue placeholder="Select a schema" />
                                        </SelectTrigger>
                                        <SelectContent className="">
                                            {schemasQuery.data?.map(
                                                (schema) => (
                                                    <SelectItem
                                                        key={schema.name}
                                                        value={schema.name}
                                                    >
                                                        {schema.name}
                                                    </SelectItem>
                                                ),
                                            )}
                                        </SelectContent>
                                    </Select>
                                </>
                            )}
                        />
                    </div>

                    {/* Only show the form if a schema is selected */}
                    {selectedSchemaIndividual && (
                        <div>
                            <FormField
                                control={form.control}
                                name="individual_first_name"
                                render={({ field }) => (
                                    <SchemaSelect
                                        label="Contact First Name"
                                        selectorDescription="Select the property which indicates name..."
                                        field={field}
                                        propertyValuesQuery={
                                            propertyIndividualsQuery
                                        }
                                        disabled={exists}
                                    />
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="individual_last_name"
                                render={({ field }) => (
                                    <SchemaSelect
                                        label="Contact Last Name"
                                        selectorDescription="Select the property which indicates name..."
                                        field={field}
                                        propertyValuesQuery={
                                            propertyIndividualsQuery
                                        }
                                        disabled={exists}
                                    />
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="individual_email"
                                render={({ field }) => (
                                    <SchemaSelect
                                        label="Contact Email"
                                        selectorDescription="Select the property which indicates email..."
                                        field={field}
                                        propertyValuesQuery={
                                            propertyIndividualsQuery
                                        }
                                        disabled={exists}
                                    />
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="individual_image_url"
                                render={({ field }) => (
                                    <SchemaSelect
                                        label="Contact Image URL"
                                        selectorDescription="Select the property which indicates image URL..."
                                        field={field}
                                        propertyValuesQuery={
                                            propertyIndividualsQuery
                                        }
                                        disabled={exists}
                                    />
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="individual_contract_value"
                                render={({ field }) => (
                                    <SchemaSelect
                                        label="Contact Contract Value"
                                        selectorDescription="Select the property which indicates contract value..."
                                        field={field}
                                        propertyValuesQuery={
                                            propertyIndividualsQuery
                                        }
                                        disabled={exists}
                                    />
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="individual_plan_type"
                                render={({ field }) => (
                                    <SchemaSelect
                                        label="Contact Plan Type"
                                        selectorDescription="Select the property which indicates plan type..."
                                        field={field}
                                        propertyValuesQuery={
                                            propertyIndividualsQuery
                                        }
                                        disabled={exists}
                                    />
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="individual_contract_frequency"
                                render={({ field }) => (
                                    <SchemaSelect
                                        label="Individual Contract Frequency"
                                        selectorDescription="Select the contract frequency (Monthly or Annual)..."
                                        field={field}
                                        propertyValuesQuery={
                                            contractFrequencyQuery
                                        }
                                        disabled={exists}
                                    />
                                )}
                            />
                        </div>
                    )}
                </form>
            </Form>
        </Card>
    );
};

interface SchemaSelectProps {
    label: string;
    selectorDescription: string;
    propertyValuesQuery: UseQueryResult<CRMProperties[], Error>;
    field: ControllerRenderProps<
        z.infer<typeof importForm>,
        | "company_name"
        | "company_domain"
        | "company_image_url"
        | "company_contract_value"
        | "company_contract_frequency"
        | "company_plan_type"
        | "individual_first_name"
        | "individual_last_name"
        | "individual_email"
        | "individual_image_url"
        | "individual_contract_value"
        | "individual_contract_frequency"
        | "individual_plan_type"
    >;
    disabled: boolean;
}

const SchemaSelect: React.FC<SchemaSelectProps> = ({
    label,
    selectorDescription,
    field,
    propertyValuesQuery,
    disabled,
}) => {
    return (
        <FormItem className="flex flex-row gap-4 items-center my-2">
            <Card className="mt-2 px-1 h-8 min-w-[220px] shadow-none bg-gray-100 flex justify-center items-center outline-none rounded-lg">
                <Label className="text-sm text-center">{label}</Label>
            </Card>
            <ArrowRightIcon />
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        className="outline outline-[0.5px] outline-gray-300 min-w-[250px] hover:outline-iris10"
                        disabled={disabled}
                    >
                        {field.value?.name ? (
                            <>{field.value.name}</>
                        ) : (
                            <>+ Select {label.toLowerCase()}</>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="p-0" side="right" align="start">
                    <Command>
                        <CommandInput placeholder={selectorDescription} />
                        <CommandList>
                            <CommandEmpty>No results found.</CommandEmpty>
                            <CommandGroup>
                                {propertyValuesQuery.data?.map((property) => (
                                    <CommandItem
                                        key={property.name}
                                        value={JSON.stringify({
                                            key: property.name,
                                            name: property.label,
                                        })}
                                        onSelect={(value) => {
                                            const selectedValue =
                                                JSON.parse(value);
                                            field.onChange(selectedValue);
                                        }}
                                    >
                                        {`${property.label} (${property.name})`.slice(
                                            0,
                                            40,
                                        )}
                                        {`${property.label} (${property.name})`
                                            .length > 40 && "..."}
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
        </FormItem>
    );
};

export default SchemaSelect;
