import type {
    CustomerGroup,
    GetUserResponse,
    ScopeResponse,
    Teams,
} from "@/interfaces/serverData";
import { Filter } from "./Filter";
import FilterDropdown from "./FilterDropdown";
import { type FilterOption, FilterType, menuThenMenuSources, sources, sourcesFilterOptions } from "./constants";
import { useCallback, useState } from "react";
import { getFilterOption } from "@/utilities/methods";

interface FilterItemsProps {
    filters: Map<string, Set<string>>;
    setFilters: (filters: Map<string, Set<string>>) => void;
    topics: { color: string; label: string; value: string }[];
    categories: { color: string; name: string }[];
    users: GetUserResponse[];
    customerGroups: CustomerGroup[];
    teams: Teams[];
    savedViewFilters: Map<string, Set<string>>;
    isGeneralTeam: boolean;
    channels: Map<string, ScopeResponse[]>;
}

export const FilterItems: React.FC<FilterItemsProps> = ({
    filters,
    setFilters,
    topics,
    categories,
    users,
    customerGroups,
    teams,
    savedViewFilters,
    isGeneralTeam,
    channels,
}) => {
    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    const filterOptions: FilterOption[] = [
        getFilterOption("Assembly Bot", FilterType.DirectSelect, true),
        getFilterOption("Assignee", FilterType.MenuThenSelect, true),
        getFilterOption("Customer Group", FilterType.MenuThenSelect, true),
        getFilterOption("Tag", FilterType.MenuThenSelect, true),
        getFilterOption("Source", FilterType.MenuThenSelect, true),
        getFilterOption("Status", FilterType.MenuThenSelect, true),
        getFilterOption("Topic", FilterType.MenuThenSelect, true),
    ];
    if (isGeneralTeam) {
        filterOptions.push(getFilterOption("Team", FilterType.MenuThenSelect, true));
    }
    filterOptions.push(...sourcesFilterOptions)

    const handleFilterSelect = (type: string, value: string) => {
        return () => {
            const newFilters = new Map(filters); // Create a shallow copy of the filters Map
            let typeToStore = type
            // Open up another context menu for these sources
            if (menuThenMenuSources.includes(value)) {
                setActiveMenu(value)
                return
            }
            // Handle source type channels/integrations separately
            if (sources.map(source => source.value).includes(type)) {
                typeToStore = "Source"
                const [source, channelName] = value.split(" -- ");
                const sourcesStored = newFilters.get(typeToStore) ?? new Set();
                if (channelName === "All Channels" || channelName === "All Emails") {
                    if (sourcesStored?.has(value)) { // Remove the selected value
                        sourcesStored.delete(value);
                        if (sourcesStored.size === 0) {
                            newFilters.delete(typeToStore); // Remove filter if no values remain
                        } else {
                            newFilters.set(typeToStore, sourcesStored); // Update the type with new values
                        }
                    } else {
                        // Remove other existing filters for that source and then add All Channels
                        const filteredSources = Array.from(sourcesStored).filter(value => {
                            const [elemSource] = value.split(" -- ");
                            return elemSource !== source;
                        })
                        filteredSources.push(value)
                        newFilters.set(typeToStore, new Set(filteredSources));
                    }
                    setFilters(newFilters);
                    return;
                }
                if (sourcesStored.size === 1 && (Array.from(sourcesStored).some(item =>
                    item.includes("-- All Channels") || item.includes("-- All Emails")
                ))) {
                    newFilters.set(typeToStore, new Set([value]));
                    setFilters(newFilters);
                    return;
                }
            }

            if (newFilters.has(typeToStore)) {
                const currValues = new Set(newFilters.get(typeToStore)); // Shallow copy of the Set
                if (currValues.has(value)) {
                    currValues.delete(value); // Remove the selected value
                    if (currValues.size === 0) {
                        newFilters.delete(typeToStore); // Remove filter if no values remain
                    } else {
                        newFilters.set(typeToStore, currValues); // Update the type with new values
                    }
                } else {
                    currValues.add(value); // Add new value to the filter
                    newFilters.set(typeToStore, currValues); // Update the type with the new value set
                }
            } else {
                newFilters.set(typeToStore, new Set([value])); // Add new filter type if it doesn't exist
            }
            setFilters(newFilters);
        };
    };

    const handleMenuClick = useCallback(
        (menu: string) => () => {
            // Reset activeMenu when dropdown is closed
            setActiveMenu(menu);
        },
        [],
    );

    return (
        <>
            {Array.from(filters.entries()).map(([type, values]) => {
                return (
                    <div key={type}>
                        <Filter
                            type={type}
                            values={values} // Convert Set to array
                            filters={filters}
                            setFilters={setFilters}
                            categories={categories}
                            topics={topics}
                            users={users}
                            customerGroups={customerGroups}
                            teams={teams}
                            channels={channels}
                            isSavedViewFilter={savedViewFilters.has(type)}
                            filterOptions={filterOptions}
                        />
                    </div>
                );
            })}
            <FilterDropdown
                filters={filters}
                filterOptions={filterOptions}
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
                handleItemSelect={handleFilterSelect}
                handleMenuClick={handleMenuClick}
                categories={categories}
                topics={topics}
                users={users}
                teams={teams}
                customerGroups={customerGroups}
                channels={channels}
            />
        </>
    );
};
