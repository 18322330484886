import type {
    UploadedFile,
    UploadedFileWithMetadata,
} from "@/interfaces/serverData";
import { ListItemNode, ListNode } from "@lexical/list";
import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { Cross1Icon } from "@radix-ui/react-icons";
import { ReaderIcon } from "@radix-ui/react-icons";
import { LineBreakNode, ParagraphNode } from "lexical";
import { useEffect } from "react";
import { ExampleTheme } from "../../component/textEditor/Theme";
import Toolbar from "../../component/textEditor/Toolbar";
import { Button } from "../shadcn/ui/button";
import { Card } from "../shadcn/ui/card";
import { AIResponsePlugin } from "./AIResponsePlugin";
import ActionsPlugin from "./ActionsPlugin";
import EnterCommand from "./EnterCommand";
import FilesPlugin from "./FilePlugin";
import ImagesPlugin from "./ImagesPlugin";
import InternalMessageCommand from "./InternalMessages";
import { EmojiNode } from "./nodes/EmojiNode";
import { FileNode } from "./nodes/FileNode";
import { ImageNode } from "./nodes/ImageNode";

interface EditorProps {
    className?: string;
    handleSubmit: (
        markdwn: string,
        files: (UploadedFile | UploadedFileWithMetadata)[],
        isInternal: boolean,
    ) => void;
    enableAIResponse: boolean;
    ai_response: string;
    isToggled?: boolean;
    setIsToggled?: React.Dispatch<React.SetStateAction<boolean>>;
    aiResponseButton?: boolean;
    setIsSendDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    isSendDisabled: boolean;
    loading?: boolean;
    setKey?: React.Dispatch<React.SetStateAction<number>>;
    handleImageUpload: (src: string, altText: string) => void;
    handleFileUpload: (
        file_name: string,
        file_type: string,
        file_size: number,
        file: File,
    ) => void;
    handleDeleteFile: (
        fileToDelete: UploadedFile | UploadedFileWithMetadata,
    ) => void;
    uploadedFiles: (UploadedFile | UploadedFileWithMetadata)[];
    source: string;
}

export function Editor({
    className,
    handleSubmit,
    enableAIResponse,
    ai_response,
    isToggled,
    setIsToggled = () => {},
    aiResponseButton,
    isSendDisabled,
    setIsSendDisabled,
    setKey = () => {},
    loading = false,
    handleImageUpload,
    handleFileUpload,
    handleDeleteFile,
    uploadedFiles,
    source,
}: EditorProps) {
    useEffect(() => {
        console.log(uploadedFiles);
    }, [uploadedFiles]);
    const initialConfig = {
        namespace: "MyEditor",
        theme: ExampleTheme,
        onError: (error: unknown) => {
            console.error(error);
            throw error;
        },
        nodes: [
            HeadingNode,
            QuoteNode,
            ListNode,
            ListItemNode,
            EmojiNode,
            LineBreakNode,
            ParagraphNode,
            ImageNode,
            FileNode,
        ],
    };

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <div
                className={`relative flex flex-row justify-between w-full pb-2 gap-[50px] bg-white ${className || ""}`}
            >
                <div className="text-sm relative flex flex-col flex-1 gap-1 rounded-lg transition-all">
                    <Toolbar
                        handleImageUpload={handleImageUpload}
                        handleFileUpload={handleFileUpload}
                        source={source}
                    />
                    {enableAIResponse && (
                        <AIResponsePlugin
                            ai_response={ai_response}
                            isToggled={isToggled ?? false}
                            setIsSendDisabled={setIsSendDisabled}
                            setIsToggled={setIsToggled}
                            aiResponseButton={aiResponseButton ?? false}
                            setKey={setKey}
                        />
                    )}
                    <div className="max-h-32 overflow-scroll scrollbar-white">
                        <RichTextPlugin
                            contentEditable={
                                <ContentEditable className="w-full" />
                            }
                            placeholder={<div />}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                    </div>
                    <HistoryPlugin />
                    <AutoFocusPlugin />
                    <ClearEditorPlugin />
                    <ImagesPlugin handleFileUpload={handleImageUpload} />
                    <EnterCommand
                        onSubmit={(internalMessage: boolean) => {
                            const mrkdwn =
                                $convertToMarkdownString(TRANSFORMERS);
                            handleSubmit(
                                mrkdwn,
                                uploadedFiles,
                                internalMessage ?? false,
                            );
                        }}
                    />
                    <InternalMessageCommand
                        onSubmit={(internalMessage: boolean) => {
                            const mrkdwn =
                                $convertToMarkdownString(TRANSFORMERS);
                            handleSubmit(
                                mrkdwn,
                                uploadedFiles,
                                internalMessage ?? true,
                            );
                        }}
                    />
                    <ListPlugin />
                    <FilesPlugin handleFileUpload={handleFileUpload} />
                    <div className="flex items-center flex-wrap gap-1">
                        {uploadedFiles.map(
                            (file) =>
                                file.type === "image" && (
                                    <div
                                        key={(file as UploadedFile).alt}
                                        className="relative"
                                    >
                                        <div className="w-[100px] h-[100px] overflow-hidden relative m-1">
                                            <img
                                                src={(file as UploadedFile).src}
                                                alt={(file as UploadedFile).alt}
                                                style={{
                                                    maxWidth: "auto",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        </div>
                                        <Button
                                            onClick={() =>
                                                handleDeleteFile(
                                                    file as UploadedFile,
                                                )
                                            }
                                            className="absolute px-1 h-4 top-0 right-0 bg-[#5B5BD6]"
                                        >
                                            <Cross1Icon className="w-2.5 h-2.5" />
                                        </Button>
                                    </div>
                                ),
                        )}
                        {uploadedFiles.map(
                            (file) =>
                                file.type === "file" && (
                                    <div
                                        key={
                                            (file as UploadedFileWithMetadata)
                                                .file_name
                                        }
                                        className="relative "
                                    >
                                        <Card className="shadow-none border-none rounded-lg flex flex-row items-center gap-1 text-sm text-gray-600 text-center px-2 overflow-hidden relative m-1 flex items-center justify-center bg-gray-100">
                                            <ReaderIcon className="w-3.5 h-3.5" />
                                            <p>
                                                {
                                                    (
                                                        file as UploadedFileWithMetadata
                                                    ).file_name
                                                }
                                            </p>
                                            <Button
                                                onClick={() =>
                                                    handleDeleteFile(
                                                        file as UploadedFileWithMetadata,
                                                    )
                                                }
                                                variant="ghost"
                                                className="p-0.5"
                                            >
                                                <Cross1Icon className="w-2.5 h-2.5" />
                                            </Button>
                                        </Card>
                                    </div>
                                ),
                        )}
                    </div>
                    <ActionsPlugin
                        isSendDisabled={isSendDisabled}
                        setIsSendDisabled={setIsSendDisabled}
                        loading={loading}
                        handleSubmit={(isInternal: boolean) => {
                            const mrkdwn =
                                $convertToMarkdownString(TRANSFORMERS);
                            handleSubmit(mrkdwn, uploadedFiles, isInternal);
                        }}
                        uploadedFiles={uploadedFiles}
                    />
                    <FilesPlugin handleFileUpload={handleFileUpload} />
                </div>
            </div>
        </LexicalComposer>
    );
}
