import { Input } from "@/component/shadcn/ui/input";
import { API, TeamsAPI, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import { BusinessHoursType, type GetTeamResponse, type GetUserResponse } from "@/interfaces/serverData";
import {
    CheckCircledIcon,
    CrossCircledIcon,
    Pencil2Icon,
    PlusIcon,
} from "@radix-ui/react-icons";
import { Box, Skeleton, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { generateMembersColumns } from "./DataTable/columns";
import type { MembersRowState } from "./DataTable/constants";
import { MembersDataTable } from "./DataTable/members-data-table";

import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from "@/component/shadcn/ui/form";
import AssemblyToastProvider from "@/reusable_components/actions/ToastProvider";
import { AssemblyErrorMessage } from "@/reusable_components/loadingStates/ErrorMessage";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import BusinessHoursComponent from "./BusinessHoursComponent";

const FormSchema = z.object({
    teamName: z.string().min(2, {
        message: "Team name must be at least 2 characters.",
    }),
    teamDescription: z.string().optional(),
});

const Team = () => {
    const { id } = useParams<{ id: string }>();

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const [rowState, setRowState] = useState<Map<string, MembersRowState>>(
        new Map(),
    );
    const { data = [] } = useQuery({
        queryKey: ["members"],
        queryFn: () => fetchMembers(),
        refetchInterval: 30000, // refetch every 30 secs
        refetchOnWindowFocus: true,
    });

    const [teamData, setTeamData] = useState<GetTeamResponse | null>(null);

    useEffect(() => {
        const [url, method] = TeamsAPI.getTeam;
        api.get(`${URLS.serverUrl}${url}/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const teamInfo: GetTeamResponse = res.data.data;
                    setTeamData(teamInfo);
                    setSelectedMembersData(teamInfo.members);

                    const newRowState = new Map<string, MembersRowState>();
                    for (let i = 0; i < teamInfo.members.length; i++) {
                        const user: GetUserResponse = teamInfo.members[i];
                        newRowState.set(String(i), {
                            id: user.id,
                            name: `${user.first_name} ${user.last_name}`,
                            email: user.email,
                            username: user.username,
                            picture_url: user.picture_url,
                            role: user.user_role,
                        });
                    }
                    setRowState(newRowState);
                    setLoadingState(loadingTypes.loaded);
                } else {
                    setLoadingState(loadingTypes.error);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingState(loadingTypes.error);
            });
    }, [id]);

    const [selectedMembersData, setSelectedMembersData] = useState<
        GetUserResponse[]
    >([]);

    const addMemberToRowState = (member: string) => {
        const newMember = data.find((user) => user.id === member);
        if (newMember) {
            const newId = rowState.size.toString();
            setSelectedMembersData([...selectedMembersData, newMember]);
            updateRowState(newId, {
                id: newMember.id,
                name: `${newMember.first_name} ${newMember.last_name}`,
                email: newMember.email,
                username: newMember.username,
                picture_url: newMember.picture_url,
                role: newMember.user_role,
            });
        }
    };

    const updateRowState = (id: string, newState: Partial<MembersRowState>) => {
        setRowState((prevState) => {
            const newStateMap = new Map(prevState);
            const currentState = newStateMap.get(id) || {
                id: "",
                name: "",
                email: "",
                username: "",
                picture_url: "",
                role: "",
            };
            newStateMap.set(id, { ...currentState, ...newState });
            return newStateMap;
        });
    };

    const deleteRowState = (id: string) => {
        const member = rowState.get(id);
        if (member) {
            setSelectedMembersData(
                selectedMembersData.filter((x) => x.id !== member.id),
            );
        }
        setRowState((prevState) => {
            const newStateMap = new Map();
            let shiftKey = 0;
            for (const [key, value] of prevState) {
                if (key !== id) {
                    const num = Number(key) - shiftKey;
                    newStateMap.set(num.toString(), value); // shift all keys left by 1 to account for deletion
                } else {
                    shiftKey = 1;
                }
            }
            return newStateMap;
        });
    };

    useEffect(() => {
        console.log("selectedMembersData", selectedMembersData);
    }, [selectedMembersData]);

    useEffect(() => {
        console.log("rowState", rowState);
    }, [rowState]);

    const fetchMembers = async (): Promise<GetUserResponse[]> => {
        const response = await api.post(URLS.serverUrl + API.getAllUsers, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };

    const actions = {
        create: "create",
        update: "update",
    };

    const [toastText, setToastText] = useState<string>(
        "Team created successfully!",
    );

    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    type SaveTeamRequest = {
        name: string;
        description: string;
        member_ids: string[];
        action: string;
    };

    const saveTeam = async () => {
        const memberIds: string[] = [];
        for (const member of selectedMembersData) {
            memberIds.push(member?.id);
        }

        const requestData: SaveTeamRequest = {
            name: form.getValues().teamName,
            description: form.getValues().teamDescription ?? "",
            member_ids: memberIds,
            action: actions.create,
        };

        const [url] = TeamsAPI.updateTeam;

        api.patch(
            `${URLS.serverUrl}${url}/${teamData?.team.id ?? ""}`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
            .then((res) => {
                if (res.status === 200) {
                    setToastText("Team updated successfully!");
                    setIsEditMode(false);
                    setToastSymbol(CheckCircledIcon);
                    const teamTempData = teamData;
                    if (teamTempData) {
                        teamTempData.team.team_name = form.getValues().teamName;
                        teamTempData.team.description =
                            form.getValues().teamDescription ?? "";
                        setTeamData(teamTempData);
                    }
                    window.location.reload();
                } else {
                    setToastText("Failed to save team.");
                    setToastSymbol(CrossCircledIcon);
                }
            })
            .catch((err) => {
                setToastText("Failed to save team.");
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setToastOpen(true);
            });
    };

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            teamName: teamData?.team.team_name,
        },
    });

    useEffect(() => {
        if (teamData) {
            form.setValue("teamName", teamData.team.team_name);
            form.setValue("teamDescription", teamData.team.description);
        }
    }, [teamData]);

    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    return (
        <div>
            {loadingState === loadingTypes.loading ? (
                <Skeleton>
                    <Text>
                        {[...Array(6)].map((_, index) => (
                            <p
                                key={
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    index
                                }
                            >
                                {loremIpsum}
                            </p>
                        ))}
                    </Text>
                </Skeleton>
            ) : loadingState === loadingTypes.loaded ? (
                <Box mt={"5%"} ml={teamData?.team.team_name === "General" ? "24%" : "7%"} mr={teamData?.team.team_name === "General" ? "24%" : "7%"}>
                    <div className="flex flex-row gap-8">
                        <div>
                            <div className="flex flex-row justify-between">
                                <h2 className="text-2xl font-semibold">Team</h2>
                                <Button
                                    className="flex flex-row gap-2"
                                    size="sm"
                                    variant="ghost"
                                    onClick={() => setIsEditMode(!isEditMode)}
                                >
                                    {isEditMode ? "Cancel" : "Edit"}
                                    <Pencil2Icon className="w-4 h-4" />
                                </Button>
                            </div>
                            <p className="text-sm text-gray11 pb-8">
                                Manage your team.
                            </p>

                            <Form {...form}>
                                <form onSubmit={form.handleSubmit(saveTeam)}>
                                    <div className="flex flex-col gap-8">
                                        <div className="flex flex-col gap-2">
                                            <p className="text-sm font-semibold">
                                                Team Name
                                            </p>
                                            {isEditMode ? (
                                                <FormField
                                                    control={form.control}
                                                    name="teamName"
                                                    render={({ field }) => (
                                                        <FormItem>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="team name"
                                                                    {...field}
                                                                    defaultValue={
                                                                        teamData?.team
                                                                            .team_name
                                                                    }
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                            ) : (
                                                <p className="text-sm pl-2">
                                                    {teamData?.team.team_name}
                                                </p>
                                            )}
                                        </div>

                                        <div className="flex flex-col gap-2">
                                            <p className="text-sm font-semibold">
                                                Team Description
                                            </p>
                                            {isEditMode ? (
                                                <FormField
                                                    control={form.control}
                                                    name="teamDescription"
                                                    render={({ field }) => (
                                                        <FormItem>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="team description"
                                                                    {...field}
                                                                    defaultValue={
                                                                        teamData?.team
                                                                            .description
                                                                    }
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                            ) : (
                                                <p className="text-sm pl-2">
                                                    {teamData?.team.description}
                                                </p>
                                            )}
                                        </div>

                                        <div className="flex flex-col gap-2">
                                            <div className="flex flex-row gap-2 items-center justify-between">
                                                <p className="text-sm font-semibold">
                                                    Members
                                                </p>

                                                {isEditMode && (
                                                    <DropdownMenu>
                                                        <DropdownMenuTrigger>
                                                            <Button
                                                                className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                                                size="sm"
                                                                variant="outline"
                                                            >
                                                                Add Member
                                                                <PlusIcon />
                                                            </Button>
                                                        </DropdownMenuTrigger>
                                                        <DropdownMenuContent>
                                                            <FilterDropdownElement
                                                                key={"Assignee"}
                                                                type={"Assignee"}
                                                                filters={new Map()}
                                                                handleItemSelect={(
                                                                    type: string,
                                                                    value: string,
                                                                ) =>
                                                                    () => {
                                                                        if (
                                                                            type ===
                                                                            "Assignee"
                                                                        ) {
                                                                            console.log(
                                                                                value,
                                                                            );
                                                                            addMemberToRowState(
                                                                                value,
                                                                            );
                                                                        }
                                                                    }}
                                                                topics={[]}
                                                                users={data.filter(
                                                                    (user) =>
                                                                        !selectedMembersData.some(
                                                                            (
                                                                                selectedUser,
                                                                            ) =>
                                                                                selectedUser.id ===
                                                                                user.id,
                                                                        ),
                                                                )}
                                                                customerGroups={[]}
                                                                isSavedViewFilter={
                                                                    false
                                                                }
                                                            />
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>
                                                )}
                                            </div>

                                            {loadingState === loadingTypes.loaded && (
                                                <MembersDataTable
                                                    data={selectedMembersData}
                                                    columns={generateMembersColumns(
                                                        rowState,
                                                        updateRowState,
                                                        deleteRowState,
                                                        isEditMode,
                                                    )}
                                                />
                                            )}
                                        </div>
                                        {isEditMode && (
                                            <div className="flex flex-col items-end gap-2">
                                                <Button
                                                    className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                                    size="sm"
                                                    variant="outline"
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </Form>
                        </div>
                        {teamData?.team.team_name !== "General" &&
                            <div className="w-[650px] mt-2">
                                <BusinessHoursComponent type={BusinessHoursType.Team} id={id ?? ""} />
                            </div>
                        }
                    </div>
                </Box>
            ) : (
                <AssemblyErrorMessage className="mt-5 mx-20" />
            )}
            <AssemblyToastProvider
                open={toastOpen}
                setOpen={setToastOpen}
                toastText={toastText}
                ToastSymbol={ToastSymbol}
            />
        </div>
    );
};

export default Team;
