import type { ComposerFormProps } from "@liveblocks/react-ui/primitives";
import "./styles.css";
import { Card } from "@/component/shadcn/ui/card";
import { Editor } from "@/component/textEditor/Editor";
import { useApi } from "@/interfaces/api";
import type {
    HistoryResponse,
    PublishMessage,
    Ticket,
    UploadedFile,
    UploadedFileWithMetadata,
} from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import { CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import * as Toast from "@radix-ui/react-toast";
import { Flex } from "@radix-ui/themes";

import { API, URLS } from "@/constant";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import slackifyMarkdown from "slackify-markdown";

interface ComposerProps extends ComposerFormProps {
    originalTicket: Ticket;
    placeholder?: string;
    submit?: string;
    source_specific_id: string;
    source: string;
    update: boolean;
    setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    ai_response: string;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
}

export function MyComposer({
    originalTicket,
    className,
    placeholder = "Write a comment…",
    submit = "Send",
    source,
    source_specific_id,
    title,
    update,
    setUpdate,
    ai_response,
    refetchThreadData,
    ...props
}: ComposerProps) {
    const api = useApi();
    const queryClient = useQueryClient();

    const [loading, setLoading] = useState(false);

    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);
    useEffect(() => {
        authInfoRef.current = authInfo;
    }, [authInfo]);

    const [open, setOpen] = useState<boolean>(false);

    const [toastSuccess, setToastSuccess] = useState<boolean>(true);
    const [toastText, setToastText] = useState<string>("Message sent");
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const [isSendDisabled, setIsSendDisabled] = useState(false);
    function handleContentChange(content: string) {
        setIsSendDisabled(
            (content.trim().length ?? -1) > 0 || uploadedFiles?.length > 0,
        );
    }
    const [aiResponseButton, hideAIResponseButton] = useState(true);

    const [key, setKey] = useState<number>(0);

    const [uploadedFiles, setUploadedFiles] = useState<
        (UploadedFile | UploadedFileWithMetadata)[]
    >([]);
    const handleFileUpload = (
        file_name: string,
        file_type: string,
        file_size: number,
        file: File,
    ) => {
        setUploadedFiles((prevFiles) => [
            ...prevFiles,
            {
                file_name: file_name,
                file_type: file_type,
                file_size: file_size,
                file: file,
                type: "file",
            },
        ]);
    };

    const handleImageUpload = (src: string, altText: string) => {
        setUploadedFiles((prevFiles) => [
            ...prevFiles,
            { src: src, alt: altText, type: "image" },
        ]);
    };

    const handleDeleteFile = (
        fileToDelete: UploadedFile | UploadedFileWithMetadata,
    ) => {
        const newUploadedFiles = uploadedFiles.filter((file) => {
            if (file.type === "image") {
                return (
                    (file as UploadedFile).alt !==
                    (fileToDelete as UploadedFile).alt
                );
            }
            return true;
        });
        setUploadedFiles(newUploadedFiles);
        if (newUploadedFiles.length === 0) {
            setIsSendDisabled(false);
        }
    };

    useEffect(() => {
        if (ai_response !== undefined && ai_response.trim() !== "") {
            hideAIResponseButton(false);
        }
    }, [ai_response]);

    const [isToggled, setIsToggled] = useState(false);

    const sendMessage = ({
        isInternal,
        content,
        files,
    }: {
        isInternal: boolean;
        content: string;
        files: (UploadedFile | UploadedFileWithMetadata)[];
    }) => {
        if (!authInfoRef.current.isLoggedIn) {
            throw new Error("User not logged in");
        }

        const userId = authInfoRef.current.user?.userId;
        if (userId === undefined) {
            throw new Error("User ID not found");
        }

        if (source === "Slack" || source === "CommunitySlack") {
            content = slackifyMarkdown(content);
        }

        const images: UploadedFile[] = files?.filter(
            (file) => file.type === "image",
        ) as UploadedFile[];
        const fileMetadata: UploadedFileWithMetadata[] = files?.filter(
            (file) => file.type === "file",
        ) as UploadedFileWithMetadata[];

        const requestData: PublishMessage = {
            message: content,
            source: isInternal ? "InternalWeb" : source,
            user: userId,
            source_specific_id: isInternal
                ? originalTicket.id
                : source_specific_id,
            integration_id: originalTicket.integration_id,
            message_title: title,
            images: images,
            files: fileMetadata,
        };

        return api.post(URLS.serverUrl + API.publishMessage, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        });
    };

    const publishMessageMutation = useMutation({
        mutationFn: sendMessage,
        onSuccess: () => {
            setToastText("Message sent");
            setToastSymbol(CheckCircledIcon);
            setToastSuccess(true);
            setOpen(true);
            queryClient.invalidateQueries({ queryKey: ["threadData"] });
            setTimeout(() => {
                setUpdate(!update);
                setLoading(false);
                refetchThreadData();
            }, 2000);
        },
        onError: (error) => {
            setToastText(
                "Oops! Something's wrong. Please try again at a later time.",
            );
            setLoading(false);
            setToastSymbol(CrossCircledIcon);
            setToastSuccess(false);
            setOpen(true);
            console.error("Error publishing message:", error);
        },
        onSettled: () => {
            setLoading(false);
            setIsSendDisabled(false);
            setUploadedFiles([]);
        },
    });

    const handleSend = (
        mrkdwn: string,
        files: (UploadedFile | UploadedFileWithMetadata)[],
        isInternal = false,
    ) => {
        if (mrkdwn === undefined || !isSendDisabled) {
            return;
        }

        setLoading(true);
        publishMessageMutation.mutate({ content: mrkdwn, files, isInternal });
    };

    return (
        <>
            <Toast.Provider swipeDirection="right">
                <Toast.Root
                    className="ToastRoot"
                    open={open}
                    onOpenChange={setOpen}
                >
                    <Toast.Title className="ToastTitle">
                        <Flex direction={"row"} align={"center"} gap="2">
                            <ToastSymbol
                                color={toastSuccess ? "green" : "red"}
                            />
                            {toastText}
                        </Flex>
                    </Toast.Title>
                </Toast.Root>
                <Toast.Viewport className="ToastViewport" />
            </Toast.Provider>

            <div className="flex flex-col">
                <Card className="focus-within:shadow-md focus-within:outline-0.5 focus-within:outline-offset-0 flex flex-row px-2 py-1 mt-4 mb-2 shadow-sm shadow-[#f3f4f6] color-[#f3f4f6] border relative">
                    <Editor
                        className="max-h-80 overflow-scroll scrollbar-white"
                        enableAIResponse={true}
                        handleSubmit={handleSend}
                        ai_response={ai_response}
                        isToggled={isToggled}
                        setIsSendDisabled={setIsSendDisabled}
                        isSendDisabled={isSendDisabled}
                        setIsToggled={setIsToggled}
                        aiResponseButton={aiResponseButton}
                        setKey={setKey}
                        loading={loading}
                        handleFileUpload={handleFileUpload}
                        handleImageUpload={handleImageUpload}
                        handleDeleteFile={handleDeleteFile}
                        uploadedFiles={uploadedFiles}
                        source={source}
                    />
                </Card>
            </div>
        </>
    );
}
