import { Card, CardContent } from "@/component/shadcn/ui/card";
import { API, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    SaveNotificationSettingsResponse,
    ScopeResponse,
} from "@/interfaces/serverData";
import {
    ChevronDownIcon,
    CrossCircledIcon,
    Pencil2Icon,
} from "@radix-ui/react-icons";
import {
    Badge,
    Button,
    DataList,
    Dialog,
    Flex,
    Select,
    Skeleton,
    Switch,
    Text,
} from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import type React from "react";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { ReactComponent as SlackSvg } from "../../images/integrations/slack.svg";

import { cn } from "@/lib/utils";
import { Check } from "lucide-react";

import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";

interface SlackAlertsProps {
    enabled: boolean;
    channel: ScopeResponse | undefined;
    loadingState: loadingTypes;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    refetch: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<any, Error>>;
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    setToastOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setToastText: React.Dispatch<React.SetStateAction<string>>;
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType>>;
    timerRef: React.MutableRefObject<number>;
    inheritedFromOrg: boolean;
    teamID?: string;
}

const SlackAlerts: React.FC<SlackAlertsProps> = ({
    enabled,
    channel,
    loadingState,
    refetch,
    isOpen,
    onOpenChange,
    setToastOpen,
    setToastSymbol,
    setToastText,
    timerRef,
    inheritedFromOrg,
    teamID,
}) => {
    const [slackChannel, setSlackChannel] = useState<ScopeResponse | undefined>(
        channel,
    );
    const [slackEnabled, setSlackEnabled] = useState<boolean>(enabled);
    const [savedSlackChannel, setSavedSlackChannel] = useState<
        ScopeResponse | undefined
    >(channel);
    const [savedSlackEnabledValue, setSavedSlackEnabledValue] =
        useState<boolean>(enabled);
    const [loading, setLoading] = useState<loadingTypes>(loadingState);

    const api = useApi();

    // Fetch the Slack scopes using useQuery
    const [cursor, setCursor] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const [scopeData, setScopeData] = useState<ScopeResponse[]>([]);
    const [scopeLoading, setScopeLoading] = useState<boolean>(false);

    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

    const fetchSlackChannels = useCallback(() => {
        const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
        const requestData = {
            type: "Slack",
            cursor: cursor,
        };
        api.post(serverUrl + API.getScopesPaginated, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const channelData = res.data.data.scopes;
                const newCursor = res.data.data.cursor;
                setScopeData((prevChannels) => [
                    ...prevChannels,
                    ...channelData,
                ]);
                setCursor(newCursor);
                setHasMore(!!newCursor);
                setLoading(loadingTypes.loaded);
            })
            .catch((res) => {
                setLoading(loadingTypes.error);
                console.log(res);
            });
    }, [api, cursor]);

    useEffect(() => {
        if (hasMore) {
            fetchSlackChannels();
        }
    }, [fetchSlackChannels, hasMore]);

    useEffect(() => {
        setSlackEnabled(enabled);
        setSavedSlackEnabledValue(enabled);
        setSlackChannel(channel);
        setSavedSlackChannel(channel);
        setLoading(loadingTypes.loaded);
    }, [loadingState, channel, enabled]);

    const channelOptions = scopeData || [];

    function saveSettings() {
        const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
        const requestData: SaveNotificationSettingsResponse = {
            enabled: slackEnabled,
            metadata: { channel: slackChannel }, // Send full object here
        };
        if (teamID) {
            requestData.team_id = teamID;
        }
        api.post(
            `${serverUrl}${API.saveNotificationSettings}/Slack`,
            requestData,
            {
                headers: { "Content-Type": "application/json" },
            },
        )
            .then((res) => {
                if (res.status === 200) {
                    const slackResData = res.data.data;
                    setSlackEnabled(slackResData.enabled);
                    setSavedSlackEnabledValue(slackResData.enabled);
                    setSavedSlackChannel(slackResData.channel);
                    setLoading(loadingTypes.loaded);
                    refetch();
                    setToastText("Updated Settings!");
                } else {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                }
            })
            .catch((res) => {
                setLoading(loadingTypes.error);
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setToastOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setToastOpen(true);
                }, 100);
            });
    }

    return (
        <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
            <Card className={`py-6 px-2 ${inheritedFromOrg && "bg-muted"}`}>
                <CardContent>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-row items-center justify-between">
                            <div className="flex flex-row items-center gap-1">
                                <SlackSvg className="max-h-5 max-w-5" />
                                <h2 className="text-md font-medium">
                                    Slack Notifications
                                </h2>
                            </div>

                            {!inheritedFromOrg && (
                                <Dialog.Trigger className="self-end justify-self-end">
                                    <Button className="iris text-white">
                                        <Pencil2Icon />
                                        Edit
                                    </Button>
                                </Dialog.Trigger>
                            )}
                        </div>
                        {savedSlackEnabledValue && savedSlackChannel && (
                            <Card
                                className={`mx-6 ${inheritedFromOrg && "bg-muted"}`}
                            >
                                <CardContent className="py-6 px-6 ">
                                    <DataList.Root>
                                        <DataList.Item align="center">
                                            <DataList.Label minWidth="88px">
                                                Status
                                            </DataList.Label>
                                            <DataList.Value>
                                                <Badge
                                                    color="jade"
                                                    variant="soft"
                                                    radius="full"
                                                >
                                                    Enabled
                                                </Badge>
                                            </DataList.Value>
                                        </DataList.Item>
                                        <DataList.Item align="center">
                                            <DataList.Label minWidth="88px">
                                                Notification Channel
                                            </DataList.Label>
                                            <DataList.Value>
                                                <p className="text-iris11">{`#${savedSlackChannel.name}`}</p>
                                            </DataList.Value>
                                        </DataList.Item>
                                    </DataList.Root>
                                </CardContent>
                            </Card>
                        )}
                    </div>
                </CardContent>
            </Card>

            <Dialog.Content style={{ maxWidth: 450 }}>
                <Dialog.Title>Slack Notification Settings</Dialog.Title>
                <Flex direction="column" gap="4">
                    <Flex align="center" justify="between">
                        <Text>Enable Slack Notifications</Text>
                        <Switch
                            checked={slackEnabled}
                            onCheckedChange={(value) => setSlackEnabled(value)}
                        />
                    </Flex>
                    {slackEnabled &&
                        (scopeLoading ||
                            loadingState === loadingTypes.loading) && (
                            <Skeleton>
                                <Select.Root>
                                    <Select.Trigger
                                        className="w-full"
                                        placeholder="Select Slack channel"
                                    />
                                    <Select.Content />
                                </Select.Root>
                            </Skeleton>
                        )}

                    {slackEnabled &&
                        !scopeLoading &&
                        loadingState === loadingTypes.loaded && (
                            <Popover
                                open={popoverOpen}
                                onOpenChange={setPopoverOpen}
                            >
                                <PopoverTrigger asChild>
                                    <Button
                                        aria-expanded={popoverOpen}
                                        variant="outline"
                                        color="gray"
                                        className="h-9 text-sm"
                                    >
                                        {slackChannel?.name ??
                                            "Select Slack channel..."}
                                        <ChevronDownIcon />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent className="w-full p-0">
                                    <Command>
                                        <CommandInput
                                            placeholder="Search framework..."
                                            className="h-9"
                                        />
                                        <CommandList>
                                            <CommandEmpty>
                                                No framework found.
                                            </CommandEmpty>
                                            <CommandGroup>
                                                {channelOptions.map(
                                                    (framework) => (
                                                        <CommandItem
                                                            key={framework.key}
                                                            value={
                                                                framework.name
                                                            }
                                                            onSelect={(
                                                                currentValue,
                                                            ) => {
                                                                const selectedChannel =
                                                                    channelOptions.find(
                                                                        (channel: {
                                                                            name: string;
                                                                        }) =>
                                                                            channel.name ===
                                                                            currentValue,
                                                                    );
                                                                setSlackChannel(
                                                                    selectedChannel,
                                                                );
                                                            }}
                                                        >
                                                            {framework.name}
                                                            <Check
                                                                className={cn(
                                                                    "ml-auto",
                                                                    slackChannel?.name ===
                                                                        framework.name
                                                                        ? "opacity-100"
                                                                        : "opacity-0",
                                                                )}
                                                            />
                                                        </CommandItem>
                                                    ),
                                                )}
                                            </CommandGroup>
                                        </CommandList>
                                    </Command>
                                </PopoverContent>
                            </Popover>
                            // <Select.Root
                            //     value={slackChannel?.name ?? ""}
                            //     onValueChange={(name) => {
                            //         const selectedChannel = channelOptions.find(
                            //             (channel: { name: string }) =>
                            //                 channel.name === name,
                            //         );
                            //         setSlackChannel(selectedChannel);
                            //     }}
                            // >
                            //     <Select.Trigger
                            //         className="w-full"
                            //         placeholder="Select Slack channel"
                            //     />
                            //     <Select.Content>
                            //         {channelOptions.map(
                            //             (channel: {
                            //                 name: string;
                            //                 key: string;
                            //             }) => {
                            //                 return (
                            //                     <Select.Item
                            //                         key={channel.key}
                            //                         value={channel.name}
                            //                     >
                            //                         #{channel.name}
                            //                     </Select.Item>
                            //                 );
                            //             },
                            //         )}
                            //     </Select.Content>
                            // </Select.Root>
                        )}

                    {loading === loadingTypes.loaded && (
                        <Flex justify="end" gap="3" mt="4">
                            <Dialog.Close>
                                <Button
                                    className="iris text-white"
                                    onClick={saveSettings}
                                >
                                    Save
                                </Button>
                            </Dialog.Close>
                        </Flex>
                    )}
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    );
};

export default SlackAlerts;
