import { Component } from "react";

interface TimerProps {
    startTime: Date;
}

interface TimerState {
    elapsed: number;
}

class ForwardTimer extends Component<TimerProps, TimerState> {
    intervalId: NodeJS.Timeout | null = null;

    constructor(props: TimerProps) {
        super(props);

        const currDate = new Date();
        const elapsed = currDate.getTime() - props.startTime.getTime();

        this.state = {
            elapsed: elapsed,
        };
    }

    componentDidMount() {
        this.intervalId = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    tick() {
        const currDate = new Date();
        this.setState({
            elapsed: currDate.getTime() - this.props.startTime.getTime(),
        });
    }

    getDisplayTime() {
        const { elapsed } = this.state;

        const hours = Math.floor(elapsed / (60 * 60 * 1000));
        const mins = Math.floor((elapsed % (60 * 60 * 1000)) / (60 * 1000));
        const secs = Math.floor((elapsed % (60 * 1000)) / 1000);

        if (hours > 0) {
            return `${hours}h ${mins}m`;
        } else if (mins > 0) {
            return `${mins}m ${secs}s`;
        } else {
            return `${secs}s`;
        }
    }

    render() {
        const displayTime = this.getDisplayTime();

        return <span>{displayTime}</span>;
    }
}

export default ForwardTimer;
