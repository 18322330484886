import { Button } from "@/component/shadcn/ui/button";
import { Command, CommandGroup, CommandItem, CommandList } from "@/component/shadcn/ui/command";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/component/shadcn/ui/dropdown-menu";
import type { Account } from "@/interfaces/serverData";
import { CheckIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { ImageIcon } from "lucide-react";
import { useEffect, useState } from "react";

interface CompaniesDropdownProps<TData> {
    selectedCompany: Account | undefined
    companies: Account[]
    toggleCompany: (company: Account, customerCompany: Account | undefined) => void,
    orgID?: string;
}

export function CompaniesDropdown<TData>({
    selectedCompany,
    companies,
    toggleCompany,
    orgID
}: CompaniesDropdownProps<TData>) {
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredCompanies, setFilteredCompanies] = useState<Account[]>(companies);

    const handleSearchChange = (event: { target: { value: string } }) => {
        const searchVal = event.target.value.toLowerCase();
        setSearchQuery(searchVal);

        if (searchVal.trim() === "") {
            // If the search query is empty, reset to the full list
            setFilteredCompanies(companies);
        } else {
            // Filter the queries based on the search value
            setFilteredCompanies(
                companies.filter((company) =>
                    (company.name)
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    (company.domain)
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                ),
            );
        }
    };

    useEffect(() => {
        if (searchQuery.trim() === "") {
            // If the search query is empty, reset to the full list
            setFilteredCompanies(companies);
        } else {
            // Filter the queries based on the search value
            setFilteredCompanies(
                companies.filter((company) =>
                    (company.name)
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    (company.domain)
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                ),
            );
        }
    }, [companies])

    const getBadgeForCompany = (company: Account) => {
        return <div
            className="flex items-center gap-1"
        >
            {company.image_url !== "" &&
                company.image_url !== undefined ? (
                <div className="lb-avatar rounded w-5 h-5">
                    <img
                        className="lb-avatar-image"
                        src={company.image_url}
                        alt={company.name}
                    />

                    <span>{company.name ?? ""}</span>
                </div>
            ) : (
                <div className="lb-avatar rounded w-6 h-6">
                    <ImageIcon className="w-5 h-5 mx-1" />
                </div>
            )}
            <div className="flex flex-col items-start gap-1">
                {company.name !== "" &&
                    company.name !== undefined ? (
                    <p className="text-xs pl-1 font-semibold">
                        {company.name}
                    </p>
                ) : (
                    <p className="text-xs pl-1 font-semibold">
                        <i>Untitled</i>
                    </p>
                )}
            </div>
        </div>
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger
                asChild
                className="hover:bg-muted col-span-3 w-full"
                type="button"
            >
                <div className="w-full">
                    <Button
                        variant="ghost"
                        className="flex flex-wrap items-center gap-2 justify-start p-0 w-full data-[state=open]:bg-muted !h-auto p-1.5"
                        type="button"
                    >
                        {selectedCompany ? (
                            <div>
                                {getBadgeForCompany(selectedCompany)}
                            </div>
                        ) : (
                            "None"
                        )}
                        <Pencil2Icon />
                    </Button>
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                side="bottom"
                align="start"
                className="fixed w-[250px] p-0 bg-muted rounded-md shadow-lg"
            >
                <div className="p-2">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="w-full p-1.5 border rounded-md text-xs"
                    />
                </div>
                <div className="h-full max-h-[200px] overflow-y-auto">
                    <Command className="bg-muted pb-2">
                        <CommandList>
                            <CommandGroup>
                                {filteredCompanies.length >
                                    0 ? (
                                    filteredCompanies.map(
                                        (company) => {
                                            const isSelected = (selectedCompany?.id === company.id);
                                            return (
                                                <CommandItem
                                                    key={
                                                        company.id
                                                    }
                                                    onSelect={() => {
                                                        toggleCompany(company, selectedCompany)
                                                    }
                                                    }
                                                    className="py-1 hover:bg-secondary cursor-pointer text-xs"
                                                >
                                                    <div
                                                        className={`mr-2 flex h-3 w-3 items-center justify-center rounded-sm border border-primary ${isSelected
                                                            ? "bg-primary text-primary-foreground"
                                                            : "opacity-50"
                                                            }`}
                                                    >
                                                        {isSelected && (
                                                            <CheckIcon className="h-3 w-3" />
                                                        )}
                                                    </div>
                                                    {getBadgeForCompany(company)}
                                                </CommandItem>
                                            );
                                        },
                                    )
                                ) : (
                                    <div className="p-2 text-xs text-gray-500">
                                        No results found
                                    </div>
                                )}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </div>
            </DropdownMenuContent>
        </DropdownMenu>)
}