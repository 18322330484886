import { TeamsDropdown } from "@/Ticket/TeamsDropdown";
import { Button } from "@/component/shadcn/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/component/shadcn/ui/dialog";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import { API, URLS } from "@/constant";
import { GradientPicker } from "@/design/GradientPicker";
import { useApi } from "@/interfaces/api";
import type { Teams } from "@/interfaces/serverData";
import { toggleTeamSelection } from "@/utilities/methods";
import {
    Cross2Icon,
    DotsHorizontalIcon,
    LinkBreak1Icon,
    Pencil2Icon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { useState } from "react";
import { TeamBadges } from "../TeamBadges";
import type { MembersRowState, TagsRowState } from "./constants";

interface DataTableRowActionsMembersProps<GetUserResponse> {
    id: string;
    rowState: MembersRowState | undefined;
    updateRowState: (id: string, newState: Partial<MembersRowState>) => void;
    deleteRowState?: (id: string) => void;
    isDelete?: boolean;
}

export function DataTableRowActionsMembers<TData>({
    id,
    rowState,
    updateRowState,
    deleteRowState = () => { },
    isDelete = false,
}: DataTableRowActionsMembersProps<TData>) {
    const api = useApi();
    const roles = ["Member", "Admin", "Owner"];

    function changeUserRole(role: string) {
        const requestData = {
            user_id: rowState?.id,
            new_role: role,
        };
        api.post(URLS.serverUrl + API.changeUserRole, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    updateRowState(id, {
                        role: role,
                    });
                }
            })
            .catch((res) => {
                console.log("failed to change user role");
            });
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                    <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[160px]">
                {roles
                    .filter((role) => role !== rowState?.role)
                    .map((role) => (
                        <DropdownMenuItem
                            onClick={() => changeUserRole(role)}
                            className="cursor-pointer hover:bg-muted"
                            key={role}
                        >
                            Make {role}
                        </DropdownMenuItem>
                    ))}
                {isDelete && (
                    <DropdownMenuItem
                        onClick={() => deleteRowState(id)}
                        className="cursor-pointer hover:bg-muted"
                    >
                        Delete
                    </DropdownMenuItem>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

interface DataTableRowActionsTopicsProps<GetUserResponse> {
    id: string;
    rowState: TagsRowState | undefined;
    saveTag: (id: string, newState: Partial<TagsRowState>) => void;
    deleteTag: (id: string) => void;
    teams: Teams[];
    teamID?: string;
}

export function DataTableRowActionsTopics<TData>({
    id,
    rowState,
    saveTag,
    deleteTag,
    teams,
    teamID,
}: DataTableRowActionsTopicsProps<TData>) {
    const defaultBadgeColor = "#9B9EF0";
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const [tagName, setTagName] = useState(rowState?.tag_name || "");
    const [tagDescription, setTagDescription] = useState(
        rowState?.description || "",
    );
    const [tagTeams, setTagTeams] = useState(rowState?.teams || []);
    const [tagColor, setTagColor] = useState(
        rowState?.colorSelected || defaultBadgeColor,
    );

    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [filteredTeams, setFilteredTeams] = useState<Teams[]>(teams);

    const handleSearchChange = (event: { target: { value: string } }) => {
        const searchVal = event.target.value.toLowerCase();
        setSearchQuery(searchVal);

        if (searchVal.trim() === "") {
            // If the search query is empty, reset to the full list
            setFilteredTeams(teams);
        } else {
            // Filter the queries based on the search value
            setFilteredTeams(
                teams.filter((team) =>
                    team.team_name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                ),
            );
        }
    };

    const submitEdits = () => {
        const rowStateInfo: Partial<TagsRowState> = {
            id: rowState?.id ?? "",
            colorSelected: tagColor,
            tag_name: tagName,
            description: tagDescription,
            teams: tagTeams,
        };
        saveTag(rowState?.id ?? "", rowStateInfo);
    };

    const removeTeam = () => {
        let newTagTeams: Teams[];
        // If it's a workspace tag, add all the teams except the current one
        if (tagTeams.length === 0) {
            const validTeams = [
                ...teams.filter((team) => team.id !== "workspace"),
            ];
            newTagTeams = validTeams.filter((team) => team.id !== teamID);
        } else {
            newTagTeams = tagTeams.filter((team) => team.id !== teamID);
        }
        setTagTeams(newTagTeams);
        const rowStateInfo: Partial<TagsRowState> = {
            teams: newTagTeams,
        };
        saveTag(rowState?.id ?? "", rowStateInfo);
    };

    const toggleTeam = (team: Teams, tagTeams: Teams[]) => {
        setTagTeams(toggleTeamSelection(team, tagTeams));
    };

    return (
        <>
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogContent className="inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50 max-h-full overflow-auto">
                    <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                        <DialogClose asChild>
                            <Button
                                type="button"
                                variant="ghost"
                                className="absolute top-4 right-4 hover:bg-muted"
                            >
                                <Cross2Icon />
                            </Button>
                        </DialogClose>
                        <div className="mx-12 flex flex-col gap-6">
                            <DialogHeader className="justify-left text-left items-left pb-5">
                                <DialogTitle>Edit Tag</DialogTitle>
                                <DialogDescription>
                                    {`Edit the existing tag: ${rowState?.tag_name}`}
                                </DialogDescription>
                            </DialogHeader>
                            <div className="grid grid-cols-4 items-center gap-4">
                                Tag Name
                                <Input
                                    className="col-span-3"
                                    value={tagName}
                                    onChange={(e) => setTagName(e.target.value)}
                                />
                            </div>
                            <div className="grid grid-cols-4 items-center gap-4">
                                Tag Description
                                <Input
                                    className="col-span-3"
                                    value={tagDescription}
                                    onChange={(e) =>
                                        setTagDescription(e.target.value)
                                    }
                                />
                            </div>
                            <div className="grid grid-cols-4 items-center gap-4">
                                Color
                                <GradientPicker
                                    id={"dialog"}
                                    background={tagColor ?? defaultBadgeColor}
                                    setColor={setTagColor}
                                />
                            </div>
                            <div className="grid grid-cols-4 items-center gap-4">
                                Teams
                                {teamID ? (
                                    <div className="flex flex-col gap-2 col-span-3">
                                        <Button
                                            variant="ghost"
                                            className="flex items-center gap-2 justify-start p-0 w-full data-[state=open]:bg-muted overflow-hidden"
                                            type="button"
                                            disabled={true}
                                        >
                                            <TeamBadges teams={tagTeams} />
                                        </Button>
                                        <div className="text-[12px] text-[#5B5BD6] italic">
                                            Edit teams from the Workspace Labels
                                            Page or use the Remove from
                                            Team/Delete option.
                                        </div>
                                    </div>
                                ) : (
                                    <TeamsDropdown
                                        selectedTeams={tagTeams}
                                        teams={teams}
                                        toggleTeam={toggleTeam}
                                        defaultIsWorkspace={true}
                                    />
                                )}
                            </div>
                            <DialogFooter className="justify-end text-end items-end">
                                <Button
                                    className="bg-iris9"
                                    type="submit"
                                    onClick={submitEdits}
                                >
                                    Save
                                </Button>
                            </DialogFooter>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
                <DialogContent className="inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50 max-h-full overflow-auto">
                    <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                        <DialogClose asChild>
                            <Button
                                type="button"
                                variant="ghost"
                                className="absolute top-4 right-4 hover:bg-muted"
                            >
                                <Cross2Icon />
                            </Button>
                        </DialogClose>
                        <div>
                            <DialogHeader className="justify-left text-left items-left pb-5">
                                <DialogTitle>
                                    Are you absolutely sure?
                                </DialogTitle>
                                <DialogDescription>
                                    {`This action cannot be undone. This will permanently delete the tag "${rowState?.tag_name}" from the Workspace.`}
                                </DialogDescription>
                            </DialogHeader>
                        </div>
                        <DialogFooter className="justify-end text-end items-end gap-3">
                            <Button
                                className="bg-iris9 py-0.5"
                                type="submit"
                                onClick={() => setDeleteDialogOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="bg-red-500 py-0.5"
                                type="submit"
                                onClick={() => deleteTag(rowState?.id ?? "")}
                            >
                                Delete
                            </Button>
                        </DialogFooter>
                    </div>
                </DialogContent>
            </Dialog>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant="ghost"
                        className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                    >
                        <DotsHorizontalIcon className="h-4 w-4" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-[200px]">
                    <DropdownMenuItem
                        onClick={() => {
                            setIsOpen(true);
                        }}
                        className="cursor-pointer hover:bg-muted text-xs flex items-center gap-2"
                    >
                        <Pencil2Icon className="w-3.5 h-3.5" />
                        Edit
                    </DropdownMenuItem>
                    {teamID ? (
                        tagTeams.length === 1 && tagTeams[0].id === teamID ? (
                            <DropdownMenuItem
                                onClick={() => setDeleteDialogOpen(true)}
                                className="cursor-pointer hover:bg-muted text-xs flex items-center gap-1.5"
                            >
                                <TrashIcon className="w-4 h-4" />
                                Delete
                            </DropdownMenuItem>
                        ) : (
                            <DropdownMenuItem
                                onClick={() => removeTeam()}
                                className="cursor-pointer hover:bg-muted text-xs flex items-center gap-1.5"
                            >
                                <LinkBreak1Icon className="w-3.5 h-3.5" />
                                Remove From Team
                            </DropdownMenuItem>
                        )
                    ) : (
                        <DropdownMenuItem
                            onClick={() => setDeleteDialogOpen(true)}
                            className="cursor-pointer hover:bg-muted text-xs flex items-center gap-1.5"
                        >
                            <TrashIcon className="w-4 h-4" />
                            Delete
                        </DropdownMenuItem>
                    )}
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    );
}

interface DataTableRowActionsViewsProps<ViewsRowState> {
    id: string;
    rowState: ViewsRowState | undefined;
    updateRowState: (id: string, newState: Partial<ViewsRowState>) => void;
    deleteRowState?: (id: string) => void;
}

export function DataTableRowActionsViews<TData>({
    id,
    rowState,
    updateRowState,
    deleteRowState = () => { },
}: DataTableRowActionsViewsProps<TData>) {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                    <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[160px]">
                <DropdownMenuItem
                    onClick={() => deleteRowState(id)}
                    className="cursor-pointer hover:bg-muted"
                >
                    Delete
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}