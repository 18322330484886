import { Badge } from "@/component/shadcn/ui/badge";
import { Button } from "@/component/shadcn/ui/button";
import { Command, CommandGroup, CommandItem, CommandList } from "@/component/shadcn/ui/command";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/component/shadcn/ui/dropdown-menu";
import type { Account } from "@/interfaces/serverData";
import { CheckIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { ImageIcon } from "lucide-react";
import { useEffect, useState } from "react";

interface ContactsDropdownProps<TData> {
    selectedContacts: Account[]
    customers: Account[]
    toggleContact: (customer: Account, contacts: Account[]) => void,
}

export function ContactsDropdown<TData>({
    selectedContacts,
    customers,
    toggleContact,
}: ContactsDropdownProps<TData>) {
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredCustomer, setFilteredCustomer] = useState<Account[]>(customers);

    const handleSearchChange = (event: { target: { value: string } }) => {
        const searchVal = event.target.value.toLowerCase();
        setSearchQuery(searchVal);

        if (searchVal.trim() === "") {
            // If the search query is empty, reset to the full list
            setFilteredCustomer(customers);
        } else {
            // Filter the queries based on the search value
            setFilteredCustomer(
                customers.filter((customer) =>
                    (customer.name)
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    (customer.domain)
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                ),
            );
        }
    };

    useEffect(() => {
        if (searchQuery.trim() === "") {
            // If the search query is empty, reset to the full list
            setFilteredCustomer(customers);
        } else {
            // Filter the queries based on the search value
            setFilteredCustomer(
                customers.filter((customer) =>
                    (customer.name)
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    (customer.domain)
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                ),
            );
        }
    }, [customers])

    const getBadgeForCustomer = (customer: Account, condensed: boolean) => {
        return <div
            className="flex items-center gap-2 py-0.5"
        >
            <div className="flex items-center gap-1">
                {customer.image_url !== "" &&
                    customer.image_url !== undefined ? (
                    <div className="lb-avatar rounded w-5 h-5">
                        <img
                            className="lb-avatar-image"
                            src={customer.image_url}
                            alt={customer.name}
                        />

                        <span>{customer.name ?? ""}</span>
                    </div>
                ) : (
                    <div className="lb-avatar rounded w-6 h-6">
                        <ImageIcon className="w-5 h-5 mx-1" />
                    </div>
                )}
                <div className="flex flex-col items-start gap-1">
                    {customer.name !== "" &&
                        customer.name !== undefined ? (
                        <p className="text-xs pl-1 font-semibold">
                            {customer.name}
                        </p>
                    ) : (
                        <p className="text-xs pl-1 font-semibold">
                            <i>Untitled</i>
                        </p>
                    )}
                </div>
            </div>

            {!condensed && customer.company &&
                <Badge className="text-muted-foreground flex items-center gap-1" variant="outline">
                    {customer.company.image_url !== "" &&
                        customer.company.image_url !== undefined ? (
                        <div className="lb-avatar rounded w-5 h-5">
                            <img
                                className="lb-avatar-image w-4 h-4 my-0.5"
                                src={customer.company.image_url}
                                alt={customer.company.name}
                            />
                        </div>
                    ) : (
                        <div className="lb-avatar rounded w-5 h-5">
                            <ImageIcon className="w-4 h-4 mx-1" />
                        </div>
                    )}
                    {customer.company.name}
                </Badge>}
        </div>
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger
                asChild
                className="hover:bg-muted col-span-3 w-full"
                type="button"
            >
                <div className="w-full">
                    <Button
                        variant="ghost"
                        className="flex flex-wrap items-center gap-2 justify-start p-0 w-full data-[state=open]:bg-muted !h-auto p-1.5"
                        type="button"
                    >
                        {(selectedContacts.length ?? 0) > 0 ? (
                            selectedContacts.map((customer: Account) => {
                                return getBadgeForCustomer(customer, true)
                            })
                        ) : (
                            "None"
                        )}
                        <Pencil2Icon />
                    </Button>
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                side="bottom"
                align="start"
                className="fixed w-[300px] p-0 bg-muted rounded-md shadow-lg"
            >
                <div className="p-2">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="w-full p-1.5 border rounded-md text-xs"
                    />
                </div>
                <div className="h-full max-h-[200px] overflow-y-auto">
                    <Command className="bg-muted pb-2">
                        <CommandList>
                            <CommandGroup>
                                {filteredCustomer.length >
                                    0 ? (
                                    filteredCustomer.map(
                                        (customer) => {
                                            const isSelected =
                                                selectedContacts.map((t: Account) => t.id).includes(customer.id);
                                            return (
                                                <CommandItem
                                                    key={
                                                        customer.id
                                                    }
                                                    onSelect={() => {
                                                        toggleContact(customer, selectedContacts)
                                                    }
                                                    }
                                                    className="py-1 hover:bg-secondary cursor-pointer text-xs"
                                                >
                                                    <div
                                                        className={`mr-2 flex h-3 w-3 items-center justify-center rounded-sm border border-primary ${isSelected
                                                            ? "bg-primary text-primary-foreground"
                                                            : "opacity-50"
                                                            }`}
                                                    >
                                                        {isSelected && (
                                                            <CheckIcon className="h-3 w-3" />
                                                        )}
                                                    </div>
                                                    {getBadgeForCustomer(customer, false)}
                                                </CommandItem>
                                            );
                                        },
                                    )
                                ) : (
                                    <div className="p-2 text-xs text-gray-500">
                                        No results found
                                    </div>
                                )}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </div>
            </DropdownMenuContent>
        </DropdownMenu>)
}