import type {
    DOMConversionMap,
    DOMConversionOutput,
    DOMExportOutput,
    LexicalEditor,
    LexicalNode,
    NodeKey,
    SerializedEditor,
    SerializedLexicalNode,
    Spread,
} from "lexical";

import { $applyNodeReplacement, DecoratorNode, createEditor } from "lexical";
import * as React from "react";
import { Suspense } from "react";

const FileComponent = React.lazy(() => import("./FileComponent"));

export interface FilePayload {
    fileName: string;
    fileType: string;
    fileSize: number;
    file?: File;
    caption?: LexicalEditor;
    key?: NodeKey;
}

function $convertFileElement(domNode: Node): null | DOMConversionOutput {
    const fileElement = domNode as HTMLElement;
    const fileName = fileElement.getAttribute("data-file-name") || "";
    const fileType = fileElement.getAttribute("data-file-type") || "";
    const fileSize = Number.parseInt(
        fileElement.getAttribute("data-file-size") || "0",
        10,
    );

    const node = $createFileNode({ fileName, fileType, fileSize });
    return { node };
}

export type SerializedFileNode = Spread<
    {
        fileName: string;
        fileType: string;
        fileSize: number;
        caption: SerializedEditor;
    },
    SerializedLexicalNode
>;

export class FileNode extends DecoratorNode<JSX.Element> {
    __fileName: string;
    __fileType: string;
    __fileSize: number;
    __caption: LexicalEditor;

    static getType(): string {
        return "file";
    }

    static clone(node: FileNode): FileNode {
        return new FileNode(
            node.__fileName,
            node.__fileType,
            node.__fileSize,
            node.__caption,
            node.__key,
        );
    }

    static importJSON(serializedNode: SerializedFileNode): FileNode {
        const { fileName, fileType, fileSize, caption } = serializedNode;
        const node = $createFileNode({
            fileName,
            fileType,
            fileSize,
        });
        const nestedEditor = node.__caption;
        const editorState = nestedEditor.parseEditorState(caption.editorState);
        if (!editorState.isEmpty()) {
            nestedEditor.setEditorState(editorState);
        }
        return node;
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement("div");
        element.setAttribute("data-file-name", this.__fileName);
        element.setAttribute("data-file-type", this.__fileType);
        element.setAttribute("data-file-size", this.__fileSize.toString());
        return { element };
    }

    static importDOM(): DOMConversionMap | null {
        return {
            div: (node: Node) => ({
                conversion: $convertFileElement,
                priority: 0,
            }),
        };
    }

    constructor(
        file_name: string,
        file_type: string,
        file_size: number,
        caption?: LexicalEditor,
        key?: NodeKey,
    ) {
        super(key);
        this.__fileName = file_name;
        this.__fileType = file_type;
        this.__fileSize = file_size;
        this.__caption =
            caption ||
            createEditor({
                nodes: [],
            });
    }

    exportJSON(): SerializedFileNode {
        return {
            fileName: this.__fileName,
            fileType: this.__fileType,
            fileSize: this.__fileSize,
            caption: this.__caption.toJSON(),
            type: "file",
            version: 1,
        };
    }

    decorate(): JSX.Element {
        return (
            <Suspense fallback={null}>
                <FileComponent
                    fileName={this.__fileName}
                    fileType={this.__fileType}
                    fileSize={this.__fileSize}
                    caption={this.__caption}
                />
            </Suspense>
        );
    }
}

export function $createFileNode({
    fileName,
    fileType,
    fileSize,
    caption,
    key,
}: FilePayload): FileNode {
    return $applyNodeReplacement(
        new FileNode(fileName, fileType, fileSize, caption, key),
    );
}

export function $isFileNode(
    node: LexicalNode | null | undefined,
): node is FileNode {
    return node instanceof FileNode;
}
