import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { IconEntry, Insight, InsightData, Query } from "@/interfaces/serverData";
import {
    QuestionMarkCircledIcon,
    TriangleDownIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { statuses } from "./constants";
import { getStatusIcon } from "@/utilities/methods";
import React from "react";

interface InsightStatusDropdownProps<TData> {
    insight: Insight;
    updateInsightState: (newState: Partial<Insight>) => void;
    userID: string;
}

export function InsightStatusDropdown<TData extends Query>({
    insight,
    updateInsightState,
    userID,
}: InsightStatusDropdownProps<TData>) {
    const api = useApi();
    const initialStatus = statuses.find(
        (status) => status.value === insight.status,
    );
    const [statusSelected, setStatusSelected] = useState<string>(
        initialStatus?.value ?? "Unknown",
    );

    const Icon = getStatusIcon(statusSelected ?? "Unknown");
    const statusesInfo: { label: string; value: string; icon: IconEntry }[] =
        [];
    for (const option of statuses) {
        const IconComponent = getStatusIcon(option.value ?? "Unknown");
        const iconEntry = {
            Component: IconComponent,
            props: {
                width: 15,
                height: 15,
                style: { marginRight: "8" },
            },
        };
        statusesInfo.push({
            label: option.label,
            value: option.value,
            icon: iconEntry,
        });
    }

    function saveStatus(value: string) {
        const requestData: InsightData = {
            existing_insight_id: insight.id,
            status: value,
            user_id: userID,
        };
        api.post(URLS.serverUrl + API.saveInsight, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setStatusSelected(value);
                updateInsightState({ status: value });
                console.log(`Updated insight status to ${value} successfully`);
            } else {
                console.log("Call to update insight status failed");
            }
        });
    }

    useEffect(() => {
        const initialStatus = statuses.find(
            (status) => status.value === insight.status,
        );
        setStatusSelected(initialStatus?.value ?? "Unknown");
    }, [insight.status]);

    return (
        <div className="text-sm flex items-center px-1 mt-0">
            <DropdownMenu>
                <DropdownMenuTrigger asChild className="hover:bg-muted">
                    <Button variant="ghost" className="flex p-1.5 py-0">
                        <Badge
                            color={"gray"}
                            size="2"
                            radius="medium"
                            variant="outline"
                            className="my-0 ring-[0.8px] text-gray-700 ring-[#E0E1E6]"
                        >
                            {statusSelected ? (
                                <Icon
                                    style={{
                                        height: "15px",
                                    }}
                                />
                            ) : (
                                <QuestionMarkCircledIcon
                                    style={{
                                        height: "15px",
                                    }}
                                />
                            )}
                            <span className="text-xs font-normal ">
                                {statusSelected ?? "Unknown"}
                            </span>
                            <TriangleDownIcon className="h-3 w-3" />
                        </Badge>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                    side="bottom"
                    align="end"
                    className="w-[160px] p-2"
                >
                    {statusesInfo.map((status) => (
                        <DropdownMenuItem
                            key={status.value}
                            className="py-1 hover:bg-muted cursor-pointer flex flex-row items-center gap-2"
                            onSelect={() => saveStatus(status.value)}
                        >
                            {React.createElement(
                                status.icon.Component,
                                status.icon.props,
                            )}
                            <p className="text-xs font-normal">
                                {status.label}
                            </p>
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}
